import { useRecoilCallback } from "recoil"
import { Quaternion, Vector3 } from "three"
import { PartTypeAPI } from "../../../common/api/Types"
import {
  areSameConnection
} from "../../components/main/DesignScreen/scene/part/parts/connector/utils/ConnectorUtils"
import { innerToOuter, isInner, outerToInner } from "../../utils/MarkerUtil"
import { Marker, PartTypeEnum, } from "../../utils/Types"
import { filterWithValue } from "../../../common/utils/utils"
import { initialDataType, PosAndRotType } from "../types"
import { partsSelector } from "./atoms"
import { ConnectedPartType, OppositePartType, PartConnectionType } from "./types"
import { v4 as uuidv4 } from "uuid"
import { ApiClient } from "../../../common/api/ApiClient"
import { FIRST_PART_INITIAL_MARKER } from "../../utils/PartUtils"

export const isInConnection = (
  { partA, partB, }: PartConnectionType,
  partId: string,
  markerName?: string
) => (
  ((partA.partId === partId
    && (!markerName || innerToOuter(partA.markerName) === innerToOuter(markerName))))
  || (partB.partId === partId
    && (!markerName || innerToOuter(partB.markerName) === innerToOuter(markerName)))
)

export const isInConnectionAndSameSourceMarker = (
  { partA, partB, }: PartConnectionType,
  partId: string,
  markerName: string,
  sourceMarkerName: string
) => {
  const partAMatches = partA.partId === partId
    && innerToOuter(partA.markerName) === innerToOuter(markerName)

  const partBMatches = partB.partId === partId
    && innerToOuter(partB.markerName) === innerToOuter(markerName)

  if (partAMatches) {
    const A = innerToOuter(partB.markerName)
    const B = innerToOuter(sourceMarkerName)
    const result = A === B
    return result
  } else if (partBMatches) {
    const A = innerToOuter(partA.markerName)
    const B = innerToOuter(sourceMarkerName)
    const result = A === B
    return result
  }

  return false
}

export const isMarkerConnected = (
  markerName: string,
  partId: string,
  allConnections: PartConnectionType[],
  sourceMarkerName?: string,
) => {
  if (sourceMarkerName) {
    return allConnections
      .some(c => isInConnectionAndSameSourceMarker(c, partId, markerName, sourceMarkerName,))
  } else {
    return allConnections.some(c => isInConnection(c, partId, markerName))
  }
}

export const isMarkerToMarkerConnected = (
  sourcePartId: string,
  sourceMarkerName: string,
  destinationPartId: string,
  destinationMarkerName: string,
  allConnections: PartConnectionType[]
) => {
  //console.log(`checking if ${sourcePartId} is connected to ${destinationPartId}
  //via ${sourceMarkerName} and ${destinationMarkerName}`)
  return allConnections.some(connection => {
    // Check source -> destination direction
    const sourceToDestination
      = connection.partA.partId === sourcePartId
      && connection.partB.partId === destinationPartId
      && innerToOuter(connection.partA.markerName) === innerToOuter(sourceMarkerName)
      && innerToOuter(connection.partB.markerName) === innerToOuter(destinationMarkerName)

    // Check destination -> source direction
    const destinationToSource
      = connection.partA.partId === destinationPartId
      && connection.partB.partId === sourcePartId
      && innerToOuter(connection.partA.markerName) === innerToOuter(destinationMarkerName)
      && innerToOuter(connection.partB.markerName) === innerToOuter(sourceMarkerName)

    return sourceToDestination || destinationToSource
  })
}


export const getConnectionMarkerName = (
  { partA, partB, }: PartConnectionType,
  partId: string,
) => {
  if (partA.partId === partId) {
    return partA.markerName
  } else if (partB.partId === partId) {
    return partB.markerName
  } else {
    return undefined
  }
}

export const getOtherSideOfTheConnection = (
  { partA, partB, }: PartConnectionType,
  partId: string
) => {
  if (partA.partId === partId) {
    return partB
  } else if (partB.partId === partId) {
    return partA
  }
  throw new Error("Part doesn't exist in the connection")
}

export const getOtherPartOfTheConnection = (
  { partA, partB, }: PartConnectionType,
  partId: string
) => {
  return partA.partId === partId ? partB.partId : partA.partId
}

export const areOpposite = (markerA: Marker, markerB: Marker) => {
  return markerB.name === `inner${markerA.oppositeOf}`
    && markerA.name === `inner${markerB.oppositeOf}`
}

export const useGetMarkerData = () => {
  return useRecoilCallback(
    ({ snapshot, }) => {
      return (partId: string, markerName: string) => {
        const part = snapshot.getLoadable(partsSelector({ id: partId, })).getValue()
        if (part?.type === PartTypeEnum.connector || part?.type === PartTypeEnum.segmentedTube) {
          return part.markers.find(m => outerToInner(m.name) === outerToInner(markerName))
        } else {
          return part?.marker
        }
      }
    }
  )
}

export const getNewPartOrigin = (
  newMarkerLength: number,
  actualMarkerLength: number,
  placeholderId: string,
  posAndRot: PosAndRotType,
  actualPartType?: PartTypeEnum,
  newPartIsTube?: boolean,
) => {
  let initialMarkerName
  let position = new Vector3()
  let rotation = new Quaternion()
  if (newPartIsTube
    || (newMarkerLength >= actualMarkerLength && actualPartType !== PartTypeEnum.tube)
  ) {
    initialMarkerName = innerToOuter(placeholderId)
    position = posAndRot.inner.pos
    rotation = posAndRot.inner.rot
  } else {
    initialMarkerName = outerToInner(placeholderId)
    position = posAndRot.outer.pos
    rotation = posAndRot.outer.rot
  }
  return { initialMarkerName, position, rotation, }
}

const getInitialMarkerData = (props: {
  placeholderId?: string,
  part: PartTypeAPI,
  connectionLength?: number,
  posAndRot: PosAndRotType,
  actualPartType?: PartTypeEnum,
}) => {
  let initialMarkerName
  let position = new Vector3()
  let rotation = new Quaternion()
  if (props.placeholderId) {
    let marker
    if (props.part.tube) {
      marker = props.part.connections.find(
        c => c.placeholderId === "inner1"
      )!
    } else {
      marker = props.part.connections.find(
        c => areSameConnection(c.placeholderId, props.placeholderId!)
      )!
    }
    if (props.connectionLength) {
      const origin = getNewPartOrigin(
        marker.iELength,
        props.connectionLength,
        props.placeholderId,
        props.posAndRot,
        props.actualPartType,
        props.part.tube)
      initialMarkerName = origin.initialMarkerName
      position = origin.position
      rotation = origin.rotation
    }
  } else {
    initialMarkerName = FIRST_PART_INITIAL_MARKER
    if (isInner(initialMarkerName)) {
      position = props.posAndRot.inner.pos
      rotation = props.posAndRot.inner.rot
    } else {
      position = props.posAndRot.outer.pos
      rotation = props.posAndRot.outer.rot
    }
  }
  return { initialMarkerName, position, rotation, }
}

export const getNewConnections = (props: {
  placeholderId?: string,
  newId: string,
  connectedPart?: ConnectedPartType,
  oppositePart?: OppositePartType,
}) => {
  const connection = props.placeholderId
    ? {
      newPart: {
        partId: props.newId,
        markerName: props.placeholderId,
      },
      otherPart: props.connectedPart!,
    } : undefined

  const connectionToOpposite = props.oppositePart
    ? {
      newPart: {
        partId: props.newId,
        markerName: props.oppositePart.oppositeMarkerName,
      },
      otherPart: props.oppositePart.oppositeConnectedPart,
    } : undefined

  return filterWithValue([connection, connectionToOpposite,])
}

export const generateNewPart = (props: {
  initialDataValue: initialDataType | undefined,
  part: PartTypeAPI,
  placeholderId?: string,
  posAndRot: PosAndRotType,
  connectionLength?: number,
  actualPartType?: PartTypeEnum,
  initialLength?: number,
  userRotation?: number,
}) => {
  const { initialDataValue, part, placeholderId,
    posAndRot, connectionLength, actualPartType,
    initialLength, userRotation, } = props
  const color = initialDataValue
    && initialDataValue.colors[part.colorId]
    && initialDataValue.colors[part.colorId].hex
    ? initialDataValue?.colors[part.colorId].hex
    : "#fff"
  const newId = part.preferedId || uuidv4()

  const { initialMarkerName, position, rotation, } = getInitialMarkerData({
    placeholderId,
    part,
    posAndRot,
    connectionLength,
    actualPartType,
  })

  return ApiClient.translateToPartValue(
    part, newId, position, rotation, color, initialMarkerName, initialLength, userRotation
  )
}