import { useContext, useEffect, useState } from "react"
import { CameraControls } from "./CameraControls"
import useCamera from "./useCamera"
import { MultiSelectContext } from "../multiselectProvider/MultiSelectProvider"

export const usePointerRotation = (cameraControls: CameraControls | undefined) => {
    const { enableMousePan, } = useCamera()
    const multiSelectContext = useContext(MultiSelectContext)
    const [isRotating, setIsRotating,] = useState(false)

    useEffect(() => {
        const isInArea = (event: MouseEvent) => {
            const targetArea = {
                x: 0,
                y: window.innerHeight - 150,
                width: 150,
                height: 150,
            }
            const withinXRange = event.clientX >= targetArea.x
                && event.clientX <= targetArea.x + targetArea.width
            const withinYRange = event.clientY >= targetArea.y
                && event.clientY <= targetArea.y + targetArea.height

            return withinXRange && withinYRange
        }

        const onMouseDown = (event: MouseEvent) => {
            if (isInArea(event) || event.buttons === 4 || event.button === 2) {
                setIsRotating(true)
                enableMousePan(false, cameraControls)
            }
        }

        const onMouseUp = (event: MouseEvent) => {
            if (isRotating) {
                enableMousePan(true, cameraControls)
                setIsRotating(false)
            }
        }

        document.addEventListener("pointerdown", onMouseDown, true)
        document.addEventListener("pointerup", onMouseUp, true)

        return () => {
            document.removeEventListener("pointerdown", onMouseDown, true)
            document.removeEventListener("pointerup", onMouseUp, true)
        }
    }, [cameraControls, multiSelectContext, isRotating,]) // Add state to dependency array
}