/* eslint-disable max-len */
import React from "react"
import { sizeStringAtom, boundingBoxAtom, unitSelector } from "../../../../state/scene/atoms"
import {
    Box3,
    Vector3,
    Matrix4
} from "three"

import { Text, Line } from "@react-three/drei"
import { useRecoilState, useRecoilValue } from "recoil"

const DimensionLabels: React.FC = () => {
    const boundingBox = useRecoilValue(boundingBoxAtom)
    const unit = useRecoilValue(unitSelector)
    const [sizeInfo, setSize,] = useRecoilState(sizeStringAtom)
    const size = new Vector3()
    boundingBox.box.getSize(size)

    const width = size.x
    const height = size.y
    const depth = size.z

    const center = new Vector3()
    boundingBox.box.getCenter(center)

    const offsetValue = Math.min(width, height, depth) * 0.3

    const textColor = "blue"

    const fontSize = Math.min(width, height, depth) * 0.3 // Font size based on the smallest dimension

    // Create a matrix to transform the positions based on the box's orientation
    const matrix = new Matrix4()
    matrix.setPosition(center)

    if (!boundingBox.show) {
        return null
    }

    const unitLabel = unit === "in" ? '"' : "cm"

    return (
        <>
            {sizeInfo.height > 0 && (
                <>
                    <Text
                        position={new Vector3(0, height / 2 + offsetValue, 0)
                            .applyMatrix4(matrix)
                            .toArray()}
                        fontSize={fontSize}
                        color="red" // Color for height (Y-axis)
                        anchorX="left"
                        rotation={[0, 0, Math.PI / 2,]}
                    >
                        {`${sizeInfo.height.toFixed(2)}${unitLabel}`}
                    </Text>
                    <Line
                        points={[
                            new Vector3(0, height / 2, 0).applyMatrix4(matrix)
                                .toArray(),
                            new Vector3(0, -height / 2, 0).applyMatrix4(matrix)
                                .toArray(),
                        ]}
                        color="red"
                    />
                </>
            )}
            {sizeInfo.width > 0 && (
                <>
                    <Text
                        position={new Vector3(width / 2 + offsetValue, 0, 0)
                            .applyMatrix4(matrix)
                            .toArray()}
                        fontSize={fontSize}
                        color="green" // Color for width (X-axis)
                        anchorX="left"
                        rotation={[0, 0, 0,]}
                    >
                        {`${sizeInfo.width.toFixed(2)}${unitLabel}`}
                    </Text>
                    <Line
                        points={[
                            new Vector3(-width / 2, 0, 0).applyMatrix4(matrix)
                                .toArray(),
                            new Vector3(width / 2, 0, 0).applyMatrix4(matrix)
                                .toArray(),
                        ]}
                        color="green"
                    />
                </>
            )}
            {sizeInfo.depth > 0 && (
                <>
                    <Text
                        position={new Vector3(0, 0, depth / 2 + offsetValue)
                            .applyMatrix4(matrix)
                            .toArray()}
                        fontSize={fontSize}
                        color="blue" // Color for depth (Z-axis)
                        anchorX="right"
                        rotation={[0, Math.PI / 2, 0,]} // Rotate 90 degrees around the Y-axis for depth
                    >
                        {`${sizeInfo.depth.toFixed(2)}${unitLabel}`}
                    </Text>
                    <Line
                        points={[
                            new Vector3(0, 0, -depth / 2).applyMatrix4(matrix)
                                .toArray(),
                            new Vector3(0, 0, depth / 2).applyMatrix4(matrix)
                                .toArray(),
                        ]}
                        color="blue"
                    />
                </>
            )}
        </>
    )
}

export default DimensionLabels