/* eslint-disable complexity */
/* eslint-disable no-continue */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-negated-condition */
/* eslint-disable no-useless-return */
/* eslint-disable default-case */
/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState, useCallback } from "react"
import { Vector3, LineSegments, Line as LineThree, BufferGeometry, Float32BufferAttribute, LineBasicMaterial, Color, Box3, Box3Helper, Mesh, Scene, MeshBasicMaterial, ArrowHelper, Camera, Matrix4, Raycaster, } from "three"
import { CameraControls, Html, Line, Text, Icosahedron, Billboard } from "@react-three/drei"
import { useComponentRegistry } from "../providers/multiselectProvider/useComponentMethods"
import { ScalerUIConfig, viewForScalerUI } from "../providers/multiselectProvider/MultiSelectProvider"
import { useThree } from "@react-three/fiber"
import useCamera from "../providers/cameraProvider/useCamera"
import tinycolor2 from "tinycolor2"
import { isSameDirection } from "../../builder/components/main/DesignScreen/utils/utilsThree"
import { MeshUtils } from "../utils/MeshUtils"
import { Arrow } from "@radix-ui/react-dropdown-menu"
import useGetDebugVariables from "./main/DesignScreen/utils/useGetDebugVariables"
import { messageUtils } from "./main/DesignScreen/scene/LowerRightMessages"
import { useLevaControls } from "../providers/debugProvider/useLevaControls"
import isMobile from "ismobilejs"
import { BackSide } from "three"
import { box3ToMesh } from "../utils/MeshUtils"
interface ScalerUILinesProps {
    views: viewForScalerUI[];

    cameraControls: React.MutableRefObject<CameraControls | any>;
    onScaleStart: (view: viewForScalerUI, position: Vector3, scaleDirection: "horizontal" | "vertical") => void;
    onScaleEnd: (view: viewForScalerUI, line: LineInfo, distance: number, scaleDirection: "horizontal" | "vertical" | null, callback: () => void, debug: boolean) => void;
    onScaleUpdate: (view: viewForScalerUI, position: Vector3) => void;
    userIsScaling: boolean;
    setUserIsScaling: (value: boolean) => void;
    setAutofocusMode: (value: boolean) => void;
    highlightedPartIds: React.MutableRefObject<string[]>;
    autofocusMode: boolean;
    config: ScalerUIConfig;
    scene: Scene;
    enabled: boolean;
    selectionBox: Box3;
    setDontResetSelection: (value: boolean) => void;
}

// Update the LineInfo type to include marker information
type MarkerInfo = {
    partId: string,
    marker: Mesh,  // The movable marker mesh
    distance: number,  // Distance from marker to line center
}

export type LineInfo = {
    points: Vector3[],
    isPerpendicularToNormal: boolean,
    viewType: "width" | "height",
    position: "left" | "right" | "top" | "bottom",
    markers: MarkerInfo[],  // Add this new field
    offsetDirection?: Vector3,
}


// Add this type for marker grouping
export type DirectionalMarkers = {
    normalAligned: {
        position: Vector3,
        partId: string,
        markerName: string,
        direction: Vector3,
    }[],
    inverseAligned: {
        position: Vector3,
        partId: string,
        markerName: string,
        direction: Vector3,
    }[],
}

// Add new state and types
interface ViewButtonInfo {
    position: Vector3;
    viewIndex: number;
    viewId: string;
    color: string;
}

interface OBBResult {
    box: Box3;
    startFace: Vector3[]; // 4 points defining start face
    endFace: Vector3[];   // 4 points defining end face
    leftFace: Vector3[];
    rightFace: Vector3[];
    width: number;
    height: number;
    depth: number;
    center: Vector3;
    transformation: Matrix4;
    mesh: Mesh;
}

interface OBBOptions {
    minWidth?: number;
    minHeight?: number;
    minDepth?: number;
    padding?: number;
}

interface MeasurementLines {
    startFace: {
        width: [Vector3, Vector3],  // Points for width line
        height: [Vector3, Vector3], // Points for height line
    };
    endFace: {
        width: [Vector3, Vector3],
        height: [Vector3, Vector3],
    };
}

type LineSet = {
    points: [Vector3, Vector3],
    position: "left" | "right" | "top" | "bottom",
    viewType: "width" | "height",
}



const ScalerUILines: React.FC<ScalerUILinesProps> = ({ views, cameraControls, config, enabled, scene, selectionBox, onScaleStart, onScaleUpdate, onScaleEnd, setUserIsScaling, userIsScaling, setAutofocusMode, autofocusMode, highlightedPartIds, setDontResetSelection, }) => {
    const debugBoxRef = useRef<Box3Helper | null>(null)
    const normalHelperRef = useRef<ArrowHelper | null>(null)
    const { getComponent, } = useComponentRegistry()
    const [activeView, setActiveView,] = useState<viewForScalerUI | null>(null)
    const [viewToClean, setViewToClean,] = useState<viewForScalerUI | null>(null)
    const [linePoints, setLinePoints,] = useState<LineInfo[]>([])
    const [isVisible, setIsVisible,] = useState(false)
    const [hoveredLineIndex, setHoveredLineIndex,] = useState<number | null>(null)
    const { gl, } = useThree()
    const originalMouseXYRef = useRef<{ x: number, y: number, } | null>(null)
    const originalCursorRef = useRef<string>("auto")
    const [activeLine, setActiveLine,] = useState<LineInfo | null>(null)
    const scaleTypeRef = useRef<"horizontal" | "vertical" | null>(null)
    const { drawVector3Point, } = useCamera()
    const { getVariables, } = useGetDebugVariables()
    const [hoveredOverViewButton, setHoveredOverViewButton,] = useState(false)

    const isAdmin = getVariables().isAdmin
    const autoFocusModeRef = useRef<boolean>(autofocusMode ?? false)

    const { showScalerNormals, showOrientedBoundingBox, logNormalLineSequences, } = useLevaControls()

    // Add new state and types
    const [viewButtons, setViewButtons,] = useState<ViewButtonInfo[]>([])

    // Calculate button positions for all views
    const calculateViewButtonPositions = useCallback(() => {
        const buttonInfos: ViewButtonInfo[] = views.map((view, index) => {
            // Create OBB for the view
            const viewDirectionalMarkers = generateDirectionalMarkers(view, scene, showScalerNormals)

            const obb = createOrientedBoundingBox(viewDirectionalMarkers, {
                minWidth: 0.02,
                minHeight: 0.02,
                minDepth: 0.02,
                padding: isMobile(window.navigator).any ? 0.125 * 2 : 0.2 * 2,
            })

            // Calculate center of left face
            const leftFaceCenter = new Vector3()
            obb.leftFace.forEach(point => leftFaceCenter.add(point))
            leftFaceCenter.divideScalar(4)

            // Add padding to move button away from the face
            const normal = view.normal.clone()

            return {
                position: leftFaceCenter,
                viewIndex: index,
                viewId: view.viewID,
                color: tinycolor2.random()
                    .darken(20)
                    .toHexString(),
            }
        })
        return buttonInfos
    }, [views,])

    useEffect(() => {
        if (hoveredOverViewButton) {
            //console.log("changing to pointer")
            gl.domElement.style.cursor = "pointer"
            setDontResetSelection(true)
        } else {
            //console.log("changing to default")
            gl.domElement.style.cursor = "default"
            setDontResetSelection(false)
        }

        return () => {
            gl.domElement.style.cursor = "default"
        }
    }, [hoveredOverViewButton,])

    const handleClick = (viewIndex: number) => {
        //console.log("handleClick", viewIndex)
        const viewToActivate = views[viewIndex]
        if (activeView === viewToActivate) {
            //console.log("same view")
            return
        }
        setViewToClean(activeView)
        const { duplicateLines, orderedView, } = processView(showOrientedBoundingBox, showScalerNormals, viewToActivate)
        setLinePoints(duplicateLines)
        setActiveView(orderedView)
        setIsVisible(true)
    }



    // Calculate button positions when views change
    useEffect(() => {
        const newViewButtons = calculateViewButtonPositions()
        if (newViewButtons.length > 1) {
            setViewButtons(newViewButtons)
        } else {
            setViewButtons([])
        }
    }, [views,])


    const calculateOffsetDirection = (
        linePoints: Vector3[],
        normal: Vector3,
        isPerpendicularToNormal: boolean,
        position: "left" | "right" | "top" | "bottom",
        viewCenter: Vector3  // Center point of the view/selection
    ): Vector3 => {
        // Get line center
        const lineCenter = new Vector3()
            .addVectors(linePoints[0], linePoints[1])
            .multiplyScalar(0.5)

        // Vector from view center to line center
        const centerToLine = new Vector3()
            .subVectors(lineCenter, viewCenter)
            .normalize()

        // Check if normal is pointing towards or away from the line
        const normalAlignment = centerToLine.dot(normal)

        if (isPerpendicularToNormal) {
            // For lines perpendicular to normal, use the normal direction
            const direction = normal.clone()

            // If normal is pointing away from line, flip it
            if (normalAlignment < 0) {
                direction.negate()
            }

            return direction
        } else {
            // For lines parallel to normal, get perpendicular direction
            const lineDirection = new Vector3()
                .subVectors(linePoints[1], linePoints[0])
                .normalize()

            const perpDirection = new Vector3()
                .crossVectors(lineDirection, normal)
                .normalize()

            // If normal is pointing away from line, flip the perpendicular direction
            if (normalAlignment < 0) {
                perpDirection.negate()
            }

            return perpDirection
        }
    }

    const pixelsTo3DUnits = (pixels: number, camera: Camera, targetZ = 0) => {
        // Get two points in screen space, separated by the pixel distance
        const startPoint = new Vector3()
        const endPoint = new Vector3()

        // Get the camera's position and target distance
        const cameraPosition = camera.position.clone()
        const targetPoint = new Vector3(0, 0, targetZ)
        const distanceToTarget = cameraPosition.distanceTo(targetPoint)

        // Convert to NDC space (-1 to +1)
        startPoint.x = (0 / gl.domElement.clientWidth) * 2 - 1
        startPoint.y = -(0 / gl.domElement.clientHeight) * 2 + 1
        startPoint.z = -1 // Use near plane

        endPoint.x = (pixels / gl.domElement.clientWidth) * 2 - 1
        endPoint.y = -(0 / gl.domElement.clientHeight) * 2 + 1
        endPoint.z = -1 // Use near plane

        // Unproject to get world space coordinates
        startPoint.unproject(camera)
        endPoint.unproject(camera)

        // Calculate the direction vectors
        const startDir = startPoint.sub(camera.position).normalize()
        const endDir = endPoint.sub(camera.position).normalize()

        // Scale the vectors to hit the target distance
        const startWorldPos = camera.position.clone().add(startDir.multiplyScalar(distanceToTarget))
        const endWorldPos = camera.position.clone().add(endDir.multiplyScalar(distanceToTarget))

        // Return the world space distance
        return startWorldPos.distanceTo(endWorldPos)
    }

    // Add new state for preview lines
    const [previewLines, setPreviewLines,] = useState<LineInfo[]>([])

    // Add new state for debug points
    const [debugPoints, setDebugPoints,] = useState<Vector3[]>([])

    // Add new state for the distance display
    const [scaleDistance, setScaleDistance,] = useState<number | null>(null)
    const [scalePosition, setScalePosition,] = useState<Vector3 | null>(null)

    // Add this helper function at the component level
    const findNormalLineSequences = (view: viewForScalerUI, cam: CameraControls, scene: Scene, debug: boolean) => {


        const normalLineSequences: string[][] = []
        const processedParts = new Set<string>()

        const color = tinycolor2.random().toHexString()

        // Helper to create debug arrow
        const createDebugArrow = (origin: Vector3, direction: Vector3, length = 1.5, debug: boolean) => {
            const arrow = new ArrowHelper(
                direction.normalize(),
                origin,
                length,
                color,
                0.02,
                0.02
            )
            if (debug) {
                scene.add(arrow)
            }
            return arrow
        }

        let castDirection: Vector3
        // Get camera's right and up directions to use as reference
        const cameraRight = new Vector3()
        const cameraUp = new Vector3()
        cam.camera.getWorldDirection(cameraRight)
        cameraRight.cross(new Vector3(0, 1, 0)).normalize() // Right vector
        cameraUp.copy(cameraRight).cross(cam.camera.getWorldDirection(new Vector3()))
            .normalize() // Up vector


        // Determine if normal needs to be flipped to point "right" or "up"
        const normal = view.normal.clone()
        const rightDotProduct = normal.dot(cameraRight)
        const upDotProduct = normal.dot(cameraUp)

        // Determine cast direction based on which alignment is stronger
        // Determine cast direction based on which alignment is stronger
        if (Math.abs(rightDotProduct) > Math.abs(upDotProduct)) {
            // Normal is more aligned with horizontal direction
            castDirection = rightDotProduct < 0 ? normal.negate() : normal
            view.scalingDirection = "horizontal"
        } else {
            // Normal is more aligned with vertical direction
            castDirection = upDotProduct > 0 ? normal.negate() : normal  // Changed < to >
            view.scalingDirection = "vertical"
        }

        debug && console.groupCollapsed("Normal Line Detection")
        debug && console.log("Scaling direction:", view.scalingDirection)
        debug && console.log("Cast direction:", castDirection)

        // Rest of the function remains the same, but use our new castDirection
        while (processedParts.size < view.uniqueIds.length) {
            // Find first unprocessed part from horizontal order
            const nextPart = view.partsOrder.find(
                (partId: string) => !processedParts.has(partId)
            )

            if (!nextPart) { break }

            const currentLine: string[] = [nextPart,]
            processedParts.add(nextPart)

            // Get marker position for raycast origin
            const marker = view.normalMarkers[nextPart]
            if (!marker) {
                debug && console.warn(`No marker found for part ${nextPart}`)
                continue
            }

            const origin = marker.getWorldPosition(new Vector3())

            // Create debug arrow
            if (debug) {
                const debugArrow = createDebugArrow(origin, castDirection, undefined, debug)
                debug && console.log(`Casting ray for part ${nextPart} from`, origin)
                setTimeout(() => {
                    scene.remove(debugArrow)
                }, 5000)
            }

            // Setup raycaster
            const raycaster = new Raycaster()
            raycaster.set(origin, castDirection)


            //we have to filter out top and bottom markers because sometimes they are beyond or after the part they connect to
            //so they can't be trusted to detetect a connecting part - therefore, we have to use the middle of their two markers

            // Get all potential intersections
            const allMarkerMeshes = Object.entries(view.markers)
                .filter(([partId,]) => partId !== nextPart) // Exclude current part's markers
                .flatMap(([_, meshes,]) => meshes.filter(mesh => {
                    const name = mesh.name.toLowerCase()
                    return !name.includes("top")
                        && !name.includes("bottom")
                        && !name.includes("aligmentPlane")
                        && name !== ""
                }))

            const intersects = raycaster.intersectObjects(
                allMarkerMeshes, false
            )

            debug && console.log("allMarkerMeshes being sent for intersection checks", allMarkerMeshes)
            debug && console.log("Intersects:", intersects)

            // Process intersections
            const intersectedParts = new Set<string>()
            intersects.forEach(intersection => {
                const partIdOfIntersectedPart = intersection.object.userData?.partId
                if (partIdOfIntersectedPart && !processedParts.has(partIdOfIntersectedPart)) {
                    intersectedParts.add(partIdOfIntersectedPart)
                    processedParts.add(partIdOfIntersectedPart)
                    currentLine.push(partIdOfIntersectedPart)
                }
            })

            debug && console.log("Found intersections with parts:", Array.from(intersectedParts))



            normalLineSequences.push(currentLine)
        }

        const filteredNormalLineSequences = normalLineSequences.filter(sequence => {
            // Skip arrays with only one part
            if (sequence.length === 1) {
                const component = getComponent(sequence[0])
                const partType = component?.getPartInfo()?.type
                // If it's a single connector part, filter it out
                if (partType === "connector_part_type") {
                    return false
                }
            }
            return true
        })

        //here we are filtering out when a connector is by itself in a sequence

        debug && console.log("Filtered normal lines:", filteredNormalLineSequences)
        debug && console.log("Final normal lines:", normalLineSequences)
        debug && console.groupEnd()

        return filteredNormalLineSequences
    }

    // Modify orderMarkersForView to include the normal line detection
    const orderMarkersForView = (view: viewForScalerUI, center: Vector3, cam: CameraControls, lines: LineInfo[], debug: boolean) => {
        // Add a map to store part types
        const partTypeMap = new Map<string, string>()

        const markerPositions: {
            partId: string,
            marker: Mesh,
            position: Vector3,
            partType?: string, // Add partType to the marker position info
        }[] = []

        // Use the markers object which is keyed by partId
        if (view.markers) {
            Object.entries(view.markers).forEach(([partId, meshes,]) => {
                if (Array.isArray(meshes)) {
                    // Get part type once per partId
                    const component = getComponent(partId)
                    const partType = component?.getPartInfo()?.type
                    if (partType) {
                        partTypeMap.set(partId, partType)
                    }

                    meshes.forEach(marker => {
                        if (marker instanceof Mesh) {
                            markerPositions.push({
                                partId,
                                marker,
                                position: marker.getWorldPosition(new Vector3()),
                                partType, // Add part type to marker position
                            })
                        }
                    })
                }
            })
        }

        // Store the part type map in the view for easy access
        view.partTypes = partTypeMap

        view.lines = lines
        const cameraMatrix = cam.camera.matrixWorldInverse


        // Create marker ID with format: partId:markerName
        const createMarkerId = (partId: string, marker: Mesh) =>
            `${partId}:${marker.name}`

        //search to see if the left line is perpendicular to the normal, if it is, then we need to order the markers differently
        const leftLine = lines.find(line => line.position === "left")
        if (leftLine && leftLine.isPerpendicularToNormal) {
            //order the markers horizontally, but start with the left line
            const horizontalOrder = [...markerPositions,].sort((a, b) => {
                const aPos = a.position.clone().applyMatrix4(cameraMatrix)
                const bPos = b.position.clone().applyMatrix4(cameraMatrix)
                return aPos.x - bPos.x
            })
            view.markersOrder = horizontalOrder.map(item => createMarkerId(item.partId, item.marker))
            view.partsOrder = Array.from(new Set(horizontalOrder.map(item => item.partId)))
            view.partsOrderBy = "leftToRight"
            view.scalingDirection = "horizontal"
        } else {
            // Sort vertically (top to bottom)
            const verticalOrder = [...markerPositions,].sort((a, b) => {
                const aPos = a.position.clone().applyMatrix4(cameraMatrix)
                const bPos = b.position.clone().applyMatrix4(cameraMatrix)
                return bPos.y - aPos.y
            })
            view.markersOrder = verticalOrder.map(item => createMarkerId(item.partId, item.marker))
            view.partsOrder = Array.from(new Set(verticalOrder.map(item => item.partId)))
            view.partsOrderBy = "topToBottom"
            view.scalingDirection = "vertical"
        }

        //console.log("View after ordering:", view)

        // After existing logic, add normal line detection
        const normalLineSequences = findNormalLineSequences(view, cam, scene, debug)
        view.normalLineSequences = normalLineSequences // Add to view object

        return view
    }

    useEffect(() => {
        // Add debug log at the start of effect
        //console.log("Effect running with enabled:", enabled)

        //console.log("viewToClean", viewToClean)
        // Cleanup previous view when viewToClean changes
        if (viewToClean) {
            // Reset colors
            viewToClean.uniqueIds.forEach((id: string) => {
                const component = getComponent(id)
                if (component) {
                    component.updateColor(0x1b7fe3)
                }
            })

            // Hide lines and debug box for previous view
            if (debugBoxRef.current) { debugBoxRef.current.visible = false }
            if (normalHelperRef.current) {
                scene.remove(normalHelperRef.current)
                normalHelperRef.current = null
            }
            setViewToClean(null)

        }
    }, [viewToClean,])


    function createOrientedBoundingBox(
        markers: DirectionalMarkers,
        options: OBBOptions = {}
    ): OBBResult {
        const {
            minWidth = 0,
            minHeight = 0,
            minDepth = 0,
            padding = 0,
        } = options

        // 1. First, let's extract all positions and compute the center
        const allPositions = [
            ...markers.normalAligned.map(m => m.position),
            ...markers.inverseAligned.map(m => m.position),
        ]

        const center = new Vector3()
        allPositions.forEach(pos => center.add(pos))
        center.divideScalar(allPositions.length)

        // 2. Get the primary axis (normal direction) from the first marker
        const normal = markers.normalAligned.length > 0
            ? markers.normalAligned[0].direction.clone()
            : markers.inverseAligned[0].direction.clone().negate()

        // 3. Find a perpendicular vector to use as the second axis
        const up = new Vector3(0, 1, 0)
        const right = new Vector3().crossVectors(normal, up)
            .normalize()
        if (right.lengthSq() < 0.1) {
            // If normal is parallel to up, use forward instead
            up.set(0, 0, 1)
            right.crossVectors(normal, up).normalize()
        }

        // Get the third axis by crossing normal and right
        const secondAxis = right
        const thirdAxis = new Vector3().crossVectors(normal, secondAxis)
            .normalize()

        // 4. Create rotation matrix from these axes
        const rotationMatrix = new Matrix4()
        rotationMatrix.makeBasis(secondAxis, thirdAxis, normal)

        // 5. Transform all points to the oriented space
        const inverseRotation = rotationMatrix.clone().invert()
        const transformedPoints = allPositions.map(pos => {
            return pos.clone()
                .sub(center)
                .applyMatrix4(inverseRotation)
        })

        // 6. Find min and max in the oriented space
        const min = new Vector3(Infinity, Infinity, Infinity)
        const max = new Vector3(-Infinity, -Infinity, -Infinity)
        transformedPoints.forEach(point => {
            min.min(point)
            max.max(point)
        })

        // 7. Create box and compute dimensions, applying minimum sizes
        const box = new Box3(min, max)
        box.expandByScalar(padding)
        const size = box.getSize(new Vector3())

        // Apply minimum dimensions while maintaining the center
        const adjustedSize = new Vector3(
            Math.max(size.x, minWidth),
            Math.max(size.y, minHeight),
            Math.max(size.z, minDepth)
        )

        // Calculate size differences
        const sizeDiff = new Vector3(
            adjustedSize.x - size.x,
            adjustedSize.y - size.y,
            adjustedSize.z - size.z
        )

        // Adjust min and max to maintain center while applying minimum dimensions
        min.x -= sizeDiff.x / 2
        min.y -= sizeDiff.y / 2
        min.z -= sizeDiff.z / 2
        max.x += sizeDiff.x / 2
        max.y += sizeDiff.y / 2
        max.z += sizeDiff.z / 2

        // 8. Generate the face points using adjusted dimensions
        const halfSize = adjustedSize.clone().multiplyScalar(0.5)
        const startFaceLocal = [
            new Vector3(-halfSize.x, -halfSize.y, -halfSize.z),
            new Vector3(halfSize.x, -halfSize.y, -halfSize.z),
            new Vector3(halfSize.x, halfSize.y, -halfSize.z),
            new Vector3(-halfSize.x, halfSize.y, -halfSize.z),
        ]

        const endFaceLocal = startFaceLocal.map(p =>
            new Vector3(p.x, p.y, halfSize.z * 2 + p.z)
        )

        // Transform faces back to world space
        const startFace = startFaceLocal.map(p =>
            p.clone()
                .applyMatrix4(rotationMatrix)
                .add(center)
        )

        const endFace = endFaceLocal.map(p =>
            p.clone()
                .applyMatrix4(rotationMatrix)
                .add(center)
        )

        // Left face
        const leftFaceLocal = [
            new Vector3(-halfSize.x, -halfSize.y, -halfSize.z),
            new Vector3(-halfSize.x, halfSize.y, -halfSize.z),
            new Vector3(-halfSize.x, halfSize.y, halfSize.z),
            new Vector3(-halfSize.x, -halfSize.y, halfSize.z),
        ]

        // Right face
        const rightFaceLocal = [
            new Vector3(halfSize.x, -halfSize.y, -halfSize.z),
            new Vector3(halfSize.x, halfSize.y, -halfSize.z),
            new Vector3(halfSize.x, halfSize.y, halfSize.z),
            new Vector3(halfSize.x, -halfSize.y, halfSize.z),
        ]

        const leftFace = leftFaceLocal.map(p =>
            p.clone()
                .applyMatrix4(rotationMatrix)
                .add(center)
        )

        const rightFace = rightFaceLocal.map(p =>
            p.clone()
                .applyMatrix4(rotationMatrix)
                .add(center)
        )

        // 9. Create final transformation matrix
        const transformation = new Matrix4()
            .multiply(new Matrix4().makeTranslation(center.x, center.y, center.z))
            .multiply(rotationMatrix)


        const mesh = box3ToMesh(new Box3(min, max))
        mesh.applyMatrix4(transformation)
        //add transparency to the mesh
        const material = new MeshBasicMaterial({
            color: tinycolor2.random().toHexString(),
            transparent: true,
            opacity: 0.25,
            wireframe: true,
        })
        mesh.material = material

        return {
            box: new Box3(min, max),
            startFace,
            endFace,
            leftFace,
            rightFace,
            width: adjustedSize.x,
            height: adjustedSize.y,
            depth: adjustedSize.z,
            center,
            transformation,
            mesh,
        }
    }


    const getViewRelativePositions = (
        widthSet: [[Vector3, Vector3] | [], [Vector3, Vector3] | []], // Two lines that might be width
        heightSet: [[Vector3, Vector3] | [], [Vector3, Vector3] | []], // Two lines that might be height
        center: Vector3
    ): LineSet[] => {
        const cam = cameraControls.current
        const cameraMatrix = cam.camera.matrixWorldInverse

        // Helper function to transform points to camera space
        const toCameraSpace = (point: Vector3) => point.clone().applyMatrix4(cameraMatrix)

        // Transform all points and center to camera space
        const widthSetInCamera = widthSet.map(line => line.map(toCameraSpace))
        const heightSetInCamera = heightSet.map(line => line.map(toCameraSpace))
        const centerInCamera = toCameraSpace(center)

        // Helper to get line properties
        const getLineProperties = (points: Vector3[]) => {
            const delta = points[1].clone().sub(points[0])
            const midpoint = points[0].clone().add(points[1])
                .multiplyScalar(0.5)
            const angle = Math.abs(Math.atan2(delta.y, delta.x))
            return { delta, midpoint, angle, }
        }

        // Calculate properties for all lines
        const widthProps = widthSet.map(line => getLineProperties(line.map(toCameraSpace)))
        const heightProps = heightSet.map(line => getLineProperties(line.map(toCameraSpace)))

        // Determine if our initial width/height guess was correct
        // Compare average angles of each set to determine which is more horizontal
        const avgWidthAngle = widthProps.reduce((sum, prop) => sum + prop.angle, 0) / 2
        const avgHeightAngle = heightProps.reduce((sum, prop) => sum + prop.angle, 0) / 2

        // If our guess was wrong, swap the sets
        const shouldSwap = Math.abs(avgWidthAngle - Math.PI / 2) < Math.abs(avgHeightAngle - Math.PI / 2)

        const [finalWidthSet, finalWidthProps,] = shouldSwap
            ? [heightSet, heightProps,]
            : [widthSet, widthProps,]

        const [finalHeightSet, finalHeightProps,] = shouldSwap
            ? [widthSet, widthProps,]
            : [heightSet, heightProps,]

        // Sort width lines by Y position to determine top/bottom
        const sortedWidthLines = finalWidthSet.map((line, i) => ({
            line,
            props: finalWidthProps[i],
            y: finalWidthProps[i].midpoint.y,
        })).sort((a, b) => b.y - a.y)

        // Sort height lines by X position to determine left/right
        const sortedHeightLines = finalHeightSet.map((line, i) => ({
            line,
            props: finalHeightProps[i],
            x: finalHeightProps[i].midpoint.x,
        })).sort((a, b) => b.x - a.x)

        return [
            // Width lines (top and bottom)
            {
                points: sortedWidthLines[0].line as [Vector3, Vector3],
                viewType: "width",
                position: "top",
            },
            {
                points: sortedWidthLines[1].line as [Vector3, Vector3],
                viewType: "width",
                position: "bottom",
            },
            // Height lines (left and right)
            {
                points: sortedHeightLines[0].line as [Vector3, Vector3],
                viewType: "height",
                position: "right",
            },
            {
                points: sortedHeightLines[1].line as [Vector3, Vector3],
                viewType: "height",
                position: "left",
            },
        ]
    }

    function createMeasurementLines(result: OBBResult): MeasurementLines {
        const { startFace, endFace, } = result

        // For each face, we'll create width and height lines
        // Width lines will be at the vertical center
        // Height lines will be at the horizontal center

        // Helper function to get center point between two vectors
        const getMidpoint = (v1: Vector3, v2: Vector3): Vector3 => {
            return new Vector3().addVectors(v1, v2)
                .multiplyScalar(0.5)
        }

        // Start face measurements
        const startLeft = getMidpoint(startFace[0], startFace[3])    // Left middle
        const startRight = getMidpoint(startFace[1], startFace[2])   // Right middle
        const startTop = getMidpoint(startFace[2], startFace[3])     // Top middle
        const startBottom = getMidpoint(startFace[0], startFace[1])  // Bottom middle

        // End face measurements
        const endLeft = getMidpoint(endFace[0], endFace[3])         // Left middle
        const endRight = getMidpoint(endFace[1], endFace[2])        // Right middle
        const endTop = getMidpoint(endFace[2], endFace[3])          // Top middle
        const endBottom = getMidpoint(endFace[0], endFace[1])       // Bottom middle

        return {
            startFace: {
                width: [startLeft, startRight,],     // Horizontal line at vertical center
                height: [startBottom, startTop,],     // Vertical line at horizontal center
            },
            endFace: {
                width: [endLeft, endRight,],         // Horizontal line at vertical center
                height: [endBottom, endTop,],         // Vertical line at horizontal center
            },
        }
    }

    // Add this helper function near the top of the component
    const calculateDashParameters = (points: Vector3[], isMobileDevice: boolean) => {
        const lineLength = points[0].distanceTo(points[1])

        // For mobile, we want larger dashes relative to line length
        const desiredDashCount = isMobileDevice ? 6 : 12 // Adjust these numbers to control dash count

        // Calculate dash and gap sizes based on desired number of dashes
        const totalSize = lineLength / desiredDashCount
        const dashSize = totalSize * 0.6 // 60% dash, 40% gap
        const gapSize = totalSize * 0.4

        return { dashSize, gapSize, }
    }

    const generateDirectionalMarkers = (
        view: viewForScalerUI,
        scene: Scene,
        showScalerNormals: boolean
    ): DirectionalMarkers => {
        const viewDirectionalMarkers: DirectionalMarkers = {
            normalAligned: [],
            inverseAligned: [],
        }

        view.uniqueIds.forEach((partId: string) => {
            const startAndEndMarkers = Object.values(view.startAndEndMarkers?.[partId] ?? {})
            if (!startAndEndMarkers) { return }

            // Establish the two directions
            const normal = view.normal.clone()
            const inverseNormal = normal.clone().negate()

            // Draw debug arrows for the normal and inverse normal
            if (showScalerNormals) {
                const blue = new Color(0x00008B)
                const green = new Color(0x008B00)
                const arrowHelperInverse = new ArrowHelper(inverseNormal, new Vector3(0, 0, 0), 1.25, blue, 0.08, 0.08)
                const arrowHelperNormal = new ArrowHelper(normal, new Vector3(0, 0, 0), 1.25, green, 0.08, 0.08)

                scene.add(arrowHelperInverse)
                scene.add(arrowHelperNormal)

                setTimeout(() => {
                    scene.remove(arrowHelperInverse)
                    scene.remove(arrowHelperNormal)
                }, 5000)
            }

            startAndEndMarkers.forEach((marker) => {
                if (!marker) { return }

                const markerPosition = marker.getWorldPosition(new Vector3())
                const markerDirection = MeshUtils.copyWorldDirection(marker)

                // Draw debug arrow for marker direction
                if (showScalerNormals) {
                    const randomColor = tinycolor2.random().toHexString()
                    const arrowHelperMarker = new ArrowHelper(
                        markerDirection,
                        markerPosition,
                        0.5,
                        randomColor,
                        0.04,
                        0.04
                    )
                    scene.add(arrowHelperMarker)

                    setTimeout(() => {
                        scene.remove(arrowHelperMarker)
                    }, 10000)
                }

                // Check marker alignment with normal or inverse normal
                if (isSameDirection(markerDirection, normal)) {
                    viewDirectionalMarkers.normalAligned.push({
                        position: markerPosition,
                        partId,
                        markerName: marker.name,
                        direction: markerDirection,
                    })
                } else if (isSameDirection(markerDirection, inverseNormal)) {
                    viewDirectionalMarkers.inverseAligned.push({
                        position: markerPosition,
                        partId,
                        markerName: marker.name,
                        direction: markerDirection,
                    })
                }
            })
        })

        return viewDirectionalMarkers
    }

    const processView = (showOrientedBoundingBox: boolean, showScalerNormals: boolean, view: viewForScalerUI) => {
        // Add debug log before line creation
        //console.log("Creating lines for views:", views)
        //console.group("View Angle Check")

        // Create a view-level collection for all markers

        // First, collect all markers from all parts

        const viewDirectionalMarkers = generateDirectionalMarkers(view, scene, showScalerNormals)

        const obb = createOrientedBoundingBox(viewDirectionalMarkers, {
            minWidth: 0.02,
            minHeight: 0.02,
            minDepth: 0.02,
            padding: isMobile(window.navigator).any ? 0.125 : 0.2,
        })


        //console.log("obb", obb)

        if (showOrientedBoundingBox) {
            console.log("viewDirectionalMarkers", viewDirectionalMarkers, "debug", showOrientedBoundingBox)
            scene.add(obb.mesh)
        }


        //obb.startFace.forEach((point) => {
        //drawVector3Point(point, scene, 0xff0000, 0.011, 5000, true, undefined, "obb_corner")
        //})

        //obb.endFace.forEach((point) => {
        //drawVector3Point(point, scene, 0xff0000, 0.011, 5000, true, undefined, "obb_corner")
        //})

        const measurementLines = createMeasurementLines(obb)

        const color = 0xff0000
        const color2 = 0x0000ff

        const material = new LineBasicMaterial({
            color: color,
            linewidth: 2,  // Note: linewidth only works in WebGLRenderer with special extensions
            depthTest: true,
            depthWrite: true,
            transparent: true,
            opacity: 0.1,
        })

        const material2 = new LineBasicMaterial({
            color: color2,
            linewidth: 5,  // Note: linewidth only works in WebGLRenderer with special extensions
            depthTest: true,
            depthWrite: true,
            transparent: true,
            opacity: 0.1,
        })

        const widthDistance = measurementLines.startFace.width[0].distanceTo(measurementLines.startFace.width[1])
        const heightDistance = measurementLines.startFace.height[0].distanceTo(measurementLines.startFace.height[1])

        // Determine if width or height is longer to use for the lines
        const isWidthLonger = widthDistance > heightDistance

        if (isAdmin) {
            console.log("isWidthLonger", isWidthLonger,)
        }

        // Create lines with appropriate materials
        const geometry1 = new BufferGeometry().setFromPoints(measurementLines.startFace.width)
        const line1 = new LineThree(geometry1, isWidthLonger ? material2 : material)


        const geometry2 = new BufferGeometry().setFromPoints(measurementLines.endFace.width)
        const line2 = new LineThree(geometry2, isWidthLonger ? material2 : material)


        const geometry3 = new BufferGeometry().setFromPoints(measurementLines.startFace.height)
        const line3 = new LineThree(geometry3, isWidthLonger ? material : material2)


        const geometry4 = new BufferGeometry().setFromPoints(measurementLines.endFace.height)
        const line4 = new LineThree(geometry4, isWidthLonger ? material : material2)


        const color3 = 0x00ff00
        const material3 = new LineBasicMaterial({
            color: color3,
            linewidth: 5,  // Note: linewidth only works in WebGLRenderer with special extensions
            depthTest: true,
            depthWrite: true,
            transparent: true,
            opacity: 0.1,
        })
        //draw connecting lines

        const geometryConnectingHeights0 = new BufferGeometry().setFromPoints([measurementLines.startFace.height[0], measurementLines.endFace.height[0],])
        const lineConnectingHeights0 = new LineThree(geometryConnectingHeights0, isWidthLonger ? material : material3)

        const geometryConnectingHeights1 = new BufferGeometry().setFromPoints([measurementLines.startFace.height[1], measurementLines.endFace.height[1],])
        const lineConnectingHeights1 = new LineThree(geometryConnectingHeights1, isWidthLonger ? material : material3)


        const geometryConnectingWidths0 = new BufferGeometry().setFromPoints([measurementLines.startFace.width[0], measurementLines.endFace.width[0],])
        const lineConnectingWidths0 = new LineThree(geometryConnectingWidths0, isWidthLonger ? material3 : material)


        const geometryConnectingWidths1 = new BufferGeometry().setFromPoints([measurementLines.startFace.width[1], measurementLines.endFace.width[1],])
        const lineConnectingWidths1 = new LineThree(geometryConnectingWidths1, isWidthLonger ? material3 : material)


        if (showOrientedBoundingBox) {
            scene.add(line1)
            scene.add(line2)
            scene.add(line3)
            scene.add(line4)
            scene.add(lineConnectingWidths0)
            scene.add(lineConnectingWidths1)
            scene.add(lineConnectingHeights0)
            scene.add(lineConnectingHeights1)

            setTimeout(() => {
                scene.remove(obb.mesh)
                scene.remove(line1)
                scene.remove(line2)
                scene.remove(line3)
                scene.remove(line4)
                scene.remove(lineConnectingWidths0)
                scene.remove(lineConnectingWidths1)
                scene.remove(lineConnectingHeights0)
                scene.remove(lineConnectingHeights1)
            }, 5000)
        }

        view.directionalMarkers = viewDirectionalMarkers

        const duplicateLines: LineInfo[] = []

        //pushing first the top lines

        const lineToUseForStartFace = isWidthLonger ? measurementLines.startFace.width : measurementLines.startFace.height
        const centerOfLineToUseTwoPoints = new Vector3()
        centerOfLineToUseTwoPoints.addVectors(
            lineToUseForStartFace[0],
            lineToUseForStartFace[1]
        ).divideScalar(2)



        const lineToUseForEndFace = isWidthLonger ? measurementLines.endFace.width : measurementLines.endFace.height
        const centerOfLineToUseTwoPointsEndFace = new Vector3()
        centerOfLineToUseTwoPointsEndFace.addVectors(
            lineToUseForEndFace[0],
            lineToUseForEndFace[1]
        ).divideScalar(2)




        const lineToUseForConnectingLinesFace1 = isWidthLonger ? [measurementLines.startFace.width[0], measurementLines.endFace.width[0],] : [measurementLines.startFace.height[0], measurementLines.endFace.height[0],]
        const centerOfLineToUseTwoPointsConnectingLinesFace1 = new Vector3()
        centerOfLineToUseTwoPointsConnectingLinesFace1.addVectors(
            lineToUseForConnectingLinesFace1[0],
            lineToUseForConnectingLinesFace1[1]
        ).divideScalar(2)



        const lineToUseForConnectingLinesFace2 = isWidthLonger ? [measurementLines.startFace.width[1], measurementLines.endFace.width[1],] : [measurementLines.startFace.height[1], measurementLines.endFace.height[1],]
        const centerOfLineToUseTwoPointsConnectingLinesFace2 = new Vector3()
        centerOfLineToUseTwoPointsConnectingLinesFace2.addVectors(
            lineToUseForConnectingLinesFace2[0],
            lineToUseForConnectingLinesFace2[1]
        ).divideScalar(2)

        const classifications = getViewRelativePositions(
            [[lineToUseForStartFace[0], lineToUseForStartFace[1],], [lineToUseForEndFace[0], lineToUseForEndFace[1],],] as [[Vector3, Vector3], [Vector3, Vector3]],
            [[lineToUseForConnectingLinesFace1[0], lineToUseForConnectingLinesFace1[1],], [lineToUseForConnectingLinesFace2[0], lineToUseForConnectingLinesFace2[1],],] as [[Vector3, Vector3], [Vector3, Vector3]],
            obb.center
        )


        // Helper function to find matching classification
        const findMatchingClassification = (points: Vector3[]) => {
            return classifications.find(classification => {
                const classPoints = classification.points as [Vector3, Vector3]
                return (
                    (points[0].equals(classPoints[0]) && points[1].equals(classPoints[1]))
                    || (points[0].equals(classPoints[1]) && points[1].equals(classPoints[0]))
                )
            })
        }

        // Add lines using classifications
        const linesToAdd = [
            { points: lineToUseForStartFace, isPerpendicular: true, },
            { points: lineToUseForEndFace, isPerpendicular: true, },
            { points: lineToUseForConnectingLinesFace1, isPerpendicular: false, },
            { points: lineToUseForConnectingLinesFace2, isPerpendicular: false, },
        ]

        //draw each one of the lines and color the perpendicular ones red
        linesToAdd.forEach(({ points, isPerpendicular, }) => {
            const line = new LineThree(
                new BufferGeometry().setFromPoints(points),
                new LineBasicMaterial({ color: isPerpendicular ? 0xff0000 : 0x00ff00, })
            )
            if (showOrientedBoundingBox) {
                scene.add(line)
                setTimeout(() => {
                    scene.remove(line)
                }, 5000)
            }
        })

        linesToAdd.forEach(({ points, isPerpendicular, }) => {
            const markerInfos: MarkerInfo[] = []
            // Iterate through each part ID in the view
            view.uniqueIds.forEach((partId: string) => {
                const markersForPart = view.markers?.[partId]
                if (!markersForPart?.length) { return }

                // Get line center in world coordinates
                const lineCenter = new Vector3()
                lineCenter.addVectors(
                    points[0],
                    points[1]
                ).divideScalar(2)
                const lineCenterWorld = lineCenter.clone()

                // Find the closest marker using world positions
                let closestMarker = markersForPart[0]
                let closestDistance = closestMarker.getWorldPosition(new Vector3()).distanceTo(lineCenterWorld)

                markersForPart.forEach((marker: Mesh) => {
                    // Get marker's world position
                    const markerWorldPos = marker.getWorldPosition(new Vector3())
                    const distance = markerWorldPos.distanceTo(lineCenterWorld)

                    if (distance < closestDistance) {
                        closestDistance = distance
                        closestMarker = marker
                    }
                })

                markerInfos.push({
                    partId,
                    marker: closestMarker,
                    distance: closestDistance,
                })
            })
            const classification = findMatchingClassification(points)
            if (classification) {
                const offsetDirection = calculateOffsetDirection(points, view.normal.clone(), isPerpendicular, classification.position, obb.center)
                duplicateLines.push({
                    points,
                    isPerpendicularToNormal: isPerpendicular,
                    viewType: classification.viewType,
                    position: classification.position,
                    markers: markerInfos,
                    offsetDirection,
                })
            } else {
                console.warn("Could not find matching classification for line", points)
            }
        })

        if (showOrientedBoundingBox) {
            console.log("duplicateLines", duplicateLines)
        }


        debugBoxRef.current && (debugBoxRef.current.visible = true)
        view.uniqueIds.forEach((id: string) => {
            const component = getComponent(id)
            if (component) {
                const darkBlue = new Color(0x00008B)
                component.updateColor(darkBlue)
            }
        })

        // Update debug box
        debugBoxRef.current && (debugBoxRef.current.box.copy(selectionBox))

        // Order the markers before setting the active view
        const orderedView = orderMarkersForView(view, obb.center, cameraControls.current, duplicateLines, logNormalLineSequences)
        if (isAdmin) {
            console.log("orderedView", orderedView)
        }
        //setActiveView(orderedView)


        messageUtils.custom("The dashed lines allow you to resize the dark blue parts at once.", {
            duration: 8,
            showUpTo: 5,
            minTimeBetweenShows: 30,
        })

        setTimeout(() => {
            messageUtils.custom("Click on a circle to change your selection of resizable parts.", {
                duration: 8,
                showUpTo: 5,
                minTimeBetweenShows: 30,
            })
        }, 8000)

        const size = new Vector3()
        obb.box.getSize(size)

        // Show both lines and bbox together
        debugBoxRef.current && (debugBoxRef.current.visible = true)

        // Add normal vector visualization
        if (normalHelperRef.current) {
            scene.remove(normalHelperRef.current)
        }


        setTimeout(() => {
            if (debugBoxRef.current) {
                scene.remove(debugBoxRef.current)
            }
        }, 5000)

        //console.groupEnd() // Close Processing Valid View group
        //console.groupEnd() // Close View Angle Check group
        //setIsVisible(true)  // Show lines when we have a valid view
        return { duplicateLines, orderedView, }

    }




    //console.log("linePoints", linePoints)

    const handlePointerOver = (index: number, event: any) => {
        if (!activeView || userIsScaling) { return }

        const line = linePoints[index]
        //console.log("line", line)
        // Don't handle hover for lines that are not parallel to normal
        if (!line.isPerpendicularToNormal) { return }

        setHoveredLineIndex(index)
        originalCursorRef.current = gl.domElement.style.cursor
        gl.domElement.style.cursor = line.viewType === "height" ? "ew-resize" : "ns-resize"
    }



    const handlePointerDown = (index: number, event: any) => {
        const line = linePoints[index]
        // Don't handle click for lines that are not parallel to normal
        if (!line.isPerpendicularToNormal) { return }

        event.stopPropagation()
        setUserIsScaling(true)
        scaleTypeRef.current = line.viewType === "width" ? "vertical" : "horizontal"
        originalMouseXYRef.current = { x: event.clientX, y: event.clientY, }
        setActiveLine(line)

        //remeber the autofocus mode
        autoFocusModeRef.current = autofocusMode ?? false
        //console.log("autoFocusModeRef.current", autofocusMode)
        setAutofocusMode(false)

        // Create preview lines
        const selectedLine = linePoints[index]
        const otherLines = linePoints.filter((line, i) => {
            return line.viewType !== selectedLine.viewType || line.position === selectedLine.position
        })

        // Calculate debug points for perpendicular lines
        const fixedPoints: Vector3[] = []
        otherLines.forEach(line => {
            if (line.viewType !== selectedLine.viewType) {
                // For perpendicular lines, get the point furthest from the selected line
                const point1 = line.points[0]
                const point2 = line.points[1]

                // Calculate distances from points to selected line center
                const selectedLineCenter = new Vector3().addVectors(
                    selectedLine.points[0],
                    selectedLine.points[1]
                )
                    .multiplyScalar(0.5)

                const dist1 = point1.distanceTo(selectedLineCenter)
                const dist2 = point2.distanceTo(selectedLineCenter)

                // The point with larger distance should be fixed
                fixedPoints.push(dist1 > dist2 ? point1.clone() : point2.clone())
            }
        })

        setDebugPoints(fixedPoints)
        setPreviewLines(otherLines.map(line => ({
            ...line,
            points: line.points.map(point => point.clone()),
        })))
    }

    const handlePointerOut = (index: number, event: any) => {
        //console.log("handle pointer out")
        if (userIsScaling) { return }
        setHoveredLineIndex(null)
        setActiveLine(null)
        scaleTypeRef.current = null
        gl.domElement.style.cursor = originalCursorRef.current
    }


    // Add useEffect for mouse up listener
    useEffect(() => {
        const handleGlobalMouseUp = (event: MouseEvent | TouchEvent) => {
            if (userIsScaling && activeLine && originalMouseXYRef.current) {
                gl.domElement.style.cursor = originalCursorRef.current
                setHoveredLineIndex(null)

                // Get coordinates based on event type
                const clientX = "touches" in event
                    ? (event as TouchEvent).changedTouches[0].clientX
                    : (event as MouseEvent).clientX
                const clientY = "touches" in event
                    ? (event as TouchEvent).changedTouches[0].clientY
                    : (event as MouseEvent).clientY
                const diffinX = clientX - originalMouseXYRef.current.x
                const diffinY = clientY - originalMouseXYRef.current.y

                // Rest of your existing logic remains the same
                const diffWeCareAbout = activeLine.viewType === "width" ? diffinY : diffinX
                const directionMultiplier = {
                    left: -1,
                    right: 1,
                    top: -1,
                    bottom: 1,
                }[activeLine.position]

                const rawDistance = pixelsTo3DUnits(Math.abs(diffWeCareAbout), cameraControls.current.camera)
                const distance = rawDistance * Math.sign(diffWeCareAbout) * directionMultiplier

                const putBackInAutofocusMode = setTimeout(() => {
                    setAutofocusMode(autoFocusModeRef.current)
                }, 500)

                if (activeView) {
                    onScaleEnd(activeView, activeLine, distance, scaleTypeRef.current!, () => {
                        clearTimeout(putBackInAutofocusMode)
                        setTimeout(() => {
                            setAutofocusMode(autoFocusModeRef.current)
                        }, 500)
                    }, isAdmin ?? false)
                }
            }

            // Clear preview lines
            setUserIsScaling(false)
            scaleTypeRef.current = null
            setDebugPoints([])
            //setActiveLine(null)
            //setPreviewLines([])
        }

        window.addEventListener("mouseup", handleGlobalMouseUp)
        window.addEventListener("touchend", handleGlobalMouseUp)
        return () => {
            window.removeEventListener("mouseup", handleGlobalMouseUp)
            window.removeEventListener("touchend", handleGlobalMouseUp)
        }
    }, [userIsScaling, activeLine, activeView, onScaleEnd,])

    // Add useEffect for pointer move
    useEffect(() => {
        const handlePointerMove = (event: PointerEvent) => {
            if (!userIsScaling || !activeLine || !originalMouseXYRef.current) { return }

            const diffinX = event.clientX - originalMouseXYRef.current.x
            const diffinY = event.clientY - originalMouseXYRef.current.y
            const diffWeCareAbout = activeLine.viewType === "width" ? diffinY : diffinX

            // Determine direction multiplier based on line position
            const directionMultiplier = {
                left: -1,
                right: 1,
                top: -1,
                bottom: 1,
            }[activeLine.position]

            const rawDistance = pixelsTo3DUnits(Math.abs(diffWeCareAbout), cameraControls.current.camera)
            const distance = rawDistance * Math.sign(diffWeCareAbout) * directionMultiplier

            // Update scale distance for display
            setScaleDistance(distance)

            if (!activeLine.offsetDirection) {
                console.warn("No offset direction defined for active line")
                return
            }

            // Calculate the actual offset using the stored offsetDirection
            const offset = activeLine.offsetDirection.clone().multiplyScalar(distance)

            // Update preview lines positions
            const updatedPreviewLines = previewLines.map(line => {
                if (line.viewType !== activeLine.viewType) {
                    // Find the fixed point for perpendicular lines
                    const point1 = line.points[0].clone()
                    const point2 = line.points[1].clone()

                    const activeLineCenter = new Vector3().addVectors(
                        activeLine.points[0],
                        activeLine.points[1]
                    )
                        .multiplyScalar(0.5)

                    const dist1 = point1.distanceTo(activeLineCenter)
                    const dist2 = point2.distanceTo(activeLineCenter)

                    if (dist1 > dist2) {
                        // point1 is fixed, point2 moves
                        return {
                            ...line,
                            points: [
                                point1,                           // Fixed point
                                point2.clone().add(offset),       // Moving point with offset
                                point1,                           // Back to fixed point
                            ],
                        }
                    } else {
                        // point2 is fixed, point1 moves
                        return {
                            ...line,
                            points: [
                                point2,                           // Fixed point
                                point1.clone().add(offset),       // Moving point with offset
                                point2,                           // Back to fixed point
                            ],
                        }
                    }
                }

                // For parallel lines, move both points by the offset
                return {
                    ...line,
                    points: line.points.map(point => point.clone().add(offset)),
                }
            })

            // Update position for distance text display
            const topLine = updatedPreviewLines.find(line => line.position === "top")
            if (topLine) {
                const center = new Vector3()
                center.addVectors(topLine.points[0], topLine.points[1]).multiplyScalar(0.5)
                center.y += 0.1  // Offset text slightly above the line
                setScalePosition(center)
            }

            setPreviewLines(updatedPreviewLines)

            if (activeView) {
                const mousePosition = new Vector3(event.clientX, event.clientY, 0)
                onScaleUpdate(activeView, mousePosition)
            }
        }

        window.addEventListener("pointermove", handlePointerMove)
        return () => {
            window.removeEventListener("pointermove", handlePointerMove)
        }
    }, [userIsScaling, activeLine,])



    const onHover = (event: any) => {
        event.stopPropagation()
        setHoveredOverViewButton(true)
        const material = event.object.material
        // Darken the color by creating a darker version of the current color
        const currentColor = material.color.getHexString()
        const darkerColor = tinycolor2(currentColor).darken(5)
            .toHexString()
        material.color.set(darkerColor)

        // Get the view index from the object's userData
        const viewIndex = event.object.userData.viewIndex
        const view = views[viewIndex]
    }

    const onHoverOut = (event: any) => {
        event.stopPropagation()
        setHoveredOverViewButton(false)
        // Restore the original color
        const material = event.object.material
        const originalColor = event.object.userData.originalColor
        material.color.set(originalColor)
    }

    // Initial check
    useEffect(() => {
        //setting the first view as the default
        const { duplicateLines, orderedView, } = processView(showOrientedBoundingBox, showScalerNormals, views[0])
        setLinePoints(duplicateLines)
        setActiveView(orderedView)
        setIsVisible(true)
    }, [])

    useEffect(() => {
        //console.log("unmounting", highlightedPartIds)
        //put colors back on the highlighted parts
        return () => {
            highlightedPartIds.current.forEach(partId => {
                const component = getComponent(partId)
                if (component) {
                    component.updateColor(0x1b7fe3)
                }
            })
        }
    }, [])

    // Generate view selection buttons
    const renderViewButtons = () => {
        return viewButtons.map((buttonInfo) => {
            const isActiveView = activeView?.viewID === buttonInfo.viewId

            return (
                <group key={buttonInfo.viewId}>
                    {/* Add border sphere when active */}
                    {isActiveView && (
                        <Icosahedron
                            args={[0.25 / 2 + 0.03, 3,]}
                            position={buttonInfo.position}
                            userData={{ ignoreRaycast: true, scalerButton: true, }}
                        >
                            <meshBasicMaterial
                                color="#00008B" // Blue border color
                                transparent
                                opacity={0.5}
                                side={BackSide}
                                depthTest={false}
                            />
                        </Icosahedron>
                    )}

                    {/* Main button */}
                    <Icosahedron
                        args={[0.25 / 2, 3,]}
                        position={buttonInfo.position}
                        onPointerLeave={onHoverOut}
                        onPointerEnter={onHover}
                        onClick={() => handleClick(buttonInfo.viewIndex)}
                        userData={{
                            viewIndex: buttonInfo.viewIndex,
                            originalColor: buttonInfo.color,
                            scalerButton: true,
                        }}
                    >
                        <meshBasicMaterial
                            color={buttonInfo.color}
                            side={BackSide}
                        />
                        <Billboard>
                            <Text
                                fontSize={0.1}
                                color={"#fff"}
                                renderOrder={1}
                                userData={{ ignoreRaycast: true, scalerButton: true, }}
                                raycast={() => null}
                            >
                                {buttonInfo.viewIndex + 1}
                                <meshBasicMaterial
                                    color="#ffffff"
                                    transparent
                                    depthTest={false}
                                />
                            </Text>
                        </Billboard>
                    </Icosahedron>
                </group>
            )
        })
    }

    return (
        <>
            {linePoints.map((line, index) => {
                const { dashSize, gapSize, } = calculateDashParameters(line.points, isMobile(window.navigator).any)

                return (
                    <Line
                        key={`main-${line.viewType}-${line.position}`}
                        points={line.points}
                        dashed={line.isPerpendicularToNormal}
                        dashSize={dashSize}
                        gapSize={gapSize}
                        color={!line.isPerpendicularToNormal
                            ? config.disabledColor
                            : hoveredLineIndex === index
                                ? config.hoverColor
                                : config.activeColor}
                        lineWidth={!line.isPerpendicularToNormal
                            ? config.disabledLineThickness
                            : isMobile(window.navigator).any
                                ? config.lineThickness * 2
                                : config.lineThickness}
                        opacity={1}
                        userData={{ ignoreRaycast: true, }}
                        visible={isVisible}
                        onPointerOver={(e) => handlePointerOver(index, e)}
                        onPointerDown={(e) => handlePointerDown(index, e)}
                        onPointerOut={(e) => handlePointerOut(index, e)}
                    />
                )
            })}

            {/* Preview lines */}
            {previewLines.map((line, index) => (
                <Line
                    key={`preview-${line.viewType}-${line.position}-${index}`}
                    points={line.points}
                    color={config.previewLines.color}
                    lineWidth={config.previewLines.lineWidth}
                    dashed={false}
                    opacity={config.previewLines.opacity}
                    visible={isVisible}
                    userData={{ ignoreRaycast: true, }}
                />
            ))}

            {/* Debug points */}
            {debugPoints.map((point, index) => (
                <mesh
                    key={`debug-point-${index}`}
                    position={point}
                    visible={isVisible && userIsScaling}
                >
                    <sphereGeometry args={[0.01, 16, 16,]} />
                    <meshBasicMaterial color={0xff0000} />
                </mesh>
            ))}



            {/* Distance display text */}
            {scaleDistance !== null && scalePosition && (
                <Html
                    position={scalePosition}
                    center
                    style={{
                        transform: "translate3d(-50%, -100%, 0)",
                        pointerEvents: "none",
                        fontFamily: "Roboto,sans-serif",
                        fontSize: "14px",
                        color: "#333",
                        background: "rgba(255, 255, 255, 0.9)",
                        padding: "4px 8px",
                        borderRadius: "4px",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        whiteSpace: "nowrap",
                    }}
                >
                    {`${scaleDistance < 0 ? "Reducing Length by" : "Increasing Length by"} ${Math.abs(scaleDistance * 100).toFixed(1)}cm | ${Math.abs(scaleDistance * 39.3701).toFixed(1)}in`}

                </Html>
            )}

            {renderViewButtons()}
        </>
    )
}

export default ScalerUILines