import { initialDataType } from "./types"
import { atom, atomFamily, selectorFamily } from "recoil"
import { AddPartState, RenderConnectionsType, UIType } from "./types"
import { DesignTypeAPI } from "../../common/api/Types"

export const addPartModal = atom<AddPartState | null>({
    key: "addPartModal",
    default: null,
})

export const renderConnectionsData = atom<RenderConnectionsType | undefined>({
    key: "connectionModal",
    default: undefined,
    dangerouslyAllowMutability: true,
})

export const selectedItemID = atom<string | null>({
    key: "selectedItemID",
    default: null,
})

export const shiftSelectedItemID = atom<string | null>({
    key: "shiftSelectedItemID",
    default: null,
})

export const connectionIndexState = atomFamily<number, string>({
    key: "connectionIndexState",
    default: 0,
})

export const isItemSelected = selectorFamily<boolean, string>({
    key: "isItemSelected",
    get: (id) => ({ get, }) => {
        return get(selectedItemID) === id
    },
    set: (id,) => ({ set, }, newValue) => {
        if (newValue) {
            set(selectedItemID, id)
        } else {
            set(selectedItemID, null)
        }
    },
})

export const initialData = atom<initialDataType | undefined>({
    key: "initialData",
    default: undefined,
})

export const uiState = atom<UIType>({
    key: "uiState",
    default: {
        uiTunnels: [],
    },
})

export const saveDesignAtom = atom<boolean>({
    key: "saveDesign",
    default: false,
})

export const pendingChangesAtom = atom<boolean>({
    key: "pendingChanges",
    default: false,
})

export const userDesigns = atom({
    key: "userDesigns",
    default: [] as DesignTypeAPI[],
})

export const userInputState = atom({
    key: "userInputState",
    default: "",
})

export const userQueryState = atom({
    key: "userQueryState",
    default: "",
})