
import { useThree } from "@react-three/fiber"
import { useCallback, useState } from "react"
import { Color, PerspectiveCamera, Vector2 } from "three"

export const useScreenshot = () => {
    const [isCapturing, setIsCapturing,] = useState(false)
    const { gl, scene, camera, } = useThree()
    const cloudName = "dqub10reb"
    const unsignedUploadPreset = "ai_images_crafty"

    const getScreenshot = () => {
        const newCam = camera.clone(true) as PerspectiveCamera
        if (newCam) {
            scene.add(newCam)
            gl.setClearColor(new Color("white"))
            gl.autoClear = true

            const size = new Vector2()
            gl.getSize(size)
            const w = size.x
            const h = w / (16 / 9)

            newCam.aspect = w / h
            newCam.updateProjectionMatrix()


            gl.setSize(w, h, true)
            gl.render(scene, newCam)
            const imgData = gl.domElement.toDataURL("image/jpeg")
            scene.remove(newCam)
            gl.setSize(size.x, size.y, true)
            gl.render(scene, camera)
            return imgData
        }
        return ""
    }

    const uploadFile = async (file: string) => {
        const url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`
        const fd = new FormData()
        fd.append("upload_preset", unsignedUploadPreset)
        fd.append("tags", "browser_upload")
        fd.append("file", file)

        try {
            const response = await fetch(url, {
                method: "POST",
                body: fd,
            })
            const data = await response.json()
            const imageUrl = data.url
            return imageUrl
        } catch (error) {
            console.error("Error uploading the file:", error)
        }
    }

    const getAndUploadScreenshot = async () => {
        const imgData = getScreenshot()
        return await uploadFile(imgData)
    }

    return {
        getScreenshot,
        getAndUploadScreenshot,
        isCapturing,
    }
}