/* eslint-disable max-lines-per-function */
import React, { useEffect } from "react"
import Button from "../../../../../../../../../common/components/Button"
import { PartConnectionType } from "../../../../../../../../state/scene/types"
import styled from "styled-components"
import RadixDropdown from "../../../../../header/Dropdown/RadixDropdown"
import { useComponentRegistry }
 from "../../../../../../../../providers/multiselectProvider/useComponentMethods"

interface RelativeMoveProps {
    connectedToSegmentedParts: PartConnectionType[] | undefined;
    connectionIndex: number;
    setConnectionIndex: (index: number) => void;
    onHover?: (partId: string | null) => void;
    currentPartId: string;
    getConnectionAtIndex: (index: number) => PartConnectionType | undefined;
}

const Container = styled.div`
    padding: 0 16px;
`

const DropdownButton = styled.button`
    min-width: 40px;
    height: 32px;
    padding: 0 8px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    font-size: 14px;
    color: #333;

    &:hover {
        background-color: #f5f5f5;
    }

    &::after {
        content: "▼";
        font-size: 10px;
        color: #666;
    }
`

const DropdownItem = styled.div<{ isSelected: boolean, }>`
    padding: 8px 16px;
    cursor: pointer;
    background-color: ${props => (props.isSelected ? "#0088ff" : "transparent")};
    color: ${props => (props.isSelected ? "white" : "inherit")};
    border-radius: 4px;
    font-size: 14px;
    display: flex;
    align-items: center;

    &:hover {
        background-color: ${props => (props.isSelected ? "#0088ff" : "#f5f5f5")};
    }
`

const StyledButton = styled.button`
    padding: 8px;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.16s ease-in-out;
    
    &:hover {
        background-color: #eaeaea;
    }
`

const RelativeMove: React.FC<RelativeMoveProps> = (props) => {
    const {getComponent,} = useComponentRegistry()
    const [highlightedPartId, setHighlightedPartId,] = React.useState<string | null>(null)
    const highlightTimeoutRef = React.useRef<NodeJS.Timeout>()
    const currentHighlightedPartRef = React.useRef<string | null>(null)

    const resetHighlightedComponent = () => {
        const partId = currentHighlightedPartRef.current
        if (partId) {
            const component = getComponent(partId)
            component?.updateColor(component.getColor())
            currentHighlightedPartRef.current = null
            setHighlightedPartId(null)
        }
    }

    const handleHover = (index: number | null) => {

        if (index === null) {
            return
        }

        // connections get reordered, so current is always on top
        // we use a frozen array to reference the original order
        const part = props.getConnectionAtIndex(index)
        // Clear any existing timeout
        if (highlightTimeoutRef.current) {
            clearTimeout(highlightTimeoutRef.current)
        }

        // Reset previous highlight
        resetHighlightedComponent()

        // If no part provided (unhover) or null, just reset
        if (!part) {
            return
        }

        const partId = part.partA.partId === props.currentPartId
            ? part.partB.partId
            : part.partA.partId

        // Apply new highlight if partId exists
        if (partId) {
            const component = getComponent(partId)
            if (component) {
                component.updateColor("#00008B")
                currentHighlightedPartRef.current = partId
                setHighlightedPartId(partId)
            }
        }
    }

    const handleButtonClick = (index: number) => {
        if (props.connectionIndex !== null) {
            props.setConnectionIndex(index)

            // Get the corresponding part
            const part = props.getConnectionAtIndex(index)
            if (part) {
                handleHover(index)

                // Set timeout to reset color after 2 seconds
                highlightTimeoutRef.current = setTimeout(() => {
                    resetHighlightedComponent()
                }, 2000)
            }
        }
    }

    // Cleanup on unmount
    React.useEffect(() => {
        return () => {
            if (highlightTimeoutRef.current) {
                clearTimeout(highlightTimeoutRef.current)
            }
            resetHighlightedComponent()
        }
    }, [])

    if (!props.connectedToSegmentedParts || props.connectedToSegmentedParts.length <= 1) {
        return null
    }

    // Use regular buttons for 2 options
    if (props.connectedToSegmentedParts.length === 2) {
        return (
            <Container>
                {props.connectedToSegmentedParts
                    .slice()
                    .reverse()
                    .map((part, index) => (
                        <StyledButton
                            key={index}
                            onClick={() => handleButtonClick(index)}
                            onMouseEnter={() => handleHover(index)}
                            onMouseLeave={() => handleHover(null)}
                            style={{
                                backgroundColor: props.connectionIndex === index
                                    ? "#0088ff"
                                    : "white",
                                color: props.connectionIndex === index
                                    ? "white"
                                    : "initial",
                            }}
                        >
                            {String.fromCharCode(65 + ((
                                props.connectedToSegmentedParts ?? []).length - 1 - index)
                            )}
                        </StyledButton>
                    ))}
            </Container>
        )
    }

    // Use dropdown for 3 or more options
    const currentLetter = String.fromCharCode(
        65 + ((props.connectedToSegmentedParts.length - 1) - props.connectionIndex)
    )

    return (
        <Container>
            <RadixDropdown
                trigger={
                    <DropdownButton>
                        {currentLetter}
                    </DropdownButton>
                }
                closeOnSelect={true}
            >
                <div>
                    {props.connectedToSegmentedParts
                        .slice()
                        .reverse()
                        .map((part, index) => (
                            <DropdownItem
                                key={index}
                                isSelected={props.connectionIndex === index}
                                onClick={() => handleButtonClick(index)}
                                onMouseEnter={() => handleHover(index)}
                                onMouseLeave={() => handleHover(null)}
                            >
                                {String.fromCharCode(65 + ((
                                    props.connectedToSegmentedParts ?? []).length - 1 - index)
                                    )}
                            </DropdownItem>
                        ))}
                </div>
            </RadixDropdown>
        </Container>
    )
}

export default RelativeMove