/* eslint-disable no-param-reassign */
/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from "react"
import { useRecoilValue, useSetRecoilState } from "recoil"
import styled from "styled-components"
import { Collapse, Dropdown, Menu, Tooltip } from "antd"
import { SettingOutlined, RetweetOutlined, DragOutlined, CopyOutlined } from "@ant-design/icons"
import { PartTypeAPI, ShopifyIdAPI } from "../../../../../../../../common/api/Types"
import { scenePartsSelector, groupsSelector, sceneAtom, showGroupingAtom } from "../../../../../../../state/scene/atoms"
import { ObjDictionary } from "../../../../../../../../common/utils/utils"
import { rowTemplate, partsListStyles } from "./partsListHelpers"
import { usePartsList } from "../usePartsList"
import { NoPartsInScene } from "./NoPartsInScene"
import UnitToggle from "../../../../scene/UnitToggle"
import PrintButton from "./Print/PrintButton"
import { useComponentRegistry } from "../../../../../../../providers/multiselectProvider/useComponentMethods"
import NameModal from "../../../../../../../../common/components/NameModal"
import { SceneType } from "../../../../../../../state/scene/types"
import ConfirmModal from "../../../../../../../../manager/components/Designs/ConfirmModal"
import { useElementScroll } from "framer-motion"
import tinycolor from "tinycolor2"
import { GroupPanel } from "./GroupPanel"
import { SamePartsPanel } from "./SamePartsPanel"
import GroupingToggle from "../../../../scene/GroupingToggle"

interface PartsUsedListProps {
  parts: PartTypeAPI[];
  shopifyIds: ObjDictionary<ShopifyIdAPI>;
  sceneCallbacks: any;
  listRef: React.MutableRefObject<HTMLDivElement | null>;
  showPrintButton: boolean;
}

export const PartsUsedList: React.FC<PartsUsedListProps> = ({
  parts,
  shopifyIds,
  sceneCallbacks,
  listRef,
  showPrintButton,
}) => {
  const sceneAtomList = useRecoilValue(scenePartsSelector)
  const groupsAtomList = useRecoilValue(groupsSelector)
  const { getComponent, } = useComponentRegistry()
  const [groupToRename, setGroupToRename,] = useState<string | null>(null)
  const setSceneAtom = useSetRecoilState(sceneAtom)
  const currentGroupIDRef = useRef<string | null>(null)
  const [showDeleteModal, setShowDeleteModal,] = useState<boolean>(false)
  const [groupToDelete, setGroupToDelete,] = useState<string | null>(null)
  //const multiSelectContext = useContext(MultiSelectContext)
  //console.log("multiSelectContext:", multiSelectContext)
  const [activeGroups, setActiveGroups,] = useState<string[]>([])
  const showGrouping = useRecoilValue(showGroupingAtom)
  const [showGroupToggleOption, setShowGroupToggleOption,] = useState<boolean>(false)

  const { getRows, } = usePartsList({ parts, shopifyIds, allowCm: true, })


  useEffect(() => {
    if (Object.keys(groupsAtomList).length > 0) {
      setShowGroupToggleOption(true)
    } else {
      setShowGroupToggleOption(false)
    }
  }, [groupsAtomList,])

  // Add useEffect to apply colors on initial load
  useEffect(() => {
    // Apply colors to all parts in groups that are initially expanded
    Object.entries(groupsAtomList).forEach(([_, group,]) => {
      if (group.partIds && group.color) {
        group.partIds.forEach(partId => {
          const component = getComponent(partId)
          if (component) {
            component.updateColor(group.color)
          }
        })
      }
    })

    return () => {
      // Clean up colors when component unmounts
      Object.values(groupsAtomList).forEach(group => {
        group.partIds.forEach(partId => {
          const component = getComponent(partId)
          if (component) { component.originalColor() }
        })
      })
    }
  }, [groupsAtomList, getComponent,])

  // Hover handlers
  const handleGroupHover = (groupIds: string[]) => {
    groupIds.forEach(id => {
      const component = getComponent(id)
      if (component) {
        component.updateColor(0x1b7fe3)
      }
    })
  }

  const handleGroupLeave = (groupIds: string[]) => {
    groupIds.forEach(id => {
      const component = getComponent(id)
      if (component) {
        component.originalColor()
      }
    })
  }

  const handleItemHover = (itemIds: string[]) => {
    itemIds.forEach(id => {
      const component = getComponent(id)
      if (component) {
        component.updateColor(0x1b7fe3)
      }
    })
  }

  const handleItemLeave = (itemIds: string[]) => {
    itemIds.forEach(id => {
      const component = getComponent(id)
      if (component) {
        component.originalColor()
      }
    })
  }

  // Group action handlers
  const handleRenameGroup = (groupId: string) => {
    currentGroupIDRef.current = groupId
    setGroupToRename(groupId)
  }

  const handleRenameSubmit = (newName: string) => {
    const groupId = currentGroupIDRef.current
    //console.log("handleRenameSubmit", groupId, newName)
    if (!groupId) { return }

    setSceneAtom((prevScene: SceneType) => ({
      ...prevScene,
      groups: prevScene.groups?.map(group =>
        (group.id === groupId ? { ...group, name: newName, } : group)
      ),
    }))
    setGroupToRename(null)
    currentGroupIDRef.current = null
  }

  const handleUngroup = (groupId: string) => {
    setSceneAtom((prevScene: SceneType) => ({
      ...prevScene,
      groups: prevScene.groups?.filter(group => group.id !== groupId),
    }))
    if (sceneCallbacks?.resetSelection) {
      sceneCallbacks.resetSelection()
    } else {
      console.warn("sceneCallbacks or resetSelection is undefined")
    }
  }

  const handleDeleteGroup = (groupId: string) => {
    setGroupToDelete(groupId)
    setShowDeleteModal(true)
  }

  const handleConfirmDelete = () => {
    if (!groupToDelete) { return }

    // Get all part IDs from the group
    const group = Object.values(groupsAtomList).find(g => g.id === groupToDelete)
    if (!group) { return }

    // Delete all parts in the group
    group.partIds.forEach(id => {
      const component = getComponent(id)
      if (component) {
        component.deletePart()
      }
    })

    // Close modal and reset state
    setShowDeleteModal(false)
    setGroupToDelete(null)
  }

  const getGroupMenu = (groupId: string) => (
    <Menu>
      <Menu.Item key="rename" onClick={() => handleRenameGroup(groupId)}>Rename</Menu.Item>
      <Menu.Item key="ungroup" onClick={() => handleUngroup(groupId)}>Ungroup</Menu.Item>
      <Menu.Item key="delete" onClick={() => handleDeleteGroup(groupId)}>Delete Group</Menu.Item>
    </Menu>
  )

  const handlePanelChange = (activeKeys: string | string[]) => {
    const newActiveKeys = Array.isArray(activeKeys) ? activeKeys : [activeKeys,]
    setActiveGroups(newActiveKeys)

    // Reset all parts to original color first
    Object.values(sceneAtomList).forEach(part => {
      const component = getComponent(part.id)
      if (component) {
        component.originalColor()
      }
    })

    // Update colors for active panels using existing group colors
    newActiveKeys.forEach(groupId => {
      const group = Object.values(groupsAtomList).find(g => g.id === groupId)
      if (group?.color && group.partIds) {
        group.partIds.forEach(partId => {
          const component = getComponent(partId)
          if (component) {
            component.updateColor(group.color)
          }
        })
      }
    })
  }

  const renderPartsList = () => {
    if (!showGrouping) {
      // When grouping is off, show all parts as ungrouped
      const allPartsByType: { [key: string]: any[], } = {}
      Object.values(sceneAtomList).forEach(part => {
        const typeKey = part.name
        if (!allPartsByType[typeKey]) {
          allPartsByType[typeKey] = []
        }
        allPartsByType[typeKey].push(part)
      })

      return (
        <>
          {Object.entries(allPartsByType).map(([typeKey, sameParts,]) => (
            <SamePartsPanel
              key={typeKey}
              typeKey={typeKey}
              sameParts={sameParts}
              handleItemHover={handleItemHover}
              handleItemLeave={handleItemLeave}
              sceneCallbacks={sceneCallbacks}
              getRows={getRows}
            />
          ))}
        </>
      )
    }

    // Separate grouped and ungrouped parts
    const groupedParts: { [key: string]: any[], } = {}
    const ungroupedParts: any[] = []

    Object.values(sceneAtomList).forEach(part => {
      const groupEntry = Object.entries(groupsAtomList)
        .find(([_, group,]) => group.partIds.includes(part.id))

      if (groupEntry) {
        const [_, group,] = groupEntry
        if (!groupedParts[group.id]) { groupedParts[group.id] = [] }
        groupedParts[group.id].push(part)
      } else {
        ungroupedParts.push(part)
      }
    })

    // Organize ungrouped parts by their type/name
    const ungroupedByType: { [key: string]: any[], } = {}
    ungroupedParts.forEach(part => {
      const typeKey = part.name // or another unique identifier for the part type
      if (!ungroupedByType[typeKey]) {
        ungroupedByType[typeKey] = []
      }
      ungroupedByType[typeKey].push(part)
    })


    return (
      <>
        {/* Render grouped parts */}
        {Object.entries(groupedParts).map(([groupId, groupParts,]) => (
          <GroupPanel
            key={groupId}
            groupId={groupId}
            parts={groupParts}
            allParts={parts}
            groupsAtomList={groupsAtomList}
            getGroupMenu={getGroupMenu}
            handleItemHover={handleItemHover}
            handleItemLeave={handleItemLeave}
            sceneCallbacks={sceneCallbacks}
            isActive={activeGroups.includes(groupId)}
            onPanelChange={(isActive) => {
              handlePanelChange(
                isActive
                  ? [...activeGroups, groupId,]
                  : activeGroups.filter(id => id !== groupId)
              )
            }}
            shopifyIds={shopifyIds}
          />
        ))}

        {/* Render ungrouped parts using SamePartsPanel */}
        {Object.entries(ungroupedByType).map(([typeKey, sameParts,]) => (
          <SamePartsPanel
            key={typeKey}
            typeKey={typeKey}
            sameParts={sameParts}
            handleItemHover={handleItemHover}
            handleItemLeave={handleItemLeave}
            sceneCallbacks={sceneCallbacks}
            getRows={getRows}
          />
        ))}
      </>
    )
  }

  if (Object.keys(sceneAtomList).length === 0) {
    return <NoPartsInScene />
  }

  return (
    <Wrapper ref={listRef}>
      {groupToRename && (
        <NameModal
          onCancel={() => {
            setGroupToRename(null)
            currentGroupIDRef.current = null
          }}
          onRename={handleRenameSubmit}
          submitButtonText="Save"
          title="Rename Group"
          loading={false}
        />
      )}
      {showDeleteModal && (
        <ConfirmModal
          title="Delete Group"
          message="Are you sure you want to delete this group and all its parts?"
          confirmButtonText="Delete"
          confirmButtonColor="#FF0000"
          onConfirm={handleConfirmDelete}
          onCancel={() => {
            setShowDeleteModal(false)
            setGroupToDelete(null)
          }}
          loading={false}
        />
      )}
      <partsListStyles.Header>
        {showPrintButton && <PrintButton listRef={listRef} />}
        <div style={{ display: "flex", gap: "8px", }}>
          {showGroupToggleOption && <GroupingToggle />}
          <UnitToggle />
        </div>
      </partsListStyles.Header>
      {renderPartsList()}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 0 2px 0 16px;
  scrollbar-gutter: stable;
`
// padding is uneven because of the scrollbar gutter behavior