/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import { MutableRefObject, useContext, useEffect } from "react"
import { Intersection, MathUtils, Mesh, Object3D, Raycaster, Vector3 } from "three"
import { SegmentedTubeSectionType } from "../../../common/api/Types"
import { filterWithValue } from "../../../common/utils/utils"
import {
    SegmentedTubeInfo
} from "../../components/main/DesignScreen/scene/part/parts/segmentedTube/types/types"
import SegmentedTubeUtils from
    "../../components/main/DesignScreen/scene/part/parts/segmentedTube/utils/SegmentedTubeUtils"
import { MeshUtils } from "../../utils/MeshUtils"
import { CloseMarkersContext } from "./CloseMarkersProvider"
import { toDictionary } from "../../../common/utils/utils"
import { instancedMeshContext } from "../instancedMesh/InstancedMeshProvider"
import useInstancedMeshSegmentedTubes
    from "../instancedMeshSegmentedTubesProvider/useInstancedMeshSegmentedTubes"
import { metersToInch } from "../../components/main/DesignScreen/utils/utilsThree"
import { useThree } from "@react-three/fiber"

const useCloseMarkers = (partId: string) => {
    const context = useContext(CloseMarkersContext)
    const connectorsInstancesContext = useContext(instancedMeshContext)
    const { getTubeId, } = useInstancedMeshSegmentedTubes()
    const { scene, } = useThree()

    useEffect(() => {
        return () => context?.deletePart(partId)
    }, [])

    const updateMarkers = (markers: Mesh[]) => {
        context?.updateMarkers(partId, markers)
    }

    const getIntersectedPartsIds = (
        intersections: Intersection[],
    ) => {
        const instancedMeshesIntersections = intersections.filter(intersection =>
            intersection.object
            && (intersection.object.type === "InstancedMesh"
                || intersection.object.type === "Mesh")
        )
        const partIds = instancedMeshesIntersections.reduce(
            (pv: string[], intersection: Intersection) => {
                if (intersection.object.userData.type === "InstancedMesh") {
                    const instanceId = intersection.instanceId!
                    const meshName = intersection.object.name
                    let partId: string | undefined = undefined
                    if (intersection.object.userData.partType === "SECTIONED_TUBE") {
                        partId = getTubeId(meshName, instanceId)
                    } else {
                        partId = connectorsInstancesContext?.getPartId(meshName, instanceId)
                    }
                    if (partId) {
                        pv.push(partId)
                    }
                }
                return pv
            },
            []
        )
        return partIds
    }

    const getPartsMarkers = (partsIds: string[]) => {
        const markers = partsIds.reduce(
            (pv: Mesh[], partId: string) => {
                const newMarkers = context?.getPartMarkers(partId)
                if (newMarkers) {
                    return pv.concat(newMarkers)
                }
                return pv
            },
            []
        )
        return markers
    }

    const getMovedRayOriginMarkers = (markers: Mesh[]) => {
        const movedMarkers = markers.reduce((pv: Vector3[], marker: Mesh) => {
            const markerDirection = MeshUtils.copyWorldDirection(marker)
            const markerPosition = MeshUtils.copyWorldPosition(marker)
            const newPosition = new Vector3()
            const axis = new Vector3(0, 0, 1)
            newPosition.addVectors(
                markerPosition,
                markerDirection.normalize().multiplyScalar(0.05 / metersToInch)
            )
            pv.push(newPosition)
            const angle90 = MathUtils.degToRad(90)
            const upDirection = markerDirection.applyAxisAngle(axis, angle90)
            const upMovedPosition = new Vector3()
            upMovedPosition.addVectors(
                newPosition,
                upDirection.normalize().multiplyScalar(0.5 / metersToInch)
            )
            pv.push(upMovedPosition)
            const angle180 = MathUtils.degToRad(180)
            const downDirection = upDirection.applyAxisAngle(axis, angle180)
            const downMovedPositon = new Vector3()
            downMovedPositon.addVectors(
                upMovedPosition,
                downDirection.normalize().multiplyScalar(1 / metersToInch)
            )
            pv.push(downMovedPositon)
            return pv
        }, [])
        return movedMarkers
    }

    const getIntersections = (
        position: Vector3,
        directions: Vector3[],
        intersectableObjects: Object3D[]
    ) => {
        const instersections = directions.reduce(
            (pv: Intersection[], direction: Vector3) => {
                const ray = new Raycaster(position, direction)
                const objectsToIntersect = intersectableObjects.filter(object => !object.userData.ignoreRaycast)
                const intersections = ray.intersectObjects(objectsToIntersect)
                return pv.concat(intersections)
            }, [])
        return instersections
    }

    const getRayDirections = (info: MutableRefObject<SegmentedTubeInfo>) => {
        const tubeDirectionStart = SegmentedTubeUtils.getTubeDirection(
            info,
            SegmentedTubeSectionType.START
        )!
        const tubeDirectionEnd = SegmentedTubeUtils.getTubeDirection(
            info,
            SegmentedTubeSectionType.END
        )!
        return [tubeDirectionStart, tubeDirectionEnd,]
    }

    const getInstancedMeshes = () => {
        const instancedMeshes: Object3D[] = []
        scene.traverse(object => {
            if (object.userData && object.userData.type === "InstancedMesh") {
                instancedMeshes.push(object)
            }
        })
        return instancedMeshes
    }

    const getClosestMarkers = (
        info: MutableRefObject<SegmentedTubeInfo>
    ) => {
        const instancedMeshes = getInstancedMeshes()
        const auxSide = Object.values(info.current.middleSection)[0]
        if (auxSide) {
            const auxMiddlePosition = Object.keys(auxSide)[0]
            if (auxMiddlePosition) {
                const rayOriginMarkers = filterWithValue(
                    Object.values(info.current.middleSection).map(side => {
                        return side[auxMiddlePosition].outer
                    })
                )
                const rayDirections = getRayDirections(info)
                const allIntersections = getMovedRayOriginMarkers(rayOriginMarkers).reduce(
                    (pv: Intersection[], originMarkerPosition: Vector3) => {
                        const newIntersections = getIntersections(
                            originMarkerPosition,
                            rayDirections,
                            instancedMeshes)
                        return pv.concat(newIntersections)
                    }, []
                )

                const partsIds = Object.values(
                    toDictionary(getIntersectedPartsIds(allIntersections), part => part)
                )
                return getPartsMarkers(partsIds)
            }
        }
        return []
    }

    return {
        updateMarkers,
        getClosestMarkers,
    }
}

export default useCloseMarkers