import React from "react"
import { Typography } from "antd"
import styled from "styled-components"
import { PartListItem } from "../usePartsList"
import dompurify from "dompurify"

type RowTemplateParams = Omit<PartListItem & {
  quantity: number,
  inStock: boolean,
  onMouseEnter?: () => void,
  onMouseLeave?: () => void,
  key?: string,
}, "isATube">

export const rowTemplate = ({
  imgURL,
  title,
  subTitle,
  longerThan,
  quantity,
  affiliateURL,
  noProvider,
  inStock,
  cartNotice,
  onMouseEnter,
  key,
  onMouseLeave,
}: RowTemplateParams, showBuyData?: boolean) => {
  const showLongerThan = showBuyData && longerThan
  return (
    <Row
      key={key || title}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <RowContent>
        <Image><img crossOrigin="anonymous" src={imgURL} alt="Part icon" /></Image>
        <Content>
          <Title>{title}</Title>
          <Subtitle>{subTitle}</Subtitle>
          {(showLongerThan)
            && <SubtitleTubes data-html2canvas-ignore="true">
              This part is too long for us to ship. {affiliateURL
                && <AffiliateUrlText>
                  You can order it <AffiliateUrlLink
                    href={affiliateURL}
                    target="_blank">from here</AffiliateUrlLink>
                </AffiliateUrlText>}
            </SubtitleTubes>
          }
          {(!showLongerThan && showBuyData && (affiliateURL || noProvider) && !inStock) && (() => {
            if (affiliateURL) {
              return <AffiliateUrlText>
                buy <AffiliateUrlLink
                  href={affiliateURL}
                  target="_blank">here</AffiliateUrlLink>
              </AffiliateUrlText>
            }
            return <AffiliateUrlText>Find in your local {noProvider} store</AffiliateUrlText>
          })()}
        </Content>
        <Quantity>{quantity}</Quantity>
      </RowContent>
      {cartNotice && cartNotice.length > 0
        && <CartNotice><div dangerouslySetInnerHTML={{
          __html: dompurify.sanitize(cartNotice, { ADD_ATTR: ["target",], }),
        }} /></CartNotice>}
    </Row>
  )
}

const Header = styled.div`
  width: 100%;
  padding: 8px 0;
  display: flex;
`

const Labels = styled.div`
  width: 100%;
  padding: 8px 18px;
  display: grid;
  grid-template-columns: 6fr 1fr;
  border-bottom: solid 1px #E2E8F0;
`

const Label = styled.div<{ bold?: boolean, }>`
  color: #717171;
  font-size: 16px;
  ${({ bold, }) => (bold ? `
    font-weight: bold;
    color: black;
  ` : "")}
`

const LabelRightAlined = styled(Label)`
  text-align: right;
  padding-right: 8px;
`

const RowsContainer = styled.div`
  width: 100%;
  flex: 1 1 auto;
  overflow-y: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  

  article:nth-child(odd) {
    background: #F9FAFC;
  }
`

const Row = styled.article`
  width: 100%;
  padding: 8px 18px;
  border-bottom: solid 1px #E2E8F0;

  &:last-child {
    border-bottom: none;
  }
`

const RowContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 56px 4fr 1fr;
`

const Image = styled.div`
    width: 43px;
    height: 43px;
    border: solid 1px #E2E8F0;
    border-radius: 9px;
    overflow: hidden;
    box-shadow: 0 2px 8px -5px #00000059;

  img {
    width: 100%;
    object-fit: cover;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
`

const Title = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
`

const Subtitle = styled.div`
  font-size: 14px;
  line-height: 1;
`

const SubtitleTubes = styled.div`
  font-size: 14px;
  line-height: 1;
  color: black;
  font-weight: bolder;
`

const Quantity = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: bold;
  padding-right: 8px;
`

const AffiliateUrlLink = styled(Typography.Link)`
  color: black !important;
  text-decoration: underline !important;
  font-weight: bolder !important;
`

const AffiliateUrlText = styled(Typography.Link)`
  font-weight: bolder;
  color: black !important;
`

const CartNotice = styled(Typography.Text)`
  margin-top: 8px;
  display: inline-block;
  a {
    text-decoration: underline;
    font-weight: 700;
  }
`

export const partsListStyles = {
  Labels,
  Label,
  LabelRightAlined,
  RowsContainer,
  Header,
}