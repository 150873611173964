import { PartTypeAPIRaw } from "./Types"
import { EnvHelper } from "../utils/EnvHelper"
import algoliasearch from "algoliasearch/lite"

const { appId, apiKey, indexName, } = EnvHelper.algoliaConfig

const client = algoliasearch(appId, apiKey)

// Initialize both indices
const index = client.initIndex(indexName)
const fuzzyIndex = client.initIndex("Tubedesigner_fuzzy")

const searchAlgolia = async (query: string, page: number, targetIndex = index) => {
    const retries = 3
    let attempt = 0
    while (attempt < retries) {
        try {
            return await index.search<PartTypeAPIRaw>(query, { page, hitsPerPage: 1000, })
        } catch (error) {
            attempt++
            if (attempt >= retries) {
                throw error
            }
        }
    }
    throw new Error("Unexpected error in searchAlgolia function.")
}

export const searchSecondaryIndex = async (userQuery: string) => {
    return await searchCompatibleParts("", userQuery, fuzzyIndex)
}

export const searchCompatibleParts = async (
    query: string,
    userQuery: string,
    targetIndex = index) => {
    //console.log("algolia", query, "userQuery", userQuery)
    let page = 0
    let concatedQuery = ""
    if (userQuery) {
        concatedQuery = `${userQuery} ${query}`
    } else {
        concatedQuery = query
    }
    let res = await searchAlgolia(concatedQuery, page, targetIndex)
    //console.log(res)
    let hits = res.hits
        .map(hit => ({ ...hit, id: hit.objectID, }))
    while (res.page < res.nbPages - 1) {
        page++
        res = await searchAlgolia(concatedQuery, page)
        //console.log(res)
        hits = hits.concat(res.hits.map(hit => ({ ...hit, id: hit.objectID, })))
    }
    return hits
}

