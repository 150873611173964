/* eslint-disable callback-return */
/* eslint-disable no-console */
/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-negated-condition */
/* eslint-disable max-statements */
/* eslint-disable default-case */
/* eslint-disable max-len */
import React, { useCallback, useContext } from "react"
import { useEffect, useRef, useState } from "react"
import { useThree } from "@react-three/fiber"
import { SegmentedTubeInfo, SectionType, SEGMENTED_TUBE_UI, SegmentedTubeMarkers } from "./types/types"
import SegmentedTubeUtils from "./utils/SegmentedTubeUtils"
import useInstancedMeshSegmentedTubes from "../../../../../../../providers/instancedMeshSegmentedTubesProvider/useInstancedMeshSegmentedTubes"
import { ArrowHelper, Box3, Box3Helper, BoxGeometry, BufferGeometry, Color, DoubleSide, Euler, Line, LineBasicMaterial, MathUtils, Matrix4, Mesh, MeshBasicMaterial, MeshStandardMaterial, Object3D, PlaneGeometry, Quaternion, Scene, Sphere, SphereGeometry, Vector3 } from "three"
import SegmentedTubeUI from "./ui/SegmentedTubeUI"
import useSegmentedTube from "./utils/useSegmentedTube"
import { isItemSelected } from "../../../../../../../state/atoms"
import { useRecoilState, useRecoilValue } from "recoil"
import { PartTypeEnum, TubeValues, SegmentedTubePart, XYZ, XYZW } from "../../../../../../../utils/Types"
import NewPartButtonsGroup from "./NewPartButtons/NewPartButtonsGroup"
import { allConnections, partConnections, undoRedoInProgressSelector, undoRedoChangeSelector } from "../../../../../../../state/scene/selectors"
import useGLTFLoader from "../../../../../../../providers/GLTFLoaderProvider/useGLTFLoader"
import { arrayRange, filterWithValue, SELECTED_PART_COLOR } from "../../../../../../../../common/utils/utils"
import { useRegisterSlide, useSlide } from "../../../../../../../providers/slideProvider/useSlide"
import { getMarkerNumber, innerToOuter, outerToInner } from "../../../../../../../utils/MarkerUtil"
import { MeshUtils } from "../../../../../../../utils/MeshUtils"
import { connectionTypesSelector } from "../../../../../../../state/initialDataSelectors"
import { useMultipleUpdates, useReplaceConnection, useUnsnap } from "../../../../../../../state/scene/setters"
import { useMultipleMovement, useRegisterMultipleMovement } from "../../../../../../../providers/multipleMovementProvider/useMultipleMovement"
import { ConnectionOfPart, PartConnectionType, UnitType } from "../../../../../../../state/scene/types"
import { MultipleMovementUtils } from "./utils/MultipleMovementUtils"
import { useHandleLengthChange } from "./utils/useHandleLengthChange"
import { SegmentedTubeSectionType } from "../../../../../../../../common/api/Types"
import { SoundHelper } from "../../../../utils/SoundHelper"
import { metersToInch } from "../../../../utils/utilsThree"
import useCamera from "../../../../../../../providers/cameraProvider/useCamera"
import { useRotationUtils } from "./utils/useRotationUtils"
import useCloseMarkers from "../../../../../../../providers/closeMarkersProvider/useCloseMarkers"
import useUnitConversion, { convertCmToIn, convertInToCm } from "../../../../utils/UnitUtils"
import { Billboard, CameraControls, Html, Text } from "@react-three/drei"
import { checkSnap } from "../tube/utils/TubeUtils"
import { useRegisterComponent, useComponentRegistry } from "../../../../../../../providers/multiselectProvider/useComponentMethods"
import { useNewPart } from "../../../../../../../state/scene/setters"
import { initialData } from "../../../../../../../state/atoms"
import { initialDataType } from "../../../../../../../state/types"
import { sceneAtom } from "../../../../../../../state/scene/atoms"
import { useLevaControls } from "../../../../../../../providers/debugProvider/useLevaControls"
import { MultiSelectContext } from "../../../../../../../providers/multiselectProvider/MultiSelectProvider"
import useMoonTexture from "../hooks/useMoonTexture"
import { calc } from "antd/es/theme/internal"
import useGetDebugVariables from "../../../../utils/useGetDebugVariables"
import { getPointsArrayForSingleEnd } from "../../../../../../../providers/moveProvider/meshBuilderEnds"
import hotkeys from "hotkeys-js"
import { messageUtils } from "../../../LowerRightMessages"
import { useGlobalAnimation } from "../utils/animations/GlobalAnimationProvider"
import { debounce } from "lodash"
import { getNormalDirection } from "../../../../../../../utils/MarkerUtil"
import { cameraContext } from "../../../../../../../providers/cameraProvider/CameraProvider"
import DebugText from "../../common/DebugText"
interface Props {
    id: string;
    handleDeletePart: (id: string) => void;
}

interface RefactorLater {
    [key: string]: any;  // Replace with specific types if known
}


const SegmentedTube = (props: Props) => {
    const [boundingBox, setBoundingBox,] = useState<Box3 | null>(null)
    const undoRedoInProgress = useRecoilValue(undoRedoInProgressSelector)
    const { tube, updateTubeValues, } = useSegmentedTube(props.id)
    const [isSelected, setIsSelected,] = useRecoilState(isItemSelected(tube.id))
    const [tubeUI, setTubeUI,] = useState<SEGMENTED_TUBE_UI>(SEGMENTED_TUBE_UI.NONE)
    const { scene, } = useThree()
    const segmentedTubeRef = useRef<SegmentedTubeInfo>(SegmentedTubeUtils.initSegmentedTubeInfo(scene))
    const [, setAttachmentPoint,] = useState(new Object3D())
    const { loadModel, } = useGLTFLoader()
    const [hideAddPartButtons, setHideAddPartButtons,] = useState(false)
    const partConnectionsValue = useRecoilValue(partConnections(tube.id))
    const [maxPossibleLength, setMaxPossibleLength,] = useState(tube.maxMiddles)
    const minLengthRef = useRef(1)
    const auxPointRef = useRef(new Mesh())
    const connectionTypes = useRecoilValue(connectionTypesSelector)
    const connections = useRecoilValue(allConnections)
    const multipleUpdate = useMultipleUpdates()
    const unsnap = useUnsnap()
    const markerMeshesWithoutMiddleSegments = useRef<Object3D[]>([])
    const closestMarkersHook = useCloseMarkers(tube.id)
    const closestMarkers = useRef<Mesh[]>([])
    const instantiated = useRef(false)
    const [unit, updateUnit,] = useState(tube.partUnits)
    const slide = useSlide(tube.id)
    const [boundingBoxWithPadding, setBoundingBoxWithPadding,] = useState<Box3 | null>(null)
    const allParts = useRecoilValue(initialData)
    const { viewOuterMarkers, viewInnerMarkers, viewTubeNames, showDebugText, } = useLevaControls()
    const multiSelectContext = useContext(MultiSelectContext)
    const { unregister, } = useComponentRegistry()
    const [initialMarkerState, setInitialMarkerState,] = useState(tube.initialMarkerName)
    const [uiVisible, setUiVisible,] = useState(false)
    const middleConnectionPositionsRef = useRef<{ segmentStep: number, connection: ConnectionOfPart, }[]>([])
    const { moonTexture, } = useMoonTexture(8, 8)
    const replaceConnection = useReplaceConnection()
    const { getVariables, } = useGetDebugVariables()
    const isAdmin = getVariables().isAdmin
    const { hideMergedMeshes, } = useLevaControls()
    const [scalingDimension, setScalingDimension,] = useState<"length" | "width" | "height">("length")
    const { triggerAnimation, } = useGlobalAnimation()
    const lastVisualUpdate = useRef<number>(0) //meant to prevent too many back to back undo/redo updates
    const [debugText, setDebugText,] = useState<string[]>([])
    const [boundingBoxCenter, setBoundingBoxCenter,] = useState<Vector3 | null>(null)
    const timeLimitBetweenUpdates = 100
    const context = useContext(cameraContext)
    const cameraControls = context ? context.getRef().current : null
    const wasDuplicated = !!tube.duplicatedFrom || !!tube.bulkCreated

    const findPartById = (apiTypeId: string) => {
        return allParts?.parts.find(part => part.id === apiTypeId)
    }

    const createPart = useNewPart()

    const {
        attachToMove,
        detachMarkers,
        handleSegmentedTubeLenghMovement,
        saveSegmentedTubeSliderChanges,
        canEditSegmentedTubeLength,
        handleSlideMovement,
        checkMaxLength,
        canEditSlide,
        saveRotationChanges,
    } = useMultipleMovement(tube.id)



    const [movableSection, setMovableSection,] = useState<Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>>(SegmentedTubeSectionType.END)

    const setUserRotationApplied = () => {
        updateTubeValues(tube.id, (t) => {
            t.userRotation = 0
        })
    }

    const [actualLength, setActualLength,] = useState({ positiveLength: tube.length, negativeLength: tube.lengthNegativeSide, })

    const middlePartInstancedMeshProvider = useInstancedMeshSegmentedTubes()
    const startPartInstancedMeshProvider = useInstancedMeshSegmentedTubes()
    const endPartInstancedMeshProvider = useInstancedMeshSegmentedTubes()
    const { getMiddleMesh, getStartMesh, getEndMesh, } = useInstancedMeshSegmentedTubes()
    const middleMesh = getMiddleMesh(tube.name)
    const startMesh = getStartMesh(tube.name)
    const endMesh = getEndMesh(tube.name)

    const updateTransforms = useCallback(() => {
        return
        SegmentedTubeUtils.updateInstancedTransforms(
            middlePartInstancedMeshProvider,
            startPartInstancedMeshProvider,
            endPartInstancedMeshProvider,
            segmentedTubeRef,
            tube.segmentScaleFactor,
            undefined,
            movableSection
        )
    }, [
        middlePartInstancedMeshProvider,
        startPartInstancedMeshProvider,
        endPartInstancedMeshProvider,
        segmentedTubeRef,
        tube.segmentScaleFactor,
        movableSection,
    ])

    const { fitBox, getInAutofocusMode, setInAutofocusMode, addMeshToSceneBounds, drawVector3Point, } = useCamera()

    const [debugNormals, setDebugNormals,] = useState(false)
    const [debugLabels, setDebugLabels,] = useState<{
        position: Vector3,
        text: string,
    }[]>([])

    useEffect(() => {
        if (boundingBox) {
            const growthFactor = 0.0005 // Adjust this value as needed
            const clonedBox = boundingBox.clone() // Clone the bounding box
            clonedBox.expandByScalar(growthFactor)
            setBoundingBoxWithPadding(clonedBox)
        }
    }, [boundingBox,])

    const { rotationSliderConfig, } = useRotationUtils({
        tubeId: props.id,
        tubeInfoRef: segmentedTubeRef,
        updateTransforms,
        setHideAddPartButtons,
        setUserRotationApplied,
        tubeUI,
        setTubeUI,
        movableSection,
        userRotation: tube.userRotation,
        unsnap: unsnap,
        boundingBox: boundingBoxWithPadding,
        initialMarkerName: initialMarkerState,
    })

    const boundgBoxForCamera = new Box3()

    const requestidleCallbackWithFallback = (callback: () => void, timeout: number) => {
        if (typeof requestIdleCallback === "undefined") {
            setTimeout(callback, timeout)
        } else {
            requestIdleCallback(callback)
        }
    }

    useEffect(() => {
        if (hideMergedMeshes && segmentedTubeRef.current.mergedMesh) {
            segmentedTubeRef.current.mergedMesh.visible = false
        } else if (!hideMergedMeshes && segmentedTubeRef.current.mergedMesh) {
            segmentedTubeRef.current.mergedMesh.visible = true
        }
    }, [hideMergedMeshes,])

    const calcBoundgBox = () => {
        if (segmentedTubeRef.current.mergedMesh) {
            boundgBoxForCamera.makeEmpty()
            segmentedTubeRef.current.mergedMesh.geometry.computeBoundingBox()
            boundgBoxForCamera.expandByObject(segmentedTubeRef.current.mergedMesh)
            // const clonedBox = boundgBoxForCamera.clone().applyMatrix4(segmentedTubeRef.current.mergedMesh.matrixWorld)
            setBoundingBox(boundgBoxForCamera)
            //const help = new Box3Helper(boundgBoxForCamera, new Color("blue"))
            //scene.add(help)
            return boundgBoxForCamera
        }
        const origins = segmentedTubeRef.current.instancedMeshOrigins
        const firstObject = origins[0]
        const lastObject = origins[origins.length - 1]
        if (firstObject && lastObject) {
            const firstMesh = firstObject.children.find(child => child.name.includes("mesh")) || firstObject.children[1]
            const lastMesh = lastObject.children.find(child => child.name.includes("mesh")) || lastObject.children[1]

            if (firstMesh && lastMesh) {
                (firstMesh as Mesh).geometry.computeBoundingBox();
                (lastMesh as Mesh).geometry.computeBoundingBox()

                boundgBoxForCamera.makeEmpty()

                boundgBoxForCamera.expandByObject(firstMesh)
                boundgBoxForCamera.expandByObject(lastMesh)

                const clonedBox = boundgBoxForCamera.clone()
                setBoundingBox(clonedBox)

                // Uncoment to debug bounds
                //const help = new Box3Helper(clonedBox, new Color("blue"))
                //scene.add(help)
                return boundgBoxForCamera
            }
        }

    }


    const updateCamera = () => {
        if (segmentedTubeRef.current.mergedMesh) {
            if (getInAutofocusMode()) {

                segmentedTubeRef.current.mergedMesh.geometry.computeBoundingBox()
                if (segmentedTubeRef.current.mergedMesh.geometry.boundingBox) {
                    fitBox(
                        segmentedTubeRef.current.mergedMesh.geometry.boundingBox,
                        undefined
                    )
                }
            }
            setTimeout(() => {
                calcBoundgBox()
            }, 2000)
            return
        }
        const origins = segmentedTubeRef.current.instancedMeshOrigins
        const firstObject = origins[0]
        const lastObject = origins[origins.length - 1]
        if (firstObject && lastObject) {
            const firstMesh = firstObject.children[1]
            const lastMesh = lastObject.children[1]
            if (firstMesh && lastMesh) {

                calcBoundgBox()

                if (getInAutofocusMode()) {
                    fitBox(
                        boundgBoxForCamera,
                        undefined
                    )
                }
            }
        }
    }

    const handleLengthChanges = useHandleLengthChange({
        saveChanges: saveSegmentedTubeSliderChanges,
        tubeInternalRef: segmentedTubeRef,
        tubeUI: tubeUI,
        canEdit: canEditSegmentedTubeLength,
        direction: movableSection,
        checkMaxLength,
        updateMaxMinPossibleLength: (partsToMove?: string[]) => {
            updateMinLength()
            SegmentedTubeUtils.checkMaxPossibleLength(
                scene,
                tube,
                segmentedTubeRef,
                partConnectionsValue,
                connectionTypes,
                movableSection,
                setMaxPossibleLength,
                actualLength,
                partsToMove
            )
        },
    })
    const slideRegister = useRegisterSlide({
        id: props.id,
        sides: segmentedTubeRef.current.middleSection,
        startLength: tube.startSegmentLength,
        endLength: tube.endSegmentLength,
        segmentLength: tube.segmentLength,
        type: PartTypeEnum.segmentedTube,
        mergedMesh: segmentedTubeRef.current.mergedMesh!,
    })

    const detachFromMarker = MultipleMovementUtils.useDetachFromMarker({ scene, })

    useRegisterMultipleMovement({
        id: props.id,

        attachToMarker: (marker: Mesh) => {
            const origin = segmentedTubeRef.current.attachmentPoint!
            marker.attach(origin)
        },

        detachFromMarker: (
            marker: Mesh,
            connections: PartConnectionType[],
            getExactSnaps?: boolean,
            checkCollisions?: boolean,
            resetGuidelines?: boolean
        ) => {
            return detachFromMarker(
                marker,
                tube,
                segmentedTubeRef,
                partConnectionsValue,
                connectionTypes,
                connections,
                movableSection,
                getExactSnaps,
                resetGuidelines)
        },
        checksOnLengthMove: (
            elongationDirection: Vector3,
            connections: PartConnectionType[],
            lengthDirection: number
        ) => {
            return {
                ...MultipleMovementUtils.checkMultipleMoveSnap(
                    scene,
                    tube,
                    segmentedTubeRef,
                    partConnectionsValue,
                    connectionTypes,
                    connections,
                    movableSection),
                ...{
                    drag: true,
                    dragDistance: lengthDirection < 0 ? -lengthDirection : lengthDirection,
                },
            }
        },
        checksOnSegmentedTubeLengthMove: (intersectableMeshes: Object3D[], connections: PartConnectionType[], checkSnap?: boolean) => {
            MultipleMovementUtils.checkMultipleMoveAligments(scene, tube, segmentedTubeRef, partConnectionsValue, [SegmentedTubeSectionType.START, SegmentedTubeSectionType.END,], intersectableMeshes)
            if (checkSnap) {
                const { newConnections, } = SegmentedTubeUtils.checkSnapOnEditionMovableSection(
                    scene,
                    tube,
                    segmentedTubeRef,
                    partConnectionsValue,
                    connectionTypes,
                    connections,
                    movableSection,
                    [],
                    [],
                    true
                )
                if (newConnections.length > 0) {
                    SoundHelper.playUnsnap()
                }
            }
        },
        checksOnRotationMove: (connections: PartConnectionType[], intersectableMeshes: Object3D[]) => {
            const { newConnections, } = SegmentedTubeUtils.checkSnapOnEditionMovableSection(
                scene,
                tube,
                segmentedTubeRef,
                partConnectionsValue,
                connectionTypes,
                connections,
                movableSection,
                [],
                [],
                true
            )
            MultipleMovementUtils.checkMultipleMoveAligments(scene, tube, segmentedTubeRef, partConnectionsValue, [movableSection,], intersectableMeshes)
            if (newConnections.length > 0) {
                SoundHelper.playUnsnap()
            }
        },
        getMaxLength: (direction: Vector3, connections?: PartConnectionType[]) => {
            if (MultipleMovementUtils.isMovableMarkerConnected(tube, segmentedTubeRef, connections!, movableSection)) {
                return null
            } else {
                return ((SegmentedTubeUtils.getMaxPossibleLength(
                    scene,
                    tube,
                    segmentedTubeRef,
                    partConnectionsValue,
                    connectionTypes,
                    movableSection,
                    actualLength
                ) - (actualLength.positiveLength + actualLength.negativeLength)) * tube.segmentLength) / metersToInch
            }
        },
        getPosAndRot: () => {
            const attachmentPoint = segmentedTubeRef.current.attachmentPoint
            const pos = MeshUtils.copyWorldPosition(attachmentPoint!)
            attachmentPoint!.rotateY(MathUtils.degToRad(-180))
            const rot = MeshUtils.copyWorldQuaternion(attachmentPoint!)
            attachmentPoint!.rotateY(MathUtils.degToRad(180))
            return { pos, rot, }
        },
        updateTransforms,
    })

    const attachOriginToPoint = (referencePoint: Vector3, anchorPosition?: Vector3) => {
        // window.drawVector3Point(referencePoint, "ff0000", 0.002, 10000)
        // window.drawVector3Point(anchorPosition, "00ff00", 0.002, 10000)
    }

    const attachOriginToPoint2 = (referencePoint: Vector3, anchorPosition?: Vector3) => {
        const attachmentPoint = segmentedTubeRef.current.attachmentPoint
        if (!attachmentPoint) { return }

        // Store the original parent for reference
        const originalParent = attachmentPoint.parent
        if (!originalParent) { return }

        // Create a temporary object to help with transformations
        const tempObject = new Object3D()
        scene.add(tempObject)

        // First, calculate the local position relative to the original parent
        const localPosition = new Vector3()
        const worldPosition = new Vector3()
        const parentWorldMatrix = new Matrix4()

        // Get parent's world matrix
        originalParent.updateWorldMatrix(true, false)
        parentWorldMatrix.copy(originalParent.matrixWorld)

        // Calculate offset between reference and anchor
        const offset = anchorPosition
            ? anchorPosition.clone().sub(referencePoint)
            : new Vector3()

        // Get attachment point's current world position
        attachmentPoint.getWorldPosition(worldPosition)

        // Apply the offset
        worldPosition.add(offset)

        // Convert world position to local position relative to parent
        localPosition.copy(worldPosition)
        localPosition.applyMatrix4(parentWorldMatrix.invert())

        // Apply the new position
        attachmentPoint.position.copy(localPosition)

        // Update matrices
        attachmentPoint.updateMatrix()
        originalParent.updateMatrixWorld(true)

        // Clean up
        tempObject.removeFromParent()

        // For debugging
        const debugSphere = new Mesh(
            new SphereGeometry(0.002, 32, 32),
            new MeshBasicMaterial({ color: 0xff0000, })
        )
        debugSphere.position.copy(worldPosition)
        scene.add(debugSphere)

        setTimeout(() => {
            debugSphere.removeFromParent()
        }, 1000)
    }
    const onTubeSelected = useCallback(() => {
        setInAutofocusMode(true)
        updateColor(SELECTED_PART_COLOR)
        setIsSelected(true)


        //when not following this flow, the box orientation is wrong
        const box = new Box3()
        box.makeEmpty()
        if (segmentedTubeRef.current.mergedMesh) {
            segmentedTubeRef.current.mergedMesh.geometry.computeBoundingBox()
            box.expandByObject(segmentedTubeRef.current.mergedMesh)
        }

        //unfortunately sometimes autofocus state is not updating fast enough sometimes
        if (segmentedTubeRef.current.mergedMesh && getInAutofocusMode()) {
            segmentedTubeRef.current.mergedMesh.geometry.computeBoundingBox()
            if (segmentedTubeRef.current.mergedMesh.geometry.boundingBox) {
                fitBox(
                    box,
                    undefined
                )
            }
        }
        if (viewOuterMarkers || viewInnerMarkers) {
            // hack to allow marker selection on debug tool without activating other tube selection
            setTubeUI(SEGMENTED_TUBE_UI.SEGMENTED_SLIDER)
            return
        }
        multiSelectContext?.setTransformMode("off")
        //console.log(rotationSliderConfig.getRotationMarker(), "rotationSliderConfig.getRotationMarker()")
    }, [setInAutofocusMode, getInAutofocusMode,])

    const getProvider = (sectionType: SectionType) => {
        switch (sectionType) {
            case SectionType.END:
                return endPartInstancedMeshProvider
            case SectionType.MIDDLE:
                return middlePartInstancedMeshProvider
            case SectionType.START:
                return startPartInstancedMeshProvider
        }
    }


    const loadModelsAndMarkers = async (modelPath: string) => {
        const gltf = await loadModel(modelPath)
        // This function will decompose the blender file into usable data
        segmentedTubeRef.current.blenderData = SegmentedTubeUtils.processData(gltf)
    }

    const createPools = () => {
        segmentedTubeRef.current.blenderData.forEach((bd) => {
            SegmentedTubeUtils.createPool(
                props.id,
                `${tube.name}_${bd.sectionType}`,
                bd.mainModel,
                scene,
                getProvider(bd.sectionType),
                onTubeSelected)
        })
    }

    const updateMarkersClosest = () => {
        const markers = [
            ...Object.values(segmentedTubeRef.current.startSection).map(marker => marker.outer),
            ...Object.values(segmentedTubeRef.current.endSection).map(marker => marker.outer),
            ...Object.values(segmentedTubeRef.current.middleSection).flatMap(side =>
                Object.values(side).map(marker => marker.outer)
            ),
        ]
        closestMarkersHook.updateMarkers(filterWithValue(markers))
    }

    const createPivotPoint = () => {
        // Create pivot point of the tube
        // Dummy objects of each part will be attached to this
        segmentedTubeRef.current.originPoint = new Object3D()
        segmentedTubeRef.current.originPoint.name = "Origin Point"
        // const axeHelper = new AxesHelper(1)
        // info.current.pivotPoint.add(axeHelper);
        // info.current.pivotPoint.position.set(props.position[0], props.position[1], props.position[2]);
        // SegmentedTubeInfo.current.tubePivotPoint.rotation.set(MathUtils.radToDeg(45), MathUtils.radToDeg(45), 0)
        scene.add(segmentedTubeRef.current.originPoint)
    }

    const updateSlide = (tubeRef: SegmentedTubeInfo) => {
        const middleSection = tubeRef.middleSection
        const filterSectionByMeshProperty = (section: SegmentedTubeMarkers) => {
            return Object.entries(section)
                .filter(([_, value,]) => "mesh" in value)
                .reduce((acc, [key, value,]) => ({ ...acc, [key]: value, }), {})
        }
        const encloseSectionObjects = (section: SegmentedTubeMarkers) => {
            return Object.entries(section)
                .reduce((acc, [key, value,]) => ({ ...acc, [key]: { "0": value, }, }), {})
        }

        const startSection = encloseSectionObjects(filterSectionByMeshProperty(tubeRef.startSection))
        const endSection = encloseSectionObjects(filterSectionByMeshProperty(tubeRef.endSection))

        const sides = {
            ...startSection,
            ...middleSection,
            ...endSection,
        }

        slideRegister.updateSlide(sides, tubeRef.mergedMesh!)
    }

    const setupAttachmentPoint = () => {
        const attachmentPoint = getAttachmentPointOrigin()

        const secondaryAttachmentPoint = segmentedTubeRef.current.instancedMeshOrigins[2]
            ? (segmentedTubeRef.current.instancedMeshOrigins[2].children.length >= 3
                ? segmentedTubeRef.current.instancedMeshOrigins[2].children[2]
                : segmentedTubeRef.current.instancedMeshOrigins[2].children[0])
            : undefined

        //console.log(secondaryAttachmentPoint, "secondaryAttachmentPoint")

        let markerPosition
        if (tube.markerOffset) {
            markerPosition = tube.markerOffset
        } else {
            markerPosition = tube.position
        }
        SegmentedTubeUtils.setAtachmentPoint(
            attachmentPoint || secondaryAttachmentPoint!, segmentedTubeRef, setAttachmentPoint
        )
        //console.log(markerPosition, "markerPosition")
        //console.log(tube.rotation, "tube.rotation")
        setPositionAndRotation(markerPosition, tube.rotation)
        //console.log(tube.markerOffset, "tube.markerOffset")

        if (tube.markerOffset) {
            const apPosition = new Vector3(tube.position.x, tube.position.y, tube.position.z)
            SegmentedTubeUtils.setAtachmentPoint(
                attachmentPoint || secondaryAttachmentPoint!, segmentedTubeRef, setAttachmentPoint, apPosition
            )
        }

    }

    const setupUnits = () => {
        //migration for bad negative length values
        let negativeLengthTouse = tube.lengthNegativeSide

        if (tube.lengthNegativeSide < 0) {
            negativeLengthTouse = 0
        }
        if (tube.unitRealValue || tube.loaded) {
            SegmentedTubeUtils.createInstancedMeshOrigins(
                tube,
                segmentedTubeRef,
                middlePartInstancedMeshProvider,
                startPartInstancedMeshProvider,
                endPartInstancedMeshProvider,
                tube.length,
                negativeLengthTouse,
                tube.segmentScaleFactor,
                moonTexture,
                getColor(),
                isAdmin ?? undefined,
            )
            return
        }
        const isInches = tube.name.includes("'") || /\b(in|In|IN)\b/.test(tube.name)
        const isMetric = /\b(mm|cm|MM|CM)\b/.test(tube.name) || tube.name.includes("mm") || tube.name.includes("cm")

        if (isInches) {
            SegmentedTubeUtils.createInstancedMeshOrigins(
                tube,
                segmentedTubeRef,
                middlePartInstancedMeshProvider,
                startPartInstancedMeshProvider,
                endPartInstancedMeshProvider,
                tube.length,
                negativeLengthTouse,
                tube.segmentScaleFactor,
                moonTexture,
                getColor(),
            )
            updateUnit("in")
        } else if (isMetric) {
            updateUnit("cm")
            const currentInLength = ((tube.length + negativeLengthTouse) * tube.segmentLength + tube.endSegmentLength + tube.startSegmentLength)
            const lengthInCm = convertInToCm(currentInLength)
            saveLength(lengthInCm, true, true, false, undefined, undefined, true)
        }
    }


    const getMiddleMarker = (inner: boolean, outer: boolean) => {
        const markers = Object.values(segmentedTubeRef.current.middleSection)
            .flatMap(side =>
                Object.values(side)
                    .filter(marker => (inner && "inner" in marker) || (outer && "outer" in marker))
                    .map(marker => {
                        const result = []
                        if (inner && "inner" in marker) {
                            result.push(marker.inner)
                        }
                        if (outer && "outer" in marker) {
                            result.push(marker.outer)
                        }
                        return result
                    })
            )
            .flat() // Flatten in case both inner and outer are selected

        //console.log(markers, "getMiddleMarker");
        return markers
    }


    const findMissingMarkersFromPartConnections = (partConnectionsValue: ConnectionOfPart[]) => {
        const allMarkers = getMiddleMarker(true, true)
        const missingMarkers: string[] = []

        //console.log(connections, "connections first")

        // Find missing markers
        partConnectionsValue.forEach(connection => {
            if (!allMarkers.some(marker => marker?.name === connection.partMarkerName) && connection.partMarkerName.includes("_")) {
                missingMarkers.push(connection.partMarkerName)
            }
        })

        if (missingMarkers.length === 0) { return }

        const newConnections: PartConnectionType[] = []

        //console.log(missingMarkers, "missingMarkers")

        //console.log(allMarkers, "allMarkers")

        missingMarkers.forEach(missingMarker => {
            if (missingMarker.includes("_")) {
                const [baseMarker,] = missingMarker.split("_")
                const isOuter = missingMarker.startsWith("outer")
                const middleMarkers = getMiddleMarker(true, true)

                const replacementMarker = middleMarkers.find(marker => {
                    const markerName = marker?.name
                    return markerName?.startsWith(baseMarker)
                        && ((isOuter && markerName?.startsWith("outer"))
                            || (!isOuter && markerName?.startsWith("inner")))
                })

                //console.log(replacementMarker, "replacementMarker")

                if (replacementMarker) {
                    // Find the existing connection
                    const existingConnection = partConnectionsValue.find(
                        conn => conn.partMarkerName === missingMarker
                    )
                    if (existingConnection) {
                        newConnections.push({
                            partA: {
                                partId: existingConnection.destinationPartId,
                                markerName: existingConnection.destinationMarkerName,
                            },
                            partB: {
                                partId: tube.id,
                                markerName: replacementMarker.name,
                            },
                        })
                        //console.log(existingConnection.slidePosition, "existingConnection.slidePosition")
                        replaceConnection(
                            tube.id,
                            existingConnection.partMarkerName,
                            {
                                partA: {
                                    partId: existingConnection.destinationPartId,
                                    markerName: existingConnection.destinationMarkerName,
                                },
                                partB: {
                                    partId: tube.id,
                                    markerName: replacementMarker.name,
                                },
                            },
                            existingConnection.slidePosition!,
                            undefined,
                            true
                        )

                    }
                }
                // Check if the initialMarkerName needs to be updated
                if (tube.initialMarkerName === missingMarker && replacementMarker) {
                    updateTubeValues(tube.id, (t) => {
                        t.initialMarkerName = replacementMarker?.name
                    })
                    setInitialMarkerState(replacementMarker?.name)
                    //console.log(`Updated initialMarkerName from ${missingMarker} to ${replacementMarker.name}`)
                }
            }
        })

        //console.log(connections, "all connections")

        //console.log(newConnections, "newCOnnections")

        if (newConnections.length > 0) {
            //multipleUpdate([], newConnections)
        }
    }


    const initialSetup = async (visualUpdateOnly?: boolean) => {
        //console.log("initialSetup")

        if (!visualUpdateOnly) {

            if (tube.lengthNegativeSide < 0) {
                updateTubeValues(tube.id, (t) => {
                    t.lengthNegativeSide = 0
                }, true)
            }
            createPivotPoint()
            await loadModelsAndMarkers(`${tube.fileURL}?t=${Date.now()}`)

        }

        setTimeout(() => {
            SegmentedTubeUtils.createInstancedMeshOrigins(
                tube,
                segmentedTubeRef,
                middlePartInstancedMeshProvider,
                startPartInstancedMeshProvider,
                endPartInstancedMeshProvider,
                tube.length,
                0,
                tube.segmentScaleFactor,
                moonTexture,
                getColor(),
                isAdmin ?? undefined,
            )

            setupAttachmentPoint()

            SegmentedTubeUtils.checkMaxPossibleLength(scene, tube, segmentedTubeRef, partConnectionsValue, connectionTypes, movableSection, setMaxPossibleLength, actualLength)
            SegmentedTubeUtils.setSnappedStateOnLoad(segmentedTubeRef, partConnectionsValue)
            const defaultMovementSide = segmentedTubeRef.current.snapped.end ? SegmentedTubeSectionType.START : SegmentedTubeSectionType.END
            setDirection(defaultMovementSide)
            if (tube.loaded || visualUpdateOnly) {
                setTubeUI(SEGMENTED_TUBE_UI.NONE)
                updateColor(getColor())
            }
            if (!tube.loaded && !wasDuplicated) {
                onTubeSelected()
            }

            setupUnits()

            updateSlide(segmentedTubeRef.current)
            updateMinLength()

            !visualUpdateOnly && SegmentedTubeUtils.checkDiagonalEdges(segmentedTubeRef)
            !visualUpdateOnly && updateMarkersClosest()



            !visualUpdateOnly && addMeshToSceneBounds()
            !visualUpdateOnly && registerMultiSelectMethods()
            setPositionAndRotation(tube.position, tube.rotation)
            scaleSegmentedTube(undefined, undefined, true)
            instantiated.current = true
            updateTubeValues(tube.id, (t) => {
                t.instanciated = true
            }, true)
            if (!tube.markers[0].positionXYZ) {
                updateMarkerPositionMatrixInfo()
            }
        }, 50)
    }


    const getEveryMarker = () => {
        const markers = [
            ...Object.values(segmentedTubeRef.current.startSection).flatMap(markerGroup =>
                Object.values(markerGroup).filter(marker => marker !== undefined)
            ),
            ...Object.values(segmentedTubeRef.current.endSection).flatMap(markerGroup =>
                Object.values(markerGroup).filter(marker => marker !== undefined)
            ),
            ...Object.values(segmentedTubeRef.current.middleSection).flatMap(side =>
                Object.values(side).flatMap(markerGroup =>
                    Object.values(markerGroup).filter(marker => marker !== undefined)
                )
            ),
        ]
        return markers
    }


    const updateNameFromScaledDimensions = () => {
        //console.log("updateNameFromScaledDimensions")
        if ((!tube.baseName) || (!tube.widthScaling && !tube.heightScaling) || (!tube.modifiedHeight && !tube.modifiedWidth)) { return }

        const formatValue = (value: number | undefined, unit: string | undefined) => {
            if (value === undefined) { return "" }
            return `${value}${unit === "in" ? '"' : "cm"}`
        }

        let heightToUse = tube.realHeight
        let heightUnitsToUse = "in"
        let widthToUse = tube.realWidth
        let widthUnitsToUse = "in"

        if (tube.modifiedHeight && tube.modifiedHeightUnits) {
            heightToUse = tube.modifiedHeight
            heightUnitsToUse = tube.modifiedHeightUnits
        }
        if (tube.modifiedWidth && tube.modifiedWidthUnits) {
            widthToUse = tube.modifiedWidth
            widthUnitsToUse = tube.modifiedWidthUnits
        }
        const height = formatValue(heightToUse, heightUnitsToUse)
        const width = formatValue(widthToUse, widthUnitsToUse)

        let newName = tube.baseName
        if (height || width) {
            newName += ` ${[width, height,].filter(Boolean).join(" x ")}`
        }

        //console.log(newName, "newName")

        updateTubeValues(tube.id, (t) => {
            t.name = newName
        }, true)
    }

    useEffect(() => {
        updateNameFromScaledDimensions()

    }, [tube.length, tube.lengthNegativeSide, tube.modifiedHeight, tube.modifiedWidth,])


    const setPositionAndRotation = (position?: XYZ, rotation?: XYZW) => {
        if (position) {
            segmentedTubeRef.current.attachmentPoint?.position.copy(new Vector3(position.x, position.y, position.z))
        }
        if (rotation) {
            segmentedTubeRef.current.attachmentPoint?.quaternion.copy(new Quaternion(rotation.x, rotation.y, rotation.z, rotation.w))
        }
        segmentedTubeRef.current.attachmentPoint?.rotateY(MathUtils.degToRad(180))
        lastVisualUpdate.current = Date.now()
        segmentedTubeRef.current.attachmentPoint?.updateWorldMatrix(true, true)
        updateTransforms()
    }


    useEffect(() => {
        initialSetup()


        return () => {
            deletePart()
        }
    }, [])

    const deletePart = () => {
        SegmentedTubeUtils.cleanUpPreviousOrigins(segmentedTubeRef)
        SegmentedTubeUtils.removeGuidelines(scene, segmentedTubeRef)
        if (segmentedTubeRef.current.mergedMesh) {
            segmentedTubeRef.current.mergedMesh.removeFromParent()
        }
        //middlePartInstancedMeshProvider.deleteInstancedMesh()
        //startPartInstancedMeshProvider.deleteInstancedMesh()
        //endPartInstancedMeshProvider.deleteInstancedMesh()
    }

    const debugMiddleReferencePoints = (
        info: SegmentedTubeInfo,
        scene: Scene,
        color = "red",
        size = 0.0005
    ) => {
        const middleSection = info.middleSection

        Object.values(middleSection).forEach(sideMarkers => {
            Object.values(sideMarkers).forEach(markers => {
                const { inner, outer, } = markers

                console.log(inner, "inner")
                console.log(outer, "outer")

                if (inner && inner.userData.middleRefs) {
                    inner.userData.middleRefs.forEach((ref: { refName: string, localOffset: Vector3, }) => {
                        const worldPosition = inner.localToWorld(new Vector3().copy(ref.localOffset))
                        drawVector3Point(worldPosition, scene, color, size, 5000, true)
                        //console.log(`Inner middleRef point drawn: ${ref.refName}, ${worldPosition.toArray().join(', ')}`)
                    })
                }

                if (outer && outer.userData.middleRefs) {
                    outer.userData.middleRefs.forEach((ref: { refName: string, localOffset: Vector3, }) => {
                        const worldPosition = outer.localToWorld(new Vector3().copy(ref.localOffset))
                        drawVector3Point(worldPosition, scene, "blue", size, 5000, true) // Using a different color for outer points
                        //console.log(`Outer middleRef point drawn: ${ref.refName}, ${worldPosition.toArray().join(', ')}`)
                    })
                }
                if (inner && inner.userData.localSlidePoints) {
                    inner.userData.localSlidePoints.forEach((ref: { meshName: string, position: Vector3, }) => {
                        const worldPosition = inner.localToWorld(new Vector3().copy(ref.position))
                        drawVector3Point(worldPosition, scene, "purple", size, 5000, true) // Using a different color for outer points
                        //console.log(`Outer middleRef point drawn: ${ref.refName}, ${worldPosition.toArray().join(', ')}`)
                    })
                }
            })
        })

        //console.log('Middle reference points visualized')
    }

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            event.preventDefault()
            removePart()
        }

        if (isSelected) {
            hotkeys("delete,backspace", handleKeyPress)
        }

        // Cleanup function
        return () => {
            hotkeys.unbind("delete,backspace", handleKeyPress)
        }
    }, [isSelected,])

    useEffect(() => {
        if (instantiated.current) {
            if (!isSelected) {
                setTubeUI(SEGMENTED_TUBE_UI.CLOSE)
                SegmentedTubeUtils.removeGuidelines(scene, segmentedTubeRef)
                updateColor(getColor())
            }

            if (isSelected) {
                //console.log("segmentedTubeRef current", segmentedTubeRef.current)
                //SegmentedTubeUtils.setSnappedStateOnLoad(segmentedTubeRef, partConnectionsValue)
                //console.log(tube.id, tube, "tube.id is selected", segmentedTubeRef.current)
                updateColor(getColor())
                onTubeSelected()
                //debugMiddleReferencePoints(segmentedTubeRef.current, scene)
                //console.log(connections, "connections")

            }
        }

        requestidleCallbackWithFallback(calcBoundgBox, 200)
    }, [isSelected,])

    const getAttachmentPointOrigin = () => {
        const outerInitialMarker = innerToOuter(tube.initialMarkerName)

        //console.log(outerInitialMarker, "outerInitialMarker")

        //console.log(getAttachmentPointMarker(outerInitialMarker), "getAttachmentPointOrigin")
        //console.log(segmentedTubeRef.current.startSection, "segmentedTubeRef.current.startSection")
        //console.log(segmentedTubeRef.current.endSection, "segmentedTubeRef.current.endSection")
        //console.log(segmentedTubeRef.current.middleSection, "segmentedTubeRef.current.middleSection")

        //console.log(getAllMarkers(), "getAllMarkers")

        return getAttachmentPointMarker(outerInitialMarker)
    }

    const getAttachmentPointMarker = (markerName: string) => {
        //console.log(markerName, "getAttachmentPointMarker markerName")

        let makerNametoFind = markerName

        const markerNameHasUnderScore = markerName.includes("_")
        const markerDoesHasUnderScoreZero = markerName.includes("_0")

        const markerConditions = markerNameHasUnderScore && !markerDoesHasUnderScoreZero

        if (markerConditions) {
            const [baseMarker, markerNumber,] = markerName.split("_")
            makerNametoFind = `${baseMarker}_0`
            //console.log(makerNametoFind, "makerNametoFind")
        }

        const originWithMarker = segmentedTubeRef.current.instancedMeshOrigins
            .find(origin => origin.children.some(c => c.name === makerNametoFind))

        //console.log(originWithMarker, "originWithMarker")

        if (originWithMarker && !markerConditions) {
            //console.log("doesn't meet")
            const childWithName = originWithMarker.children.find(child => child.name === markerName)
            //console.log(childWithName, "childWithName does not markerNameHasUnderScore")
            return childWithName
        }

        if (originWithMarker && markerConditions) {
            //console.log("meets")
            const childWithName = originWithMarker.children.find(child => child.name === makerNametoFind)
            //console.log(childWithName, "childWithName")

            if (childWithName && childWithName.userData.middleRefs) {
                const matchingRef = childWithName.userData.middleRefs.find(
                    (ref: { refName: string, }) => ref.refName === markerName
                )

                if (matchingRef) {
                    const clonedObject = new Object3D()
                    clonedObject.name = markerName
                    clonedObject.userData = { ...childWithName.userData, middleRefs: [matchingRef,], }

                    // Clone the position, rotation, and scale
                    clonedObject.position.copy(childWithName.position)
                    clonedObject.rotation.copy(childWithName.rotation)
                    clonedObject.scale.copy(childWithName.scale)

                    // Apply the localOffset to set the new position
                    const worldPosition = childWithName.localToWorld(new Vector3().copy(matchingRef.localOffset))
                    clonedObject.position.copy(worldPosition)

                    const worldQuaternion = new Quaternion()
                    childWithName.getWorldQuaternion(worldQuaternion)
                    clonedObject.quaternion.copy(worldQuaternion)

                    //drawVector3Point(worldPosition, scene, "green", 0.002, undefined, true)
                    //console.log(clonedObject, "clonedObject with adjusted position")

                    segmentedTubeRef.current.originPoint?.attach(clonedObject)
                    return clonedObject
                }
            }

            return childWithName
        }

        //console.log(undefined, `getAttachmentPointMarker ${markerName} undefined`)
        return undefined
    }

    const handleNewAttachmentPoint = (markerName: string, sliderPartId: string | undefined, position?: Vector3, ignoreHistory?: boolean, historyKey?: string) => {
        //console.log("handleNewAttachmentPoint", markerName, sliderPartId, position, ignoreHistory, historyKey)
        const attachmentPoint = getAttachmentPointMarker(markerName)
        if (attachmentPoint) {
            const newAttachmentPoint = segmentedTubeRef.current.instancedMeshOrigins
                .find(origin => origin.children.some(c => c.name === markerName))
                ?.children.find(child => child.name === markerName)!

            if (newAttachmentPoint && sliderPartId) {
                newAttachmentPoint.rotateY(MathUtils.degToRad(-180))
                let newPos: Vector3
                if (position) {
                    newPos = position
                } else {
                    newPos = MeshUtils.copyWorldPosition(newAttachmentPoint)
                }
                const markerPosition = MeshUtils.copyWorldPosition(newAttachmentPoint)
                const newRot = MeshUtils.copyWorldQuaternion(newAttachmentPoint)
                newAttachmentPoint.rotateY(MathUtils.degToRad(180))

                SegmentedTubeUtils.setAtachmentPoint(newAttachmentPoint, segmentedTubeRef, setAttachmentPoint, newPos)
                slide.setNewAttachmentPoint(sliderPartId, markerName, newAttachmentPoint, newPos, drawVector3Point, scene, ignoreHistory, historyKey)

                updateTubeValues(tube.id, (t) => {
                    t.initialMarkerName = newAttachmentPoint.name
                    t.position = { x: newPos.x, y: newPos.y, z: newPos.z, }
                    t.rotation = { x: newRot.x, y: newRot.y, z: newRot.z, w: newRot.w, }
                    t.markerOffset = { x: markerPosition.x, y: markerPosition.y, z: markerPosition.z, }
                }, ignoreHistory, historyKey)
            }
        }
    }
    const handleUpdateAttachmentPoint = () => {
        const attachmentPoint = getAttachmentPointOrigin()
        if (!attachmentPoint && segmentedTubeRef.current.instancedMeshOrigins.length > 0) {
            const markerName = innerToOuter(innerToOuter(tube.markers[tube.markers.length - 1].name))

            const newAttachmentPoint = segmentedTubeRef.current.instancedMeshOrigins
                .find(origin => origin.children.some(c => c.name === markerName))
                ?.children.find(child => child.name === markerName)!
            if (newAttachmentPoint) {
                newAttachmentPoint.rotateY(MathUtils.degToRad(-180))
                const newPos = MeshUtils.copyWorldPosition(newAttachmentPoint)
                const newRot = MeshUtils.copyWorldQuaternion(newAttachmentPoint)
                newAttachmentPoint.rotateY(MathUtils.degToRad(180))

                SegmentedTubeUtils.setAtachmentPoint(newAttachmentPoint, segmentedTubeRef, setAttachmentPoint)

                updateTubeValues(tube.id, (t) => {
                    t.initialMarkerName = newAttachmentPoint.name
                    t.position = { x: newPos.x, y: newPos.y, z: newPos.z, }
                    t.rotation = { x: newRot.x, y: newRot.y, z: newRot.z, w: newRot.w, }
                })
            }
        }
    }

    const removePart = () => {
        if (handleLengthChanges.multipleMove
            && handleLengthChanges.multipleMove?.partsToMove
        ) {
            attachToMove(handleLengthChanges.multipleMove.partsToMove, auxPointRef.current)
            detachMarkers(
                handleLengthChanges.multipleMove.partsToMove,
                auxPointRef.current,
                false,
                false,
                true
            )
        }
        if (segmentedTubeRef.current.mergedMesh) {
            segmentedTubeRef.current.mergedMesh.removeFromParent()
        }
        props.handleDeletePart(tube.id)
        unregister(tube.id)
    }

    const getMaxAndMinMiddlePositions = () => {
        const middleSection = segmentedTubeRef.current.middleSection
        const side = middleSection[Object.keys(middleSection)[0]]
        if (side) {
            const positions = Object.keys(side)
                .map(key => {
                    const inner = side[key].inner
                    if (inner && inner.name) {
                        const parts = inner.name.split("_")
                        return parts.length > 1 ? Number(parts[1]) : null
                    }
                    return null
                })
                .filter((position): position is number => position !== null)

            if (positions.length > 0) {
                positions.sort((a, b) => a - b)
                const min = positions[0]
                const max = positions[positions.length - 1]
                return { min, max, }
            }
        }
        return { min: 1, max: 1, }
    }
    const updateInitialMarkerPositionIfMiddle = (debug = false, skipSavingValues?: boolean, callback?: () => void) => {

        if (debug) {
            const currentPosition = new Vector3(tube.position.x, tube.position.y, tube.position.z,)
            drawVector3Point(currentPosition, scene, "red", 0.002, 10000, true)
        }
        // 1. Check if initial marker name has underscore
        if (tube.initialMarkerName.includes("_")) {
            // 2. Get the new world position of that marker
            const allMarkers = getAllMarkers()
            const initialMarker = allMarkers.find(marker => marker?.name === innerToOuter(tube.initialMarkerName))
                || allMarkers.find(marker => marker?.name === outerToInner(tube.initialMarkerName))


            if (initialMarker) {
                // 3. Get the world position
                const worldPosition = new Vector3()
                initialMarker.getWorldPosition(worldPosition)

                // 4. Update the position value of the tube


                const updates: any = {
                    position: { x: worldPosition.x, y: worldPosition.y, z: worldPosition.z, },
                }

                if (tube.markerOffset) {
                    updates.markerOffset = { x: worldPosition.x, y: worldPosition.y, z: worldPosition.z, }
                }

                if (!skipSavingValues) {
                    debouncedUpdateTubeValues(tube.id, updates)
                }

                if (debug) {
                    drawVector3Point(worldPosition, scene, "blue", 0.002, 10000, true)
                }

            } else {
                console.warn(`Initial marker ${tube.initialMarkerName} not found`)
            }
        }
        callback?.()
    }

    const updateMinLength = useCallback(() => {
        const { min, max, } = getMaxAndMinMiddlePositions()

        middleConnectionPositionsRef.current = partConnectionsValue
            .map(connection => {
                const markerNumber = getMarkerNumber(connection.partMarkerName)
                // Check if markerNumber is defined and contains an underscore
                if (markerNumber && markerNumber.includes("_")) {
                    const [, segmentStep,] = markerNumber.split("_")
                    if (segmentStep) {
                        return {
                            segmentStep: Number(segmentStep),
                            connection: connection,
                        }
                    }
                }
                return null
            })
            .filter((item): item is { segmentStep: number, connection: ConnectionOfPart, } => item !== null)

        if (movableSection === SegmentedTubeSectionType.END) {
            //middleConnectionPositionsRef.current.sort((a, b) => b.segmentStep - a.segmentStep)
            //const minLength = middleConnectionPositionsRef.current[0].segmentStep - min + 1
            //console.log(minLength, "minLength in handleUpdateMinLength")
            //console.log(actualLength.negativeLength, "actualLength.negativeLength")

            //this is because we always need at least 1 segment on the positive sid
            let value = actualLength.negativeLength + 1
            if (value <= 0) {
                value = 1
            }
            //console.log(value, "value", movableSection, actualLength)
            minLengthRef.current = value
        } else {
            //middleConnectionPositionsRef.current.sort((a, b) => a.segmentStep - b.segmentStep)
            //const minLength = max - middleConnectionPositionsRef.current[0].segmentStep + 1
            //console.log(minLength, "minLength in else handleUpdateMinLength")
            let value = actualLength.positiveLength
            if (value <= 0) {
                value = 1
            }
            //console.log(value, "value", movableSection, actualLength)
            minLengthRef.current = value
            //console.log(actualLength.positiveLength, "actualLength.positiveLength")
        }
    }, [actualLength.negativeLength, actualLength.positiveLength,])

    useEffect(() => {
        updateMinLength()

        //console.log(middleConnectionPositionsRef.current, "middleConnectionPositionsRef.current")

        //visualize the connections
        //SegmentedTubeUtils.visualizeConnections(connections, 0x000000, 0x800080, scene)

        //console.log(connections, "connections", tube.id)

        segmentedTubeRef.current.snapped.end = SegmentedTubeUtils.isSomeMovableMarkerConnected(
            segmentedTubeRef,
            partConnectionsValue,
            SegmentedTubeSectionType.END
        )
        segmentedTubeRef.current.snapped.start = SegmentedTubeUtils.isSomeMovableMarkerConnected(
            segmentedTubeRef,
            partConnectionsValue,
            SegmentedTubeSectionType.START
        )
    }, [partConnectionsValue, actualLength.negativeLength, actualLength.positiveLength,])

    const handleMultipleMoveChangeLength = (value: number, isSameValue: boolean) => {
        if (!isSameValue) {
            SegmentedTubeUtils.checkSnap(scene, tube, segmentedTubeRef, partConnectionsValue, connections, connectionTypes, value, movableSection, maxPossibleLength)
            updateSlide(segmentedTubeRef.current)
            handleUpdateAttachmentPoint()
        }

        const markersByDirection = movableSection === SegmentedTubeSectionType.START ? segmentedTubeRef.current.startSection : segmentedTubeRef.current.endSection

        const marker = markersByDirection[getMarkerNumber(handleLengthChanges.multipleMove!.sliderMarker)].inner!
        const newWorldPosition = MeshUtils.copyWorldPosition(marker)

        auxPointRef.current.position.copy(newWorldPosition)
        updateTransforms()

        if (handleLengthChanges.multipleMove) {
            handleSegmentedTubeLenghMovement(
                handleLengthChanges.multipleMove!.partsToMove,
                markerMeshesWithoutMiddleSegments.current
            )
        }
    }

    const debouncedUpdateTubeValues = useCallback(
        debounce((tubeId: string, newValues: Partial<RefactorLater>) => {
            if (isAdmin) {
                console.log("updating tube values", newValues)
            }
            updateTubeValues(tube.id, (t) => {
                Object.entries(newValues).forEach(([key, value,]) => {
                    (t as any)[key] = value
                })
            })
        }, 50),
        [] // Dependencies array
    )

    useEffect(() => {
        return () => {
            debouncedUpdateTubeValues.cancel()
        }
    }, [debouncedUpdateTubeValues,])

    const handleSliderValueChange = (value: number, saveLengthAfterUpdate?: boolean, historyKey?: string,) => {

        const now = Date.now()
        lastVisualUpdate.current = now

        let convertedValue = value

        if (unit === "cm") {
            convertedValue = convertCmToIn(value)
        }

        //console.log(convertedValue, "convertedValue")

        const notRoundedSegmentsCount = (convertedValue - tube.startSegmentLength - tube.endSegmentLength) / tube.segmentLength
        //console.log(notRoundedSegmentsCount, "notRoundedSegmentsCount")

        // Round to the nearest whole number of segments
        const roundedSegmentsCount = Math.round(notRoundedSegmentsCount)

        //console.log("minLength", minLength)
        //console.log("roundedSegmentsCount", roundedSegmentsCount)
        //console.log("connection", middleConnectionPositionsRef.current)
        //console.log(actualLength, "actualLength")


        // Calculate the adjusted segment length
        const adjustedSegmentLength = (convertedValue - tube.startSegmentLength - tube.endSegmentLength) / roundedSegmentsCount

        // Calculate the scale factor
        const scaleFactor = adjustedSegmentLength / tube.segmentLength

        //console.log(roundedSegmentsCount, "roundedSegmentsCount")
        //console.log(adjustedSegmentLength, "adjustedSegmentLength")
        //console.log(scaleFactor, "scaleFactor")

        // Verify our calculations
        const verifiedLength = (roundedSegmentsCount * adjustedSegmentLength) + tube.startSegmentLength + tube.endSegmentLength
        //console.log(verifiedLength, "IN: verifiedLength (should be very close to the original value)")
        //console.log(convertInToCm(verifiedLength), "CM: verifiedLength (should be very close to the original value)")

        let segmentedCount = Math.round((convertedValue - tube.startSegmentLength - tube.endSegmentLength) / adjustedSegmentLength)
        //console.log(segmentedCount, "segmentedCount")

        if (segmentedCount < 1) {
            segmentedCount = 1
        }

        const normalizedConnections = middleConnectionPositionsRef.current.map(item => {
            let normalizedStep
            if (movableSection === SegmentedTubeSectionType.START) {
                normalizedStep = item.segmentStep < 0
                    ? actualLength.positiveLength + Math.abs(item.segmentStep)
                    : actualLength.positiveLength - item.segmentStep
            } else {
                // For END section, keep the original logic
                normalizedStep = item.segmentStep < 0
                    ? Math.abs(item.segmentStep)
                    : item.segmentStep + actualLength.negativeLength
            }
            return {
                originalSegmentStep: item.segmentStep,
                normalizedStep,
                connection: item.connection,
            }
        })
        let positiveLength = segmentedCount - actualLength.negativeLength
        let negativeLength = actualLength.negativeLength

        //console.log(negativeLength, "negativeLength")
        //console.log(positiveLength, "positiveLength")

        if (movableSection === SegmentedTubeSectionType.START) {
            positiveLength = actualLength.positiveLength
            negativeLength = segmentedCount - actualLength.positiveLength
        }

        if (negativeLength < 0 && positiveLength > 0) {
            //console.log(negativeLength, "negativeLength")
            //console.log(positiveLength, "positiveLength")
            positiveLength = positiveLength + negativeLength
            negativeLength = 0
            if (movableSection === SegmentedTubeSectionType.START) {
                setMovableSection(SegmentedTubeSectionType.END)
            } else {
                setMovableSection(SegmentedTubeSectionType.START)
            }
            setMaxPossibleLength(tube.maxMiddles * tube.segmentLength)
            //console.log("new positiveLength", positiveLength)
        }

        const updates = {
            length: positiveLength,
            lengthNegativeSide: negativeLength,
            scaledSegmentLength: adjustedSegmentLength,
            segmentScaleFactor: scaleFactor,
            unitRealValue: value,
            partUnits: unit ?? "in",
        }
        debouncedUpdateTubeValues(tube.id, updates)


        const isSameValue = actualLength.positiveLength === positiveLength
            && actualLength.negativeLength === negativeLength

        SegmentedTubeUtils.createInstancedMeshOrigins(
            tube,
            segmentedTubeRef,
            middlePartInstancedMeshProvider,
            startPartInstancedMeshProvider,
            endPartInstancedMeshProvider,
            positiveLength,
            negativeLength,
            scaleFactor,
            moonTexture,
            getColor(),
            isAdmin ?? undefined,
        )



        //put after since markers for new buttons listen for this state

        setActualLength({ positiveLength, negativeLength, })

        if (handleLengthChanges.multipleMove?.partsToMove
            && handleLengthChanges.multipleMove?.partsToMove.length > 0
        ) {
            handleMultipleMoveChangeLength(value, isSameValue)
        } else {
            SegmentedTubeUtils.checkSnap(scene, tube, segmentedTubeRef, partConnectionsValue, connections, connectionTypes, value, movableSection, maxPossibleLength)
            SegmentedTubeUtils.checkGuidelines(scene, tube, segmentedTubeRef, partConnectionsValue, movableSection)
            updateSlide(segmentedTubeRef.current)
            handleUpdateAttachmentPoint()
        }
        SegmentedTubeUtils.checkUnsnapSound(
            tube,
            segmentedTubeRef,
            value,
            movableSection,
            maxPossibleLength)
        //most users are finding this annoying
        //updateCamera()
        updateInitialMarkerPositionIfMiddle()
        scaleSegmentedTube(undefined, undefined, true)
        if (saveLengthAfterUpdate) {
            saveLength(value, undefined, undefined, true)
        }

        setTimeout(() => {
            multiSelectContext?.updateMultiSelectProviderWithNewMakersInfo()
        }, 100)
    }


    const saveLength = (value: number, forceCM?: boolean, createOriginsAfterSave?: boolean, skipValueUpdate?: boolean, historyKey?: string, callback?: () => void, skipConnectionChecking?: boolean) => {

        const now = Date.now()
        lastVisualUpdate.current = now

        //console.log("saveLength")
        //console.log(value, "value coming from slider")

        let convertedValue = value

        if (forceCM || unit === "cm") {
            convertedValue = convertCmToIn(value)
        }

        const notRoundedSegmentsCount = (convertedValue - tube.startSegmentLength - tube.endSegmentLength) / tube.segmentLength
        //console.log(notRoundedSegmentsCount, "notRoundedSegmentsCount")

        // Round to the nearest whole number of segments
        const roundedSegmentsCount = Math.round(notRoundedSegmentsCount)

        // Calculate the adjusted segment length
        const scaledSegmentLength = (convertedValue - tube.startSegmentLength - tube.endSegmentLength) / roundedSegmentsCount

        // Calculate the scale factor
        const segmentScaleFactor = scaledSegmentLength / tube.segmentLength

        //console.log(roundedSegmentsCount, "roundedSegmentsCount")
        //console.log(scaledSegmentLength, "scaledSegmentLength")
        //console.log(segmentScaleFactor, "segmentScaleFactor")

        // Verify our calculations
        const verifiedLength = (roundedSegmentsCount * scaledSegmentLength) + tube.startSegmentLength + tube.endSegmentLength
        //console.log(verifiedLength, "IN: verifiedLength (should be very close to the original value)")
        //console.log(convertInToCm(verifiedLength), "CM: verifiedLength (should be very close to the original value)")

        let segmentedCount = Math.round((convertedValue - tube.startSegmentLength - tube.endSegmentLength) / scaledSegmentLength)
        //console.log(segmentedCount, "segmentedCount")

        if (segmentedCount < 1) {
            segmentedCount = 1
        }

        //end of new logic

        //const newLength = Math.round((value - tube.startSegmentLength - tube.endSegmentLength) / tube.segmentLength)
        let positiveLength = segmentedCount - actualLength.negativeLength
        let negativeLength = actualLength.negativeLength

        if (movableSection === SegmentedTubeSectionType.START) {
            positiveLength = actualLength.positiveLength
            negativeLength = segmentedCount - actualLength.positiveLength
        }


        if (negativeLength < 0 && positiveLength > 0) {
            //console.log(negativeLength, "negativeLength")
            //console.log(positiveLength, "positiveLength")
            positiveLength = positiveLength + negativeLength
            negativeLength = 0
            if (movableSection === SegmentedTubeSectionType.START) {
                setMovableSection(SegmentedTubeSectionType.END)
            } else {
                setMovableSection(SegmentedTubeSectionType.START)
            }
            setMaxPossibleLength(tube.maxMiddles * tube.segmentLength)
            //console.log("new positiveLength", positiveLength)
        }

        let mmNewConnections: PartConnectionType[] = []
        if (handleLengthChanges.multipleMove?.partsToMove
            && handleLengthChanges.multipleMove?.partsToMove.length > 0
        ) {
            attachToMove(handleLengthChanges.multipleMove.partsToMove, auxPointRef.current)
            mmNewConnections = detachMarkers(handleLengthChanges.multipleMove!.partsToMove, auxPointRef.current, true, false, true)
            handleLengthChanges.saveMultipleMoveLength(mmNewConnections)
            if (!skipValueUpdate) {
                updateTubeValues(tube.id, (t) => {
                    t.length = positiveLength
                    t.lengthNegativeSide = negativeLength
                    t.scaledSegmentLength = scaledSegmentLength
                    t.segmentScaleFactor = segmentScaleFactor
                    t.unitRealValue = value
                    t.partUnits = forceCM ? "cm" : unit ?? "in"
                }, false, historyKey)
            }
        } else {
            const newPositions: string[] = []
            if (tube.length < positiveLength) {
                arrayRange(tube.length, positiveLength - 1, 1).forEach(number => newPositions.push(number.toString()))
            }
            if (tube.lengthNegativeSide < negativeLength) {
                arrayRange(-(tube.lengthNegativeSide + 1), -negativeLength, -1).forEach(number => newPositions.push(number.toString()))
            }
            if (!skipValueUpdate) {

                let newConnectionsToPush: PartConnectionType[] = []

                if (!skipConnectionChecking) {
                    const { newConnections, } = SegmentedTubeUtils.checkSnapOnEditionMovableSection(
                        scene,
                        tube,
                        segmentedTubeRef,
                        partConnectionsValue,
                        connectionTypes,
                        connections,
                        movableSection,
                        newPositions,
                        closestMarkers.current,
                        true
                    )
                    newConnectionsToPush = newConnections
                }

                multipleUpdate([{
                    id: tube.id,
                    updater: (p) => {
                        if (p.type === PartTypeEnum.segmentedTube) {
                            p.length = positiveLength
                            p.lengthNegativeSide = negativeLength
                            p.scaledSegmentLength = scaledSegmentLength
                            p.segmentScaleFactor = segmentScaleFactor
                            p.unitRealValue = value
                            p.partUnits = forceCM ? "cm" : unit ?? "in"
                        }
                    },
                },], [...newConnectionsToPush,], undefined, historyKey)
            }

            updateMarkersClosest()
            if (createOriginsAfterSave) {
                //this is used for when setting up the units initially
                //updating this updates the markers

                setActualLength({ positiveLength, negativeLength, })
                //console.log("createOriginsAfterSave")
                SegmentedTubeUtils.createInstancedMeshOrigins(
                    tube,
                    segmentedTubeRef,
                    middlePartInstancedMeshProvider,
                    startPartInstancedMeshProvider,
                    endPartInstancedMeshProvider,
                    positiveLength,
                    negativeLength,
                    segmentScaleFactor,
                    moonTexture,
                    getColor(),
                    isAdmin ?? undefined,
                )
            }
        }

        segmentedTubeRef.current.multipleMove.snapped = { start: false, end: false, }
        segmentedTubeRef.current.multipleMove.tempConnections = []
        if (!segmentedTubeRef.current.snapped.end) {
            SegmentedTubeUtils.checkUnsnap(tube, segmentedTubeRef, positiveLength, unsnap, SegmentedTubeSectionType.END)
        }
        if (!segmentedTubeRef.current.snapped.start) {
            SegmentedTubeUtils.checkUnsnap(tube, segmentedTubeRef, negativeLength, unsnap, SegmentedTubeSectionType.START)
        }
        //this is now handled when user is done with length changing
        //SegmentedTubeUtils.setAtachmentPoint(segmentedTubeRef.current.attachmentPoint!, segmentedTubeRef, setAttachmentPoint)

        if (!skipConnectionChecking) {
            calcBoundgBox()
            seeWhatToDisconnectedAndConnect(historyKey)
        }
        callback?.() // Call the callback if it exists

    }


    //this for undo/redo to run through the same flow as intial setup but just for visual updates
    useEffect(() => {
        const diffInTime = lastVisualUpdate.current && Date.now() - lastVisualUpdate.current
        if (((lastVisualUpdate.current && diffInTime > timeLimitBetweenUpdates) || instantiated.current) && undoRedoInProgress) {
            if (isAdmin) {
                console.log("undo/redo visual update - running initialSetup with visual update", tube.id, "undoredoInProgress", undoRedoInProgress)
            }
            initialSetup(true)
            lastVisualUpdate.current = Date.now()
        }

    }, [tube.length, tube.modifiedWidth, tube.modifiedHeight, tube.position, tube.rotation,])

    const setAttachmentPointToRef = useCallback(() => {
        SegmentedTubeUtils.setAtachmentPoint(segmentedTubeRef.current.attachmentPoint!, segmentedTubeRef, setAttachmentPoint)
    }, [segmentedTubeRef.current.attachmentPoint, setAttachmentPoint,])

    const colorMergedMesh = (color: string) => {
        if (segmentedTubeRef.current.mergedMesh && segmentedTubeRef.current.mergedMesh.material) {
            (segmentedTubeRef.current.mergedMesh.material as MeshStandardMaterial).color.set(color)
        }
    }

    const updateColor = (color: string) => {
        if (segmentedTubeRef.current.mergedMesh) {
            colorMergedMesh(color)
        } else {
            const middleOrigins = [...segmentedTubeRef.current.instancedMeshOrigins,]
            const startOrigin = middleOrigins.shift()
            const endOrigin = middleOrigins.pop()

            middlePartInstancedMeshProvider.updateColor(middleOrigins, color)
            endPartInstancedMeshProvider.updateColor(endOrigin ? [endOrigin,] : [], color)
            startPartInstancedMeshProvider.updateColor(startOrigin ? [startOrigin,] : [], color)
        }
    }


    const getColor = () => {
        if (isSelected) {
            return SELECTED_PART_COLOR
        }
        return tube.color
    }

    const originalColor = () => updateColor(getColor())

    const getPartInfo = () => {
        return tube
    }


    const duplicatePart = async (
        historyKey: string, position: Vector3,
        initialMarkerName?: string,
        markerOffset?: Vector3,
        rotation?: Quaternion,
        length?: number,
        lengthNegativeSide?: number,
        partUnits?: string,
        unitRealValue?: number,
        scaledSegmentLength?: number,
        segmentScaleFactor?: number,
        modifiedWidth?: number,
        modifiedWidthUnits?: string,
        modifiedHeight?: number,
        modifiedHeightUnits?: string,
        realWidth?: number,
        realHeight?: number,
        baseName?: string
    ) => {
        //console.log("duplicatePart", position, tube.id)
        const matchingPart = findPartById(tube.apiTypeId)
        if (!matchingPart) {
            console.warn("part no longer exists and cannot be duplicated", tube.apiTypeId)
            return
        }
        const defaultQuaternion = new Quaternion()
        let newPart: any
        if (matchingPart && position) {
            newPart = {
                part: {
                    ...matchingPart,
                },
                posAndRot: {
                    inner: {
                        pos: position,
                        rot: rotation || defaultQuaternion,
                    },
                    outer: {
                        pos: position,
                        rot: rotation || defaultQuaternion,
                    },
                },
                length,
                lengthNegativeSide,
            }
        }
        if (matchingPart && !position) {
            newPart = {
                part: {
                    ...matchingPart,
                },
                posAndRot: {
                    inner: {
                        pos: new Vector3(0, 0, 0),
                        rot: defaultQuaternion,
                    },
                    outer: {
                        pos: new Vector3(0, 0, 0),
                        rot: defaultQuaternion,
                    },
                },
            }
        }
        newPart.duplicatedFrom = tube.id
        newPart.initialMarkerName = initialMarkerName
        //console.log(initialMarkerName, "duplicate part initialMarkerName")
        if (markerOffset) {
            newPart.markerOffset = markerOffset
        }
        if (partUnits) {
            newPart.part.partUnits = partUnits
        }
        if (unitRealValue) {
            newPart.part.unitRealValue = unitRealValue
        }
        if (scaledSegmentLength) {
            newPart.part.scaledSegmentLength = scaledSegmentLength
        }
        if (segmentScaleFactor) {
            newPart.part.segmentScaleFactor = segmentScaleFactor
        }
        if (modifiedWidth) {
            newPart.part.modifiedWidth = modifiedWidth
        }
        if (modifiedWidthUnits) {
            newPart.part.modifiedWidthUnits = modifiedWidthUnits
        }
        if (modifiedHeight) {
            newPart.part.modifiedHeight = modifiedHeight
        }
        if (modifiedHeightUnits) {
            newPart.part.modifiedHeightUnits = modifiedHeightUnits
        }
        if (realWidth) {
            newPart.part.realWidth = realWidth
        }
        if (realHeight) {
            newPart.part.realHeight = realHeight
        }
        if (baseName) {
            newPart.part.baseName = baseName
        }
        //console.log(newPart, "newPart")
        const { newPartId, } = await createPart(newPart, historyKey)
        return newPartId
    }

    const updateInitialMarkerName = (initialMarkerName: string) => {
        setUItoNone()
        updateTubeValues(tube.id, (t) => {
            t.initialMarkerName = initialMarkerName
        },)
        setInitialMarkerState(initialMarkerName)
    }

    const setUItoNone = () => {
        setTubeUI(SEGMENTED_TUBE_UI.NONE)
        updateColor(tube.color)
        setIsSelected(false)
    }

    const removeMarkerOffset = (historyKey?: string) => {
        updateTubeValues(tube.id, (t) => {
            t.markerOffset = undefined
        }, false, historyKey)
    }

    // Add mountedRef at the top with other refs
    const mountedRef = useRef(true)

    // Add cleanup in useEffect
    useEffect(() => {
        return () => {
            mountedRef.current = false
        }
    }, [])

    const updatePositionAndRotation = async (position: Vector3, rotation: Quaternion, markerOffset?: Vector3, historyKey?: string, ignoreHistory?: boolean): Promise<void> => {
        await new Promise((resolve, reject) => {
            // Check if already unmounted
            if (!mountedRef.current) {
                reject(new Error("Component unmounted"))
                return
            }

            requestAnimationFrame(() => {
                // Check again after frame
                if (!mountedRef.current) {
                    reject(new Error("Component unmounted"))
                    return
                }

                try {
                    SegmentedTubeUtils.createInstancedMeshOrigins(
                        tube,
                        segmentedTubeRef,
                        middlePartInstancedMeshProvider,
                        startPartInstancedMeshProvider,
                        endPartInstancedMeshProvider,
                        actualLength.positiveLength,
                        0,
                        tube.segmentScaleFactor,
                        moonTexture,
                        getColor(),
                        isAdmin ?? undefined,
                    )

                    const attachmentPoint = getAttachmentPointOrigin()
                    const secondaryAttachmentPoint = segmentedTubeRef.current.instancedMeshOrigins[2]
                        ? (segmentedTubeRef.current.instancedMeshOrigins[2].children.length >= 3
                            ? segmentedTubeRef.current.instancedMeshOrigins[2].children[2]
                            : segmentedTubeRef.current.instancedMeshOrigins[2].children[0])
                        : undefined

                    SegmentedTubeUtils.setAtachmentPoint(
                        attachmentPoint || secondaryAttachmentPoint!,
                        segmentedTubeRef,
                        setAttachmentPoint
                    )

                    updateTubeValues(tube.id, (t) => {
                        t.position = position
                        t.rotation = rotation
                        if (markerOffset) {
                            t.markerOffset = markerOffset
                            t.position = markerOffset
                        }
                    }, ignoreHistory, historyKey)

                    const createInstancedMeshOrigins = () => {
                        // Final mount check before heavy operation
                        if (!mountedRef.current) {
                            throw new Error("Component unmounted")
                        }

                        SegmentedTubeUtils.createInstancedMeshOrigins(
                            tube,
                            segmentedTubeRef,
                            middlePartInstancedMeshProvider,
                            startPartInstancedMeshProvider,
                            endPartInstancedMeshProvider,
                            tube.length,
                            tube.lengthNegativeSide,
                            tube.segmentScaleFactor,
                            moonTexture,
                            getColor(),
                            isAdmin ?? undefined,
                        )
                    }

                    if (markerOffset) {
                        createInstancedMeshOrigins()
                        setPositionAndRotation(markerOffset, rotation)
                        const apPosition = new Vector3(markerOffset.x, markerOffset.y, markerOffset.z)
                        SegmentedTubeUtils.setAtachmentPoint(
                            attachmentPoint || secondaryAttachmentPoint!,
                            segmentedTubeRef,
                            setAttachmentPoint,
                            apPosition
                        )
                    } else {
                        createInstancedMeshOrigins()
                        setPositionAndRotation(position, rotation)
                    }

                    updateSlide(segmentedTubeRef.current)
                    scaleSegmentedTube(undefined, undefined, true)

                    resolve(undefined)
                } catch (error) {
                    reject(error)
                }
            })
        }).catch(err => {
            // Handle cancellation - you can either:
            // 1. Silently ignore: do nothing
            // 2. Log for debugging
            console.debug("SegmentedTube position update cancelled:", err.message)
            // 3. Rethrow if you want upstream handlers to know
            // throw err
        })
    }

    const updateRotation = (rotation: Quaternion): void => {
        setPositionAndRotation(undefined, rotation)
        updateTubeValues(tube.id, (t) => {
            t.rotation = rotation
        })
        segmentedTubeRef.current.attachmentPoint?.updateMatrixWorld()
        calcBoundgBox()
    }

    const getAllMarkers = (includeInners = false) => {
        //console.log(segmentedTubeRef.current, "segmentedTubeRef.current")
        const markers = [
            ...Object.values(segmentedTubeRef.current.startSection).map(marker => marker.outer),
            ...Object.values(segmentedTubeRef.current.endSection).map(marker => marker.outer),
            ...Object.values(segmentedTubeRef.current.middleSection).flatMap(side =>
                Object.values(side).map(marker => marker.outer)
            ),
        ].filter(marker => marker !== undefined)

        //this is needed when you need all of the markers but with backwards compatibility for old part that had inner_nonzero numbers
        if (includeInners) {
            markers.push(...Object.values(segmentedTubeRef.current.middleSection).flatMap(side =>
                Object.values(side).map(marker => marker.inner)
                    .filter(marker => marker !== undefined)
            ))
            markers.push(...Object.values(segmentedTubeRef.current.startSection).map(marker => marker.inner)
                .filter(marker => marker !== undefined))
            markers.push(...Object.values(segmentedTubeRef.current.endSection).map(marker => marker.inner)
                .filter(marker => marker !== undefined))
        }

        // Check if tube.initialMarkerName has an underscore
        if (tube.initialMarkerName.includes("_") && !tube.initialMarkerName.includes("_0")) {
            const [baseMarker, markerNumber,] = tube.initialMarkerName.split("_")
            const zeroMarkerName = `${baseMarker}_0`
            const zeroMarker = segmentedTubeRef.current.instancedMeshOrigins
                .flatMap(origin => origin.children)
                .find(child => child.name === zeroMarkerName)

            if (zeroMarker && zeroMarker.userData.middleRefs) {
                const matchingRef = zeroMarker.userData.middleRefs.find(
                    (ref: { refName: string, }) => ref.refName === tube.initialMarkerName
                )

                if (matchingRef) {
                    const clonedObject = zeroMarker.clone()
                    clonedObject.name = tube.initialMarkerName
                    clonedObject.userData = { ...zeroMarker.userData, middleRefs: [matchingRef,], }

                    // Get the world matrix of the zero marker
                    const worldMatrix = new Matrix4().makeRotationFromQuaternion(zeroMarker.getWorldQuaternion(new Quaternion()))
                    worldMatrix.setPosition(zeroMarker.getWorldPosition(new Vector3()))

                    // Apply the local offset to get the final world position
                    const finalPosition = new Vector3().copy(matchingRef.localOffset)
                        .applyMatrix4(worldMatrix)

                    // Set the position directly (baked in world space)
                    clonedObject.position.copy(finalPosition)

                    // Get the world rotation
                    const worldQuaternion = zeroMarker.getWorldQuaternion(new Quaternion())

                    // Set the rotation directly (baked in world space)
                    clonedObject.quaternion.copy(worldQuaternion)

                    //for debugging, create plane with the rotation of the marker
                    const planeGeometry = new PlaneGeometry(0.01, 0.01)
                    const planeMaterial = new MeshBasicMaterial({
                        color: 0xff0000,
                        side: DoubleSide,
                        wireframe: true,
                        depthTest: false,
                    })

                    const plane = new Mesh(planeGeometry, planeMaterial)
                    plane.visible = false
                    plane.position.copy(clonedObject.position)
                    plane.quaternion.copy(clonedObject.quaternion)
                    plane.name = tube.initialMarkerName
                    //plane.rotateY(MathUtils.degToRad(180))
                    plane.updateMatrix()
                    //scene.add(plane)
                    plane.userData = clonedObject.userData
                    //segmentedTubeRef.current.originPoint?.attach(clonedObject)

                    // No need to update matrix world, as we've set world values directly
                    markers.push(plane)
                }
            }
        }
        //console.log(markers, "markers")
        return markers
    }

    const scaleTubeLength = (currentDepth: number, targetDepth: number) => {
        const scaleFactor = targetDepth / currentDepth
        const currentLength = tube.length + tube.lengthNegativeSide
        const newLength = Math.round(currentLength * scaleFactor)

        console.log("Length scaling:", {
            currentLength,
            newLength,
            scaleFactor,
            currentDepth,
            targetDepth,
        })

        // Update the tube length
        const positiveLength = Math.max(1, Math.round(tube.length * scaleFactor))

        updateTubeValues(tube.id, (t) => {
            t.length = positiveLength
        })

        // Recreate instanced mesh origins with new length
        SegmentedTubeUtils.createInstancedMeshOrigins(
            tube,
            segmentedTubeRef,
            middlePartInstancedMeshProvider,
            startPartInstancedMeshProvider,
            endPartInstancedMeshProvider,
            positiveLength,
            tube.lengthNegativeSide,
            tube.segmentScaleFactor,
            moonTexture,
            getColor(),
            isAdmin ?? undefined
        )

        setActualLength({ positiveLength, negativeLength: tube.lengthNegativeSide, })
    }

    const attachOriginToBoundingBox = (anchor: "top-left" | "top-right" | "bottom-left" | "bottom-right" | "center", anchorPosition?: Vector3) => {

        console.log("attachOriginToBoundingBox")
        const attachmentPoint = segmentedTubeRef.current.attachmentPoint
        if (!attachmentPoint) { return }

        // Get bounding box
        const boundingBox = calcBoundgBox()
        if (!boundingBox) { return }

        // Create box mesh from bounding box
        const geometry = new BoxGeometry(
            boundingBox.max.x - boundingBox.min.x,
            boundingBox.max.y - boundingBox.min.y,
            boundingBox.max.z - boundingBox.min.z
        )
        const material = new MeshBasicMaterial({
            color: 0x000000,
            opacity: 0.1,
            transparent: true,
            wireframe: true,
        })
        const boxMesh = new Mesh(geometry, material)
        boxMesh.position.copy(boundingBox.getCenter(new Vector3()))
        boxMesh.name = "boundingBoxMesh"

        // Store original parent
        const originalParent = attachmentPoint.parent

        // Attach attachment point to box
        boxMesh.attach(attachmentPoint)

        boxMesh.updateWorldMatrix(true, true)


        const positions = (geometry as BoxGeometry).attributes.position.array
        const anchorPos = new Vector3()

        switch (anchor) {
            case "top-left":
                anchorPos.set(
                    positions[0], // x of first vertex
                    positions[1], // y of first vertex
                    positions[2]  // z of first vertex
                )
                break
            case "top-right":
                anchorPos.set(
                    positions[3], // x of second vertex
                    positions[4], // y of second vertex
                    positions[5]  // z of second vertex
                )
                break
            case "bottom-left":
                anchorPos.set(
                    positions[18], // x of seventh vertex
                    positions[19], // y of seventh vertex
                    positions[20]  // z of seventh vertex
                )
                break
            case "bottom-right":
                anchorPos.set(
                    positions[21], // x of eighth vertex
                    positions[22], // y of eighth vertex
                    positions[23]  // z of eighth vertex
                )
                break
            case "center":
                anchorPos.copy(boundingBox.getCenter(new Vector3()))
                break
        }

        // Transform the anchor position to world space
        anchorPos.applyMatrix4(boxMesh.matrixWorld)

        // Create anchor point
        const anchorPoint = new Object3D()
        anchorPoint.name = `anchor-${anchor}`
        anchorPoint.position.copy(anchorPos)

        // Create debug sphere
        const debugSphere = new Mesh(new SphereGeometry(0.002, 1, 1), new MeshBasicMaterial({ color: 0x000, }))
        //debugSphere.position.copy(anchorPos)
        debugSphere.name = "debugSphere"
        anchorPoint.add(debugSphere)

        // Add to scene and setup hierarchy
        scene.add(anchorPoint)
        anchorPoint.attach(boxMesh)

        if (anchorPosition) {
            anchorPoint.position.copy(anchorPosition)
        }

        // Return cleanup function
        return () => {
            // Restore original parent
            if (originalParent) {
                originalParent.attach(attachmentPoint)
            }

            // Remove debug objects
            debugSphere.removeFromParent()
            boxMesh.removeFromParent()
            anchorPoint.removeFromParent()
        }
    }

    const positionTubeFromBox = (box: Object3D, anchor: string, targetPosition: Vector3, rotation: Euler, dimensions: { width: number, height: number, depth: number, }) => {
        const attachmentPoint = segmentedTubeRef.current.attachmentPoint
        if (!attachmentPoint) { return }

        console.log(targetPosition, "targetPosition")
        drawVector3Point(targetPosition, scene, "orange", 0.002)

        attachmentPoint.rotation.copy(rotation)
        attachmentPoint.updateWorldMatrix(true, true)

        const nativeBoundingBox = calcBoundgBox()

        const geometry = new BoxGeometry(nativeBoundingBox!.max.x - nativeBoundingBox!.min.x, nativeBoundingBox!.max.y - nativeBoundingBox!.min.y, nativeBoundingBox!.max.z - nativeBoundingBox!.min.z)
        const material = new MeshBasicMaterial({ color: 0x000, opacity: 0.1, transparent: true, wireframe: true, })
        const nativeBoxMesh = new Mesh(geometry, material)

        const boxSize = new Vector3()
        nativeBoundingBox!.getSize(boxSize)

        // Calculate current dimensions in inches
        const currentWidth = boxSize.x * 100 / 2.54
        const currentHeight = boxSize.y * 100 / 2.54
        const currentDepth = boxSize.z * 100 / 2.54

        console.log("Current dimensions (scene units):", { currentWidth, currentHeight, currentDepth, })
        console.log("Target dimensions (inches):", dimensions)

        // If depth is different, scale the tube length
        if (Math.abs(currentDepth - dimensions.depth) > 0.001) {
            scaleTubeLength(currentDepth, dimensions.depth)
        }

        nativeBoxMesh.position.copy(nativeBoundingBox!.getCenter(new Vector3()))
        nativeBoxMesh.updateWorldMatrix(true, true)

        scene.attach(nativeBoxMesh)

        // Store original parent
        const originalParent = attachmentPoint.parent

        // Attach to box
        nativeBoxMesh.attach(attachmentPoint)


        // Get the new world position of the initial marker
        const initialMarker = getAllMarkers().find(
            marker => marker?.name === innerToOuter(tube.initialMarkerName)
        )

        nativeBoxMesh.updateMatrixWorld(true)

        nativeBoxMesh.name = "nativeBoxMesh"

        // Calculate the dimensions
        const halfWidth = (nativeBoundingBox!.max.x - nativeBoundingBox!.min.x) / 2
        const halfHeight = (nativeBoundingBox!.max.y - nativeBoundingBox!.min.y) / 2
        const halfDepth = (nativeBoundingBox!.max.z - nativeBoundingBox!.min.z) / 2

        // Create a matrix to transform from local to world space
        const worldMatrix = new Matrix4()

        worldMatrix.copy(nativeBoxMesh.matrixWorld)

        const cornerPosition = new Vector3()
        const worldCornerPosition = new Vector3()


        switch (anchor.toLowerCase()) {
            case "top-left":
                cornerPosition.set(-halfWidth, halfHeight, halfDepth)
                break
            case "top-right":
                cornerPosition.set(halfWidth, halfHeight, halfDepth)
                break
            case "bottom-left":
                cornerPosition.set(-halfWidth, -halfHeight, halfDepth)
                break
            case "bottom-right":
                cornerPosition.set(halfWidth, -halfHeight, halfDepth)
                break
            case "center":
                cornerPosition.copy(nativeBoundingBox!.getCenter(new Vector3()))
                break
        }

        // Transform the corner position to world space
        worldCornerPosition.copy(cornerPosition).applyMatrix4(worldMatrix)

        // Use worldCornerPosition instead of cornerPosition for the rest of the code
        const cornerSphere = new Object3D()
        cornerSphere.position.copy(worldCornerPosition)

        //for debugging
        const basicSphere = new Mesh(new SphereGeometry(0.002, 1, 1), new MeshBasicMaterial({ color: 0x000, }))
        basicSphere.position.copy(cornerSphere.position)
        basicSphere.name = "basicSphere"
        cornerSphere.attach(basicSphere)

        // Attach sphere to nativeBoxMesh
        nativeBoxMesh.attach(cornerSphere)

        // Re-attach to original parent
        originalParent?.attach(attachmentPoint)

        scene.attach(cornerSphere)
        cornerSphere.attach(nativeBoxMesh)
        cornerSphere.attach(attachmentPoint)

        cornerSphere.position.copy(targetPosition)
        cornerSphere.updateWorldMatrix(true, true)

        const cornerSphereWorldPosition = cornerSphere.getWorldPosition(new Vector3())
        console.log(cornerSphereWorldPosition, "cornerSphereWorldPosition")

        //drawVector3Point(cornerSphereWorldPosition, scene, "red", 0.05, undefined, true)

        originalParent?.attach(attachmentPoint)

        console.log(initialMarker, "initialMarker")

        if (initialMarker) {
            const newMarkerPosition = new Vector3()
            initialMarker.getWorldPosition(newMarkerPosition)

            const newRotation = new Quaternion()

            initialMarker?.rotateY(MathUtils.degToRad(180))
            initialMarker.getWorldQuaternion(newRotation)
            initialMarker.rotateY(MathUtils.degToRad(-180))

            const modifiedRotation = {
                x: newRotation.x ?? (newRotation as any)._x,
                y: newRotation.y ?? (newRotation as any)._y,
                z: newRotation.z ?? (newRotation as any)._z,
                w: newRotation.w ?? (newRotation as any)._w,
            }

            const oldPos = new Vector3(tube.position.x, tube.position.y, tube.position.z)

            //console.log(tube.rotation, "oldRotation")
            //console.log(modifiedRotation, "newRotation")
            //console.log(oldPos, "oldPos")
            //console.log(newMarkerPosition, "newMarkerPosition")

            //drawVector3Point(oldPos, scene, "purple", 0.002)
            //drawVector3Point(newMarkerPosition, scene, "red", 0.002)

            updateTubeValues(tube.id, (t) => {
                t.position = {
                    x: newMarkerPosition.x,
                    y: newMarkerPosition.y,
                    z: newMarkerPosition.z,
                }
                t.rotation = modifiedRotation
                if (t.markerOffset) {
                    t.markerOffset = {
                        x: newMarkerPosition.x,
                        y: newMarkerPosition.y,
                        z: newMarkerPosition.z,
                    }
                }
            })
        }
    }


    const addToLength = (distanceInMm: number, movableMarkerName?: string, movableMarkerPosition?: Vector3, lengthValueOverride?: number, debug?: boolean, historyKey?: string): void => {
        // Convert value to cm if needed
        const valueInCm = distanceInMm * 100
        const valueInInches = valueInCm / 2.54

        const allMarkers = getEveryMarker()
        const marker = allMarkers.find(marker => marker?.name === movableMarkerName)
        if (!marker) { return }

        //console.log(valueInInches, "valueInInches", "valueInCm", valueInCm)

        const realUnitValueToAdd = tube.partUnits === "cm" ? valueInCm : valueInInches

        //console.log("realUnitValueToAdd", realUnitValueToAdd)

        const segmentedToAdd = valueInInches / (tube.segmentLength * (tube.segmentScaleFactor ?? 1))
        const segementedToAddRounded = Math.round(segmentedToAdd)

        const realUnitBackupIN = ((tube.lengthNegativeSide + tube.length)
            * tube.segmentLength * (tube.segmentScaleFactor ?? 1)) + (tube.endSegmentLength + tube.startSegmentLength)


        //console.log("realUnitBackupIN", realUnitBackupIN)
        const realUnitBackupCM = realUnitBackupIN * 2.54

        const realUnitBackup = tube.partUnits === "cm" ? realUnitBackupCM : realUnitBackupIN

        //console.log("realUnitBackup", realUnitBackup)
        //console.log("realUnitValueToAdd", realUnitValueToAdd)

        const unitRealValueSummed = (tube.unitRealValue ?? realUnitBackup) + realUnitValueToAdd

        //console.log("unitRealValueSummed", unitRealValueSummed)

        const newLength = tube.length + segementedToAddRounded


        //same flow that we put everything else through when the user uses the slider
        const updateSliderValue = (value: number) => {
            handleLengthSliderMouseDown()
            setTimeout(() => {
                sliderRouter(value, true, historyKey)
                setTimeout(() => {
                    handleLengthSliderMouseUp(value, true, () => {
                        if (movableMarkerName && movableMarkerPosition) {
                            setTimeout(() => {
                                setPositionWithIdentifiedMarker(movableMarkerName, movableMarkerPosition, false, historyKey)
                                //this needs to be done bc the slider functions hide it
                                setHideAddPartButtons(false)
                                SegmentedTubeUtils.removeGuidelines(scene, segmentedTubeRef)
                            }, 50)
                        }
                    }, historyKey)
                }, 25)
            }, 25)
        }
        updateSliderValue(lengthValueOverride ?? unitRealValueSummed)

        //console.log("tube unit", tube.unitRealValue, "realUnitValueToAdd", realUnitValueToAdd, "addToLength unitRealValue")
        //console.log("lenght", tube.length, "new seggmented lenght", segementedToAddRounded)
        //console.log("segementedToAddRounded", segementedToAddRounded)


    }


    const setPositionWithIdentifiedMarker = (targetMarkerName: string, positionOfTargetMarker: Vector3, debug?: boolean, historyKey?: string) => {

        //using this version to get the initial marker for old parts
        const markers = getAllMarkers(true)
        const targetMarker = markers.find(marker => marker?.name === targetMarkerName)

        const initialMarker = markers.find(marker => marker?.name === tube.initialMarkerName)

        //console.log(targetMarker, "targetMarker", tube.id)

        if (!targetMarker) {
            console.warn(`Marker ${targetMarkerName} not found`)
            return
        }

        if (!initialMarker && tube.initialMarkerName.includes("_0")) {
            console.warn(`Initial marker ${innerToOuter(tube.initialMarkerName)} not found`)
            return
        }

        //get the current tube position
        const currentTubePosition = new Vector3(tube.position.x, tube.position.y, tube.position.z)
        //console.log(tube.position, "currentTubePosition")

        const currentMarkerOffset = tube.markerOffset ? new Vector3(tube.markerOffset.x, tube.markerOffset.y, tube.markerOffset.z) : undefined
        //console.log("currentMarkerOffset is", currentMarkerOffset)


        const currentTubePlaceHolder = new Mesh(
            new SphereGeometry(0.025),
            new MeshBasicMaterial({ color: "pink", })
        )
        currentTubePlaceHolder.name = `currentTubePlaceHolder_${tube.id}`

        const currentMarkerOffsetPlaceHolder = new Mesh(
            new SphereGeometry(0.028),
            new MeshBasicMaterial({ color: "purple", })
        )

        currentMarkerOffsetPlaceHolder.name = `currentMarkerOffsetPlaceHolder_${tube.id}`


        if (debug) {
            currentTubePlaceHolder.visible = true
            currentMarkerOffsetPlaceHolder.visible = true
        }
        else {
            currentTubePlaceHolder.visible = false
            currentMarkerOffsetPlaceHolder.visible = false
        }


        currentTubePlaceHolder.position.copy(currentTubePosition)
        currentMarkerOffset && currentMarkerOffsetPlaceHolder.position.copy(currentMarkerOffset)

        segmentedTubeRef.current.attachmentPoint!.attach(currentTubePlaceHolder)
        currentMarkerOffset && segmentedTubeRef.current.attachmentPoint!.attach(currentMarkerOffsetPlaceHolder)

        currentTubePlaceHolder.updateMatrixWorld(true)
        currentMarkerOffset && currentMarkerOffsetPlaceHolder.updateMatrixWorld(true)

        // Debug: Mark original position
        const originalPos = new Vector3()
        const attachmentPoint = segmentedTubeRef.current.attachmentPoint!
        attachmentPoint.getWorldPosition(originalPos)
        debug && drawVector3Point(originalPos, scene, "blue", 0.015, 5000, true, undefined, "original_position")

        // Create an intermediate object at the world position/rotation of the attachment point
        const intermediateParent = new Object3D()
        intermediateParent.position.copy(MeshUtils.copyWorldPosition(attachmentPoint))
        intermediateParent.quaternion.copy(MeshUtils.copyWorldQuaternion(attachmentPoint))
        scene.add(intermediateParent)

        // Create and position target marker clone
        const targetMarkerClone = targetMarker.clone(true)
        targetMarkerClone.position.copy(MeshUtils.copyWorldPosition(targetMarker))
        targetMarkerClone.quaternion.copy(MeshUtils.copyWorldQuaternion(targetMarker))
        scene.add(targetMarkerClone)
        targetMarkerClone.name = `targetMarkerClone ${targetMarkerName}`

        // Store original parent
        const originalParent = attachmentPoint.parent

        // First attach to intermediate parent to preserve world transform
        intermediateParent.attach(attachmentPoint)

        // Then attach intermediate parent to target marker clone
        targetMarkerClone.attach(intermediateParent)

        // Update all matrices
        targetMarkerClone.updateWorldMatrix(true, true)

        // Debug: Mark new position - should be same as original
        const newPos = new Vector3()
        attachmentPoint.getWorldPosition(newPos)
        debug && drawVector3Point(newPos, scene, "red", 0.015, 5000, true, undefined, "new_position")


        targetMarkerClone.position.copy(positionOfTargetMarker)
        targetMarkerClone.updateMatrixWorld(true)


        const newTubePosition = new Vector3()
        currentTubePlaceHolder.getWorldPosition(newTubePosition)

        const newMarkerOffsetPosition = new Vector3()
        currentMarkerOffsetPlaceHolder.getWorldPosition(newMarkerOffsetPosition)

        const newInitialMarkerPosition = new Vector3()
        initialMarker?.getWorldPosition(newInitialMarkerPosition)

        debug && drawVector3Point(newTubePosition, scene, "green", 0.015, 5000, true, undefined, "newTubePosition", undefined, "cone")
        debug && drawVector3Point(newMarkerOffsetPosition, scene, "red", 0.015, 5000, true, undefined, "newMarkerOffsetPosition", undefined, "cone")
        debug && drawVector3Point(newInitialMarkerPosition, scene, "blue", 0.015, 5000, true, undefined, "newInitialMarkerPosition", undefined, "cone")
        scene.attach(segmentedTubeRef.current.attachmentPoint!)


        const initialMarkerContains_0_In_Name = innerToOuter(tube.initialMarkerName).includes("_0")

        let positionToUse = newTubePosition

        if (initialMarkerContains_0_In_Name) {
            positionToUse = newInitialMarkerPosition
        } else if (tube.markerOffset) {
            positionToUse = newMarkerOffsetPosition
        }

        if (tube.markerOffset) {
            //console.log("updating markeroffset and position with", newMarkerOffsetPosition)
            updateTubeValues(tube.id, (t) => {
                t.position = positionToUse
                t.markerOffset = positionToUse
            }, false, historyKey)
        } else {
            //console.log("updating position only with", newTubePosition)
            updateTubeValues(tube.id, (t) => {
                t.position = positionToUse
            }, false, historyKey)
        }

        segmentedTubeRef.current.attachmentPoint?.updateWorldMatrix(true, true)
        currentMarkerOffsetPlaceHolder?.removeFromParent()
        currentTubePlaceHolder?.removeFromParent()
        targetMarkerClone?.removeFromParent()

    }


    const setPositionWithMarker = (marker: Mesh, pos: Vector3) => {

        // Calculate current offset between marker and origin
        const markerWorldPos = MeshUtils.copyWorldPosition(marker)
        const originWorldPos = MeshUtils.copyWorldPosition(segmentedTubeRef.current.attachmentPoint!)
        const offset = markerWorldPos.clone().sub(originWorldPos)

        // Calculate new position by subtracting offset from desired position
        const newPosition = pos.clone().sub(offset)

        // Update tube position
        updateTubeValues(tube.id, (t) => {
            t.position = newPosition
        })

        // Update matrices
        segmentedTubeRef.current.attachmentPoint!.updateWorldMatrix(true, true)
    }

    const updateMatrix = () => {
        segmentedTubeRef.current.attachmentPoint!.updateWorldMatrix(true, true)
    }

    const getMesh = (color?: string) => {
        const mesh = segmentedTubeRef.current.mergedMesh
        if (mesh && color) {
            (mesh.material as MeshBasicMaterial).color.set(color)
        }
        return mesh
    }

    const getFacingDirectionOfMarkers = (
        specificRotation?: { x: number, y: number, z: number, },
        debug?: boolean,
        addCloneToScene?: boolean
    ) => {
        if (!segmentedTubeRef.current.attachmentPoint) {
            return [{ originalName: "", friendlyName: "", },]
        }

        //console.log("friendlyNamesForMarkers", specificRotation, tube.id)

        const processMarkersWithRotation = (rotation?: { x: number, y: number, z: number, }, useRandomPosition?: boolean) => {
            const clone = segmentedTubeRef.current.attachmentPoint!.clone(true)
            clone.userData.clone = true

            // Apply rotation
            if (rotation) {
                clone.rotation.set(
                    MathUtils.degToRad(rotation.x),
                    MathUtils.degToRad(rotation.y),
                    MathUtils.degToRad(rotation.z)
                )
                clone.updateMatrixWorld()
            }

            // Position clone slightly offset for visibility if debugging
            if (useRandomPosition) {
                const randomPosition = new Vector3(
                    debug ? 0.5 + Math.random() * 0.5 : 0,
                    debug ? 0.5 + Math.random() * 0.5 : 0,
                    debug ? 0.5 + Math.random() * 0.5 : 0
                )
                clone.position.copy(randomPosition)
            }

            if (addCloneToScene) {
                scene.add(clone)
            }

            const markers = getAllMarkers()
            const markersInfo = tube.markers

            //console.log(markers, "markers in friendlyNamesForMarkers")
            const markerDirections = markers.map(marker => {
                if (!marker) { return null }

                const matchingCloneMarker = clone.getObjectByName(marker.name)
                if (!matchingCloneMarker) { return null }

                const markerInfo = markersInfo.find(m => m.name === marker.name)

                return {
                    originalName: marker.name,
                    sizeId: markerInfo?.sizeId,
                    typeId: markerInfo?.id,
                    facing: getNormalDirection(matchingCloneMarker, tube.id.slice(-4), scene, debug, setDebugNormals, setDebugLabels),
                }
            }).filter((item): item is { originalName: string, facing: string, sizeId: string | undefined, typeId: string | undefined, } => item !== null)

            // Cleanup clone if not needed for debug
            if (!addCloneToScene) {
                clone.traverse((obj: Object3D) => {
                    if (obj instanceof Mesh) {
                        obj.geometry?.dispose()
                        if (Array.isArray(obj.material)) {
                            obj.material.forEach(mat => mat.dispose())
                        } else {
                            obj.material?.dispose()
                        }
                    }
                })
            }

            return markerDirections
        }

        return processMarkersWithRotation(specificRotation, !!specificRotation)
    }

    useEffect(() => {
        if (boundingBox) {
            setBoundingBoxCenter(boundingBox.getCenter(new Vector3()))
        }
    }, [boundingBox,])

    const visualDebug = useCallback((
        content: string | string[],
        clear = false
    ) => {
        setDebugText(prev => {
            if (clear) { return Array.isArray(content) ? content : [content,] }
            const newLines = [...prev, ...(Array.isArray(content) ? content : [content,]),]
            return newLines.slice(-20) // Keep last 20 lines
        })
    }, [])


    const getStartAndEndInnerMarkers = () => {
        const markers = getEveryMarker()
        const firstKeyOfStartSection = Object.keys(segmentedTubeRef.current?.startSection)[0]

        if (!firstKeyOfStartSection) {
            return { startMarker: undefined, endMarker: undefined, }
        }

        const firstKeyOfEndSection = Object.keys(segmentedTubeRef.current?.endSection)[0]

        if (!firstKeyOfEndSection) {
            return { startMarker: undefined, endMarker: undefined, }
        }

        const startMarker = markers.find(marker => marker?.name === segmentedTubeRef.current?.startSection[firstKeyOfStartSection].inner!.name)
        const endMarker = markers.find(marker => marker?.name === segmentedTubeRef.current?.endSection[firstKeyOfEndSection].inner!.name)

        return { startMarker, endMarker, }

    }

    const seeWhatToDisconnectedAndConnect = (historyKey?: string, ignoreHistory?: boolean) => {

        const boundingBox = calcBoundgBox()

        if (isAdmin) {
            console.log("seeWhatToDisconnectedAndConnect")
        }

        messageUtils.custom("Checking to see what parts to unsnap and snap...", {
            duration: 1, forceShow: true, showSpinner: true,
        },)
        const { newConnections, existingConnections, }
            = SegmentedTubeUtils.checkSnapOnEditionMovableSection(
                scene,
                tube,
                segmentedTubeRef,
                partConnectionsValue,
                connectionTypes,
                connections,
                movableSection,
                [],
                [],
                true,
                undefined,
                true,
                boundingBox,
                isAdmin ?? false,
            )

        if (isAdmin) {
            console.log(partConnectionsValue, "partConnectionsValue", tube.id)
            console.log(newConnections, "newConnections", tube.id)
            console.log(existingConnections, "existingConnections", tube.id)
        }
        //function to get the destination marker name
        const getMatchingDestinationMarkerName
            = (partConnectionsValue: any[], connectedPartId: string,) => {
                for (const connection of partConnectionsValue) {
                    if (connection.destinationPartId === connectedPartId) {
                        return connection.destinationMarkerName
                    }
                }
                return null
            }

        const getCorrespondingMarkerName = (tubeId: string, connectedPartId: string, destinationMarkerName: string) => {
            return partConnectionsValue.find(connection =>
                connection.destinationPartId === connectedPartId
                && connection.destinationMarkerName === destinationMarkerName
            )?.partMarkerName
        }

        const normalizeMarkerName = (markerName: string) => {
            return markerName.replace(/^(inner|outer)/, "")
        }
        let connectionsToUnsnap

        if (existingConnections.length === 0) {
            // If existingConnections is empty, unsnap all partConnectionsValue
            connectionsToUnsnap = partConnectionsValue
        } else {
            const isConnectionInExistingConnections = (partConnection: any) => {
                return existingConnections.some(existingConnection =>
                    existingConnection.partB.partId === partConnection.destinationPartId
                    && normalizeMarkerName(existingConnection.partA.markerName) === normalizeMarkerName(partConnection.partMarkerName)
                    && normalizeMarkerName(existingConnection.partB.markerName) === normalizeMarkerName(partConnection.destinationMarkerName)
                )
            }

            connectionsToUnsnap = partConnectionsValue.filter(connection => !isConnectionInExistingConnections(connection))
        }

        if (isAdmin) {
            console.log(connectionsToUnsnap, "connectionsToUnsnap", tube.id)
        }
        //these are the connections that were connected before but they are no longer connected

        const historyKeyToUse = historyKey ?? crypto.randomUUID()

        if (unsnap && connectionsToUnsnap.length > 0) {
            const count = connectionsToUnsnap.length
            messageUtils.custom(`Unsnapping ${count} part${count > 1 ? "s" : ""}... `, {
                duration: 1, showSpinner: true, forceShow: true,
            })
            connectionsToUnsnap.forEach(connection => {
                unsnap(tube.id, [connection.partMarkerName,], historyKeyToUse, ignoreHistory)
                triggerAnimation("unsnap", tube.id, connection.partMarkerName, "black")
                triggerAnimation("unsnap", connection.destinationPartId, connection.destinationMarkerName, "black")
                //console.log("Unsnap", "connectedPartId", tube.id, "partMarkerName", connection.partMarkerName, "tubeId", tubeId)
            })
            SoundHelper.playUnsnap()
        }

        //get all the partsIds of parts in newConnections
        const connectedPartIds = newConnections.reduce((acc, connection) => {
            const { partA, partB, } = connection
            if (partA.partId === tube.id) {
                acc.push(partB.partId)
            } else if (partB.partId === tube.id) {
                acc.push(partA.partId)
            }
            return acc
        }, [] as string[])



        //give me the destinations markers of the parts that may already be connected
        //remove any cases where the new connection part doesn't have a destination marker name
        // posssibly bc it's not already connected
        const connectedPartIdsWithMarkers = connectedPartIds
            .map(connectedPartId => {
                const destinationMarkerName
                    = getMatchingDestinationMarkerName(partConnectionsValue, connectedPartId)
                return destinationMarkerName ? { connectedPartId, destinationMarkerName, } : null
            })
            .filter(item => item !== null)

        if (unsnap && newConnections.length > 0) {
            const filteredConnectedPartIdsWithMarkers = connectedPartIdsWithMarkers
                .filter((item): item is {
                    connectedPartId: string,
                    destinationMarkerName: any,
                } => item !== null)

            if (filteredConnectedPartIdsWithMarkers.length > 0) {
                const count = filteredConnectedPartIdsWithMarkers.length
                messageUtils.custom(`Unsnapping ${count} part${count > 1 ? "s" : ""}... `, {
                    duration: 1, showSpinner: true, forceShow: true,
                })
                filteredConnectedPartIdsWithMarkers
                    .forEach(({ connectedPartId, destinationMarkerName, }) => {
                        unsnap(connectedPartId, [destinationMarkerName,], historyKeyToUse, ignoreHistory)
                        triggerAnimation("unsnap", connectedPartId, destinationMarkerName, "black")
                        triggerAnimation("unsnap", tube.id, getCorrespondingMarkerName(tube.id, connectedPartId, destinationMarkerName), "black")
                        //console.log("Unsnap", "connectedPartId", connectedPartId, "partMarkerName",
                        //destinationMarkerName, "tubeId", tubeId)
                    })
            }
            if (newConnections.length > 0) {
                const count = newConnections.length
                messageUtils.custom(`Snapping ${count} part${count > 1 ? "s" : ""}... `, {
                    duration: 1, showSpinner: true, forceShow: true,
                })
            }
            if (isAdmin) {
                console.log("newConnections pushed", newConnections)
            }
            SoundHelper.playSnap()
        }

        multipleUpdate([], newConnections, ignoreHistory, historyKeyToUse)
        newConnections.forEach((connection: PartConnectionType) => {
            triggerAnimation("snap", connection.partA.partId, connection.partA.markerName, "blue")
            triggerAnimation("snap", connection.partB.partId, connection.partB.markerName, "blue")
        })
    }

    const registerMultiSelectMethods = useRegisterComponent(props.id, {
        updateColor, originalColor, getColor, deletePart: removePart, setPositionWithMarker, updateMatrix,
        getBoundingBox: calcBoundgBox, duplicatePart, getPartInfo, updateInitialMarkerName, setUItoNone,
        updatePositionAndRotation, getAllMarkers, getMesh, positionTubeFromBox, attachOriginToBoundingBox, updateRotation, getFacingDirectionOfMarkers,
        addToLength, getEveryMarker, setPositionWithIdentifiedMarker,
        visualDebug, getStartAndEndInnerMarkers, seeWhatToDisconnectedAndConnect,
    })

    registerMultiSelectMethods()

    const handleLengthSliderMouseDown = () => {
        setHideAddPartButtons(true)
        if (handleLengthChanges.multipleMove?.partsToMove
            && handleLengthChanges.multipleMove?.partsToMove.length > 0) {
            const markersByDirection = movableSection === SegmentedTubeSectionType.START ? segmentedTubeRef.current.startSection : segmentedTubeRef.current.endSection

            const marker = markersByDirection[getMarkerNumber(handleLengthChanges.multipleMove.sliderMarker)].inner!
            const newWorldPosition = MeshUtils.copyWorldPosition(marker)
            auxPointRef.current.position.copy(newWorldPosition)
            attachToMove(handleLengthChanges.multipleMove.partsToMove, auxPointRef.current)
        }
    }

    const handleLengthSliderMouseUp = (value: number, skipCheckingConnections?: boolean, callback?: () => void, historyKey?: string) => {
        /*if (multiSelectContext) {
            multiSelectContext.setBlockUI(true)
        }*/
        if (handleLengthChanges.multipleMove?.partsToMove
            && handleLengthChanges.multipleMove?.partsToMove.length > 0
        ) {
            const markersByDirection = movableSection === SegmentedTubeSectionType.START ? segmentedTubeRef.current.startSection : segmentedTubeRef.current.endSection
            const marker = markersByDirection[getMarkerNumber(handleLengthChanges.multipleMove!.sliderMarker)].inner!
            const newWorldPosition = MeshUtils.copyWorldPosition(marker)

            auxPointRef.current.position.copy(newWorldPosition)
            detachMarkers(handleLengthChanges.multipleMove!.partsToMove, auxPointRef.current, false)
        }
        saveLengthRouter(value, skipCheckingConnections, callback ?? undefined, historyKey ?? undefined)
        setTimeout(() => {
            //not running when mouse is down
            //when mouse is down, hide add buttons is true
            if (!hideAddPartButtons) {
                updateCamera()
            }
        }, 150)
        /*setTimeout(() => {
            if (multiSelectContext) {
                multiSelectContext.setBlockUI(false)
            }
        }, 500)*/

    }

    const setDirection = (direction: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>) => {
        setMovableSection(direction)
        SegmentedTubeUtils.removeGuidelines(scene, segmentedTubeRef)
    }

    useEffect(() => {
        if (tubeUI === SEGMENTED_TUBE_UI.SLIDER) {
            closestMarkers.current = closestMarkersHook.getClosestMarkers(segmentedTubeRef)
            markerMeshesWithoutMiddleSegments.current = MeshUtils.getMarkerMeshesWithoutMiddleSegments(scene)
        }
        if (tubeUI === SEGMENTED_TUBE_UI.SEGMENTED_SLIDER && multiSelectContext) {
            multiSelectContext.setSelectionMode(false)
        }
        calcBoundgBox()
    }, [tubeUI,])

    useEffect(() => {
        calcBoundgBox()
    }, [tube.modifiedHeight, tube.modifiedWidth,])

    const handleSlidePositionChange = () => {
        SegmentedTubeUtils.checkAlignments(scene, tube, segmentedTubeRef, partConnectionsValue, [movableSection,], markerMeshesWithoutMiddleSegments.current)
        /*const { newConnections, } = SegmentedTubeUtils.checkSnapOnEditionMovableSection(
            scene,
            tube,
            segmentedTubeRef,
            partConnectionsValue,
            connectionTypes,
            connections,
            movableSection,
            [],
            [],
            true,
            markerMeshesWithoutMiddleSegments.current
        )
        if (newConnections.length > 0) {
            SoundHelper.playUnsnap()
        }*/
    }

    const handleSaveSlideSnap = () => {
        /*const { newConnections, } = SegmentedTubeUtils.checkSnapOnEditionMovableSection(
            scene,
            tube,
            segmentedTubeRef,
            partConnectionsValue,
            connectionTypes,
            connections,
            movableSection,
            [],
            [],
            true
        )
        multipleUpdate([], newConnections)*/
    }

    const handleUnitsChange = (value: string) => {
        updateUnit(value as UnitType)
    }







    const scaleSegmentedTube = (newWidth?: number, newHeight?: number, skipConnectionChecking?: boolean, historyKey?: string, ignoreHistory?: boolean) => {
        const originPoint = segmentedTubeRef.current.originPoint
        if (!originPoint) { return }

        const currentWidth = tube.modifiedWidth || tube.realWidth
        const currentHeight = tube.modifiedHeight || tube.realHeight

        //console.log(currentWidth, currentHeight, "currentWidth, currentHeight")

        let scaleFactorX = 1
        let scaleFactorY = 1

        if (tube.realWidth !== undefined && currentWidth !== undefined && tube.widthScaling) {
            let unitToUse: "in" | "cm"
            if (newWidth !== undefined) {
                // Case 1: newWidth is provided
                unitToUse = (unit as "in" | "cm") ?? "in"
            } else {
                // Case 2: newWidth is not provided
                unitToUse = (tube.modifiedWidthUnits as "in" | "cm") ?? "in"
            }

            let widthInInches = newWidth !== undefined ? newWidth : currentWidth

            if (unitToUse === "cm") {
                widthInInches = convertCmToIn(widthInInches)
            }

            scaleFactorX = widthInInches / tube.realWidth
        }

        if (tube.realHeight !== undefined && currentHeight !== undefined && tube.heightScaling) {
            let unitToUse: "in" | "cm"
            if (newHeight !== undefined) {
                // Case 1: newHeight is provided
                unitToUse = (unit as "in" | "cm") ?? "in"
            } else {
                // Case 2: newHeight is not provided
                unitToUse = (tube.modifiedHeightUnits as "in" | "cm") ?? "in"
            }

            let heightInInches = newHeight !== undefined ? newHeight : currentHeight

            if (unitToUse === "cm") {
                heightInInches = convertCmToIn(heightInInches)
            }

            scaleFactorY = heightInInches / tube.realHeight
        }
        //console.log(scaleFactorX, scaleFactorY, "scaleFactorX, scaleFactorY")

        // Apply the scale

        originPoint.scale.x = scaleFactorX
        originPoint.scale.y = scaleFactorY

        originPoint.updateMatrixWorld(true,)
        originPoint.updateWorldMatrix(true, true)

        // Only update the modified values if explicit values were provided
        if ((newWidth !== undefined || newHeight !== undefined)) {
            updateTubeValues(tube.id, (t) => {
                if (newWidth !== undefined && tube.widthScaling) {
                    t.modifiedWidth = newWidth
                    t.modifiedWidthUnits = unit ?? "in"
                }
                if (newHeight !== undefined && tube.heightScaling) {
                    t.modifiedHeight = newHeight
                    t.modifiedHeightUnits = unit ?? "in"
                }
            })
        }
        if (!skipConnectionChecking) {
            //bounding box has to be updated or the intersections will not be correct
            calcBoundgBox()
            seeWhatToDisconnectedAndConnect(historyKey, ignoreHistory)
        }
    }





    useEffect(() => {
        if (instantiated.current) {
            updateMarkerPositionMatrixInfo()
        }
    }, [tube.position, tube.rotation,])


    const updateMarkerPositionMatrixInfo = () => {
        const updatedMarkers = tube.markers.map(marker => {
            // Modify the marker name if it's a middle marker
            const markerName = marker.position === "middle" ? `${marker.name}_0` : marker.name

            // Find the corresponding object in the scene
            let sceneObject: Object3D | undefined
            scene.traverse((object) => {
                if (object.name === markerName && object.userData.partId === tube.id) {
                    sceneObject = object
                }
            })

            if (sceneObject && sceneObject.userData.partId === tube.id) {
                const worldPosition = new Vector3()
                sceneObject.getWorldPosition(worldPosition)
                return {
                    ...marker,
                    positionXYZ: {
                        x: worldPosition.x,
                        y: worldPosition.y,
                        z: worldPosition.z,
                    },
                }
            } else {
                console.warn(`Marker not found in scene: ${markerName}`)
                return marker
            }
        })

        // Update the tube with the new marker information
        updateTubeValues(tube.id, (t) => {
            t.markers = updatedMarkers
        })
    }



    const sliderRouter = (value: number, skipCheckingConnections?: boolean, historyKey?: string) => {
        if (scalingDimension === "height") {
            scaleSegmentedTube(undefined, value, skipCheckingConnections, historyKey)
        } else if (scalingDimension === "width") {
            scaleSegmentedTube(value, undefined, skipCheckingConnections, historyKey)
        } else {
            handleSliderValueChange(value, undefined, historyKey)
        }
    }

    const saveLengthRouter = (value: number, skipCheckingConnections?: boolean, callback?: () => void, historyKey?: string) => {
        if (scalingDimension === "height" || scalingDimension === "width") {
            ///do something
        } else {
            saveLength(value, undefined, undefined, undefined, historyKey, callback, skipCheckingConnections)
        }
    }

    const drawVector3onInitialMarkerPos = () => {
        const markers = getEveryMarker()
        const initialMarker = markers.find(marker => marker?.name === innerToOuter(tube.initialMarkerName))

        if (initialMarker) {
            const worldPosition = new Vector3()
            initialMarker.getWorldPosition(worldPosition)
            console.log("initalMakerPos world pos was", worldPosition, "tube.position was", tube.position)
            drawVector3Point(worldPosition, scene, "grey", 0.009, 8000, true, undefined, "initialMarkerPos", undefined, "cube")
            const tubePosition = new Vector3(tube.position.x, tube.position.y, tube.position.z)
            drawVector3Point(tubePosition, scene, "red", 0.009, 5000, true, undefined, "tubePosition", undefined, "cylinder")
        }

    }

    const drawVector3onMarkerOffsetPos = () => {
        const markerOffset = tube.markerOffset ? new Vector3(tube.markerOffset.x, tube.markerOffset.y, tube.markerOffset.z) : undefined
        if (markerOffset) {
            drawVector3Point(markerOffset, scene, "green", 0.012, 5000, true, undefined, "markerOffset", undefined, "cylinder")
        }
    }

    useEffect(() => {
        if (isSelected) {
            //console.log(tube.id, partConnectionsValue,"partConnectionsValue")
            //console.log(connections, "connections")
            //this is a hack for now to make sure the snap happens for middle parts
            //after a move. it's not ideal.
            setTimeout(() => {
                seeWhatToDisconnectedAndConnect(undefined, false)
            }, 500)
            if (isAdmin) {
                console.log("Selected Tube", tube.id, tube)
                drawVector3onInitialMarkerPos()
                drawVector3onMarkerOffsetPos()
                console.log("Selected Tube", tube.id, partConnectionsValue, "partConnectionsValue")
                console.log(segmentedTubeRef.current, "segmentedTubeRef")
            }
            if (tube.lengthNegativeSide < 0) {
                messageUtils.custom("This part has a bad length value. Please remove and re-add it.", {
                    duration: 3, showSpinner: false, forceShow: true,
                })
            }
            setUiVisible(true)
            setTubeUI(SEGMENTED_TUBE_UI.NONE)
            const timer = setTimeout(() => {
                if (rotationSliderConfig.canRotate()) {
                    setTubeUI(SEGMENTED_TUBE_UI.ROTATION)
                } else {
                    setTubeUI(SEGMENTED_TUBE_UI.SLIDER)
                }
            }, 150)
            return () => clearTimeout(timer)
        } else {
            // Delay hiding UI to allow for any final updates
            const timer = setTimeout(() => {
                setUiVisible(false)
            }, 300)
            return () => clearTimeout(timer)
        }
    }, [isSelected,])

    return <>
        {tubeUI !== SEGMENTED_TUBE_UI.NONE && uiVisible && <SegmentedTubeUI
            tube={tube}
            handleNewAttachmentPoint={handleNewAttachmentPoint}
            middleInstanceMesh={middleMesh}
            startInstanceMesh={startMesh}
            endInstanceMesh={endMesh}
            middleMeshes={markerMeshesWithoutMiddleSegments}
            attachToMove={attachToMove}
            detachMarkers={detachMarkers}
            handleSlideMovement={handleSlideMovement}
            tubeInfo={segmentedTubeRef}
            canEditSlide={canEditSlide}
            saveRotationChanges={saveRotationChanges}
            saveSegmentedTubeSliderChanges={saveSegmentedTubeSliderChanges}
            updateTransforms={updateTransforms}
            onSlidePositionChange={handleSlidePositionChange}
            onSlideSave={handleSaveSlideSnap}
            lengthSliderConfig={{
                value: tube.length + tube.lengthNegativeSide,
                unit: unit ?? "in",
                updateUnit: handleUnitsChange,
                step: 1,
                minMiddles: tube.minMiddles,
                maxMiddles: tube.maxMiddles,
                validMax: maxPossibleLength,
                validMin: minLengthRef.current,
                onchange: sliderRouter,
                segmentLength: tube.segmentLength,
                scaledSegmentLength: tube.scaledSegmentLength,
                startSegmentLength: tube.startSegmentLength,
                endSegmentLength: tube.endSegmentLength,
                unitRealValue: tube.unitRealValue ?? undefined,
                saveLength: saveLengthRouter,
                setHideAddPartButtons,
                onMouseDown: handleLengthSliderMouseDown,
                onMouseUp: handleLengthSliderMouseUp,
                setDirection,
                direction: movableSection,
                setAttachmentPointToRef,
                modifiedWidth: tube.modifiedWidth,
                modifiedWidthUnits: tube.modifiedWidthUnits,
                modifiedHeight: tube.modifiedHeight,
                modifiedHeightUnits: tube.modifiedHeightUnits,

            }}
            scalingDimension={scalingDimension}
            setScalingDimension={setScalingDimension}
            rotationSliderConfig={rotationSliderConfig}
            onRemove={removePart}
            setTubeUI={setTubeUI}
            tubeUI={tubeUI}
            boundingBox={boundingBox}
            unsnap={unsnap}
            onDuplicate={multiSelectContext?.duplicateSelectedParts ?? (() => { })}
            setIdsAsHighlightedAndTurnOnControl={multiSelectContext?.setIdsAsHighlightedAndTurnOnControl ?? (() => { })}
            setIsSelected={setIsSelected}
            onRotationSliderChange={multiSelectContext?.onRotationSliderChange ?? (() => { })}
            transformMode={multiSelectContext?.transformMode ?? "off"}
            removeMarkerOffset={removeMarkerOffset}
        />}
        {(isSelected && !hideAddPartButtons)
            && <NewPartButtonsGroup
                partId={tube.id}
                info={segmentedTubeRef}
                length={actualLength.positiveLength + actualLength.negativeLength - 1}
                partConnectionsValue={partConnectionsValue}
                setIsSelected={setIsSelected}
                scene={scene}
                isItemSelected={isItemSelected}
                rotation={tube.rotation}
                position={tube.position}
            />}
        {viewTubeNames && isSelected && (
            <Html
                style={{
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                    padding: "5px",
                    borderRadius: "3px",
                    fontSize: "12px",
                }}
            >
                {tube.id}
            </Html>
        )}
        {debugNormals && debugLabels.map((label, index) => (
            <Text
                key={index}
                position={label.position}
                fontSize={0.02}
                color="black"
                anchorX="center"
                anchorY="middle"
                userData={{ ignoreRaycast: true, }}
            >
                {label.text}
            </Text>
        ))}
        {showDebugText && boundingBoxCenter && (
            <DebugText
                text={debugText}
                position={boundingBoxCenter}
                visible={showDebugText}
                isSelected={isSelected}
            />
        )}
    </>
}

export default SegmentedTube