import { Timestamp } from "firebase/firestore"

export enum ConnectionTypeThreadingEnum {
    slip = "SLIP",
    npt = "NPT",
}

export enum ConnectionTypeFemaleMale {
    male = "Male",
    female = "Female"
}

export type OppositeConnection = Connection & {
    opposite: number,
}

export enum SegmentedTubeSectionType {
    START = "start",
    MIDDLE = "middle",
    END = "end"
}

export type Connection = {
    connectionSize: string,
    connectionType: string,
    fullLeg: number,
    iELength: number,
    oppositeOf: OppositeConnection | null,
    sameConfigurationAs: number[],
    connectionPosition?: SegmentedTubeSectionType,
}

export enum NoProviderEnum {
    Hardware = "Hardware",
    Plumbing = "Plumbing"
}

export type PartTypeAPI = {
    name: string,
    modelIcon: string,
    defaultLength: number,
    maxLength: number,
    materialId: string,
    subclassId: string,
    apiTypeId?: string,
    colorId: string,
    assethPath: string,
    connectors: string[],
    tube?: boolean,
    segmentedTube?: boolean,
    diameters?: Record<string, { inner: number, outer: number, }>,
    connections: {
        placeholderId: string,
        connectorId: string,
        size: SizeAPI,
        oppositeOf?: string,
        fullLeg: number,
        iELength: number,
        sameConfigurationAs: number[],
        position?: SegmentedTubeSectionType,
        lateralFacing?: boolean,
        boundary?: number,
    }[],
    id: string,
    categories: string | string[],
    affiliateURL?: string,
    noProvider?: NoProviderEnum,
    furtherInfo?: string,
    cartNotice?: string,
    rotationDisabled?: boolean,
    segmentLength?: number,
    startSegmentLength?: number,
    endSegmentLength?: number,
    rotationSteps?: number,
    maxMiddles?: number,
    minMiddles?: number,
    defaultMiddles?: number,
    hideFromSidebar?: boolean,
    partUnits?: string,
    unitRealValue?: number,
    scaledSegmentLength?: number,
    segmentScaleFactor?: number,
    specialRotationMarker?: boolean,
    heightScaling?: boolean,
    widthScaling?: boolean,
    realWidth?: number,
    realHeight?: number,
    modifiedWidth?: number,
    modifiedWidthUnits?: string,
    modifiedHeight?: number,
    modifiedHeightUnits?: string,
    baseName?: string,
    // an ID that the user can request for the part
    preferedId?: string,
}

export type ConnectionAPIRaw = {
    connectionSize: string,
    connectionType: string,
    fullLeg: number,
    iELenght: number,
    oppositeOf: OppositeConnection | null,
    sameConfigurationAs: number[],
    connectionPosition?: SegmentedTubeSectionType,
    lateralFacing?: boolean,
    Boundary?: number,
}

export type PartTypeAPIRaw = {
    id: string,
    FriendlyName: string,
    ModelIcon: string,
    Material: string,
    SubMaterial: string,
    Color: string,
    ModelFile: string,
    Category: string[],
    IsTube?: boolean,
    cartNotice?: string,
    Connection1: ConnectionAPIRaw,
    Connection1X: string,
    Connection2: ConnectionAPIRaw,
    Connection2X: string,
    Connection3: ConnectionAPIRaw,
    Connection3X: string,
    Connection4: ConnectionAPIRaw,
    Connection4X: string,
    Connection5: ConnectionAPIRaw,
    Connection5X: string,
    Connection6: ConnectionAPIRaw,
    Connection6X: string,
    Connection7: ConnectionAPIRaw,
    Connection7X: string,
    Connection8: ConnectionAPIRaw,
    Connection8X: string,
    Connection9: ConnectionAPIRaw,
    Connection9X: string,
    Connection10: ConnectionAPIRaw,
    Connection10X: string,
    Connection11: ConnectionAPIRaw,
    Connection11X: string,
    Connection12: ConnectionAPIRaw,
    Connection12X: string,
    Connection13: ConnectionAPIRaw,
    Connection13X: string,
    Connection14: ConnectionAPIRaw,
    Connection14X: string,
    Connection15: ConnectionAPIRaw,
    Connection15X: string,
    Connection16: ConnectionAPIRaw,
    Connection16X: string,
    Connection17: ConnectionAPIRaw,
    Connection17X: string,
    Connection18: ConnectionAPIRaw,
    Connection18X: string,
    Connection19: ConnectionAPIRaw,
    Connection19X: string,
    Connection20: ConnectionAPIRaw,
    Connection20X: string,
    Connection21: ConnectionAPIRaw,
    Connection21X: string,
    affiliateURL?: string,
    noProvider?: NoProviderEnum,
    furtherInfo?: string,
    rotationDisabled?: boolean,
    heightScaling?: boolean,
    widthScaling?: boolean,
    realWidth?: number,
    realHeight?: number,
    middleLength?: number,
    startLength?: number,
    endLength?: number,
    rotationDegreeSteps?: number,
    maxMiddles?: number,
    minMiddles: number,
    defaultMiddles: number,
    hideFromSidebar?: boolean,
    specialRotationMarker?: boolean,
    baseName?: string,
}

export type ConnectionTypeAPI = {
    id: string,
    connectionName: string,
    Threading: ConnectionTypeThreadingEnum,
    ignoreSizeCompatibility?: boolean,
    compatibleWithMulti: string[],
    femaleMale: ConnectionTypeFemaleMale,
    longName: string,
}

export type MaterialAPI = {
    id: string,
    materialName: string,
    materialOrder: number,
}

export type SubClassAPI = {
    id: string,
    subclassName: string,
    additionalInfo: string,
}

export type ColorAPI = {
    id: string,
    colorName: string,
    hex: string,
}

export type SizeAPI = {
    id: string,
    size: number,
    friendlyName: string,
    friendlyIntegerName: string,
    innerDiameter: number,
    outerDiameter: number,
    hideName?: boolean,
}

export type MaterialsAndSubClassesListsType = {
    materials: MaterialAPI[],
    subclasses: SubClassAPI[],
}

export type CategoryTypeAPIRaw = {
    id: string,
    catName: string,
    iconUploader: string,
    uniqueIcons?: Record<string, string>,
}

export type CategoryTypeAPI = {
    id: string,
    name: string,
    image: string,
    imagesForMaterial?: Record<string, string>,
}

export type DesignTypeAPI = {
    id: string,
    state: string,
    imageBase64: string,
    name: string,
    creationDate: Timestamp,
    creationBuildId: string,
    modificationDate: Timestamp,
    modificationBuildId: string,
    userId: string,
    deleted: boolean,
    currentSessionId: string,
    unit?: "cm" | "in",
}


export type ShopifyIdAPI = {
    id: string,
    barcode: string,
    variantId: string,
}
