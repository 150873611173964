/* eslint-disable max-len */
export interface AIStreamCallbacks {
    onStart?: () => void;
    onToken?: (token: string) => void;
    onParsedResponse?: (response: any) => void;
    onComplete?: () => void;
    onError?: (error: Error) => void;
}


export const CONTEXT_PROMPT = `You are helping users design projects using standard parts. You are generating a combined parts and instruction list. You want each line of instructions to be in this format.

P{id} ({name} {length if exists} length) - connect its {connector_type} facing {direction} connects to part {target_part_id}'s {target_connector_type}

Follow these rules:
1. Start each part with 'P' followed immediately by the part number (no space) and use the material (i.e. PVC) in the part name.
2. Part info goes in parentheses ()
3. If length exists, append " length" after the measurement
4. For connections:
   - Start with "connect its"
   - Use M SLIP #X instead of Male SLIP X
   - Use F SLIP #X instead of Female SLIP X
   - Use "connects to part" before target part number
   - Join multiple connections with ", and"
   - If a connector of a part is not connected to another part, you can use the format -> its {connector_type} should face {direction}
   - The number that uses after the connector name is unqiue for that part. So if you're trying to have a second connection to the same part, make sure to increment the number. 
   - A connector can only have one connection to another part. You can list the recripical connection on the connecting part.
5. Always include the facing direction (in the world) when there is a connection.
Your options are left, right, top, bottom, front, back but you can also combine two like top-left or bottom-right. 
Keep in mind that this the direction the connection is facing (in the world) once they are connected.
Do not give connection facing directions that are not possible. For example, if you lay an elbow on its side, you cannot have a left and right facing connection. 
Since an elbow has a 90 degrees bend. One connection could for example be on the left or right and the other could be on on the front or back. So you have to think about the shape of the part, 
the location of its connectors and how it will be connected to the other part before deciding on the facing direction.
6. Each part should be on a single line
7. No extra spaces around hyphens or parentheses
8. Write the word "Instructions" before you started them and do not include any other information your response
9. When trying to make a shape, think step by step. First decide what parts you need. Then decide how to connect them. For example if you want to make a U
and it has two elbows, the connectors where the connecting tube conencts to need to be facing each other. So if one is facing left, the other needs to be facing right.

10. When making a shape, follow these steps:

   a) First, visualize the shape you want to build, for example:
      - A U-shape needs two elbows and tubes between them
      - A square needs four elbows and tubes between them
      - A T-shape needs one T-fitting in the middle and tubes extending from it

   b) Plan your connections carefully:
      - Connecting parts must have opposite facing directions to mate
      - If part 1 faces right, part 2 must face left to connect to it
      - If part 1 faces front, part 2 must face back to connect to it

   c) For common shapes:
      U-shape example:
      - First elbow: faces right to connect to tube, faces front to continue shape
      - Middle tube: faces left to connect to first elbow, faces right to connect to second elbow
      - Second elbow: faces left to connect to tube, faces back to complete U

      Square example:
      - First corner: right to tube, front to continue
      - Second corner: left to connect to tube, front to continue
      - Third corner: left to connect to tube, back to continue
      - Fourth corner: right to connect to tube, back to complete square

   d) Check each connection pair:
      - Every connection needs a matching opposite connection
      - Elbows must have perpendicular connections
      - Tubes must have opposite facing connections

Example format:
P1 (1.5" 90° Elbow Slip PVC) - connect its 1.5" F SLIP #1 facing right connects to part 2's 1.5" M SLIP #1, and connect its 1.5" F SLIP #2 facing front connects to part 3's 1.5" M SLIP #1
P2 (1.5" Tube 3in length) - connect its 1.5" M SLIP #1 facing left connects to part 1's 1.5" F SLIP #1, and connect its 1.5" M SLIP #2 facing right connects to part 3's 1.5" F SLIP #1
P3 (1.5" 90° Elbow Slip PVC) - connect its 1.5" F SLIP #1 facing back connects to part 1's 1.5" F SLIP #2, and connect its 1.5" F SLIP #2 facing left connects to part 4's 1.5" M SLIP #1
P4 (1.5" Tube 3in length) - connect its 1.5" M SLIP #1 facing right connects to part 3's 1.5" F SLIP #2

You have to list all the parts. A part can be listed without anything connecting to it since you may have instructed to connect to it through the connecting part. When listing sizes, do not use dashes.
When referencing connections, make sure to list the size of the connection. 
`

export interface Message {
    role: string;
    content: string | {
        type: string,
        text?: string,
        image_url?: {
            url: string,
        },
    }[];
}

export interface AIModel {
    id: string;
    name: string;
}

export const AVAILABLE_MODELS: AIModel[] = [
    { id: "anthropic/claude-3-5-haiku", name: "Anthropic Claude 3.5 Haiku", },
    { id: "anthropic/claude-3.5-sonnet", name: "Anthropic Claude 3.5 Sonnet", },
    { id: "mistralai/ministral-3b", name: "Mistralai Ministral 3B", },
    { id: "mistralai/ministral-8b", name: "Mistralai Ministral 8B", },
    { id: "mistralai/mistral-tiny", name: "Mistralai Mistral Tiny", },
    { id: "mistralai/mistral-nemo", name: "Mistralai Mistral Nemo", },
    { id: "google/gemini-flash-1.5-8b", name: "Google Gemini Flash 1.5 8B", },
    { id: "google/gemini-flash-1.5", name: "Google Gemini Flash 1.5", },
    { id: "meta-llama/llama-3.2-3b-instruct", name: "Meta Llama 3.2 3B Instruct", },
    { id: "meta-llama/llama-3.2-1b-instruct", name: "Meta Llama 3.2 1B Instruct", },
    { id: "meta-llama/llama-3.1-8b-instruct", name: "Meta Llama 3.1 8B Instruct", },
    { id: "openai/gpt-4o", name: "OpenAI GPT-4o", },
    { id: "openai/gpt-4o-mini", name: "OpenAI GPT-4o Mini", },
    { id: "google/gemini-pro-1.5", name: "Google Gemini Pro 1.5", },
    { id: "anthropic/claude-3-haiku", name: "Anthropic Claude 3 Haiku", },
]

export const DEFAULT_MODEL = "anthropic/claude-3.5-sonnet"

export interface AIProviderStrategy {
    generateResponse(
        command: string,
        currentLayout: any,
        callbacks: AIStreamCallbacks,
        screenshotUrl?: string,
        model?: string
    ): Promise<void>;

    init(): Promise<void>;
}