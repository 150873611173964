/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import React from "react"
import { Collapse, Dropdown, Menu, Tooltip } from "antd"
import { SettingOutlined, RetweetOutlined, DragOutlined, CopyOutlined } from "@ant-design/icons"
import tinycolor from "tinycolor2"
import { partsListStyles } from "./partsListHelpers"
import { rowTemplate } from "./partsListHelpers"
import { usePartsList } from "../usePartsList"
import { PartTypeAPI, ShopifyIdAPI } from "../../../../../../../../common/api/Types"
import { ObjDictionary } from "../../../../../../../../common/utils/utils"
import { SamePartsPanel } from "./SamePartsPanel"
import { GroupType } from "../../../../../../../state/scene/types"

interface GroupPanelProps {
  groupId: string;
  parts: any[];
  allParts: PartTypeAPI[];
  groupsAtomList: GroupType[];
  getGroupMenu: (groupId: string) => JSX.Element;
  handleItemHover: (itemIds: string[]) => void;
  handleItemLeave: (itemIds: string[]) => void;
  sceneCallbacks: any;
  isActive?: boolean;
  onPanelChange?: (isActive: boolean) => void;
  shopifyIds: ObjDictionary<ShopifyIdAPI>;
}

export const GroupPanel: React.FC<GroupPanelProps> = ({
  groupId,
  parts,
  allParts,
  groupsAtomList,
  getGroupMenu,
  handleItemHover,
  handleItemLeave,
  sceneCallbacks,
  isActive,
  onPanelChange,
  shopifyIds,
}) => {
  const { getRows, } = usePartsList({ parts: allParts, shopifyIds, allowCm: true, })

  const groupPartsByType = (parts: any[]) => {
    return parts.reduce((acc: { [key: string]: any[], }, part) => {
      const key = `${part.type}-${part.size}`
      if (!acc[key]) { acc[key] = [] }
      acc[key].push(part)
      return acc
    }, {})
  }

  const typeKeys = Object.keys(groupPartsByType(parts))

  return (
    <Collapse
      activeKey={isActive ? [groupId,] : []}
      onChange={(keys) => onPanelChange?.(keys.includes(groupId))}
    >
      <Collapse.Panel
        key={groupId}
        header={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <span
              style={{
                color: tinycolor(Object.values(groupsAtomList).find(g => g.id === groupId)?.color).isLight()
                  ? "#000000"
                  : "#ffffff",
              }}
            >
              {Object.values(groupsAtomList).find(group => group.id === groupId)?.name}
            </span>
            <div style={{ display: "flex", gap: "8px", }}>
              <Tooltip title="Duplicate">
                <CopyOutlined
                  style={{
                    cursor: "pointer",
                    color: tinycolor(Object.values(groupsAtomList).find(g => g.id === groupId)?.color).isLight()
                      ? "#000000"
                      : "#ffffff",
                  }}
                  onClick={e => {
                    e.stopPropagation()
                    if (sceneCallbacks?.duplicateSelectedParts) {
                      sceneCallbacks.duplicateSelectedParts(false, parts.map(p => p.id))
                    }
                  }}
                />
              </Tooltip>
              <Tooltip title="Rotate">
                <RetweetOutlined
                  style={{
                    cursor: "pointer",
                    color: tinycolor(Object.values(groupsAtomList).find(g => g.id === groupId)?.color).isLight()
                      ? "#000000"
                      : "#ffffff",
                  }}
                  onClick={e => {
                    e.stopPropagation()
                    if (sceneCallbacks?.setIdsAsHighlightedAndTurnOnControl) {
                      sceneCallbacks.setIdsAsHighlightedAndTurnOnControl(
                        parts.map(p => p.id),
                        "rotate"
                      )
                    }
                  }}
                />
              </Tooltip>
              <Tooltip title="Move">
                <DragOutlined
                  style={{
                    cursor: "pointer",
                    color: tinycolor(Object.values(groupsAtomList).find(g => g.id === groupId)?.color).isLight()
                      ? "#000000"
                      : "#ffffff",
                  }}
                  onClick={e => {
                    e.stopPropagation()
                    if (sceneCallbacks?.setIdsAsHighlightedAndTurnOnControl) {
                      sceneCallbacks.setIdsAsHighlightedAndTurnOnControl(
                        parts.map(p => p.id),
                        "translate"
                      )
                    }
                  }}
                />
              </Tooltip>
              <div onClick={e => e.stopPropagation()}>
                <Dropdown overlay={getGroupMenu(groupId)} trigger={["click",]}>
                  <Tooltip title="Settings">
                    <SettingOutlined
                      style={{
                        cursor: "pointer",
                        color: tinycolor(Object.values(groupsAtomList).find(g => g.id === groupId)?.color).isLight()
                          ? "#000000"
                          : "#ffffff",
                      }}
                    />
                  </Tooltip>
                </Dropdown>
              </div>
            </div>
          </div>
        }
        style={{
          backgroundColor: Object.values(groupsAtomList).find(g => g.id === groupId)?.color,
          marginBottom: "4px",
          borderRadius: "4px",
        }}
      >
        {Object.entries(groupPartsByType(parts)).map(([typeKey, sameParts,]) => (
          <SamePartsPanel
            key={typeKey}
            typeKey={typeKey}
            sameParts={sameParts}
            handleItemHover={handleItemHover}
            handleItemLeave={handleItemLeave}
            sceneCallbacks={sceneCallbacks}
            getRows={getRows}
          />
        ))}
      </Collapse.Panel>
    </Collapse>
  )
}