/* eslint-disable default-case */
/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable max-lines */

import { MutableRefObject } from "react"
import { ArrowHelper, Box3, BufferGeometry, Camera, Intersection, Line, LineBasicMaterial, Mesh, MeshBasicMaterial, Object3D, Quaternion, Raycaster, Scene, Vector3 } from "three"
import { MarkerType, PartTypeEnum, TubeMarkerEnum } from "./Types"
import {
    ConnectorMarkerType,
    ConnectorInternalsType
} from "../components/main/DesignScreen/scene/part/parts/connector/types/types"
import {
    TubeInternalsType
} from "../components/main/DesignScreen/scene/part/parts/tube/types/types"

import { Euler, MathUtils, } from "three"
import { MeshUtils } from "./MeshUtils"
import tinycolor from "tinycolor2"


export type MarkerUserData = {
    userDataType: "MarkerUserData",
    id: string,
    type: MarkerType,
    partId: string,
    partApiId: string,
    partType: PartTypeEnum,
    sizeId: string,
    innerOuter: ConnectorMarkerType,
    iELength: number,
    markerName: string,
}

export const getMarkerUserData = (marker: Object3D) => {
    if (marker.userData.userDataType === "MarkerUserData") {
        return marker.userData as MarkerUserData
    }
    console.error("Invalid user data", { marker, userData: marker.userData, })
    throw new Error("Invalid user data")
}

export const isMarkerUserData = (
    marker: Object3D
): marker is Object3D & { userData: MarkerUserData, } => {
    return marker.userData.userDataType === "MarkerUserData"
}

export const getMarkerRef = (
    scene: Scene,
    partId: string,
    markerName: string,
    withPosition = true,
    replaceWithZero = false
) => {
    let modifiedMarkerName = markerName
    const hasUnderscore = markerName.includes("_")

    if (hasUnderscore) {
        const [baseMarker, markerNumber,] = markerName.split("_")
        modifiedMarkerName = `${baseMarker}_0`
    }

    const markerRef = getMarkerRefRecursive(
        [scene,], partId, modifiedMarkerName, withPosition)

    if (!markerRef) {
        console.error("Marker not found", { partId, modifiedMarkerName, markerName, })
        return undefined
    }

    if (hasUnderscore && markerRef.userData.middleRefs) {
        const matchingRef = markerRef.userData.middleRefs.find(
            (ref: { refName: string, }) => ref.refName === markerName
        )

        if (matchingRef) {
            const clonedObject = new Object3D()
            clonedObject.name = markerName
            clonedObject.userData = { ...markerRef.userData, middleRefs: [matchingRef,], }

            // Clone the position, rotation, and scale
            clonedObject.position.copy(markerRef.position)
            clonedObject.rotation.copy(markerRef.rotation)
            clonedObject.scale.copy(markerRef.scale)

            // Apply the localOffset to set the new position
            const worldPosition = markerRef.localToWorld(new Vector3().copy(matchingRef.localOffset))
            clonedObject.position.copy(worldPosition)

            const worldQuaternion = new Quaternion()
            markerRef.getWorldQuaternion(worldQuaternion)
            clonedObject.quaternion.copy(worldQuaternion)

            return clonedObject
        }
    }

    return markerRef
}

export const optimizeSlidePoints = (points: { position: Vector3, }[], densityReduction = 0.5) => {
    // Early return if very few points
    if (points.length <= 20) { return points }

    // Find bounds of all points
    const bounds = {
        min: new Vector3(Infinity, Infinity, Infinity),
        max: new Vector3(-Infinity, -Infinity, -Infinity),
    }

    points.forEach(p => {
        bounds.min.x = Math.min(bounds.min.x, p.position.x)
        bounds.min.y = Math.min(bounds.min.y, p.position.y)
        bounds.min.z = Math.min(bounds.min.z, p.position.z)
        bounds.max.x = Math.max(bounds.max.x, p.position.x)
        bounds.max.y = Math.max(bounds.max.y, p.position.y)
        bounds.max.z = Math.max(bounds.max.z, p.position.z)
    })

    // Calculate cell size based on density reduction
    // Larger density reduction = larger cells = fewer points
    const avgSpacing = Math.cbrt(
        ((bounds.max.x - bounds.min.x)
            * (bounds.max.y - bounds.min.y)
            * (bounds.max.z - bounds.min.z)) / points.length
    )
    const cellSize = avgSpacing / densityReduction

    // Create buckets using Map for faster lookups
    const buckets = new Map<string, { position: Vector3, }>()

    points.forEach(point => {
        // Calculate bucket indices
        const bx = Math.floor(point.position.x / cellSize)
        const by = Math.floor(point.position.y / cellSize)
        const bz = Math.floor(point.position.z / cellSize)

        // Create bucket key
        const key = `${bx},${by},${bz}`

        // Only keep one point per bucket (first one)
        if (!buckets.has(key)) {
            buckets.set(key, point)
        }
    })

    // Convert back to array and ensure we include endpoints
    const result = Array.from(buckets.values())

    // Always include first and last points from original array
    const first = points[0]
    const last = points[points.length - 1]

    if (!result.includes(first)) { result.unshift(first) }
    if (!result.includes(last)) { result.push(last) }

    return result
}
export const getMarkerRefRecursive = (
    objects: Object3D[], partId: string, markerName: string, withPosition: boolean
): Object3D | undefined => {

    if (objects.length > 0) {
        let i = 0
        let ref = undefined
        while (!ref && objects[i]) {
            if (isMarkerUserData(objects[i])
                && objects[i].userData.partId === partId
            ) {
                const objectName = withPosition
                    ? objects[i].name
                    : getPlaceholderIdWithoutPosition(objects[i].name)
                //console.log(objectName, "objectName")
                if (objectName === markerName) {
                    ref = objects[i]
                } else if (objects[i].children.length > 1) {
                    ref = getMarkerRefRecursive(
                        objects[i].children,
                        partId,
                        markerName,
                        withPosition
                    )
                }
            } else {
                ref = getMarkerRefRecursive(objects[i].children, partId, markerName, withPosition)
            }
            i++
        }
        return ref
    } else {
        return undefined
    }
}

export const getNormalDirection = (
    marker: Object3D,
    partId: string,
    scene: Scene,
    debug = false,
    setDebugNormals: (value: boolean) => void,
    setDebugLabels: React.Dispatch<React.SetStateAction<{
        position: Vector3,
        text: string,
    }[]>>
): string => {
    const worldNormal = new Vector3(0, 0, 1)
    marker.updateWorldMatrix(true, false)
    worldNormal.applyQuaternion(marker.getWorldQuaternion(new Quaternion()))

    const parts: string[] = []

    // Define thresholds for classification
    const straightThreshold = 0.85  // Increased to require more alignment for straight
    const diagonalThreshold = 0.3   // Keep this the same

    // Get absolute values for easier comparison
    const absX = Math.abs(worldNormal.x)
    const absY = Math.abs(worldNormal.y)
    const absZ = Math.abs(worldNormal.z)

    // Check each component and classify based on magnitude
    if (absZ > diagonalThreshold) {
        parts.push(worldNormal.z > 0 ? "front" : "back")
    }

    if (absX > diagonalThreshold) {
        parts.push(worldNormal.x > 0 ? "right" : "left")
    }

    if (absY > diagonalThreshold) {
        parts.push(worldNormal.y > 0 ? "top" : "bottom")
    }

    // Only filter to single direction if one component is VERY dominant
    if (parts.length > 1) {
        const maxComponent = Math.max(absX, absY, absZ)
        if (maxComponent > straightThreshold) {
            parts.length = 0
            if (absZ === maxComponent) { parts.push(worldNormal.z > 0 ? "front" : "back") }
            else if (absX === maxComponent) { parts.push(worldNormal.x > 0 ? "right" : "left") }
            else if (absY === maxComponent) { parts.push(worldNormal.y > 0 ? "top" : "bottom") }
        }
    }
    if (debug) {

        //console.log(partId, "Normal components:", {
        //    x: worldNormal.x,
        //    y: worldNormal.y,
        //    z: worldNormal.z,
        //    parts,
        //})

        // Clear previous debug elements
        setDebugNormals(true)
        //setDebugLabels([]) // Clear previous labels before adding new ones

        const rayLength = 0.2
        const rayStart = new Vector3()
        marker.getWorldPosition(rayStart)
        const rayEnd = rayStart.clone().add(worldNormal.multiplyScalar(rayLength))

        const arrowHelper = new ArrowHelper(
            worldNormal.normalize(),
            rayStart,
            rayLength,
            0xff0000
        )
        if (arrowHelper.line) {
            arrowHelper.line.material = new LineBasicMaterial({
                color: "red",
                depthWrite: false,
                depthTest: false,
            })
        }
        if (arrowHelper.cone) {
            arrowHelper.cone.material = new MeshBasicMaterial({
                color: "red",
                depthWrite: false,
                depthTest: false,
            })
        }
        scene.add(arrowHelper)

        // Add new label
        const textPosition = rayEnd.clone().add(worldNormal.multiplyScalar(0.05))
        setDebugLabels(prev => [...prev, {
            position: textPosition,
            text: `(${partId}) ${marker.name}: ${parts.join("-") || "unknown"}`,
        },])
    }
    const result = normalizeDirectionalName(parts.join("-")) || "unknown"
    if (!result) {
        console.log("getNormalDirection result", marker, parts)
    }
    return result
}

interface RaycastEndpoints {
    start: Intersection[];
    end: Intersection[];
}

export interface RaycastResults {
    endpoints: RaycastEndpoints;
    uniquePartIds: {
        start: string[],
        end: string[],
    };
    firstRaycastOriginPos: {
        start: Vector3,
        end: Vector3,
    };
}

const getUniquePartIdsFromIntersections = (intersections: Intersection[]): string[] => {
    const uniquePartIds = new Set<string>()

    intersections.forEach(intersection => {
        const userData = (intersection.object as Object3D).userData
        if (userData && userData.partId) {
            uniquePartIds.add(userData.partId)
        }
    })

    return Array.from(uniquePartIds)
}

export const getRaycastsFromMeshEnds = (
    mesh: Mesh,
    raysPerEnd = 2,
    rayDistance = 1,
    scene: Scene,
    camera?: Camera,
    inwardOffset = 0.2,
    allRelevantMarkers: Mesh[] = [],
    debug = false,
): RaycastResults => {
    const raycaster = new Raycaster()
    if (camera) {
        raycaster.camera = camera
    }

    const results: RaycastEndpoints = {
        start: [],
        end: [],
    }

    // Initialize with default Vector3s
    let firstStartPos = new Vector3()
    let firstEndPos = new Vector3()

    // Ensure bounding box is computed
    if (!mesh.geometry.boundingBox) {
        mesh.geometry.computeBoundingBox()
    }

    const localBox = mesh.geometry.boundingBox?.clone()
    if (!localBox) {
        return {
            endpoints: results,
            uniquePartIds: {
                start: [],
                end: [],
            },
            firstRaycastOriginPos: {
                start: new Vector3(),
                end: new Vector3(),
            },
        }
    }

    // Get local vertices for front and back faces
    const backFaceVertices = [
        new Vector3(localBox.min.x, localBox.min.y, localBox.min.z),
        new Vector3(localBox.min.x, localBox.max.y, localBox.min.z),
        new Vector3(localBox.max.x, localBox.min.y, localBox.min.z),
        new Vector3(localBox.max.x, localBox.max.y, localBox.min.z),
    ]

    const frontFaceVertices = [
        new Vector3(localBox.min.x, localBox.min.y, localBox.max.z),
        new Vector3(localBox.min.x, localBox.max.y, localBox.max.z),
        new Vector3(localBox.max.x, localBox.min.y, localBox.max.z),
        new Vector3(localBox.max.x, localBox.max.y, localBox.max.z),
    ]

    const leftFaceVertices = [
        new Vector3(localBox.min.x, localBox.min.y, localBox.min.z),
        new Vector3(localBox.min.x, localBox.max.y, localBox.min.z),
        new Vector3(localBox.min.x, localBox.min.y, localBox.max.z),
        new Vector3(localBox.min.x, localBox.max.y, localBox.max.z),
    ]

    const rightFaceVertices = [
        new Vector3(localBox.max.x, localBox.min.y, localBox.min.z),
        new Vector3(localBox.max.x, localBox.max.y, localBox.min.z),
        new Vector3(localBox.max.x, localBox.min.y, localBox.max.z),
        new Vector3(localBox.max.x, localBox.max.y, localBox.max.z),
    ]

    // Transform vertices to world space
    const worldBackVertices = leftFaceVertices.map(v => v.clone().applyMatrix4(mesh.matrixWorld))
    const worldFrontVertices = rightFaceVertices.map(v => v.clone().applyMatrix4(mesh.matrixWorld))

    const meshNormal = MeshUtils.copyWorldDirection(mesh)

    // Function to cast rays from interpolated positions
    const castRaysFromPositions = (
        startVertices: Vector3[],
        endVertices: Vector3[],
        offsetStartMultiplier = 0,
        offsetEndMultiplier = 0,
        color = "red",
    ) => {
        // Track unique mesh IDs
        const startMeshIds = new Set<string>()
        const endMeshIds = new Set<string>()


        for (let i = 1; i <= raysPerEnd; i++) {
            const t = i / (raysPerEnd + 1)

            // Calculate base positions
            const startPos = new Vector3()
            startPos.lerpVectors(
                startVertices[0].clone().lerp(startVertices[1], t),
                startVertices[2].clone().lerp(startVertices[3], t),
                0.5
            )

            const endPos = new Vector3()
            endPos.lerpVectors(
                endVertices[0].clone().lerp(endVertices[1], t),
                endVertices[2].clone().lerp(endVertices[3], t),
                0.5
            )

            // Store first positions
            if (i === 1) {
                firstStartPos = startPos.clone()
                firstEndPos = endPos.clone()
            }

            // Get direction vector once
            const direction = endPos.clone().sub(startPos)
                .normalize()

            // Apply inward offsets separately to start and end positions
            if (offsetStartMultiplier !== 0) {
                startPos.add(direction.clone().multiplyScalar(inwardOffset * offsetStartMultiplier))
            }
            if (offsetEndMultiplier !== 0) {
                endPos.sub(direction.clone().multiplyScalar(inwardOffset * offsetEndMultiplier))
            }

            // Cast rays
            raycaster.set(startPos, meshNormal)
            const startIntersects = raycaster.intersectObjects(allRelevantMarkers, false)
                .filter(intersect => {
                    const meshId = (intersect.object as Mesh).uuid
                    if (!startMeshIds.has(meshId)) {
                        startMeshIds.add(meshId)
                        return true
                    }
                    return false
                })
            results.start.push(...startIntersects)

            raycaster.set(endPos, meshNormal)
            const endIntersects = raycaster.intersectObjects(allRelevantMarkers, false)
                .filter(intersect => {
                    const meshId = (intersect.object as Mesh).uuid
                    if (!endMeshIds.has(meshId)) {
                        endMeshIds.add(meshId)
                        return true
                    }
                    return false
                })
            results.end.push(...endIntersects)


            // Visualize rays for debugging
            if (debug) {
                addDebugRay(scene, startPos, meshNormal, rayDistance, color)
                addDebugRay(scene, endPos, meshNormal, rayDistance, color)
            }
        }
    }

    const randomColor = tinycolor.random()
    // Cast original rays (no offset)
    castRaysFromPositions(worldBackVertices, worldFrontVertices, 0, 0, randomColor.toHexString())

    // Cast inward offset rays for start side
    castRaysFromPositions(worldBackVertices, worldFrontVertices, 1, 0, randomColor.toHexString())

    // Cast inward offset rays for end side
    castRaysFromPositions(worldBackVertices, worldFrontVertices, 0, 1, randomColor.toHexString())

    return {
        endpoints: results,
        uniquePartIds: {
            start: getUniquePartIdsFromIntersections(results.start),
            end: getUniquePartIdsFromIntersections(results.end),
        },
        firstRaycastOriginPos: {
            start: firstStartPos,
            end: firstEndPos,
        },
    }
}

// Helper function to visualize rays (optional)
const addDebugRay = (scene: Scene, origin: Vector3, direction: Vector3, length: number, color = "red") => {
    const points = [
        origin,
        origin.clone().add(direction.clone().multiplyScalar(length)),
    ]
    const geometry = new BufferGeometry().setFromPoints(points)
    const material = new LineBasicMaterial({
        color: color,
        transparent: true,
        opacity: 0.5,
    })
    const line = new Line(geometry, material)
    scene.add(line)
    setTimeout(() => {
        scene.remove(line)
    }, 5000)
}

export const getDirectionVector = (direction: string): Vector3 => {
    const vec = new Vector3(0, 0, 0)
    const parts = direction.split("-")

    parts.forEach(part => {
        switch (part) {
            case "front": vec.z += 1; break
            case "back": vec.z -= 1; break
            case "right": vec.x += 1; break
            case "left": vec.x -= 1; break
            case "top": vec.y += 1; break
            case "bottom": vec.y -= 1; break
        }
    })

    return vec.normalize()
}

export const getDirectionalRelationship = (
    direction1: string,
    direction2: string,
    tolerance = 0.9
): "opposite" | "perpendicular" | "parallel" | "angled" => {
    const vec1 = getDirectionVector(direction1)
    const vec2 = getDirectionVector(direction2)

    // Calculate dot product to determine angle relationship
    const dotProduct = vec1.dot(vec2)

    if (Math.abs(dotProduct) >= tolerance) {
        // Vectors are parallel or opposite
        return dotProduct < 0 ? "opposite" : "parallel"
    } else if (Math.abs(dotProduct) <= (1 - tolerance)) {
        // Vectors are close to perpendicular
        return "perpendicular"
    } else {
        return "angled"
    }
}

export const normalizeDirectionalName = (name: string): string => {
    if (!name) { console.log("normalizeDirectionalName missing name", name) }
    if (!name.includes("-")) { return name }

    const parts = name.split("-")
    const primaryDirections = ["front", "back",]
    const secondaryDirections = ["left", "right", "top", "bottom",]

    // Sort parts based on priority
    if (primaryDirections.includes(parts[1]) && secondaryDirections.includes(parts[0])) {
        // Swap the order
        return `${parts[1]}-${parts[0]}`
    }

    return name
}

export const innerToOuter = (markerName: string) => {
    return markerName.replace(ConnectorMarkerType.inner, ConnectorMarkerType.outer)
}

export const outerToInner = (markerName: string) => {
    return markerName.replace(ConnectorMarkerType.outer, ConnectorMarkerType.inner)
}

export const innerToMesh = (markerName: string) => {
    return markerName.replace(ConnectorMarkerType.inner, ConnectorMarkerType.mesh)
}

export const meshToInner = (markerName: string) => {
    return markerName.replace(ConnectorMarkerType.mesh, ConnectorMarkerType.inner)
}

export const getOppositeTubeMarker = (markerName: TubeMarkerEnum) => {
    return markerName === TubeMarkerEnum.BOTTOM ? TubeMarkerEnum.TOP : TubeMarkerEnum.BOTTOM
}

export const addLines = (
    scene: Scene,
    points: Vector3[],
    internalsRef: MutableRefObject<ConnectorInternalsType> | MutableRefObject<TubeInternalsType>,
) => {
    const { guidelines, } = internalsRef.current
    const geometry = new BufferGeometry().setFromPoints(points)
    const lineMesh = new Line(
        geometry,
        new LineBasicMaterial({ color: "red", linewidth: 1, })
    )
    guidelines.push(lineMesh)
    scene.add(lineMesh)
    return guidelines
}

export const getMarkerNumber = (name: string) => {
    if (isInner(name)) {
        return name.split("inner")[1]
    } else if (isInnerOrOuter(name)) {
        return name.split("outer")[1]
    } else if (isPlus(name)) {
        return name.split("plus")[1]
    } else {
        return name.split("mesh")[1]
    }
}

export const getPlaceholderIdWithoutPosition = (placeholderId: string) => {
    return placeholderId.split("_")[0]
}

export const areDirectionsFacingEachOther = (dir1: Vector3, dir2: Vector3, tolerance = 0.9): boolean => {
    // Normalize the directions
    const normalizedDir1 = dir1.clone().normalize()
    const normalizedDir2 = dir2.clone().normalize()

    // Calculate the dot product
    const dotProduct = normalizedDir1.dot(normalizedDir2)

    // Check if the dot product is close to -1 (vectors pointing in oppos directions)
    return dotProduct <= -tolerance
}

export const getSideAndPosition = (markerName: string) => {
    if (markerName.includes("NaN")) {
        return { markerSide: undefined, markerPosition: undefined, }
    }
    if (isInnerOrOuterOrPlus(markerName) || (markerName.includes("mesh") && markerName.includes("_"))) {
        const markerNumber = getMarkerNumber(markerName)
        const markerSide = markerNumber.split("_")[0]
        const markerPosition = Number(markerNumber.split("_")[1])
        return { markerSide, markerPosition, }
    }
    return { markerSide: undefined, markerPosition: undefined, }
}

export const isInner = (markerName: string) => {
    if (markerName.includes(ConnectorMarkerType.inner)) {
        return true
    } else if (markerName.includes(ConnectorMarkerType.outer)) {
        return false
    }
}

export const isInnerOrOuter = (markerName: string) => {
    return markerName.includes(ConnectorMarkerType.inner)
        || markerName.includes(ConnectorMarkerType.outer)
}

export const isInnerOrOuterOrPlus = (markerName: string) => {
    return markerName.includes(ConnectorMarkerType.inner)
        || markerName.includes(ConnectorMarkerType.outer)
        || markerName.includes(ConnectorMarkerType.plus)
}

export const isPlus = (markerName: string) => {
    if (markerName.includes(ConnectorMarkerType.plus)) {
        return true
    } else {
        return false
    }
}

export const plusName = (markerName: string) => {
    if (markerName.includes(ConnectorMarkerType.plus)) {
        return true
    } else {
        return false
    }
}

type Position = "left" | "right" | "center" | "top" | "bottom" | "middle" | "front" | "back"
type Axis = "x" | "y" | "z"

interface PositionMapping {
    [key: string]: {
        axis: Axis,
        value: number, // -1, 0, or 1
    };
}

const positionMapping: PositionMapping = {
    left: { axis: "x", value: -1, },
    right: { axis: "x", value: 1, },
    center: { axis: "x", value: 0, },
    top: { axis: "y", value: 1, },
    bottom: { axis: "y", value: -1, },
    middle: { axis: "y", value: 0, },
    front: { axis: "z", value: 1, },
    back: { axis: "z", value: -1, },
}

export const convertFractionToDecimal = (str: string): number => {
    // Remove quotes and spaces
    const cleanStr = str.replace(/["\s]/g, "")

    // Handle mixed numbers with fractions (e.g., "1-1/2" or "1 1/2")
    const mixedMatch = cleanStr.match(/(\d+)[-\s]?(\d+)\/(\d+)/)
    if (mixedMatch) {
        const [_, whole, numerator, denominator,] = mixedMatch
        return parseInt(whole, 10) + (parseInt(numerator, 10) / parseInt(denominator, 10))
    }

    // Handle simple fractions (e.g., "1/2")
    const fractionMatch = cleanStr.match(/(\d+)\/(\d+)/)
    if (fractionMatch) {
        const [_, numerator, denominator,] = fractionMatch
        return parseInt(numerator, 10) / parseInt(denominator, 10)
    }

    // Handle decimal numbers (e.g., "1.5")
    const decimalMatch = cleanStr.match(/(\d*\.?\d+)/)
    if (decimalMatch) {
        return parseFloat(decimalMatch[0])
    }

    return 0 // Default case
}

function parsePositions(friendlyName: string): Position[] {
    return friendlyName.split("-") as Position[]
}

export function calculateRotationFromFriendlyNames(
    originalFriendlyName: string,
    rotatedFriendlyName: string,
): Quaternion {
    const originalPositions = parsePositions(originalFriendlyName)
    const rotatedPositions = parsePositions(rotatedFriendlyName)

    // Create rotation euler
    const rotationEuler = new Euler(0, 0, 0)

    // Calculate required rotations for each axis
    originalPositions.forEach(origPos => {
        const origMapping = positionMapping[origPos]
        if (!origMapping) { return }

        // Find the corresponding position in rotated name for the same axis
        const rotatedPos = rotatedPositions.find(pos =>
            positionMapping[pos]?.axis === origMapping.axis
        )

        if (!rotatedPos) { return }

        const rotatedMapping = positionMapping[rotatedPos]

        // Calculate rotation needed
        if (origMapping.value !== rotatedMapping.value) {
            const rotationAmount = calculateRotationAmount(
                origMapping.value,
                rotatedMapping.value
            )

            // Apply rotation to the appropriate axis
            switch (origMapping.axis) {
                case "x":
                    rotationEuler.z = MathUtils.degToRad(rotationAmount)
                    break
                case "y":
                    rotationEuler.x = MathUtils.degToRad(rotationAmount)
                    break
                case "z":
                    rotationEuler.y = MathUtils.degToRad(rotationAmount)
                    break
            }
        }
    })

    // Convert Euler to Quaternion
    return new Quaternion().setFromEuler(rotationEuler)
}

function calculateRotationAmount(originalValue: number, targetValue: number): number {
    // Calculate rotation amount in degrees
    if (originalValue === targetValue) { return 0 }

    // For transitions between -1 and 1 (opposite sides), rotate 180 degrees
    if (Math.abs(originalValue - targetValue) === 2) { return 180 }

    // For transitions between -1/1 and 0 (edge to center), rotate 90 degrees
    if (originalValue === 0) { return targetValue * -90 }
    if (targetValue === 0) { return originalValue * 90 }

    return 0
}



export const MAX_POSSIBLE_LENGTH_REDUCTION = 0.1