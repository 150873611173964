/* eslint-disable max-lines-per-function */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable max-len */
import { Collapse } from "antd"
import React, { useEffect, useState } from "react"
import { createRoot } from "react-dom/client"
import isMobile from "ismobilejs"
import { ReactComponent as QuestionIcon } from "../../../../assets/icons/question_mark_light.svg"
import ReactDOM from "react-dom"
import { Tooltip } from "antd"
import { Html } from "@react-three/drei"
import useGetDebugVariables from "../utils/useGetDebugVariables"


// Types for the API response
interface TutorialItem {
    fieldData: {
        description: string,
        name: string,
        slug: string,
        "short-description": string,
        youtube: {
            url: string,
        },
    };
    isArchived?: boolean;
    isDraft?: boolean;
    lastPublished: string;
    lastUpdated: string;
    id: string;
    createdOn: string;
}

interface TutorialResponse {
    collection: {
        items: TutorialItem[],
    };
}


const MiniTutorials = () => {
    const [tutorials, setTutorials,] = useState<TutorialItem[]>([])
    const [isVisible, setIsVisible,] = useState(false)
    const [container, setContainer,] = useState<HTMLDivElement | null>(null)
    const [questionMarkerVisible, setQuestionMarkerVisible,] = useState(false)
    const [activeKey, setActiveKey,] = useState<string | null>(null)
    const { getVariables, } = useGetDebugVariables()
    const isAdmin = getVariables().isAdmin

    const getFontWeight = (key: string | null, index: number) => {
        if (Array.isArray(key)) {
            return key.includes(index.toString()) ? "600" : "400"
        }
        return key === index.toString() ? "600" : "400"
    }

    useEffect(() => {
        const newContainer = document.createElement("div")
        newContainer.id = "tutorial-button-container"
        Object.assign(newContainer.style, {
            position: "fixed",
            zIndex: 1,
            bottom: isMobile(window.navigator).phone ? "60px" : "20px",
            right: isMobile(window.navigator).phone ? "10px" : "20px",
        })
        document.body.appendChild(newContainer)
        setContainer(newContainer)

        return () => {
            document.body.removeChild(newContainer)
        }
    }, [])

    const toggleTutorial = () => {
        setIsVisible(!isVisible)
    }

    useEffect(() => {
        const fetchTutorials = async () => {
            try {
                const response = await fetch("https://getwebflowcollection-pe2dtkz4ra-uc.a.run.app")
                const data: TutorialResponse = await response.json()

                const replaceWatchForEmbed = (url: string) => {
                    return url.replace("watch?v=", "embed/")
                }

                // Only filter if not admin
                const filteredTutorials = data.collection.items
                    .filter(item => (isAdmin ? true : (!item.isArchived && !item.isDraft)))
                    .map(item => ({
                        ...item,
                        fieldData: {
                            ...item.fieldData,
                            youtube: {
                                url: replaceWatchForEmbed(item.fieldData.youtube.url),
                            },
                        },
                    }))

                setTutorials(filteredTutorials)
            } catch (error) {
                console.error("Error fetching tutorials:", error)
            }
        }

        fetchTutorials()
    }, [])
    useEffect(() => {
        let container = document.getElementById("tutorial-container")
        if (!container) {
            container = document.createElement("div")
            container.id = "tutorial-container"
            document.body.appendChild(container)
        }

        // Create root only once and store it
        let root = (container as any)._reactRoot
        if (!root && tutorials.length > 0) {
            root = createRoot(container)
                ; (container as any)._reactRoot = root
        }

        // Only render if we have tutorials and a root
        if (root && tutorials.length > 0) {
            root.render(tutorialContent)
        }

        if (isMobile(window.navigator).phone) {
            setQuestionMarkerVisible(false)
        } else if (tutorials.length > 0) {
            setQuestionMarkerVisible(true)
        }

        // Cleanup
        return () => {
            if (root) {
                root.unmount()
            }
            if (container && container.parentNode) {
                container.parentNode.removeChild(container)
            }
        }
    }, [tutorials, isVisible, activeKey,])

    const wrapInDivAndDangerouslySetInnerHTML = (text: string) => (
        <div style={{ fontSize: "13px", fontWeight: "300", color: "rgb(28 28 28)", lineHeight: "1.5", marginTop: "10px", }} dangerouslySetInnerHTML={{ __html: text, }} />
    )

    const wrapInIframe = (url: string) => (
        <iframe
            src={url}
            width="100%"
            style={{ borderWidth: 1, borderColor: "#ccc", borderStyle: "solid", borderRadius: "5px", }}
            height={isMobile(window.navigator).phone ? "190px" : "190px"}
        />
    )

    const tutorialContent = (
        <div style={{
            display: isVisible ? "block" : "none",
            position: "fixed",
            bottom: isMobile(window.navigator).phone ? "60px" : "20px",
            right: isMobile(window.navigator).phone ? "10px" : "20px",
            width: isMobile(window.navigator).phone ? "calc(100% - 20px)" : "400px",
            maxHeight: "80vh",
            margin: "0 auto",
            zIndex: 2,
            pointerEvents: "auto", // Ensure pointer events work
        }}>
            <button
                onClick={() => setIsVisible(false)}
                style={{
                    border: "0px solid black",
                    background: "white",
                    fontSize: "12px",
                    fontWeight: "bold",
                    marginBottom: "5px",
                    color: "#a0a0a0",
                    float: "right",
                    zIndex: 1,
                    cursor: "pointer", // Add pointer cursor
                    transition: "all 0.2s ease", // Smooth transition for hover effects
                }}
                onMouseEnter={(e) => {
                    e.currentTarget.style.color = "#666666" // Darker color on hover
                }}
                onMouseLeave={(e) => {
                    e.currentTarget.style.color = "#a0a0a0" // Return to original color
                }}
            >
                ✕
            </button>
            <Collapse
                activeKey={activeKey || undefined}
                onChange={(key) => setActiveKey(key as string)}
                style={{
                    border: "0px solid black",
                    width: "100%",
                    maxHeight: "calc(80vh - 40px)",
                    overflowY: "auto", // Changed to auto
                    background: "white",
                    zIndex: 2,
                    boxShadow: "rgba(0, 0, 1, 0.10) 0px 0px 4px",
                    pointerEvents: "auto", // Ensure pointer events work
                }}
            >
                {tutorials.map((tutorial, index) => (
                    <Collapse.Panel
                        style={{
                            borderBottom: "1px solid #e7e7e7",
                            userSelect: "text",
                            zIndex: 1000,
                        }}
                        header={
                            <div style={{
                                fontWeight: getFontWeight(activeKey, index),
                            }}>
                                {tutorial.fieldData.name}
                            </div>
                        }
                        key={index}
                    >
                        {wrapInIframe(tutorial.fieldData.youtube.url)}
                        {wrapInDivAndDangerouslySetInnerHTML(tutorial.fieldData["short-description"])}
                    </Collapse.Panel>
                ))}
                <Collapse.Panel
                    style={{
                        borderBottom: "1px solid #e7e7e7",
                        userSelect: "text",
                        zIndex: 2,
                    }}
                    header={
                        <div style={{
                            fontWeight: "400",
                        }}>
                            Request a Tutorial
                        </div>
                    }
                    key="request-tutorial"
                >
                    {wrapInDivAndDangerouslySetInnerHTML('We are adding new tutorials weekly. If you have a tutorial request, please <a href="mailto:hi@craftyamigo.com" style="color: #0467FD text-decoration: underline">click here</a>')}
                </Collapse.Panel>
            </Collapse>
        </div >
    )

    return (
        <Html wrapperClass={"neutralHTML"}>
            {container && questionMarkerVisible && ReactDOM.createPortal(
                <div style={{ position: "fixed", }}>
                    <Tooltip title="Tutorials" placement="left">
                        <button className="tutorial-button" onClick={toggleTutorial} title="Tutorials" style={{
                            position: "fixed",
                            bottom: isMobile(window.navigator).phone ? "60px" : "20px",
                            right: isMobile(window.navigator).phone ? "10px" : "20px",
                            background: "white",
                            border: "none",
                            borderRadius: "5px",
                            width: "30px",
                            height: "30px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            boxShadow: "rgba(0, 0, 1, 0.10) 0px 0px 4px",
                            transition: "all 0.2s ease",
                        }}
                            onMouseEnter={(e) => {
                                e.currentTarget.style.backgroundColor = "#f0f0f0"
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.style.backgroundColor = "white"
                            }}
                        >
                            <QuestionIcon fill="#0467FD" />
                        </button>
                    </Tooltip>
                </div>,
                container
            )}
        </Html>
    )
}

export default MiniTutorials
