import styled, { css } from "styled-components"

const colors = {
    background: {
        selected: "#FCFCFC",
        unselected: "#fff",
    },
    text: {
        selected: "#484848",
        unselected: "#000",
    },
}

const TabItem = styled.div<{ $active: boolean, }>`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => {
        return props.$active ? colors.background.selected : colors.background.unselected
    }};
    color: ${props => {
        return props.$active ? colors.text.selected : colors.text.unselected
    }};
    font-weight: ${props => {
        return props.$active ? 700 : 500
    }};
    font-size: 14px;
    transition: background-color 0.3s;
    padding: 10px 0;
    &:not(:last-child) {
        border-right: 2px solid #EEE;
    }
`

const TabsContainer = styled.div`
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
    margin: 3px auto;
    border-radius: 8px;
    display: flex;
    cursor: pointer;
    width: 90%;
    text-align: center;
    background-color: ${colors.background.unselected};
    overflow: hidden;
`


const TabsOverlay = styled.div`
    background: #FFFFFF 90;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
`

export const TabsStyled = {
    TabsContainer,
    TabItem,
    TabsOverlay,
}
