import { MathUtils, Mesh, Quaternion, Vector3 } from "three"
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { useNewPart } from "../../../../../../../state/scene/setters"
import { innerToOuter, outerToInner } from "../../../../../../../utils/MarkerUtil"
import { MeshUtils } from "../../../../../../../utils/MeshUtils"
import { FIRST_PART_INITIAL_MARKER } from "../../../../../../../utils/PartUtils"
import { CommonProps } from "../AddPart"
import { emptyPositionForNewPartAtom } from "../../../../../../../state/scene/atoms"
import { useRecoilValue } from "recoil"

// eslint-disable-next-line max-len
export const getFirstPartPosAndRot = async (fileURL: string, isTube?: boolean, isSegmentedTube?: boolean) => {
    if (isTube || isSegmentedTube) {
        return {
            innerRotation: new Quaternion(),
            innerPosition: new Vector3(),
            outerRotation: new Quaternion(),
            outerPosition: new Vector3(),
        }
    } else {
        const dracoLoader = new DRACOLoader()
        dracoLoader.setDecoderPath("https://www.gstatic.com/draco/versioned/decoders/1.5.5/")
        const loader = new GLTFLoader();
        (loader as GLTFLoader).setDRACOLoader(dracoLoader)
        const importedGLTF = await loader.loadAsync(fileURL)
        dracoLoader.dispose()
        const innerMarker = importedGLTF.scene.children.filter(
            c => c.name === outerToInner(FIRST_PART_INITIAL_MARKER))[0] as Mesh
        const outerMarker = importedGLTF.scene.children.filter(
            c => c.name === innerToOuter(FIRST_PART_INITIAL_MARKER))[0] as Mesh

        innerMarker.rotateY(MathUtils.degToRad(180))
        const innerRotation = MeshUtils.copyWorldQuaternion(innerMarker)
        const innerPosition = MeshUtils.copyWorldPosition(innerMarker)
        innerMarker.rotateY(MathUtils.degToRad(-180))

        outerMarker.rotateY(MathUtils.degToRad(180))
        const outerRotation = MeshUtils.copyWorldQuaternion(outerMarker)
        const outerPosition = MeshUtils.copyWorldPosition(outerMarker)
        outerMarker.rotateY(MathUtils.degToRad(-180))

        return {
            innerRotation,
            innerPosition,
            outerRotation,
            outerPosition,
        }
    }
}

export const useAddInitialPart = () => {
    const createPart = useNewPart()
    const emptyPositionForNewPart = useRecoilValue(emptyPositionForNewPartAtom)

    return (props: CommonProps) => {
        const asyncCreatePart = async () => {
            props.clearModal?.()
            const firstPartPosAndRot = await getFirstPartPosAndRot(
                props.part.assethPath,
                props.part.tube,
                props.part.segmentedTube
            )

            return createPart({
                part: props.part,
                posAndRot: {
                    inner: {
                        pos: emptyPositionForNewPart || firstPartPosAndRot.innerPosition,
                        rot: firstPartPosAndRot.innerRotation,
                    },
                    outer: {
                        pos: emptyPositionForNewPart || firstPartPosAndRot.outerPosition,
                        rot: firstPartPosAndRot.outerRotation,
                    },
                },
            })
        }

        return asyncCreatePart()
    }
}