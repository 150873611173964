import { useRecoilValue } from "recoil"
import useModal from "../../../../../../../../common/providers/modalProvider/useModal"
import { connectionTypesSelector } from "../../../../../../../state/initialDataSelectors"
import { useNewPart } from "../../../../../../../state/scene/setters"
import { useGetMarkerData } from "../../../../../../../state/scene/util"
import { AddPartState } from "../../../../../../../state/types"
import { EnvHelper } from "../../../../../../../../common/utils/EnvHelper"
import { SoundHelper } from "../../../../utils/SoundHelper"
import { TubeMarkerEnum } from "../../../../../../../utils/Types"
import { roundLength } from "../../../../../../../utils/utils"
import { metersToInch } from "../../../../utils/utilsThree"
import {
    IsExpandAplicableInfo,
    showAlertAndExecuteExpandReduce
} from "../../../../../../main/DesignScreen/scene/part/parts/tube/utils/ReduceExpandToFit"
import { CommonProps } from "../AddPart"
import { breadcrumb } from "../../../../../../../../common/utils/sentrySetup"

export const useAddTube = () => {
    const createPart = useNewPart()
    const { showModal, } = useModal()
    const connectionTypes = useRecoilValue(connectionTypesSelector)
    const getMarkerData = useGetMarkerData()

    const maxDistanceExpandReduce = EnvHelper.maxDistanceExpandReduceInInches

    const isSelectedOptionApplicableWithExpand = (props: {
        expandReduceToFitInfo: IsExpandAplicableInfo,
        connectorTypeId: string,
    }) => {
        if (props.expandReduceToFitInfo.isApplicable) {
            const oppositeConnectionType = connectionTypes[props.connectorTypeId]
            return oppositeConnectionType.compatibleWithMulti.includes(
                props.expandReduceToFitInfo.oppositeConnection.typeId
            )
        } else {
            return false
        }
    }

    const getExpandTubeLength = (props: {
        originalLength: number,
        expandReduceToFitInfo: IsExpandAplicableInfo,
        sourcePartInfo?: Exclude<AddPartState["step1"]["source"], "swap">,
    }) => {
        if (props.expandReduceToFitInfo.isApplicable
            && props.sourcePartInfo
        ) {
            const markerData = getMarkerData(
                props.expandReduceToFitInfo.oppositeConnection.partId,
                props.expandReduceToFitInfo.oppositeConnection.markerName)!

            const distanceToExpandReduce = roundLength(
                props.expandReduceToFitInfo.distance * metersToInch
                + (markerData?.iELenght ?? 0)
            )

            if (Math.abs(props.originalLength - distanceToExpandReduce)
                <= maxDistanceExpandReduce) {
                return distanceToExpandReduce
            } else {
                return undefined
            }
        } else {
            return undefined
        }
    }

    return (props: CommonProps) => {
        console.log("adding tube: ", props)
        const partDataId = props.source!.partId
        const partDataMarkerName = props.source!.markerName
        const distanceToExpandReduce = getExpandTubeLength({
            originalLength: props.part.defaultLength,
            expandReduceToFitInfo: props.source!.expandReduceToFitInfo,
            sourcePartInfo: props.source,
        })

        const commonArgs = {
            part: props.part,
            posAndRot: props.source!.posAndRot,
            placeholderId: TubeMarkerEnum.BOTTOM,
            connectedPart: {
                partId: partDataId,
                markerName: partDataMarkerName,
                connectionLength: props.source!.connectionLength,
            },
        }

        if (distanceToExpandReduce
            && isSelectedOptionApplicableWithExpand({
                expandReduceToFitInfo: props.source!.expandReduceToFitInfo,
                connectorTypeId: props.part.connections[0].connectorId,
            })) {
            showAlertAndExecuteExpandReduce({
                showModal,
                distanceToExpandReduce,
                reduceExpandCase: distanceToExpandReduce + props.source!.connectionLength
                    - props.part.defaultLength >= 0
                    ? "Expand" : "Reduce",
                closeModal: props.clearModal ?? (() => { }),
                createOrEditTube: (distanceToExpandReduce2) => {
                    const expandReduceToFitInfo = props.source?.expandReduceToFitInfo!
                    if (expandReduceToFitInfo.isApplicable) {
                        createPart({
                            ...commonArgs,
                            expandReduce: {
                                partId: props.part.id,
                                length: roundLength(
                                    distanceToExpandReduce2! + props.source!.connectionLength),
                            },
                            oppositePart: {
                                oppositeMarkerName: TubeMarkerEnum.TOP,
                                oppositeConnectedPart: {
                                    partId: expandReduceToFitInfo.oppositeConnection.partId,
                                    markerName: expandReduceToFitInfo.oppositeConnection.markerName,
                                },
                            },
                        })
                        breadcrumb({
                            message: "after create part to expand or reduce",
                            level: "info",
                        })
                        SoundHelper.playUnsnap()
                    }
                    if (props.clearModal) {
                        props.clearModal()
                    }
                },
                addPart: () => {
                    createPart(commonArgs)
                },
            })
        } else {
            createPart(commonArgs)
            if (props.clearModal) {
                props.clearModal()
            }
        }
    }
}