import { auth, db } from "./firebaseSetup"
import {
  getDocs,
  collection,
  Query,
  addDoc,
  updateDoc,
  doc,
  getDoc,
  setDoc
} from "firebase/firestore"
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
  User,
  NextOrObserver,
  signOut,
  FacebookAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
} from "firebase/auth"
import { WithFieldValue, DocumentData } from "firebase/firestore"

export const getCollection = (path: string) => {
  return collection(db, path)
}
export const partsCollection = () => { return getCollection("Parts") }
export const connectionTypesCollection = () => { return getCollection("connectionTypes") }

export const signInEmailPassword = async (email: string, password: string) => {
  return await signInWithEmailAndPassword(auth, email, password)
}

export const GetDataFromFirebase = async <T>(path: string, query?: Query) => {
  const { docs, } = await getDocs(query ? query : getCollection(path))
  return docs.map((doc) => (({ id: doc.ref.id, ...doc.data(), }) as unknown as T))
}

export const saveDataToFirebase
  = async <T extends WithFieldValue<DocumentData>>(path: string, data: T) => {
    const savedData = await addDoc(getCollection(path), data)
    return savedData
  }

export const updateInFirebase = async <T extends { [key: string]: any, }>(
  path: string,
  id: string,
  data: T
) => {

  const docRef = doc(db, `${path}/${id}`)
  const docSnap = await getDoc(docRef)

  if (docSnap.exists()) {
    // Document exists, update it
    const updatedData = await updateDoc(docRef, data as {
      [x: string]: any,
    })
    return updatedData
  }

  // Document doesn't exist, create it
  await setDoc(docRef, data)
  return null
}

export const setDocInFirebase = async <T extends WithFieldValue<DocumentData>>(
  path: string,
  id: string,
  data: T
) => {
  await setDoc(doc(db, `${path}/${id}`), data)
}

export const getDatumFromFirebase = async (path: string, id: string) => {
  return await getDoc(doc(db, `${path}/${id}`))
}

export const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider()
  provider.setCustomParameters({ prompt: "select_account", })
  return await signInWithPopup(auth, provider)
}

export const signInWithFacebook = async () => {
  const provider = new FacebookAuthProvider()
  // provider.setCustomParameters({ prompt: "select_account", })
  return await signInWithPopup(auth, provider)
}

export const onAuthChange = (callback: NextOrObserver<User>) => {
  onAuthStateChanged(auth, callback)
}

export const logOut = async () => {
  await signOut(auth)
}

export const createUser = async (email: string, password: string) => {
  return await createUserWithEmailAndPassword(auth, email, password)
}

export const getUser = () => {
  const auth = getAuth()
  return auth.currentUser
}

export const forgotPassword = async (email: string) => {
  const auth = getAuth()
  return await sendPasswordResetEmail(auth, email)
}

export const updateUserSubscriptionPreference = async (userId: string, subscribed: boolean) => {
  const userRef = doc(db, "Users", userId)

  //console.log("updateUserSubscriptionPreference", userId, subscribed)

  try {
    // First, get the existing user data
    const userSnap = await getDoc(userRef)
    const userData = userSnap.data() || {}

    //console.log(userSnap.data(), userId, subscribed)

    // Update the subscription preference
    await setDoc(userRef, {
      ...userData,
      subscribedToMailingList: subscribed,
    }, { merge: true, })
  } catch (error) {
    console.error("Error updating user subscription preference:", error)
    throw error
  }
}