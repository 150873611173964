/* eslint-disable max-len */
import React, { useRef } from "react"
import { Text, Line, Html } from "@react-three/drei"
import { Vector3 } from "three"
import { ArrowHelper } from "three"
import tinycolor2 from "tinycolor2"

const DEBUG_TEXT_PADDING = 0.1
const DEBUG_ARROW_OFFSET = 0.02

interface DebugTextProps {
    text: string[];
    position: Vector3 | null;
    visible?: boolean;
    isSelected?: boolean;
}

const DebugText: React.FC<DebugTextProps> = ({ text, position, visible = true, isSelected = false, }) => {
    const colorRef = useRef(
        tinycolor2.random()
            .darken(30)
            .saturate(20)
            .toHexString()
    )

    if (!visible || !position) { return null }

    return (
        <>
            {/* HTML overlay when selected */}
            {isSelected && position && text.length > 0 && (
                <Html
                    position={position}
                    center
                    style={{
                        position: "absolute",
                        pointerEvents: "none",
                    }}
                >
                    <div
                        style={{
                            background: "rgba(0, 0, 0, 0.8)",
                            color: "white",
                            padding: "10px",
                            borderRadius: "5px",
                            fontFamily: "monospace",
                            maxWidth: "500px",
                            width: "500px",
                            transform: "translate(-50%, -50%)",
                            boxShadow: "0 0 10px rgba(0,0,0,0.5)",
                        }}
                    >
                        {text.map((line, i) => (
                            <div key={i}>
                                {i + 1}. {line}
                            </div>
                        ))}
                    </div>
                </Html>
            )}
        </>
    )
}

export default DebugText