/* eslint-disable no-loop-func */
import React from "react"
import { Camera, Object3D, Vector3 } from "three"
import { Html } from "@react-three/drei"
import styled from "styled-components"
import { PartConnectionType } from "../../../../../../../../state/scene/types"
import RelativeMove from "../../tube/ui/RelativeMove"
import AlignmentOptionsUI, { AlignmentPosition } from "./AlignmentOptionsUI"
import {
    SliderContainer,
    ButtonContainer,
    SliderText
} from "./ActionPanelStyles"
import { SegmentedTubeMarkers } from "../types/types"
import { ContentContainer, FlexContainer, Label, Section } from "./SegmentedSliderStyles"
import { useLevaControls } from "../../../../../../../../providers/debugProvider/useLevaControls"

type SegmentedSliderProps = {
    freePositions: number[],
    positions: number[],
    currentValue: number,
    onNewPosition: (newPosition: number) => void,
    onMouseDown?: (newPosition: number) => void,
    onMouseUp: (value: number) => void,
    handleFinishEditing: () => void,
    startLength: number,
    segmentLength: number,
    disabledText?: string,
    unit?: "cm" | "in",
    useDragSlide: boolean,
    connectedToSegmentedParts: PartConnectionType[] | undefined,
    connectionIndex: number,
    setConnectionIndex: (index: number) => void,
    alignmentPoints?: {
        [key in AlignmentPosition]: Vector3
    },
    onAlignmentSelect?: (position: AlignmentPosition, offsetX: number, offsetY: number) => void,
    onAlignmentHover?: (position: AlignmentPosition | null) => void,
    hoveredAlignmentPoint?: AlignmentPosition | null,
    rotationPoint?: SegmentedTubeMarkers | null,
    camera?: Camera,
    debugAlignment: boolean,
    canUseAlignment: boolean,
    positionChanged: boolean,
    currentPartId: string,
    getConnectionAtIndex: (index: number) => PartConnectionType | undefined,
}

const SegmentedSlider = (props: SegmentedSliderProps) => {
    const renderSlider = () => {
        if (props.disabledText) {
            return (
                <SliderText>
                    {props.disabledText}
                </SliderText>
            )
        }

        if (props.useDragSlide) {
            if (props.connectedToSegmentedParts && props.connectedToSegmentedParts.length > 1) {
                return (
                    <FlexContainer>
                        <AlignmentOptionsUI
                            onAlignmentSelect={props.onAlignmentSelect!}
                            onAlignmentHover={props.onAlignmentHover!}
                            hoveredPoint={props.hoveredAlignmentPoint}
                            rotationPoint={props.rotationPoint}
                            camera={props.camera}
                            unit={props.unit || "in"}
                            debugAlignment={props.debugAlignment}
                            canUseAlignment={props.canUseAlignment}
                            positionChanged={props.positionChanged}
                        />
                        <FlexContainer>
                            <Section>
                                <ContentContainer>
                                    <RelativeMove
                                    connectedToSegmentedParts={props.connectedToSegmentedParts}
                                connectionIndex={props.connectionIndex}
                                        setConnectionIndex={props.setConnectionIndex}
                                        currentPartId={props.currentPartId}
                                        getConnectionAtIndex={props.getConnectionAtIndex}
                                    />
                                </ContentContainer>
                                <Label>Move Relative To</Label>
                            </Section>
                        </FlexContainer>
                    </FlexContainer>
                )
            }
        }

        return (
            <FlexContainer>
                <AlignmentOptionsUI
                    onAlignmentSelect={props.onAlignmentSelect!}
                    onAlignmentHover={props.onAlignmentHover!}
                    hoveredPoint={props.hoveredAlignmentPoint}
                    rotationPoint={props.rotationPoint}
                    camera={props.camera}
                    unit={props.unit || "in"}
                    debugAlignment={props.debugAlignment}
                    canUseAlignment={props.canUseAlignment}
                    positionChanged={props.positionChanged}
                />
            </FlexContainer>
        )
    }

    return (
        <SliderContainer>
            {renderSlider()}
        </SliderContainer>
    )
}

export default SegmentedSlider