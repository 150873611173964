import React, { createContext, useContext, useEffect } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { swapJobsAtom, type SwapJob } from "../../state/scene/atoms"
import { PartTypeAPI } from "../../../common/api/Types"
import { useAIProvider } from "../../hooks/useAIProvider"

interface MultiSwapContextType {
    handlePartSwap: (sourcePartIds: string[], newPart: PartTypeAPI) => void;
    swapJobs: SwapJob[];
}

const MultiSwapContext = createContext<MultiSwapContextType | null>(null)

export const MultiSwapProvider: React.FC<{ children: React.ReactNode, }> = ({ children, }) => {
    const [swapJobs, setSwapJobs,] = useRecoilState(swapJobsAtom)
    const { createSimplifiedJSON, processInstructions, } = useAIProvider()

    useEffect(() => {
        //console.log("swapJobs", swapJobs)
        if (swapJobs.length > 0) {
            handlePartSwap(swapJobs[0].sourcePartIds, swapJobs[0].targetPart)
        }
    }, [swapJobs,])

    const handlePartSwap = async (sourcePartIds: string[], newPart: PartTypeAPI) => {
        console.log("handlePartSwap", sourcePartIds, newPart)
        const designState = createSimplifiedJSON()
        if (!designState) {
            throw new Error("Could not get design state")
        }
        const simplifiedDesign = JSON.parse(designState.simplifiedDesign)
        console.log("simplifiedDesign", JSON.parse(JSON.stringify(simplifiedDesign)))

        Object.keys(simplifiedDesign.parts).forEach((key: string) => {
            const part = simplifiedDesign.parts[key]
            if (sourcePartIds.includes(part.id)) {
                part.name = newPart.name
            }
        })

        console.log("simplifiedDesign after rename", JSON.parse(JSON.stringify(simplifiedDesign)))

        await processInstructions(
            undefined,
            simplifiedDesign
        )
    }

    const value = {
        handlePartSwap,
        swapJobs,
    }

    return (
        <MultiSwapContext.Provider value={value}>
            {children}
        </MultiSwapContext.Provider>
    )
}

export const useMultiSwap = () => {
    const context = useContext(MultiSwapContext)
    if (!context) {
        throw new Error("useMultiSwap must be used within a MultiSwapProvider")
    }
    return context
}