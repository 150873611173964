import React, { useEffect, useRef, useState } from "react"
import { PerspectiveCamera } from "@react-three/drei"
import { Group, Vector3, AxesHelper, Object3D, Camera, LinearToneMapping, Matrix4 } from "three"
import { AlignmentPosition } from "./AlignmentOptionsUI"
import { useFrame, ThreeEvent } from "@react-three/fiber"
import { PerspectiveCamera as ThreePerspectiveCamera } from "three"
import styled from "styled-components"
import { SegmentedTubeMarkers } from "../types/types"
import { StyledCanvas } from "./SegmentedSliderStyles"
import { useCursor } from "@react-three/drei"

// Update the POSITION_TO_ALIGNMENT mapping to match the correct order
const POSITION_TO_ALIGNMENT: { [key: string]: AlignmentPosition, } = {
    "-2,-2": "bottom-right",
    "0,-2": "bottom",
    "2,-2": "bottom-left",
    "0,0": "center",
    "2,0": "left",
    "-2,2": "top-right",
    "0,2": "top",
    "2,2": "top-left",
    "-2,0": "right",
}

const DEBUG_COLORS = [
    0xff6600, // corner 2 - orange
    0xff0000, // corner 1 - red
    0xffff00, // corner 4 - yellow
    0xffcc00, // corner 3 - yellow-orange
    0x6600ff, // midpoint 4 - purple
    0x00ffcc, // midpoint 2 - turquoise
    0x00ff00, // midpoint 1 - green
    0x0099ff, // midpoint 3 - light blue
    0xff00ff, // center - magenta
]

const CanvasContainer = styled.div`
    pointer-events: auto;
    -webkit-tap-highlight-color: transparent;
    touch-action: none;
`

// Add this new component
function CameraController({
    cameraRef,
    marker,
    groupRef,
    originalCamera,
}: {
    cameraRef: React.RefObject<ThreePerspectiveCamera>,
    marker: Object3D | undefined | null,
    groupRef: React.RefObject<Group>,
    originalCamera: Camera | undefined,
}) {
    useFrame(() => {
        if (cameraRef.current && originalCamera && marker && groupRef.current) {
            // Copy the rotation from the main camera
            cameraRef.current.quaternion.copy(originalCamera.quaternion)

            // Keep a fixed distance
            const direction = new Vector3(0, 0, 1)
            direction.applyQuaternion(cameraRef.current.quaternion)
            direction.multiplyScalar(7)

            cameraRef.current.position.copy(direction)

            // Get marker's world matrix
            const markerWorldMatrix = new Matrix4()
            marker.updateWorldMatrix(true, false)
            markerWorldMatrix.copy(marker.matrixWorld)

            // Extract rotation from marker's world matrix
            const rotationMatrix = new Matrix4()
            rotationMatrix.extractRotation(markerWorldMatrix)

            // Get the basis vectors from the marker's rotation
            const up = new Vector3(0, 1, 0).applyMatrix4(rotationMatrix)
            const forward = new Vector3(0, 0, 1).applyMatrix4(rotationMatrix)
            const right = new Vector3(1, 0, 0).applyMatrix4(rotationMatrix)

            // Ensure vectors are normalized
            up.normalize()
            forward.normalize()
            right.normalize()

            // Create rotation matrix from orthonormal basis
            const finalMatrix = new Matrix4()
            finalMatrix.makeBasis(right, up, forward)

            // Apply the rotation to the group
            groupRef.current.setRotationFromMatrix(finalMatrix)
        }
    })

    return null
}

interface Props {
    onAlignmentSelect: (position: AlignmentPosition) => void;
    onAlignmentHover: (position: AlignmentPosition | null) => void;
    rotationPoint?: SegmentedTubeMarkers | null;
    originalCamera: Camera | undefined;
    activeAlignment: AlignmentPosition | null;
    debugAlignment: boolean;
}

export default function AlignmentPointsHUD({
    onAlignmentSelect,
    onAlignmentHover,
    rotationPoint,
    originalCamera,
    activeAlignment,
    debugAlignment,
}: Props) {
    const marker = rotationPoint?.["0"]?.outer
    const cameraRef = useRef<ThreePerspectiveCamera>(null)
    const groupRef = useRef<Group>(null)

    const handlePointerDown = (e: React.TouchEvent | React.PointerEvent) => {
        if ("pointerType" in e && e.pointerType === "mouse") {
            e.preventDefault()
        }
        e.stopPropagation()
    }

    return (
        <CanvasContainer onPointerDown={handlePointerDown}>
            <StyledCanvas
                gl={{
                    toneMapping: LinearToneMapping,
                    alpha: true,
                    stencil: false,
                    antialias: false,
                    depth: false,
                }}
                dpr={window.devicePixelRatio}
                legacy={true}
                onTouchEnd={handlePointerDown}
                >
                <CameraController
                    cameraRef={cameraRef}
                    marker={marker}
                    groupRef={groupRef}
                    originalCamera={originalCamera}
                />
                <ambientLight intensity={0.5} />
                <PerspectiveCamera
                    ref={cameraRef}
                    makeDefault
                    fov={50}
                    position={[0, 0, 5,]}
                    near={0.1}
                    far={1000}
                    name="gizmoCamera"
                />
                <group ref={groupRef} name="rotationMarker">
                    <RotatingPlaneGroup
                        onAlignmentSelect={onAlignmentSelect}
                        onAlignmentHover={onAlignmentHover}
                        activeAlignment={activeAlignment}
                        debugAlignment={debugAlignment}
                    />
                </group>
            </StyledCanvas>
        </CanvasContainer>
    )
}

function RotatingPlaneGroup({
    onAlignmentSelect,
    onAlignmentHover,
    activeAlignment,
    debugAlignment,
}: {
    onAlignmentSelect: (position: AlignmentPosition) => void,
    onAlignmentHover: (position: AlignmentPosition | null) => void,
    activeAlignment: AlignmentPosition | null,
    debugAlignment: boolean,
}) {
    const points =  [
            [-2, -2, 0,], // Bottom-left
            [2, -2, 0,], // Bottom-right
            [-2, 2, 0,], // Top-left
            [2, 2, 0,], // Top-right
            [0, 0, 0,], // Center
            [0, -2, 0,], // Midpoint bottom
            [0, 2, 0,], // Midpoint top
            [-2, 0, 0,], // Midpoint left
            [2, 0, 0,], // Midpoint right
        ]

    return (
        <group>
            {debugAlignment && <primitive object={new AxesHelper(5)} />}
            <mesh>
                <planeGeometry args={[4.4, 4.4,]} />
                <meshBasicMaterial color="lightgrey" side={2} />
            </mesh>
            {points.map((position, i) => (
                <HoverablePoint
                    key={i}
                    position={new Vector3(...position)}
                    onAlignmentSelect={onAlignmentSelect}
                    onAlignmentHover={onAlignmentHover}
                    activeAlignment={activeAlignment}
                    debugAlignment={debugAlignment}
                />
            ))}
        </group>
    )
}

function HoverablePoint({
    position,
    onAlignmentSelect,
    onAlignmentHover,
    activeAlignment,
    debugAlignment,
}: {
    position: Vector3,
    onAlignmentSelect: (position: AlignmentPosition) => void,
    onAlignmentHover: (position: AlignmentPosition | null) => void,
    activeAlignment: AlignmentPosition | null,
    debugAlignment: boolean,
}) {
    const [hovered, setHovered,] = useState(false)
    const key = `${position.x},${position.y}`
    const alignmentPosition = POSITION_TO_ALIGNMENT[key]
    const isActive = activeAlignment === alignmentPosition

    // Get point index based on position
    const getPointIndex = (x: number, y: number) => {
        if (x === -2 && y === 2) {return 0}       // corner 1 - red (top-right)
        if (x === 2 && y === 2) {return 1}        // corner 2 - orange (top-left)
        if (x === -2 && y === -2) {return 2}      // corner 3 - yellow-orange (bottom-right)
        if (x === 2 && y === -2) {return 3}       // corner 4 - yellow (bottom-left)
        if (x === 0 && y === 2) {return 4}        // midpoint 1 - green (top)
        if (x === 0 && y === -2) {return 5}       // midpoint 2 - turquoise (bottom)
        if (x === -2 && y === 0) {return 6}       // midpoint 3 - light blue (right)
        if (x === 2 && y === 0) {return 7}        // midpoint 4 - purple (left)
        return 8                                   // center - magenta
    }

    const pointIndex = getPointIndex(position.x, position.y)

    useCursor(hovered, "pointer")

    useEffect(() => {
        if (hovered) {
            const key = `${position.x},${position.y}`
            const alignmentPosition = POSITION_TO_ALIGNMENT[key]
            onAlignmentHover(alignmentPosition)
        }
    }, [hovered,])

    const handleClick = (event: ThreeEvent<MouseEvent>) => {
        event.stopPropagation()
        event.nativeEvent.stopPropagation()
        event.nativeEvent.preventDefault()

        const key = `${position.x},${position.y}`
        const alignmentPosition = POSITION_TO_ALIGNMENT[key]
        if (alignmentPosition) {
            onAlignmentSelect(alignmentPosition)
        }
    }

    return (
        <group position={position}>
            <mesh
                onPointerOver={() => setHovered(true)}
                onPointerOut={() => setHovered(false)}
                onClick={handleClick}
                onPointerDown={(e) => {
                    if (e.pointerType === "mouse") {
                        return
                    }
                    handleClick(e)
                }}
                rotation={[0, 0, 0,]}
            >
                <boxGeometry args={[1.5, 1.5, 0.2,]} />
                <meshBasicMaterial transparent opacity={0} />
            </mesh>
            <mesh rotation={[0, 0, 0,]}>
                <boxGeometry args={[0.5, 0.5, 0.2,]} />
                <meshBasicMaterial
                    color={debugAlignment
                        ? `#${DEBUG_COLORS[pointIndex].toString(16).padStart(6, "0")}`
                        : getPointColor(isActive, hovered)
                    }
                />
            </mesh>
        </group>
    )
}

function getPointColor(isActive: boolean, hovered: boolean) {
    if (isActive) {
        return "#0066cc"
    } else if (hovered) {
        return "#666666"
    } else {
        return "#000000"
    }
}