/* eslint-disable max-lines-per-function */
import React, { useRef, useEffect, useState } from "react"
import { useThree, useFrame } from "@react-three/fiber"
import { Raycaster, Vector2, Object3D, Material, Color } from "three"
import { useDebugTools } from "../debugProvider/useDebugTools"
import { Html } from "@react-three/drei"
import tinycolor from "tinycolor2"

interface IntersectedObject {
  object: Object3D;
  originalVisible?: boolean;
  originalMaterialState?: {
    visible: boolean,
    depthTest: boolean,
    wireframe: boolean,
    transparent: boolean,
  };
  position: [number, number, number];
  color?: string;
}

// Add this color palette at the top of the component
const HIGHLIGHT_COLORS = [
  "#2196f3", // blue
  "#4caf50", // green
  "#f44336", // red
  "#ff9800", // orange
  "#9c27b0", // purple
  "#00bcd4", // cyan
  "#ff5722", // deep orange
  "#673ab7", // deep purple
]

function SceneRaycaster() {
  const { camera, scene, size, gl, } = useThree()
  const raycaster = useRef(new Raycaster())
  const mouse = useRef(new Vector2())
  const { visualizeRay, } = useDebugTools()
  const [intersectedObjects, setIntersectedObjects,] = useState<IntersectedObject[]>([])
  const [usedColorIndices, setUsedColorIndices,] = useState<number[]>([])

  // Add the filter arrays
  const intersectableObjects = [
    "outer",
    "inner",
  ]

  const ignoredObjects = [
    "aligmentPlane",
  ]

  // Modified helper function to check if an object is intersectable
  const isIntersectable = (object: Object3D): boolean => {
    // Check the object and all its parents for matching names
    let currentObject: Object3D | null = object
    while (currentObject) {
      const objectName = currentObject.name.toLowerCase()

      // First check if object should be ignored
      if (ignoredObjects.some(filter => objectName.includes(filter.toLowerCase()))) {
        return false
      }

      // Then check if object is intersectable
      if (intersectableObjects.some(filter => objectName.includes(filter.toLowerCase()))) {
        return true
      }

      currentObject = currentObject.parent
    }
    return false
  }

  const onMouseMove = (event: MouseEvent) => {
    // Get canvas bounds
    const rect = gl.domElement.getBoundingClientRect()

    // Calculate mouse position relative to the canvas
    const x = event.clientX - rect.left
    const y = event.clientY - rect.top

    // Convert to normalized device coordinates (-1 to +1)
    mouse.current.x = (x / rect.width) * 2 - 1
    mouse.current.y = -(y / rect.height) * 2 + 1
  }

  useEffect(() => {
    window.addEventListener("mousemove", onMouseMove)
    return () => {
      window.removeEventListener("mousemove", onMouseMove)
    }
  }, [])

  // Reset object to original state when no longer intersected
  const resetObject = (oldObject: IntersectedObject) => {
    if ("material" in oldObject.object) {
      const material = oldObject.object.material as any
      if (material && oldObject.originalMaterialState) {
        material.visible = oldObject.originalMaterialState.visible
        material.depthTest = oldObject.originalMaterialState.depthTest
        if ("wireframe" in material) {
          material.wireframe = oldObject.originalMaterialState.wireframe
        }
        material.transparent = oldObject.originalMaterialState.transparent
        material.opacity = 1

        // Always restore to white since that's the original color
        material.color.setHex(0xffffff)
      }
    }
    oldObject.object.visible = oldObject.originalVisible ?? true

    // Remove the color index when object is reset
    if (oldObject.color) {
      const colorIndex = HIGHLIGHT_COLORS.indexOf(oldObject.color)
      if (colorIndex !== -1) {
        setUsedColorIndices(prev => prev.filter(i => i !== colorIndex))
      }
    }
  }

  // Update getMaterialColor to log what it finds
  const getMaterialColor = (object: Object3D): string => {
    if ("material" in object) {
      const material = object.material as any

      // Log the material properties to debug
      /*console.log("Material:", {
        hasEmissive: !!material?.emissive,
        emissiveColor: material?.emissive && new Color(material.emissive).getHexString(),
        hasColor: !!material?.color,
        color: material?.color && new Color(material.color).getHexString(),
        materialType: material?.type,
      })*/

      // Check for emissive color first
      if (material?.emissive) {
        const emissiveColor = new Color(material.emissive)
        if (emissiveColor.getHex() !== 0) {
          return `#${emissiveColor.getHexString()}`
        }
      }

      // Try to get the base color
      if (material?.color) {
        const color = new Color(material.color)
        return `#${color.getHexString()}`
      }
    }
    return "#cccccc"
  }

  // Add this helper function to get the next available color
  const getNextAvailableColor = (): string => {
    for (let i = 0; i < HIGHLIGHT_COLORS.length; i++) {
      if (!usedColorIndices.includes(i)) {
        setUsedColorIndices(prev => [...prev, i,])
        return HIGHLIGHT_COLORS[i]
      }
    }
    // If all colors are used, start over from the beginning
    setUsedColorIndices([0,])
    return HIGHLIGHT_COLORS[0]
  }

  // Modify handleIntersection to use getNextAvailableColor
  const handleIntersection = (object: Object3D, point: [number, number, number]) => {
    if ("material" in object) {
      const material = object.material as any

      if (!intersectedObjects.find(obj => obj.object.uuid === object.uuid)) {
        const materialColor = getMaterialColor(object)
        const originalColor = material.color ? material.color.clone() : null

        // Use getNextAvailableColor instead of array index
        const highlightColor = getNextAvailableColor()

        const newIntersected: IntersectedObject = {
          object,
          originalVisible: object.visible,
          originalMaterialState: material ? {
            visible: material.visible,
            depthTest: material.depthTest,
            wireframe: ("wireframe" in material ? material.wireframe : false) as boolean,
            transparent: material.transparent,
          } : undefined,
          position: point,
          color: highlightColor,
        }

        // Modify object and material appearance
        if (material) {
          material.visible = true
          material.depthTest = false
          material.transparent = true
          material.opacity = 0.8
          material.color.setStyle(highlightColor)
        }
        object.visible = true

        setIntersectedObjects(prev => [...prev, newIntersected,])
      }
    }
  }

  useFrame(() => {
    raycaster.current.setFromCamera(mouse.current, camera)
    // visualizeRay(scene, raycaster.current, 1000, true)
    const intersects = raycaster.current.intersectObjects(scene.children, true)
      .filter(intersect => isIntersectable(intersect.object)) // Filter intersectable objects

    // Get currently intersected objects
    const currentIntersections = intersects.map(intersect => ({
      uuid: intersect.object.uuid,
      point: intersect.point.toArray() as [number, number, number],
      object: intersect.object,
    }))

    // Reset objects that are no longer intersected
    intersectedObjects.forEach(oldObject => {
      if (!currentIntersections.find(current => current.uuid === oldObject.object.uuid)) {
        resetObject(oldObject)
      }
    })

    // Update intersected objects state
    setIntersectedObjects(prev =>
      prev.filter(obj =>
        currentIntersections.some(current => current.uuid === obj.object.uuid)
      )
    )

    // Handle new intersections
    intersects.forEach(intersect => {
      handleIntersection(intersect.object, intersect.point.toArray() as [number, number, number])
    })
  })

  // Add styles for the label container
  const labelContainerStyle: React.CSSProperties = {
    position: "absolute",
    top: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    padding: "20px",
    pointerEvents: "none",
  }

  // Modify the label style to be a function
  const getLabelStyle = (color: string): React.CSSProperties => {
    const tColor = tinycolor(color)

    // Make the background color more visible
    const backgroundColor = tColor
      .setAlpha(0.85)  // Slightly more opaque
      .saturate(20)    // Increase saturation
      .toString()

    // Ensure good contrast for text
    const textColor = tinycolor.mostReadable(
      backgroundColor,
      ["#ffffff", "#000000",],
      { includeFallbackColors: true, }
    ).toHexString()

    return {
      background: backgroundColor,
      padding: "4px 8px",
      borderRadius: "4px",
      fontSize: "14px",
      color: textColor,
      border: "1px solid rgba(0,0,0,0.2)",
      whiteSpace: "nowrap",
      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    }
  }

  // Add this helper function before the return statement
  const getObjectHierarchyName = (object: Object3D): string => {
    const names: string[] = []
    let currentObject: Object3D | null = object

    while (currentObject) {
      if (currentObject.name) {
        const partId = (object as any)?.userData?.partId?.slice(-4)
        names.unshift(currentObject.name + (partId ? ` (${partId})` : ""))
      }
      currentObject = currentObject.parent
    }

    return names.join(" - ")
  }

  return (
    <Html
      as="div"
      wrapperClass="labels-wrapper"
      style={{
        position: "fixed",
        top: 0,
        left: 250,
        width: "100%",
        height: "100%",
        pointerEvents: "none",
      }}
      transform={false}
      prepend
    >
      <div style={labelContainerStyle}>
        {intersectedObjects.map((intersected, index) => (
          <div
            key={intersected.object.uuid}
            style={getLabelStyle(intersected.color || "#cccccc")}
          >
            {getObjectHierarchyName(intersected.object) || `Object ${index + 1}`}
          </div>
        ))}
      </div>
    </Html>
  )
}

export default SceneRaycaster