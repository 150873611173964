import React from "react"
import { useRecoilState } from "recoil"
import { showGroupingAtom } from "../../../../state/scene/atoms"
import PillToggle from "../../../../../common/components/PillToggle"

const GroupingToggle = () => {
    const [showGrouping, setShowGrouping,] = useRecoilState(showGroupingAtom)

    const handleToggle = (value: string) => {
        setShowGrouping(value === "Groups")
    }

    return (
        <PillToggle
            options={["Groups", "Parts",]}
            activeOption={showGrouping ? "Groups" : "Parts"}
            onChange={handleToggle}
            width={74}
        />
    )
}

export default GroupingToggle