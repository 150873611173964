import { SegmentedTubeSectionType } from "../../common/api/Types"
import { Polygon } from "./MeshUtils"

/* eslint-disable no-console */
export enum PartTypeEnum {
    tube = "tube_part_type",
    segmentedTube = "segmented_tube_part_type",
    connector = "connector_part_type",
}

export enum TubeMarkerEnum {
    TOP = "TOP",
    BOTTOM = "BOTTOM"
}

export enum MarkerType {
    COLLISION = "COLLISION_MARKER",
    COLLISION_PLANE = "COLLISION_PLANE",
    PLUS_BUTTON = "PLUS_BUTTON"
}

export type XYZ = {
    x: number,
    y: number,
    z: number,
}

export type XYZW = XYZ & {
    w: number,
}

export type CommonValues = {
    loaded: boolean,
    id: string,
    name: string,
    position: XYZ,
    rotation: XYZW,
    apiTypeId: string,
    rotationDisabled?: boolean,
    duplicatedFrom?: string,
    bulkCreated?: boolean,
    markerOffset?: XYZ,
    length?: number,
    lengthNegativeSide?: number,
    twodPolygon?: Polygon,
}

export type Marker = {
    id: string,
    name: string,
    sizeId: string,
    oppositeOf?: string,
    fullLeg: number,
    iELenght: number,
    position?: SegmentedTubeSectionType,
    lateralFacing?: boolean,
    boundary?: number,
    positionXYZ?: XYZ,
}

export type TubeValues = CommonValues & {
    color: string,
    isCreated: boolean,
    length: number,
    angle: number,
    opacity: number,
    marker: Marker,
    markers?: Marker[],
    originMarkerName: TubeMarkerEnum,
    direction: XYZ | null,
    diameter: {
        inner: number,
        outer: number,
    },
    defaultLength: number,
    maxLength: number,
    partUnits?: string,
    twodPolygon?: Polygon,
    instanciated?: boolean,
}

export type SegmentedTubeValues = CommonValues & {
    color: string,
    length: number,
    lengthNegativeSide: number,
    markers: Marker[],
    defaultLength: number,
    maxLength: number,
    fileURL: string,
    segmentLength: number,
    startSegmentLength: number,
    endSegmentLength: number,
    initialMarkerName: string,
    maxMiddles: number,
    minMiddles: number,
    rotationSteps?: number,
    userRotation?: number,
    markerOffset?: XYZ,
    partUnits?: string,
    scaledSegmentLength?: number,
    segmentScaleFactor?: number,
    unitRealValue?: number,
    heightScaling?: boolean,
    widthScaling?: boolean,
    realWidth?: number,
    realHeight?: number,
    modifiedWidth?: number,
    modifiedWidthUnits?: string,
    modifiedHeight?: number,
    modifiedHeightUnits?: string,
    baseName?: string,
    instanciated?: boolean,
    twodPolygon?: Polygon,
}

export type ConnectorValues = CommonValues & {
    color: string,
    initialMarkerName: string,
    rotationMarkerName: string,
    fileURL: string,
    markers: Marker[],
    instanciated: boolean,
    rotationSteps?: number,
    userRotation?: number,
    specialRotationMarker?: boolean,
}


type BasePartState<T extends PartTypeEnum, V extends CommonValues> = V & {
    type: T,
}

export type TubePart = BasePartState<PartTypeEnum.tube, TubeValues>
export type ConnectorPart = BasePartState<PartTypeEnum.connector, ConnectorValues>
export type SegmentedTubePart = BasePartState<PartTypeEnum.segmentedTube, SegmentedTubeValues>
export type GenericPartState = TubePart | ConnectorPart | SegmentedTubePart
export type PartIdType = {
    id: string,
    type: PartTypeEnum,
}

export type PartState<T extends PartTypeEnum> = Extract<GenericPartState, { type: T, }>

const assertPartType_ = <T extends PartTypeEnum>(
    current: GenericPartState,
    expected: T
): asserts current is PartState<T> => {
    if (current.type !== expected) {

        console.error("Existing part type is not compatible", {
            expected,
            current,
        })
        throw new Error("Existing part type is not compatible")
    }
}

export const assertPartType: typeof assertPartType_ = assertPartType_
