import React from "react"
import { Collapse } from "antd"
import styled from "styled-components"
import { PartTypeAPI } from "../../../../../../../../common/api/Types"

const PartCard = styled.div`
    display: flex;
    align-items: center;
    padding: 8px;
    border: 1px solid #f0f0f0;
    margin: 4px 0;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
        background-color: #f5f5f5;
    }
`

const PartImage = styled.img`
    width: 40px;
    height: 40px;
    object-fit: contain;
    margin-right: 12px;
`

const PartInfo = styled.div`
    display: flex;
    flex-direction: column;
`

const PartName = styled.span`
    font-weight: 700;
`

interface SwappablePartsListProps {
    compatibleParts: PartTypeAPI[];
    onPartSelect: (part: PartTypeAPI) => void;
    onCollapse: () => void;
}

export const SwappablePartsList: React.FC<SwappablePartsListProps> = ({
    compatibleParts,
    onPartSelect,
    onCollapse,
}) => {
    return (
        <Collapse
            defaultActiveKey={["compatible-parts",]}
            onChange={(keys) => {
                if (keys.length === 0) {
                    onCollapse()
                }
            }}
        >
            <Collapse.Panel header="Swappable Parts" key="compatible-parts">
                {compatibleParts.map((part) => (
                    <PartCard
                        key={part.id}
                        onClick={() => onPartSelect(part)}
                    >
                        <PartImage src={part.modelIcon} alt={part.name} />
                        <PartInfo>
                            <PartName>{part.name}</PartName>
                        </PartInfo>
                    </PartCard>
                ))}
            </Collapse.Panel>
        </Collapse>
    )
}