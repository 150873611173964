import React, { useState } from "react"
import { ObjectTyped } from "./ObjectTyped"
import { TabsStyled } from "./TabsStyles"

export type TabNameBase = string | number

export type TabDefinition = {
    name: string,
    render: () => JSX.Element,
    onClick?: () => void,
}
export type TabProps<TabName extends TabNameBase> = {
    tabs: Record<TabName, TabDefinition>,
    defaultTab: TabName,
    disabled?: boolean,
    onTabClick?: (tabName: TabName) => void,
}

export const TabsHeader = <TabName extends TabNameBase>(
    props: TabProps<TabName> & {
        current: TabName,
        onTabClick: (t: TabName) => void,
    }
) => {
    const tabs = ObjectTyped.keys(props.tabs).map(k => {
        return {
            key: k,
            tab: props.tabs[k],
        }
    })

    const handleOnClick = (t: {
        key: TabName,
        tab: Record<TabName, TabDefinition>[TabName],
    }) => {
        props.onTabClick(t.key)
        if (t.tab.onClick) {
            t.tab.onClick()
        }
    }

    return <TabsStyled.TabsContainer>
        {props.disabled && <TabsStyled.TabsOverlay />}

        {tabs.map(t => {
            const active = t.key === props.current
            return <TabsStyled.TabItem
                $active={active} key={t.key}
                onClick={_ => handleOnClick(t)}>
                {t.tab.name}
            </TabsStyled.TabItem>
        })}
    </TabsStyled.TabsContainer>
}

export const useTabs = <TabName extends TabNameBase>(props: TabProps<TabName>) => {
    const getCurrentTabOrDefault = () => {
        if (props.tabs[current]) {
            return current
        } else {
            return props.defaultTab
        }
    }

    const [current, setCurrent,] = useState<TabName>(props.defaultTab)

    const currentOrDefault = getCurrentTabOrDefault()

    const handleTabChange = (tabName: TabName) => {
        if (props.onTabClick) {
            props.onTabClick(tabName)
        }
        setCurrent(tabName)
    }

    return {
        headers: <TabsHeader
            current={currentOrDefault}
            onTabClick={handleTabChange}
            defaultTab={props.defaultTab}
            tabs={props.tabs}
        />,
        content: {
            ...props,
            current: { currentOrDefault, },
            onTabClick: { handleTabChange, },
        },
    }
}
