/* eslint-disable max-statements */
const MAX_RETRIES = 3

type SignatureResponse = {
    signature: string,
    timestamp: number,
    apiKey: string,
    cloudName: string,
}

type RetryOptions = {
    retryCount?: number,
}

const cloudFunctionHost = "https://us-central1-tubedesigner.cloudfunctions.net"
const cloudFunctionUrl = `${cloudFunctionHost}/getCloudinarySignature`

export const CloudinaryService = {
    getSignature: async (
        publicId: string,
        { retryCount = 0, }: RetryOptions = {}
    ): Promise<SignatureResponse> => {
        try {
            const signatureUrl = `${cloudFunctionUrl}?publicId=${publicId}`
            const response = await fetch(signatureUrl)

            if (!response.ok) {
                throw new Error(`Failed to get signature: ${response.status}`)
            }

            return response.json()
        } catch (error) {
            console.error(
                `Error getting signature (attempt ${retryCount + 1}/${MAX_RETRIES}):`,
                error
            )

            if (retryCount < MAX_RETRIES - 1) {
                await new Promise(resolve => setTimeout(resolve, 1000))
                return CloudinaryService.getSignature(publicId, { retryCount: retryCount + 1, })
            }

            throw new Error(`Failed to get signature after ${MAX_RETRIES} attempts`)
        }
    },

    uploadFile: async (file: string, id: string, retryCount = 0): Promise<string> => {
        try {
            const {
                signature,
                timestamp,
                apiKey,
                cloudName,
            } = await CloudinaryService.getSignature(id)

            const url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`
            const fd = new FormData()

            // Add authentication parameters in alphabetical order
            fd.append("api_key", apiKey)
            fd.append("file", file)
            fd.append("invalidate", "true")
            fd.append("overwrite", "true")
            fd.append("public_id", id)
            fd.append("timestamp", timestamp.toString())
            fd.append("signature", signature)

            const response = await fetch(url, {
                method: "POST",
                body: fd,
            })

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`)
            }

            const data = await response.json()
            //console.log("image upload response:", data)
            return data.url

        } catch (error) {
            console.error(
                `Error uploading the file (attempt ${retryCount + 1}/${MAX_RETRIES}):`,
                error
            )

            if (retryCount < MAX_RETRIES - 1) {
                // Wait for 1 second before retrying
                await new Promise(resolve => setTimeout(resolve, 1000))
                return CloudinaryService.uploadFile(file, id, retryCount + 1)
            }

            throw new Error(`Failed to upload file after ${MAX_RETRIES} attempts`)
        }
    },
}