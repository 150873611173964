import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"

const colors = {
    background: {
        selected: "#0088ff",
        unselected: "#f0f0f0",
    },
    text: {
        selected: "#fff",
        unselected: "#ababab",
    },
}

const ToggleContainer = styled.div<{ width?: number, }>`
  width: ${props => props.width || 51}px;
  height: 22px;
  margin: 3px;
  border-radius: 8px;
  display: flex;
  cursor: pointer;
  background-color: ${colors.background.unselected};
  overflow: hidden;
`

const ToggleItem = styled.div<{ $active: boolean, }>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => {
        return props.$active ? colors.background.selected : colors.background.unselected
    }};
  color: ${props => {
        return props.$active ? colors.text.selected : colors.text.unselected
    }};
  font-weight: bold;
  font-size: 8px;
  transition: background-color 0.3s;
  text-transform: uppercase;
`

interface PillToggleProps {
    options?: string[];
    activeOption: string;
    onChange: (active: string) => void;
    width?: number;
}

const PillToggle: React.FC<PillToggleProps> = ({
    options = ["ON", "OFF",],
    activeOption,
    onChange,
    width,
}) => {
    const [active, setActive,] = useState(activeOption)

    useEffect(() => {
        setActive(activeOption)
    }, [activeOption,])

    const handleClick = () => {
        const newActive = active === options[0] ? options[1] : options[0]
        setActive(newActive)
        onChange(newActive)
    }

    return (
        <ToggleContainer onClick={handleClick} width={width}>
            {options.map(option => (
                <ToggleItem
                    key={option}
                    $active={active === option}
                >
                    {option}
                </ToggleItem>
            ))}
        </ToggleContainer>
    )
}

export default PillToggle
