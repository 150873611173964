/* eslint-disable max-statements */
import {
    areSameConnection
} from "../components/main/DesignScreen/scene/part/parts/connector/utils/ConnectorUtils"
import { ModalState, ModalType } from "../../common/providers/modalProvider/modalProvider"
import { getOppositeTubeMarker } from "./MarkerUtil"
import { GenericPartState, PartTypeEnum, TubeMarkerEnum } from "./Types"
import { PartConnectionType } from "../state/scene/types"
import { LineInfo } from "../components/ScalerUILines"
import { Camera, Mesh, Vector3 } from "three"

export const handleCollisionOnCreation = (props: {
    buildCollider: () => void,
    updateCollider: (removePart?: () => void) => void,
    deleteCollider: () => void,
    partType: PartTypeEnum,
    onRemove: () => void,
    showModal: (props: ModalState) => void,
}) => {
    const part = props.partType === PartTypeEnum.tube ? "Tube" : "Connector"
    props.buildCollider()
    props.updateCollider(() => {
        props.showModal({
            title: `${part} is colliding`,
            subtitle: `The ${part.toLowerCase()} you just placed collides with another part.
                You can either delete it or keep it and fix the colliding parts yourself.`,
            onCancel: props.onRemove,
            cancelText: "Remove part",
            type: ModalType.confirm,
            onConfirm: () => { },
            confirmText: "Keep part",
        })
    })
}

export const getConnectedMarkerNames = (
    connections: PartConnectionType[],
    partId: string,
    markerNameIncludes: string
): string[] => {
    const markerNames = connections
        .filter(connection => {
            // Check if partA matches our criteria and has the marker name we want
            const isPartAMatch = connection.partA.partId === partId
                && connection.partA.markerName.includes(markerNameIncludes)

            // Check if partB matches our criteria and has the marker name we want
            const isPartBMatch = connection.partB.partId === partId
                && connection.partB.markerName.includes(markerNameIncludes)

            return isPartAMatch || isPartBMatch
        })
        .map(connection => {
            // Return the marker name from our part's side of the connection
            if (connection.partA.partId === partId) {
                return connection.partA.markerName
            }
            return connection.partB.markerName
        })

    // Remove duplicates using Set and convert back to array
    return Array.from(new Set(markerNames))
}

export const getConnectedPartIds = (
    connections: PartConnectionType[],
    partId: string,
    markerNameIncludes?: string
): string[] => {
    return connections
        .filter(connection => {
            // Check if partA matches our criteria
            const isPartAMatch = connection.partA.partId === partId
                && (!markerNameIncludes || connection.partA.markerName.includes(markerNameIncludes))

            // Check if partB matches our criteria
            const isPartBMatch = connection.partB.partId === partId
                && (!markerNameIncludes || connection.partB.markerName.includes(markerNameIncludes))

            return isPartAMatch || isPartBMatch
        })
        .map(connection => {
            // Return the ID of the other part in the connection
            if (connection.partA.partId === partId) {
                return connection.partB.partId
            }
            return connection.partA.partId
        })
}

export const getPositionInCM = (
    localPos: Vector3,
    totalLengthCM: number,
    sourceMarkerMesh: Mesh,
    camera: Camera
) => {
    // Get camera position relative to the marker mesh
    const cameraPosition = new Vector3()
    camera.getWorldPosition(cameraPosition)
    const markerPosition = new Vector3()
    sourceMarkerMesh.getWorldPosition(markerPosition)

    // Get marker's forward direction in world space
    const markerForward = new Vector3(0, 0, 1)
    markerForward.applyQuaternion(sourceMarkerMesh.quaternion)

    // Get vector from marker to camera
    const toCamera = cameraPosition.clone().sub(markerPosition)

    // Project camera vector onto the plane perpendicular to marker's forward
    toCamera.projectOnPlane(markerForward).normalize()

    // Get marker's right vector in world space
    const markerRight = new Vector3(1, 0, 0)
    markerRight.applyQuaternion(sourceMarkerMesh.quaternion)

    // Invert the flip logic
    const shouldFlip = toCamera.dot(markerRight) > 0  // Changed from < 0 to > 0
    const adjustedLocalX = shouldFlip ? -localPos.x : localPos.x

    console.log("getPositionInCM", {
        cameraPos: cameraPosition,
        markerPos: markerPosition,
        toCamera: toCamera,
        markerRight: markerRight,
        dot: toCamera.dot(markerRight),
        shouldFlip,
        localX: localPos.x,
        adjustedLocalX,
    })

    const halfLength = totalLengthCM / 2

    if (adjustedLocalX >= 0) {
        return halfLength - (adjustedLocalX * 100)
    } else {
        return (Math.abs(adjustedLocalX) * 100) + halfLength
    }
}

export const getPositionInMM = (localX: number, totalLengthMM: number) => {
    // First normalize the x coordinate to 0-1 range
    // Current range is roughly -1 to 1, so we add 1 and divide by 2
    const normalizedPosition = (localX + 1) / 2

    // Multiply by total length to get position in MM
    return normalizedPosition * totalLengthMM
}


export const getOffsetDirection = (line: LineInfo, partId: string) => {
    console.log("getting offset direction", line, partId)
    const markers = Object.values(line.markers)
    console.log("markers", markers)
    const markerInfo = markers.find((m: any) => m.partId === partId)

    if (!markerInfo) {
        console.warn("No marker info found for", partId)
        return "positive" // default fallback
    }

    const sectionType = markerInfo.marker.userData.sectionType

    if (sectionType === "START") {
        console.log("sectionType is START so returning positive")
        return "negative"
    } else {
        console.log("sectionType is not START so returning negative")
        return "positive"
    }


}


export const getConnectedPartsWithMarkers = (
    connections: PartConnectionType[],
    partId: string,
    markerNameIncludes?: string
): { connectedPartId: string, sourceMarker: string, connectedMarker: string, }[] => {
    return connections
        .filter(connection => {
            // Check if partA matches our criteria
            const isPartAMatch = connection.partA.partId === partId
                && (!markerNameIncludes || connection.partA.markerName.includes(markerNameIncludes))

            // Check if partB matches our criteria
            const isPartBMatch = connection.partB.partId === partId
                && (!markerNameIncludes || connection.partB.markerName.includes(markerNameIncludes))

            return isPartAMatch || isPartBMatch
        })
        .map(connection => {
            // If partA is our source part, return partB's info and vice versa
            if (connection.partA.partId === partId) {
                return {
                    connectedPartId: connection.partB.partId,
                    sourceMarker: connection.partA.markerName,
                    connectedMarker: connection.partB.markerName,
                }
            }
            return {
                connectedPartId: connection.partA.partId,
                sourceMarker: connection.partB.markerName,
                connectedMarker: connection.partA.markerName,
            }
        })
}

export type PositionedItem = {
    id: string,
    position: number,
}

export const generateOffsets = (
    currentLength: number,
    growthAmount: number,
    items: PositionedItem[],
    fromStart = true
): { id: string, offset: number, }[] => {
    return items.map(item => {
        // Calculate percentage based on direction
        const positionPercentage = fromStart
            ? item.position / currentLength
            : (currentLength - item.position) / currentLength

        // Multiply that percentage by the growth amount to get the offset
        const offset = positionPercentage * growthAmount / 100

        return {
            id: item.id,
            offset: offset,
        }
    })
}

export const getFreeMarkers = (part: GenericPartState, connectedMarkers: string[]) => {
    if (part.type === PartTypeEnum.connector) {
        return part.markers.filter(m =>
            !connectedMarkers.some(cm => areSameConnection(cm, m.name)))
            .map(c => c.name)
    } else if (connectedMarkers.length === 0) {
        return [TubeMarkerEnum.BOTTOM, TubeMarkerEnum.TOP,]
    } else if (connectedMarkers.length === 1) {
        return [getOppositeTubeMarker(connectedMarkers[0] as TubeMarkerEnum),]
    } else {
        return []
    }
}

export const FIRST_PART_INITIAL_MARKER = "inner1"
