import React from "react"
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import styled from "styled-components"

type CustomStyles = {
  maxWidth?: string,
  maxHeight?: string,
}

interface Props {
  children: JSX.Element;
  trigger: JSX.Element;
  disabled?: boolean;
  customStyles?: CustomStyles;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  closeOnSelect?: boolean;
}

const RadixDropdown = (props: Props) => {
  const [internalOpen, setInternalOpen,] = React.useState(false)

  const isControlled = props.open !== undefined
  const isOpen = isControlled ? props.open : internalOpen

  const handleOpenChange = (open: boolean) => {
    if (!isControlled) {
      setInternalOpen(open)
    }
    props.onOpenChange?.(open)
  }

  return (
    <DropdownMenu.Root
      open={isOpen}
      onOpenChange={handleOpenChange}
    >
      <DropdownMenu.Trigger disabled={props.disabled} asChild>
        {props.trigger}
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownContent
          className="DropdownMenuContent"
          sideOffset={5}
          {...props.customStyles}
          onClick={() => props.closeOnSelect && handleOpenChange(false)}
        >
          {props.children}
          <DropdownArrow className="DropdownMenuArrow" />
        </DropdownContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

const DropdownContent = styled(DropdownMenu.Content) <CustomStyles>`
    border-radius: 4px;
    padding: 16px;
    font-size: 15px;
    line-height: 1.5;
    background-color: white;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, 
        hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    user-select: none;
    z-index: 5;
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : "300px")};
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px;
    max-height: ${(props) => (props.maxHeight ? props.maxHeight : "none")};
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    & {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
`

const DropdownArrow = styled(DropdownMenu.Arrow)`
    fill: white;
`

export default RadixDropdown