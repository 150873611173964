/* eslint-disable guard-for-in */
/* eslint-disable max-statements */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useThree } from "@react-three/fiber"
import React, { createContext, useRef } from "react"
import {
    BufferGeometry,
    DynamicDrawUsage,
    InstancedMesh,
    Mesh,
    MeshMatcapMaterial,
    SRGBColorSpace,
    Texture,
} from "three"

interface Props {
    children: React.ReactNode;
}

type NewMeshType = {
    index: number,
    instancedMesh: InstancedMesh,
}

type MeshCache = {
    createMesh: (
        id: string,
        meshName: string,
        mesh: Mesh,
        callback: () => void,
        updateInstance: (newIndex: number) => void,
        woodTexture?: Texture) => NewMeshType,
    deleteMesh: (meshName: string, id: string) => void,
    clickCallback: (meshName: string, instanceId: number) => void,
    getPartId: (meshName: string, instanceId: number) => string | undefined,
}

type Instance = {
    id: string | undefined,
    index: number,
    callback: (() => void) | undefined,
    updateInstance: ((newIndex: number) => void) | undefined,
}

type Cache = {
    meshName: string,
    instances: Instance[],
    instancedMesh: InstancedMesh,
    count: number,
}

export type InstancedMeshContextType = MeshCache | undefined

export const instancedMeshContext = createContext<InstancedMeshContextType>(undefined)

const InstancedMeshProvider: React.FC<Props> = ({ children, }) => {
    const cache = useRef<Cache[]>([])
    const { scene, } = useThree()

    const deleteMesh = (meshName: string, id: string) => {
        const meshCache = cache.current.find((c) => c.meshName === meshName)
        const instancedMesh = meshCache?.instancedMesh
        if (instancedMesh && meshCache) {
            const indexToDelete = meshCache.instances.findIndex((i) => i.id === id)
            meshCache.instances.splice(indexToDelete, 1)
            meshCache.instances.forEach((i, index) => {
                i.index = index
                if (i.updateInstance) {
                    i.updateInstance(index)
                }
            })
            meshCache.count = instancedMesh.count - 1
            meshCache.instancedMesh.count = meshCache.count
            meshCache.instancedMesh.computeBoundingSphere() // Add this line
        }
    }

    const getGeometryByteLength = (geometry: BufferGeometry) => {

        let total = 0

        if (geometry.index) { total += geometry.index.array.length }

        for (const name in geometry.attributes) {
            total += geometry.attributes[name].array.length
        }

        return total
    }

    const formatBytes = (bytes: number, decimals: number) => {

        if (bytes === 0) { return "0 bytes" }

        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["bytes", "KB", "MB",]

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`

    }


    const createMesh = (
        id: string,
        meshName: string,
        mesh: Mesh,
        callback: () => void,
        updateInstance: (newIndex: number) => void,
        woodTexture?: Texture) => {

        const cacheMesh = cache.current.find((c) => c.meshName === meshName)
        if (cacheMesh) {
            const index = cacheMesh.instances.length
            cacheMesh.instances.push({
                index: index,
                id: id,
                callback,
                updateInstance,
            })
            cacheMesh.count = cacheMesh.count + 1
            cacheMesh.instancedMesh.count = cacheMesh.count
            return {
                index: index,
                instancedMesh: cacheMesh.instancedMesh,
            }
        }

        const materialColor = meshName.split("-mat-")[1]
        let material = new MeshMatcapMaterial({ color: "white", })
        if (materialColor && woodTexture) {
            woodTexture.colorSpace = SRGBColorSpace
            material = new MeshMatcapMaterial({ color: "#fff", map: woodTexture, })
        }
        material.normalMap = (mesh.material as any).normalMap
        const instancedMesh = new InstancedMesh(mesh.geometry, material, 500)
        instancedMesh.userData = { type: "InstancedMesh", }
        instancedMesh.count = 1
        instancedMesh.instanceMatrix.setUsage(DynamicDrawUsage)
        instancedMesh.name = meshName
        scene.add(instancedMesh)

        const instancesArray = []
        instancesArray.push({
            index: 0,
            id: id,
            callback,
            updateInstance,
        })
        cache.current.push({
            meshName: meshName,
            instances: instancesArray,
            instancedMesh: instancedMesh,
            count: 1,
        })

        const memoryListElement = document.getElementById("memory-debug")
        if (memoryListElement) {
            const geometryByteLength = getGeometryByteLength(mesh.geometry)
            const li = document.createElement("li")
            li.innerHTML = `<p>${meshName}: ${formatBytes(200 * 16 + geometryByteLength, 2)}</p>`
            memoryListElement.appendChild(li)
        }

        return {
            index: 0,
            instancedMesh,
        }
    }

    const init = () => {
        return {
            createMesh,
            deleteMesh,
            clickCallback,
            getPartId,
        }
    }

    const clickCallback = (meshName: string, instanceId: number,) => {
        if (instanceId !== -1) {
            const cacheIntance = cache.current.find((c) => c.meshName === meshName)
            const instance = cacheIntance?.instances.find((i) => i.index === instanceId)
            if (instance && instance.callback) {
                instance.callback()
            }
        }
    }

    const getPartId = (meshName: string, instanceId: number) => {
        if (instanceId !== -1) {
            const cacheIntance = cache.current.find((c) => c.meshName === meshName)
            const instance = cacheIntance?.instances.find((i) => i.index === instanceId)
            if (instance) {
                return instance.id
            }
        }
    }

    return <instancedMeshContext.Provider value={init()} children={children} />
}

export default InstancedMeshProvider