/* eslint-disable max-len */
/* eslint-disable max-statements */
import { BoxGeometry, Box3, Intersection, MeshBasicMaterial, Object3D, Quaternion, Raycaster, Scene, Vector3, Mesh } from "three"
import { isParallel, metersToInch } from "../components/main/DesignScreen/utils/utilsThree"
import { MarkerType } from "./Types"

const APROXIMATELLY_COLLINEAR_MARGIN = 0.5 / metersToInch

const copyWorldPosition = (mesh: Object3D | null | undefined) => {
    if (!mesh || typeof mesh.getWorldPosition !== "function") {
        return new Vector3()
    }
    const position = new Vector3()
    mesh.getWorldPosition(position)
    return position
}

const copyWorldQuaternion = (mesh: Object3D) => {
    const quaternion = new Quaternion()
    if (!mesh || typeof mesh.getWorldQuaternion !== "function") {
        return new Quaternion()
    }
    mesh.getWorldQuaternion(quaternion)
    return quaternion
}

const copyWorldDirection = (mesh: Object3D | null) => {
    if (!mesh || typeof mesh.getWorldDirection !== "function") {
        return new Vector3()
    }
    const direction = new Vector3()
    mesh.getWorldDirection(direction)
    return direction
}

const getMarkerMeshesWithoutMiddleSegments = (scene: Scene) => {
    const meshes: Object3D[] = []
    scene.traverse(object => {
        if (object.userData.type === MarkerType.COLLISION && !object.userData.middleSection) {
            meshes.push(object)
        }
    })
    return meshes
}

const checkDistanceToCenter = (intersection: Intersection, center: Vector3) => {
    const distanceToCenter = intersection.point.distanceTo(center)
    return distanceToCenter <= APROXIMATELLY_COLLINEAR_MARGIN
}

const getOriginPositionMoved = (origin: Vector3, direction: Vector3, distanceToMove: number) => {
    const newPos = new Vector3()
    const auxDir = new Vector3(direction.x, direction.y, direction.z)
    newPos.addVectors(
        origin,
        auxDir.normalize().multiplyScalar(distanceToMove / metersToInch)
    )
    return newPos
}


export function box3ToMesh(box: Box3, material?: MeshBasicMaterial): Mesh {
    // Create geometry (default unit cube 1x1x1)
    const geometry = new BoxGeometry(1, 1, 1)

    // Create default material if none provided
    const boxMaterial = material || new MeshBasicMaterial({
        color: 0xff0000,
        transparent: true,
        opacity: 0.2,
        wireframe: true,
    })

    // Create mesh
    const mesh = new Mesh(geometry, boxMaterial)

    // Get box center and size
    const center = new Vector3()
    const size = new Vector3()
    box.getCenter(center)
    box.getSize(size)

    // Apply transformations
    mesh.position.copy(center)
    mesh.scale.copy(size)

    // Update matrices
    mesh.updateMatrix()
    mesh.updateMatrixWorld(true)

    return mesh
}

const checkAproxCollinearToDirection = (
    aPos: Vector3,
    aDir: Vector3,
    meshB: Object3D,
    backward: boolean,
) => {
    const movedOrigin = getOriginPositionMoved(aPos, aDir, backward ? 0.2 : -0.2)
    const dir = backward ? aDir.multiplyScalar(-1) : aDir
    const ray = new Raycaster(movedOrigin, dir)
    const intersection = ray.intersectObject(meshB)
    return intersection[0]
}

const areApproximatelyCollinear = (
    meshA: Object3D,
    meshB: Object3D,
) => {
    const aDir = copyWorldDirection(meshA)
    const bDir = copyWorldDirection(meshB)
    if (isParallel(aDir, bDir)) {
        const aPos = copyWorldPosition(meshA)
        const bPos = copyWorldPosition(meshB)

        const intersectionForward = checkAproxCollinearToDirection(aPos, aDir, meshB, false)
        if (intersectionForward) {
            return checkDistanceToCenter(intersectionForward, bPos)
        } else {
            const intersectionBackward = checkAproxCollinearToDirection(aPos, aDir, meshB, true)
            if (intersectionBackward) {
                return checkDistanceToCenter(intersectionBackward, bPos)
            }
        }
    }
    return false
}

interface Point {
    x: number;
    y: number;
}

export interface Polygon {
    center: Point;
    vertices: Point[];
    minHeight?: number;
}

const createPolygon = (points: Point[], minHeight = 1): Polygon => {
    const center = calculateCenter(points)
    let vertices = organizePoints(points, minHeight)

    // Check if shape is too line-like and adjust if needed
    if (isLineLike(vertices, minHeight)) {
        vertices = convertToRectangle(vertices, minHeight)
    }

    return {
        minHeight,
        center,
        vertices,
    }
}

const calculateCenter = (points: Point[]): Point => {
    const sumX = points.reduce((sum, p) => sum + p.x, 0)
    const sumY = points.reduce((sum, p) => sum + p.y, 0)
    return {
        x: sumX / points.length,
        y: sumY / points.length,
    }
}

const pointToLineDistance = (point: Point, line: { x1: number, y1: number, x2: number, y2: number, }): number => {
    const numerator = Math.abs(
        (line.y2 - line.y1) * point.x
        - (line.x2 - line.x1) * point.y
        + line.x2 * line.y1
        - line.y2 * line.x1
    )
    const denominator = Math.sqrt(
        Math.pow(line.y2 - line.y1, 2)
        + Math.pow(line.x2 - line.x1, 2)
    )
    return numerator / denominator
}

const isLineLike = (vertices: Point[], minHeight: number): boolean => {
    let maxHeight = 0
    for (let i = 0; i < vertices.length; i++) {
        for (let j = i + 1; j < vertices.length; j++) {
            const line = {
                x1: vertices[i].x,
                y1: vertices[i].y,
                x2: vertices[j].x,
                y2: vertices[j].y,
            }

            for (let k = 0; k < vertices.length; k++) {
                if (k !== i && k !== j) {
                    const height = pointToLineDistance(vertices[k], line)
                    maxHeight = Math.max(maxHeight, height)
                }
            }
        }
    }
    return maxHeight < minHeight
}

const findFurthestPoints = (vertices: Point[]): [Point, Point] => {
    let maxDist = 0
    let points: [Point, Point] = [vertices[0], vertices[1],]

    for (let i = 0; i < vertices.length; i++) {
        for (let j = i + 1; j < vertices.length; j++) {
            const dist = Math.hypot(
                vertices[i].x - vertices[j].x,
                vertices[i].y - vertices[j].y
            )
            if (dist > maxDist) {
                maxDist = dist
                points = [vertices[i], vertices[j],]
            }
        }
    }
    return points
}

const convertToRectangle = (vertices: Point[], minHeight: number): Point[] => {
    const [p1, p2,] = findFurthestPoints(vertices)
    const angle = Math.atan2(p2.y - p1.y, p2.x - p1.x)

    const halfHeight = minHeight / 2
    const dx = halfHeight * Math.sin(angle)
    const dy = halfHeight * Math.cos(angle)

    return [
        { x: p1.x - dx, y: p1.y + dy, },
        { x: p1.x + dx, y: p1.y - dy, },
        { x: p2.x + dx, y: p2.y - dy, },
        { x: p2.x - dx, y: p2.y + dy, },
    ]
}

const isLeftTurn = (p1: Point, p2: Point, p3: Point): boolean => {
    return (
        (p2.x - p1.x) * (p3.y - p1.y)
        - (p2.y - p1.y) * (p3.x - p1.x)
    ) > 0
}

const organizePoints = (points: Point[], minHeight: number): Point[] => {
    if (points.length < 3) {
        const [p1, p2,] = points.length === 2 ? points : [points[0], points[0],]
        return convertToRectangle([p1, p2,], minHeight)
    }

    let bottomPoint = points[0]
    for (let i = 1; i < points.length; i++) {
        if (points[i].y < bottomPoint.y
            || (points[i].y === bottomPoint.y && points[i].x < bottomPoint.x)) {
            bottomPoint = points[i]
        }
    }

    const sortedPoints = points
        .filter(p => p !== bottomPoint)
        .map(p => ({
            point: p,
            angle: Math.atan2(p.y - bottomPoint.y, p.x - bottomPoint.x),
            distance: Math.sqrt(
                Math.pow(p.x - bottomPoint.x, 2)
                + Math.pow(p.y - bottomPoint.y, 2)
            ),
        }))
        .sort((a, b) => {
            if (a.angle === b.angle) {
                return a.distance - b.distance
            }
            return a.angle - b.angle
        })
        .map(p => p.point)

    sortedPoints.unshift(bottomPoint)

    const hull = [sortedPoints[0], sortedPoints[1],]
    for (let i = 2; i < sortedPoints.length; i++) {
        while (
            hull.length >= 2
            && !isLeftTurn(
                hull[hull.length - 2],
                hull[hull.length - 1],
                sortedPoints[i]
            )
        ) {
            hull.pop()
        }
        hull.push(sortedPoints[i])
    }

    return hull
}

const containsPoint = (polygon: Polygon, point: Point, scale = 1): boolean => {
    if (scale === 1) {
        return pointInPolygon(point, polygon.vertices)
    }

    const scaledVertices = polygon.vertices.map(vertex => ({
        x: polygon.center.x + (vertex.x - polygon.center.x) * scale,
        y: polygon.center.y + (vertex.y - polygon.center.y) * scale,
    }))

    return pointInPolygon(point, scaledVertices)
}

const pointInPolygon = (point: Point, vertices: Point[]): boolean => {
    const { x, y, } = point
    let inside = false

    for (let i = 0, j = vertices.length - 1; i < vertices.length; j = i++) {
        const xi = vertices[i].x
        const yi = vertices[i].y
        const xj = vertices[j].x
        const yj = vertices[j].y

        const intersect = ((yi > y) !== (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi)

        if (intersect) { inside = !inside }
    }

    return inside
}

const debugDrawPolygon = (
    ctx: CanvasRenderingContext2D,
    polygon: Polygon,
    options = {
        fillStyle: "rgba(0, 255, 0, 0.2)",
        strokeStyle: "rgba(0, 255, 0, 0.8)",
        centerColor: "red",
        scale: 1,
        offsetX: 0,
        offsetY: 0,
    }
) => {
    const { vertices, center, } = polygon
    const { fillStyle, strokeStyle, centerColor, scale, offsetX, offsetY, } = options

    // Draw the polygon
    ctx.beginPath()
    vertices.forEach((point, index) => {
        const x = point.x * scale + offsetX
        const y = point.y * scale + offsetY

        if (index === 0) {
            ctx.moveTo(x, y)
        } else {
            ctx.lineTo(x, y)
        }
    })
    ctx.closePath()

    // Fill polygon
    ctx.fillStyle = fillStyle
    ctx.fill()

    // Stroke polygon
    ctx.strokeStyle = strokeStyle
    ctx.stroke()

    // Draw center point
    ctx.beginPath()
    ctx.arc(
        center.x * scale + offsetX,
        center.y * scale + offsetY,
        3,
        0,
        2 * Math.PI
    )
    ctx.fillStyle = centerColor
    ctx.fill()

    // Draw vertices as small circles
    ctx.fillStyle = strokeStyle
    vertices.forEach(point => {
        ctx.beginPath()
        ctx.arc(
            point.x * scale + offsetX,
            point.y * scale + offsetY,
            2,
            0,
            2 * Math.PI
        )
        ctx.fill()
    })
}

export const PolygonUtils = {
    createPolygon,
    containsPoint,
    pointInPolygon,
    debugDrawPolygon,
}

export const MeshUtils = {
    copyWorldPosition,
    copyWorldQuaternion,
    copyWorldDirection,
    getMarkerMeshesWithoutMiddleSegments,
    areApproximatelyCollinear,
    checkAproxCollinearToDirection,
    getOriginPositionMoved,
}