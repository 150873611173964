/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */

import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from "react"
import { Icosahedron, Text } from "@react-three/drei"
import {
    ArrowHelper, BackSide, Box3, Box3Helper, BoxHelper, BufferGeometry, Color, Group, Line,
    LineBasicMaterial, Mesh, Object3D, Quaternion, Raycaster, Scene, Vector3
} from "three"
import { useFrame, useThree } from "@react-three/fiber"
import { MarkerType, PartTypeEnum } from "../../../../../../../../utils/Types"
import { SectionType, SegmentedTubeInfo, SegmentedTubeMarker } from "../types/types"
import { MeshUtils } from "../../../../../../../../utils/MeshUtils"
import { addPartModal, isItemSelected } from "../../../../../../../../state/atoms"
import { useSetRecoilState } from "recoil"
import { ConnectionOfPart } from "../../../../../../../../state/scene/types"

export type SectionedMarkerData = {
    section: SectionType, key: string, position?: string,
}

type NewPartButtonProps = {
    info: MutableRefObject<SegmentedTubeInfo>,
    marker: SegmentedTubeMarker,
    partId: string,
    setIsSelected: (selected: boolean) => void,
    isMiddleSection?: boolean,
    scene?: Scene,
    partConnectionsValue: ConnectionOfPart[],
    length: number,
}


const NewPartButton = (props: NewPartButtonProps) => {
    const setNewConnectionData = useSetRecoilState(addPartModal)

    const { camera, } = useThree()
    const icoRef = useRef<Mesh>(null)
    const [hovered, hover,] = useState(false)
    const [makeSeeThrough, setMakeSeeThrough,] = useState(true)
    const targetMarker = props.marker.plus || props.marker.outer!

    const { worldOuterPosition, worldOuterQuaternion, outerMarkerWorldPos, outerMarkerWorldRotation, innerMarkerWorldPos, innerMarkerWorldRotation, } = useMemo(() => {
        const worldOuterPosition = MeshUtils.copyWorldPosition(targetMarker)
        const worldOuterDirection = MeshUtils.copyWorldDirection(targetMarker)
        worldOuterPosition.add(worldOuterDirection.multiplyScalar(0.02))
        const outerMarkerWorldPos = MeshUtils.copyWorldPosition(props.marker.outer!)
        const outerMarkerWorldRotation = MeshUtils.copyWorldQuaternion(props.marker.outer!)
        const innerMarkerWorldPos = MeshUtils.copyWorldPosition(props.marker.inner!)
        const innerMarkerWorldRotation = MeshUtils.copyWorldQuaternion(props.marker.inner!)
        const worldOuterQuaternion = MeshUtils.copyWorldQuaternion(targetMarker)
        return { worldOuterPosition, worldOuterDirection, worldOuterQuaternion, outerMarkerWorldPos, outerMarkerWorldRotation, innerMarkerWorldPos, innerMarkerWorldRotation, }
    }, [props.marker, isItemSelected, props.partConnectionsValue, props.length,])



    const [markerPosition, setMarkerPosition,] = useState(new Vector3())

    const [offset, setOffset,] = useState(0)

    const raycastHelper: ArrowHelper | null = null

    const idealPosition: Vector3 = useMemo(() => {
        const calculateIdealPosition = (
            longSide: number,
            isVertical: boolean,
            worldOuterWorking: Vector3,
            worldOuterDirectionWorking: Vector3,
            worldOuterQuaternionWorking: Quaternion
        ) => {
            const increments = Array.from({ length: Math.ceil(longSide / 0.0125), },
                (_, i) => (i + 1) * 0.0125)
            const directions = [0, ...increments, ...increments.map(inc => -inc),]

            // Define axis based on isVertical
            const axis = isVertical
                ? { x: 0, y: 1, z: 0, } // y-axis for vertical
                : { x: 1, y: 0, z: 0, } // x-axis for horizontal

            for (const direction of directions) {
                const localOffset = new Vector3(
                    axis.x * direction,
                    axis.y * direction,
                    axis.z * direction
                )

                localOffset.applyQuaternion(worldOuterQuaternionWorking)
                const testPosition = worldOuterWorking.clone().add(localOffset)

                const raycaster = new Raycaster(testPosition, worldOuterDirectionWorking)

                // Remove previous helper if it exists
                /*if (raycastHelper) {
                    props.scene?.remove(raycastHelper)
                }

                // Create and add new raycast helper
                raycastHelper = new ArrowHelper(
                    worldOuterDirectionWorking.clone().normalize(),
                    testPosition,
                    0.1,
                    0xff0000
                )
                props.scene?.add(raycastHelper)*/

                const sceneChildrenWithoutIgnore = props.scene?.children.filter(child => !child.userData.ignoreRaycast) || []

                const newIntersections = raycaster.intersectObjects(sceneChildrenWithoutIgnore)
                const filteredIntersections = newIntersections.filter(intersect =>
                    intersect.object.userData.partId !== props.partId
                    && intersect.object.userData.type !== MarkerType.PLUS_BUTTON
                    && intersect.object.type !== "ArrowHelper"
                    && intersect.object.parent?.type !== "ArrowHelper"
                    && intersect.object.parent?.name !== "Plus Button"
                    && intersect.object.type !== "Line"
                    && !intersect.object.name.includes("aligment")
                    && !intersect.object.name.includes("ignore")
                )
                const minDistance = 0.09
                //console.log("intersections", props.marker.outer?.name, props.marker.mesh?.name, newIntersections, filteredIntersections)
                if (!filteredIntersections.some(intersect => intersect.distance < minDistance)) {
                    setOffset(direction)
                    const position = new Vector3()
                    const plusOffset = new Vector3(0, 0, 0.02)
                        .applyQuaternion(worldOuterQuaternionWorking)
                    position.copy(testPosition).add(plusOffset)
                    setMarkerPosition(position)
                    //console.log(position, "position")
                    return position
                }
            }
            setMakeSeeThrough(false)
            //console.warn("Could not find a suitable position for the new part button")
            return null
        }

        const worldOuterWorking = MeshUtils.copyWorldPosition(targetMarker)
        const worldOuterDirectionWorking = MeshUtils.copyWorldDirection(targetMarker)
        const worldOuterQuaternionWorking = MeshUtils.copyWorldQuaternion(targetMarker)

        if (props.marker.mesh) {
            //console.log(props.marker.mesh.name, "props.marker.mesh.name")
            props.marker.mesh.geometry.computeBoundingBox()
            const boundingBox = props.marker.mesh.geometry.boundingBox
            if (boundingBox) {
                const meshWidth = boundingBox.max.x - boundingBox.min.x
                const meshHeight = boundingBox.max.y - boundingBox.min.y
                const longSide = Math.max(meshWidth, meshHeight) / 2 - 0.0125
                return calculateIdealPosition(longSide, false, worldOuterWorking,
                    worldOuterDirectionWorking, worldOuterQuaternionWorking) || new Vector3()
            }
        } else if (props.marker.outer && props.marker.outer.userData.boundary) {
            const longSide = props.marker.outer.userData.boundary * 2.54 / 100
            return calculateIdealPosition(longSide, true, worldOuterWorking,
                worldOuterDirectionWorking, worldOuterQuaternionWorking) || new Vector3()
        }

        return new Vector3()
    }, [props.partConnectionsValue, isItemSelected,])

    const cameraVector = new Vector3()
    useFrame((state) => {
        if (icoRef.current) {
            camera.getWorldPosition(cameraVector)
            icoRef.current.lookAt(cameraVector)
        }
    })

    const onHover = () => {
        hover(true)
        //console.log(props.marker.outer?.name, "marker.outer")
    }

    const onHoverOut = () => {
        hover(false)
    }

    useEffect(() => {
        if (hovered) {
            document.body.style.cursor = "pointer"
        } else {
            document.body.style.cursor = "default"
        }

        return () => {
            document.body.style.cursor = "default"
        }
    }, [hovered,])

    const handleNewPart = () => {

        props.setIsSelected(false)
        document.body.style.cursor = "default"

        const applyOffset = (position: Vector3, isVertical: boolean) => {
            const offsetVector = isVertical
                ? new Vector3(0, offset, 0)
                : new Vector3(offset, 0, 0)
            offsetVector.applyQuaternion(worldOuterQuaternion)
            const clonedPosition = new Vector3().copy(position)
                .add(offsetVector)
            return clonedPosition
        }

        //using instead values from useMemo
        //const outerPos = MeshUtils.copyWorldPosition(props.marker.outer!)
        //const outerRot = MeshUtils.copyWorldQuaternion(props.marker.outer!)
        //const innerPos = MeshUtils.copyWorldPosition(props.marker.inner!)
        //const innerRot = MeshUtils.copyWorldQuaternion(props.marker.inner!)

        let innerPos = innerMarkerWorldPos
        let outerPos = outerMarkerWorldPos

        if (props.marker.mesh && !markerPosition.equals(new Vector3())) {
            outerPos = applyOffset(outerMarkerWorldPos, false)
            innerPos = applyOffset(innerMarkerWorldPos, false)
        } else if (props.marker.outer && props.marker.outer.userData.boundary) {
            outerPos = applyOffset(outerMarkerWorldPos, true)
            innerPos = applyOffset(innerMarkerWorldPos, true)
        }

        //console.log(props.marker.outer, "props.marker.outer")

        setNewConnectionData({
            step1: {
                source: {
                    partId: props.partId,
                    markerId: props.marker.outer!.userData.id,
                    markerName: props.marker.outer!.name,
                    posAndRot: {
                        inner: {
                            pos: innerPos,
                            rot: innerMarkerWorldRotation,
                        },
                        outer: {
                            pos: outerPos,
                            rot: outerMarkerWorldRotation,
                        },
                    },
                    connectionLength: props.marker.outer!.userData.iELength,
                    sizeId: props.marker.outer!.userData.sizeId,
                    expandReduceToFitInfo: { isApplicable: false, },
                    type: PartTypeEnum.segmentedTube,
                    canSlide: props.isMiddleSection,
                },
            },
        })
    }

    const materialColor = useMemo(() => {
        return new Color(hovered ? "#43a7ff" : "#0088ff").convertSRGBToLinear()
    }, [hovered,])

    return <Icosahedron
        userData={{
            type: MarkerType.PLUS_BUTTON,
        }}
        ref={icoRef}
        name={"Plus Button"}
        args={[0.02 / 2, 3,]}
        position={markerPosition.equals(new Vector3()) ? worldOuterPosition : markerPosition}
        quaternion={worldOuterQuaternion}
        onPointerLeave={onHoverOut}
        onPointerEnter={onHover}
        onClick={handleNewPart}
    >
        <meshBasicMaterial color={materialColor} side={BackSide} depthTest={makeSeeThrough} depthWrite={makeSeeThrough} />
        <Text fontSize={0.02} color={"#fff"} >
            +
            <meshBasicMaterial color="#ffffff" depthTest={makeSeeThrough} depthWrite={makeSeeThrough} />
        </Text>
    </Icosahedron>
}

export default NewPartButton