/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
import * as React from "react"
import { Html } from "@react-three/drei"
import { message } from "antd"
import { useState } from "react"
import { useEffect } from "react"
import { useNavigate, useSearchParams, } from "react-router-dom"
import { useThree } from "@react-three/fiber"
import {
    useRecoilBridgeAcrossReactRoots_UNSTABLE, useRecoilState, useRecoilValue,
    useResetRecoilState, useSetRecoilState
} from "recoil"
import { Color, PerspectiveCamera, Vector2 } from "three"
import { getUser } from "../../../../../common/api/firebase/firebaseUtils"
import { LoginPopUp } from "../../../../../common/components/LoginPopup/LoginPopUp"
import { authState } from "../../../../../common/state/LoginState/RecoilAuthState"
import { pendingChangesAtom, saveDesignAtom, selectedItemID, } from "../../../../state/atoms"
import { isSaveInProgressAtom, sceneAtom, unitSelector } from "../../../../state/scene/atoms"
import { RouterContexts, RouterProviders } from "../../../../../common/utils/RouterBridge"
import NameModal from "../../../../../common/components/NameModal"
import { DesignsApi } from "../../../../../common/api/firebase/designs"
import { getUrlWithQueriesToPreserve } from "../../../../../common/utils/utils"
import { events } from "../../../../../common/utils/rudderAnalyticsUtils"
import { RoutesHelper } from "../../../../../common/utils/routesHelper"
import { initialData } from "../../../../state/atoms"
import { getPredominantMaterials } from "../utils/EventsHelper"
import { messageUtils } from "./LowerRightMessages"
import { CloudinaryService } from "../../../../../common/api/cloudinary/cloudinaryService"
import { useComponentRegistry } from "../../../../providers/multiselectProvider/useComponentMethods"
import { connectionTypesSelector, sizesSelector } from "../../../../state/initialDataSelectors"
type Props = {
    userId?: string,
    designId?: string,
}

const SaveHandler = (props: Props) => {

    const { getComponent, } = useComponentRegistry()
    const { gl, scene, camera, } = useThree()
    const [showModal, setShowModal,] = useState(false)
    const [saving, setSaving,] = useState(false)
    const resetSaveDesignData = useResetRecoilState(saveDesignAtom)
    const resetSelectedItem = useResetRecoilState(selectedItemID)
    const sceneData = useRecoilValue(sceneAtom)
    const unit = useRecoilValue(unitSelector)
    const setPendingChanges = useSetRecoilState(pendingChangesAtom)
    const navigate = useNavigate()
    const partsListLength = Object.keys(sceneData.parts).length
    const allData = useRecoilValue(initialData)
    const connectionTypes = useRecoilValue(connectionTypesSelector)
    const sizes = useRecoilValue(sizesSelector)
    const predominantMaterials = getPredominantMaterials(
        sceneData?.parts,
        allData?.parts,
        allData?.materials,
        allData?.subclasses)
    const setIsSaveInProgress = useSetRecoilState(isSaveInProgressAtom)
    const getScreenshot = async () => {
        await resetSelectedItem()
        const newCam = camera.clone(true) as PerspectiveCamera
        if (newCam) {
            scene.add(newCam)
            gl.setClearColor(new Color("white"))
            gl.autoClear = true

            const size = new Vector2()
            gl.getSize(size)
            const w = size.x
            const h = w / (16 / 9)

            newCam.aspect = w / h
            newCam.updateProjectionMatrix()


            gl.setSize(w, h, true)
            gl.render(scene, newCam)
            const imgData = gl.domElement.toDataURL("image/jpeg")
            scene.remove(newCam)
            gl.setSize(size.x, size.y, true)
            gl.render(scene, camera)
            return imgData
        }
        return ""
    }

    const saveProject = async (params:
        { screenshot: string, name: string, id?: never, }
        | { screenshot: string, id: string, name?: never, }
    ) => {
        const { screenshot, } = params
        const toSave = JSON.stringify(sceneData)
        try {
            const newProject = !params.id
            //console.log(getComponent, "getComponent in SaveProject")
            const newId = await DesignsApi.saveDesign(
                toSave,
                screenshot,
                newProject ? params.name : undefined,
                newProject ? undefined : params.id,
                unit,
                getComponent as any,
                connectionTypes,
                sizes,
            )
            if (props.designId === newId || !props.designId) {
                events.saveDesign({
                    totalPartsInDesign: partsListLength,
                    mainMaterial: predominantMaterials?.material,
                    mainSubmaterial: predominantMaterials?.submaterial,
                    unit: unit,
                })
            } else {
                events.cloneDesign({
                    idClonedDesign: props.designId!,
                    totalPartsInDesign: partsListLength,
                    mainMaterial: predominantMaterials?.material,
                    mainSubmaterial: predominantMaterials?.submaterial,
                    unit: unit,
                })
            }
            setPendingChanges(false)
            return newProject ? newId : params.id
        }
        catch (e: any) {
            message.error("Couldn't save.")
            throw new Error("Couldn't save.", e)
        } finally {
            resetSaveDesignData()
        }
    }

    useEffect(() => {
        const save = async () => {
            await resetSelectedItem()
            try {
                if (props.userId && props.designId) {
                    //Do i own the design?
                    const user = getUser()!
                    if (props.userId === user.uid) {
                        messageUtils.custom("Saving design...", {
                            duration: 1, showSpinner: true, forceShow: true,
                        })
                        setSaving(true)
                        const screenshot = await getScreenshot()
                        await saveProject({ screenshot, id: props.designId, })
                        setSaving(false)
                        resetSaveDesignData()
                        CloudinaryService.uploadFile(screenshot, `${user.uid}_${props.designId}`)
                    } else {
                        setShowModal(true)
                    }
                } else {
                    setShowModal(true)
                }

            }
            catch (e: any) {
                resetSaveDesignData()
                message.error("Couldn't load the design.")
                throw new Error("Couldn't load the design.", e)
            }
        }
        save()
    }, [])

    const handleCancel = () => {
        setIsSaveInProgress(false)
        setSaving(false)
        resetSaveDesignData()
        setShowModal(false)
    }

    const saveWithName = async (name: string) => {
        try {
            setSaving(true)
            const user = getUser()!
            const screenshot = await getScreenshot()
            const newId = await saveProject({ screenshot, name, })
            setSaving(false)
            setShowModal(false)
            resetSaveDesignData()
            setIsSaveInProgress(true)
            CloudinaryService.uploadFile(screenshot, `${user.uid}_${newId}`)
            navigate(getUrlWithQueriesToPreserve(RoutesHelper.generateIdRoute(
                {
                    userId: user.uid,
                    designId: newId,
                })))
        }
        catch (e: any) {
            setIsSaveInProgress(false)
            resetSaveDesignData()
            message.error("Couldn't save.")
            throw new Error("Couldn't save.", e)
        }

    }


    if (showModal) {
        return <Html style={{ width: "100vw", }} className="htmlShowModal">
            <NameModal
                onCancel={handleCancel}
                onRename={saveWithName}
                submitButtonText="Save"
                title="Name Design"
                loading={saving}
            />
        </Html>
    }
    return null
}

const LoginPopUpWrapper = (props: Props) => {
    const setSaveDesignData = useSetRecoilState(saveDesignAtom)
    const RecoilBridge = useRecoilBridgeAcrossReactRoots_UNSTABLE()
    const handleCancelSaveLogin = () => {
        setSaveDesignData(false)
    }

    return <Html style={{ width: "100vw", }} className="htmlShowModal">
        <RecoilBridge>
            <LoginPopUp
                onCancel={handleCancelSaveLogin}
            />
        </RecoilBridge>
    </Html>
}

const DesignSaver = (props: Props) => {

    const [saveDesignData, setSaveDesignData,] = useRecoilState(saveDesignAtom)
    const auth = useRecoilValue(authState)
    const [urlParam, setUrlParam,] = useSearchParams()

    useEffect(() => {
        const param = urlParam.get("save")
        if (param) {
            urlParam.delete("save")
            setUrlParam(urlParam)

            setSaveDesignData(true)
        }
    }, [])

    const renderContent = () => {
        if (saveDesignData) {
            if (!auth.user) {
                return <LoginPopUpWrapper />
            }
            return <SaveHandler {...props} />
        }
    }

    return renderContent() || null
}

const DesignSaverWithRouter = (props: Props & { router: RouterContexts, }) => {
    return <RouterProviders values={props.router}>
        <DesignSaver {...props} />
    </RouterProviders >

}


export default DesignSaverWithRouter