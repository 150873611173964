import { createContext, useContext } from "react"
import { ParsedResponse } from "../providers/aiProvider/types"
import { DesignsApi } from "../../common/api/firebase/designs"

interface AIProviderContextType {
    createSimplifiedJSON: () => {
        simplifiedDesign: string,
        simplifiedInstructions: string[],
    } | undefined;
    processInstructions: (
        instructions?: string,
        json?: ReturnType<typeof DesignsApi.parseTextToJson>
    ) => Promise<void>;
    isLoading?: boolean;
}

// Create the context
export const AIProviderContext = createContext<AIProviderContextType | undefined>(undefined)

// Create the hook
export const useAIProvider = () => {
    const context = useContext(AIProviderContext)
    if (!context) {
        throw new Error("useAIProvider must be used within an AIProvider")
    }
    return context
}