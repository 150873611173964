/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { useRecoilCallback, useRecoilValue } from "recoil"
import {
    Mesh,
    Object3D,
    Quaternion,
    Scene,
    Vector3
} from "three"
import {
    SegmentedTubeMarkers
} from "../../components/main/DesignScreen/scene/part/parts/segmentedTube/types/types"
import { allConnections } from "../../state/scene/selectors"
import { useReplaceConnection } from "../../state/scene/setters"
import {
    getConnectionMarkerName,
    getOtherSideOfTheConnection,
    isInConnection
} from "../../state/scene/util"
import { getSideAndPosition, isInner } from "../../utils/MarkerUtil"
import { MeshUtils } from "../../utils/MeshUtils"
import { filterWithValue } from "../../../common/utils/utils"
import { SlideContext, SlidePartType } from "./SlideProvider"
import { EnvHelper } from "../../../common/utils/EnvHelper"
import { PartConnectionType } from "../../state/scene/types"
import { Marker, PartTypeEnum, SegmentedTubeValues } from "../../exportables"
import { useGetPart } from "../../state/scene/hooks"
import { sceneAtom } from "../../state/scene/atoms"
import { set } from "immer/dist/internal"
import { useComponentRegistry } from "../multiselectProvider/useComponentMethods"

const useRegisterSlide = (props: SlidePartType) => {
    const context = useContext(SlideContext)

    useEffect(() => {
        context?.registerSegmentedTube(props)

        return () => {
            context?.removeSegmentedTube(props.id)
        }
    }, [])

    const updateSlide = (
        sides: { [side: string]: SegmentedTubeMarkers, },
        mergedMesh: Mesh,
        instanceIndex?: number
    ) => {
        context?.updateSlider(props.id, sides, mergedMesh, instanceIndex)
    }

    return {
        updateSlide,
    }
}

const reorderConnections = (
    connections: PartConnectionType[],
    partId: string,
    newIndex: number,
    getPart: any,
) => {
    const partType = getPart(partId)?.type

    // Filter connections based on partType
    // const segmentedTubeConnections = connections.filter(connection => {
    //     const partA = getPart(connection.partA.partId)
    //     const partB = getPart(connection.partB.partId)
    //     if (partType === PartTypeEnum.segmentedTube) {
    //         return partA?.type === PartTypeEnum.segmentedTube
    //             && partB?.type === PartTypeEnum.segmentedTube
    //     }
    //     return partA?.type === PartTypeEnum.segmentedTube
    //         || partB?.type === PartTypeEnum.segmentedTube
    // })

    const segmentedTubeConnections = connections

    // Then filter the segmentedTubeConnections to find those containing the specified partId
    const partIdConnections = segmentedTubeConnections.filter(connection =>
        connection.partA.partId === partId || connection.partB.partId === partId
    )
    // Reorder the partId-specific connections based on the newIndex
    if (newIndex >= 0 && newIndex < partIdConnections.length) {
        const [movedItem,] = partIdConnections.splice(newIndex, 1,)
        partIdConnections.unshift(movedItem)
    }

    // Create a new array with the reordered partId-specific connections
    const reorderedConnections = connections.map(connection => {
        if (connection.partA.partId === partId || connection.partB.partId === partId) {
            return partIdConnections.shift()!
        }
        return connection
    }).filter((connection): connection is PartConnectionType => connection !== undefined)

    return reorderedConnections
}


const useSlide = (
    partId: string,
    connectionsIndex = 0,
) => {

    const getLatestSceneData = useRecoilCallback(
        ({ snapshot, }) =>
            () => {
                return snapshot.getLoadable(sceneAtom).contents
            },
        [],
    )
    const context = useContext(SlideContext)
    const initialStateConnections = getLatestSceneData().connections
    const getPart = useGetPart()
    const { getComponent, } = useComponentRegistry()



    const replaceConnection = useReplaceConnection()

    //had to use useMemo to avoid a few sideeffect bugs
    const connections = useMemo(() => {
        return reorderConnections(initialStateConnections, partId, connectionsIndex, getPart)
    }, [initialStateConnections, partId, connectionsIndex,])

    //i removed getPart from the dependency array to avoid a few sideeffect bugs

    const isSlidePart = (segmentedTubeId: string) => {
        const connectedToParts = connections
            .filter(c => isInConnection(c, partId))

        const otherSideOfTheConnection
            = getOtherSideOfTheConnection(connectedToParts[0], partId)

        return otherSideOfTheConnection.partId === segmentedTubeId
    }

    const getSlidePartUnits = (partId: string) => {
        const part = getPart(partId)
        return (part as SegmentedTubeValues)?.partUnits
    }

    const canSlide = () => {
        const connectedToParts = connections
            .filter(c => isInConnection(c, partId))

        const connectedToSegmentedParts = connectedToParts.filter(c => {
            const otherSideOfTheConnection = getOtherSideOfTheConnection(c, partId)
            const sideAndPosition = getSideAndPosition(otherSideOfTheConnection.markerName)
            const { markerSide, } = sideAndPosition
            const sliderPart = context?.getSlidePart(otherSideOfTheConnection.partId)
            if (sliderPart && sliderPart.type === PartTypeEnum.connector) {
                return false
            }
            return !!sliderPart && Object.keys(sliderPart.sides).includes(markerSide!)
        })

        const connectedToConnectorParts = connectedToParts.filter(c => {
            const otherSideOfTheConnection = getOtherSideOfTheConnection(c, partId)
            const sliderPart = context?.getSlidePart(otherSideOfTheConnection.partId)
            const sideAndPosition = getSideAndPosition(otherSideOfTheConnection.markerName)
            const { markerSide, } = sideAndPosition
            if (!sliderPart) {
                return false
            }
            const marker = sliderPart.sides[markerSide!]
            if (!marker) {
                return false
            }
            const firstMarker = Object.keys(marker)[0]
            const firstMarkerObject = marker[firstMarker]
            const hasMesh = firstMarkerObject && firstMarkerObject.mesh
            return sliderPart.type === PartTypeEnum.connector && hasMesh
        })
        // const connectedToSegmentedParts = connectedToParts
        const validParts = [...connectedToSegmentedParts, ...connectedToConnectorParts,]
        const connectionWithSliderPart = validParts[0]

        if (validParts.length > 0) {
            const otherSideOfTheConnection
                = getOtherSideOfTheConnection(connectionWithSliderPart, partId)
            const thisSideOfTheConnection = getOtherSideOfTheConnection(
                connectionWithSliderPart,
                otherSideOfTheConnection.partId
            )
            const sliderPart = context?.getSlidePart(otherSideOfTheConnection.partId)!
            const slidingPart = context?.getSlidePart(partId)!
            const slidePartUnits = getSlidePartUnits(otherSideOfTheConnection.partId)
            const sideAndPosition = getSideAndPosition(thisSideOfTheConnection.markerName)
            const { markerSide, } = sideAndPosition
            let slideSides: SegmentedTubeMarkers = {}
            if (slidingPart) {
                slideSides = slidingPart.sides[markerSide!]
            }

            // const logSimplifiedConnections = (prefix: string, parts: PartConnectionType[]) => {
            //     const ids: string[] = []
            //     // Fix map usage by using forEach instead since we're not returning anything
            //     parts?.forEach(connection => {
            //         const id = `${connection.partA.partId.slice(-4)}-${connection.partB.partId.slice(-4)}`
            //         ids.push(id)
            //     })
            //     console.log(`connections @ ${prefix}`, ids)
            // }

            return {
                slideEnabled: true,
                canSwap: false,
                markerName: getConnectionMarkerName(connectionWithSliderPart, partId),
                sliderPartId: otherSideOfTheConnection.partId,
                sliderMarkerName: otherSideOfTheConnection.markerName,
                sliderStartLength: sliderPart?.startLength,
                sliderEndLength: sliderPart?.endLength,
                sliderSectionLength: sliderPart?.segmentLength,
                slidedMarkerName: thisSideOfTheConnection.markerName,
                slideSides,
                slideMergedMesh: sliderPart?.mergedMesh,
                slidingMergedMesh: slidingPart?.mergedMesh,
                sliderInstanceIndex: sliderPart?.instanceIndex,
                slidingInstanceIndex: slidingPart?.instanceIndex,
                connectedToSegmentedParts: validParts,
                slidePartUnits,
                getConnectionAtIndex: (index: number) => {
                    const partIdConnections = initialStateConnections.filter((connection: PartConnectionType) =>
                        connection.partA.partId === partId || connection.partB.partId === partId
                    )
                    return partIdConnections[index] as PartConnectionType
                },
            }
        }

        return { slideEnabled: false, canSwap: validParts.length === 0, }
    }

    const getSlideSide = (markerName: string) => {
        if (markerName) {
            const connectedToPart
                = getOtherSideOfTheConnection(
                    connections.find(c => isInConnection(c, partId, markerName))!,
                    partId
                )

            const { markerSide, } = getSideAndPosition(connectedToPart.markerName)
            const slideSides = context?.getSlidePart(connectedToPart.partId)!
            if (slideSides) {
                const side = slideSides.sides[markerSide!]
                if (side) {
                    return side
                }
            }
        }
        return null
    }

    const getPositionsToSlide = (markerName: string) => {
        // TODO: use the new method to avoid duplicated code
        if (markerName) {
            const connectedToPart
                = getOtherSideOfTheConnection(
                    connections.find(c => isInConnection(c, partId, markerName))!,
                    partId
                )

            const { markerSide, markerPosition, } = getSideAndPosition(connectedToPart.markerName)
            const connectedToSidePositions = filterWithValue(
                connections.filter(c => isInConnection(c, connectedToPart.partId))
                    .map(c => {
                        const markerName = getConnectionMarkerName(c, connectedToPart.partId)!
                        const {
                            markerSide: connectionMarkerSide,
                            markerPosition: connectionMarkerPosition,
                        } = getSideAndPosition(markerName)

                        if (markerSide === connectionMarkerSide) {
                            return Number(connectionMarkerPosition)
                        }
                        return undefined
                    }))
            const slideSides = context?.getSlidePart(connectedToPart.partId)!
            if (slideSides) {
                const side = slideSides.sides[markerSide!]
                if (side) {
                    const freePositions = Object.keys(side)
                        .map(pos => Number(pos))
                        .filter(pos => !connectedToSidePositions.includes(pos)
                            || pos === markerPosition)
                    const positions = Object.keys(slideSides.sides[markerSide!])
                        .map(pos => Number(pos))

                    return {
                        freePositions,
                        positions,
                        currentValue: markerPosition!,
                    }
                }
            }
        }
        return {
            freePositions: [],
            positions: [],
            currentValue: 0,
        }
    }

    const getNewWorldPosition = (
        newPosition: number,
        sliderPartId: string,
        sliderMarkerName: string
    ) => {
        const sliderPart = context?.getSlidePart(sliderPartId)!
        const { markerSide, } = getSideAndPosition(sliderMarkerName)
        if (isInner(sliderMarkerName)) {
            return MeshUtils.copyWorldPosition(sliderPart.sides[markerSide!][newPosition].inner!)
        } else {
            return MeshUtils.copyWorldPosition(sliderPart.sides[markerSide!][newPosition].outer!)
        }
    }

    const saveNewPosition = (
        sliderPartId: string,
        newPosition: number | null,
        newWorldPosition: Vector3,
        newWorldRotation?: Quaternion,
        ignoreHistory?: boolean,
        historyKey?: string,
    ) => {
        let newMarkerName = ""
        const connection = connections.find(
            c => isInConnection(c, partId) && isInConnection(c, sliderPartId))!
        const markerName = getConnectionMarkerName(connection, sliderPartId)!
        if (newPosition) {
            newMarkerName = `${markerName.split("_")[0]}_${newPosition}`
        } else {
            newMarkerName = markerName
        }

        let relativePos: Vector3 | undefined
        const component = getComponent(sliderPartId)
        if (component) {
            const markers = component.getAllMarkers()
            const marker = markers.find((marker: Marker) => marker.name === newMarkerName)
            const wolrdPos = MeshUtils.copyWorldPosition(marker)
            relativePos = newWorldPosition.clone().sub(wolrdPos)
        }


        const newConnection = {
            partA: getOtherSideOfTheConnection(connection, sliderPartId)!,
            partB: {
                partId: sliderPartId,
                markerName: newMarkerName,
                slidePosition: newWorldPosition,
                relativeSlidePosition: relativePos,
            },
        }
        replaceConnection(
            partId,
            getConnectionMarkerName(connection, partId)!,
            newConnection,
            newWorldPosition,
            newWorldRotation,
            ignoreHistory,
            historyKey,
        )
    }

    const setNewAttachmentPoint = (
        sliderPartId: string,
        newMarkerName: string,
        newAttachmentPoint: Object3D,
        offsetPosition?: Vector3,
        drawVector3Point?:
            (position: Vector3,
                scene: Scene, color: number, size: number, duration: number)
                => void,
        scene?: Scene,
        ignoreHistory?: boolean,
        historyKey?: string,
    ) => {
        const connection = connections.find(
            c => isInConnection(c, partId) && isInConnection(c, sliderPartId))!

        const newWorldPosition = offsetPosition
            ? offsetPosition
            : newAttachmentPoint.position

        // console.log("newMarkerName", newMarkerName)
        // console.log("draft- connection", JSON.stringify(connection, null, 2))
        const otherSideOfTheConnection = getOtherSideOfTheConnection(connection, partId)
        const thisSideOfTheConnection = getOtherSideOfTheConnection(connection, sliderPartId)

        if (drawVector3Point && scene) {
            // drawVector3Point(newWorldPosition, scene, 0xff0500, 0.0015, 10000)
        }

        let relativePos: Vector3 | undefined
        const component = getComponent(sliderPartId)
        if (component) {
            const markers = component.getAllMarkers()
            const marker = markers.find((marker: Marker) => marker.name === newMarkerName)
            const wolrdPos = MeshUtils.copyWorldPosition(marker)
            relativePos = newWorldPosition.clone().sub(wolrdPos)
        }

        const newConnection = {
            partA: {
                partId: partId,
                markerName: newMarkerName,
            },
            partB: {
                partId: otherSideOfTheConnection.partId,
                markerName: otherSideOfTheConnection.markerName,
                slidePosition: newWorldPosition,
                relativeSlidePosition: relativePos,
            },
        }

        replaceConnection(
            thisSideOfTheConnection.partId,
            thisSideOfTheConnection.markerName,
            newConnection,
            newWorldPosition,
            undefined,
            ignoreHistory,
            historyKey,
        )
    }

    return {
        canSlide,
        getPositionsToSlide,
        getNewWorldPosition,
        saveNewPosition,
        isSlidePart,
        getSlideSide,
        setNewAttachmentPoint,
    }
}


export { useRegisterSlide, useSlide, }
