import React from "react"
import jsPDF from "jspdf"
import styled from "styled-components"
import { ReactComponent as PrintIcon } from "./print.svg"
import ContentTooltip from "../../../../../../../../../common/components/Tooltip/ContentTooltip"

interface PrintButtonProps {
    listRef: React.MutableRefObject<HTMLDivElement | null>;
}

const StyledButton = styled.button`
    background: none;
    border: none;
    padding-inline: 0;
    padding-right: 8px;

    &:hover {
        cursor: pointer;
    }
`

const PrintButton: React.FC<PrintButtonProps> = ({ listRef, }) => {
    const downloadResults = () => {
        const doc = new jsPDF({
            orientation: "portrait",
            unit: "px",
            format: "a4",
            putOnlyUsedFonts: true,
            floatPrecision: 16, // or "smart", default is 16
            hotfixes: ["px_scaling",],
        })
        doc.html(listRef.current!, {
            callback: (doc) => {
                doc.save("part list.pdf")
            },
            margin: 40,
            width: 700,
            windowWidth: 900,
            autoPaging: "text",
            x: 0,
            y: 0,
        })
    }

    return (
        <ContentTooltip tooltipContent="Print Parts List">
            <StyledButton onClick={downloadResults}>
                <PrintIcon color="#717171" />
            </StyledButton>
        </ContentTooltip>
    )
}

export default PrintButton