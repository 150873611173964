import React, { useEffect, useRef } from "react"
import { Mesh, Vector3 } from "three"
import useUnitConversion, { convertCmToIn } from "../../../../../../utils/UnitUtils"
import TShapedLine from "./TShapedLine"
import TextLabel from "./TextLabel"
import useCamera from "../../../../../../../../../providers/cameraProvider/useCamera"
import { useLevaControls } from "../../../../../../../../../providers/debugProvider/useLevaControls"

interface MeasurementProps {
    pointA: Vector3;
    pointB: Vector3;
}

const Measurement = ({ pointA, pointB, }: MeasurementProps) => {
    const distance = pointA.distanceTo(pointB)
    const distanceCm = distance * 100
    const distanceIn = convertCmToIn(distanceCm)
    const { getCameraDistance, } = useCamera()
    const { unit, } = useUnitConversion()
    const textRef = useRef<Mesh>(null)

    pointA.z -= 0.0005
    pointB.z -= 0.0005

    // Calculate label position with dynamic scaling based on camera distance
    const getLabelPosition = () => {
        const cameraDistance = getCameraDistance() ?? 2
        const labelScale = 0.4
        const scaleFactor = cameraDistance * (labelScale / 10)

        return new Vector3().addVectors(
            pointB,
            pointB.clone()
                .sub(pointA)
                .normalize()
                .multiplyScalar(scaleFactor)
        )
    }

    const labelPosition = getLabelPosition()

    useEffect(() => {
        if (textRef.current) {
            const lookAtPointLocal = new Vector3(0, 0, -1)
            const lookAtPointWorld = textRef.current.localToWorld(lookAtPointLocal)
            textRef.current.lookAt(lookAtPointWorld)
        }
    }, [])

    const measurementNumber = unit === "in"
        ? parseFloat(distanceIn.toFixed(2))
        : parseFloat(distanceCm.toFixed(2))
    const measurementUnit = unit === "in" ? "\"" : " cm"

    return (
        <>
            <TShapedLine pointA={pointA} pointB={pointB} />
            <TextLabel
                position={labelPosition}
                text={`${measurementNumber}${measurementUnit}`}
            />
        </>
    )
}

export default Measurement