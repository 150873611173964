import { useCallback, useEffect, useRef } from "react"

type UseInfiniteScrollProps = {
    onLoadMore: () => void,
    hasMore: boolean,
    loadingMore?: boolean,
    rootMargin?: string,
    threshold?: number,
}

export const useInfiniteScroll = ({
    onLoadMore,
    hasMore,
    loadingMore = false,
    rootMargin = "300px",
    threshold = 0.1,
}: UseInfiniteScrollProps) => {
    const observerTarget = useRef<HTMLDivElement>(null)

    const observerCallback = useCallback((entries: IntersectionObserverEntry[]) => {
        const [entry,] = entries
        if (entry.isIntersecting && hasMore && !loadingMore) {
            onLoadMore()
        }
    }, [onLoadMore, hasMore, loadingMore,])

    useEffect(() => {
        const target = observerTarget.current
        if (!target) {return}

        const observer = new IntersectionObserver(observerCallback, {
            root: null,
            rootMargin,
            threshold,
        })

        observer.observe(target)

        return () => {
            observer.unobserve(target)
        }
    }, [observerCallback, rootMargin, threshold,])

    return {
        observerTarget,
    }
}