/* eslint-disable max-len */
import React, { useState, useEffect, ReactNode } from "react"
import { useControls, Leva, button } from "leva"
import { atom, useRecoilState, useSetRecoilState, useRecoilValue, useRecoilCallback } from "recoil"
import { sceneAtom } from "../../state/scene/atoms"
import { useComponentRegistry } from "../multiselectProvider/useComponentMethods"
import { PartTypeEnum } from "../../utils/Types"

// Define atoms
const adminModeState = atom({
    key: "adminModeState", // unique ID (with respect to other atoms/selectors)
    default: false, // default value (aka initial value)
})

const controlsState = atom({
    key: "controlsState",
    default: {}, // default empty object, adjust as needed
})

type MarkerControlsConfig = {
    viewHelpers: boolean,
    viewInnerMarkers: boolean,
    viewOuterMarkers: boolean,
    viewAttachmentPoint: boolean,
    viewOriginPoint: boolean,
    hideMergedMeshes: boolean,
    viewLocalAxis: boolean,
};

type CameraControlsConfig = {
    useCameraUp: boolean,
    useFit: boolean,
    yOffset: number,
    xOffset: number,
    zOffset: number,
    removeCameraLock: boolean,
    polarRotateSpeed: number,
    azimuthRotateSpeed: number,
    cameraPadding: number,
}

type MoveControlsConfig = {
    viewDragMesh: boolean,
    viewSurfaceMesh: boolean,
    viewSurfacePoints: boolean,
    viewIntersectionPoints: boolean,
    stepDensity: {
        value: number,
        min: number,
        max: number,
        step: number,
    } | number,
    debugAlignment: boolean,
    debugMeasurements: boolean,
};

type twoDdebuggingConfig = {
    view2DPolygon: boolean,
    view2DPoints: boolean,
};

type scalerDebuggingConfig = {
    storeAndIncrementMiddles: boolean,
    showScalerNormals: boolean,
    showOrientedBoundingBox: boolean,
    seeRaycastsForEnds: boolean,
    logNormalLineSequences: boolean,
    showMarkerPositionForBaseParts: boolean,
    segmentedTubePositionWithMarker: boolean,
    createViewsDebug: boolean,
};

type AppControlsConfig = {
    printJson: any,
    runSegmentedTubeSnapChecks: any,
    viewTubeNames: boolean,
    showStats: boolean,
    showGlobalAxes: boolean,
    useAiPanel: boolean,
    showDebugText: boolean,
    raycastLayers: boolean,
};

// Helper function to parse URL parameters for boolean controls
const getBooleanFromUrl = (paramName: string, defaultValue: boolean): boolean => {
    const urlParams = new URLSearchParams(window.location.search)
    const paramValue = urlParams.get(paramName)
    return paramValue === null ? defaultValue : paramValue === "true"
}

export const DebugState = () => {
    const [adminMode, setAdminMode,] = useRecoilState(adminModeState)

    useEffect(() => {
        setAdminMode(getBooleanFromUrl("admin", false))
    }, [setAdminMode,])

    const { getComponent, } = useComponentRegistry()

    const printJsonCallback = useRecoilCallback(({ snapshot, }) => async () => {
        const latestSceneData = await snapshot.getPromise(sceneAtom)
        const sceneDataJSON = JSON.stringify(latestSceneData, null, 2)
        const blob = new Blob([sceneDataJSON,], { type: "application/json", })
        const url = URL.createObjectURL(blob)
        window.open(url, "_blank")
    }, [])

    const segmentedPartsMigrationConnectionsCallback = useRecoilCallback(({ snapshot, }) => async () => {
        const latestSceneData = await snapshot.getPromise(sceneAtom)

        const processSegmentedParts = async () => {
            for (const partId of latestSceneData.partsIds) {
                const partComponent = getComponent(partId.id)
                if (partId.type === PartTypeEnum.segmentedTube) {
                    if (typeof partComponent?.seeWhatToDisconnectedAndConnect === "function") {
                        console.log(`Processing segmented part: ${partId}`)
                        await partComponent.seeWhatToDisconnectedAndConnect()
                        // Wait for 1 second before processing next part
                        await new Promise(resolve => setTimeout(resolve, 1000))
                    }
                }
            }
            console.log("Finished processing segmented parts")
        }

        processSegmentedParts()
    }, [])



    // Initialize configs with URL parameters if present
    const markerControlsConfig: MarkerControlsConfig = {
        viewHelpers: getBooleanFromUrl("viewHelpers", false),
        viewInnerMarkers: getBooleanFromUrl("viewInnerMarkers", false),
        viewOuterMarkers: getBooleanFromUrl("viewOuterMarkers", false),
        viewAttachmentPoint: getBooleanFromUrl("viewAttachmentPoint", false),
        viewOriginPoint: getBooleanFromUrl("viewOriginPoint", false),
        hideMergedMeshes: getBooleanFromUrl("hideMergedMeshes", false),
        viewLocalAxis: getBooleanFromUrl("viewLocalAxis", false),
    }

    const cameraControlsConfig: CameraControlsConfig = {
        useCameraUp: getBooleanFromUrl("useCameraUp", false),
        useFit: getBooleanFromUrl("useFit", false),
        yOffset: 1.5,
        xOffset: 1.5,
        zOffset: 1.5,
        removeCameraLock: getBooleanFromUrl("removeCameraLock", true),
        polarRotateSpeed: 5,
        azimuthRotateSpeed: 5,
        cameraPadding: 2,
    }

    const twoDdebuggingConfig: twoDdebuggingConfig = {
        view2DPolygon: getBooleanFromUrl("view2DPolygon", false),
        view2DPoints: getBooleanFromUrl("view2DPoints", false),
    }

    const scalerDebuggingConfig: scalerDebuggingConfig = {
        storeAndIncrementMiddles: getBooleanFromUrl("storeAndIncrementMiddles", true),
        showScalerNormals: getBooleanFromUrl("showScalerNormals", false),
        showOrientedBoundingBox: getBooleanFromUrl("showOrientedBoundingBox", false),
        seeRaycastsForEnds: getBooleanFromUrl("seeRaycastsForEnds", false),
        logNormalLineSequences: getBooleanFromUrl("logNormalLineSequences", false),
        showMarkerPositionForBaseParts: getBooleanFromUrl("showMarkerPositionForBaseParts", false),
        segmentedTubePositionWithMarker: getBooleanFromUrl("segmentedTubePositionWithMarker", false),
        createViewsDebug: getBooleanFromUrl("createViewsDebug", false),
    }

    const moveControlsConfig: MoveControlsConfig = {
        viewDragMesh: getBooleanFromUrl("viewDragMesh", false),
        viewSurfaceMesh: getBooleanFromUrl("viewSurfaceMesh", false),
        viewSurfacePoints: getBooleanFromUrl("viewSurfacePoints", false),
        viewIntersectionPoints: getBooleanFromUrl("viewIntersectionPoints", false),
        stepDensity: {
            value: 8,
            min: 1,
            max: 10,
            step: 1,
        },
        debugAlignment: getBooleanFromUrl("debugAlignment", false),
        debugMeasurements: getBooleanFromUrl("debugMeasurements", false),
    }

    const AppControlsConfig: AppControlsConfig = {
        printJson: button(printJsonCallback),
        runSegmentedTubeSnapChecks: button(segmentedPartsMigrationConnectionsCallback),
        viewTubeNames: getBooleanFromUrl("viewTubeNames", false),
        showStats: getBooleanFromUrl("showStats", false),
        showGlobalAxes: getBooleanFromUrl("showGlobalAxes", false),
        useAiPanel: getBooleanFromUrl("useAiPanel", true),
        showDebugText: getBooleanFromUrl("showDebugText", true),
        raycastLayers: getBooleanFromUrl("raycastLayers", false),
    }

    const markerControls = useControls("Marker Options", markerControlsConfig, {
        collapsed: true,
    })
    const cameraControls = useControls("Camera Options", cameraControlsConfig, {
        collapsed: true,
    })
    const moveControls = useControls("Move Options", moveControlsConfig, {
        collapsed: true,
    })

    const twoDdebugging = useControls("2D Debugging", twoDdebuggingConfig, {
        collapsed: true,
    })

    const scalerDebugging = useControls("Scaler Debugging", scalerDebuggingConfig, {
        collapsed: true,
    })

    const appControls = useControls("App Options", AppControlsConfig, {
        collapsed: true,
    })

    const setControls = useSetRecoilState(controlsState)
    useEffect(() => {
        setControls({
            ...markerControls,
            ...cameraControls,
            ...moveControls,
            ...twoDdebugging,
            ...scalerDebugging,
            ...appControls,
        })
    }, [markerControls, cameraControls, moveControls, twoDdebugging, scalerDebugging, appControls, setControls,])

    return (
        <div style={{ position: "absolute", left: 10, top: 50, width: "300px", zIndex: 100, }}>
            <Leva fill hidden={!adminMode} collapsed={true} />
        </div>
    )
}

export const useLevaControls = () => {
    const controls = useRecoilValue(controlsState)
    return controls as MarkerControlsConfig
        & CameraControlsConfig
        & MoveControlsConfig
        & AppControlsConfig
        & twoDdebuggingConfig
        & scalerDebuggingConfig
}
