/* eslint-disable max-len */
/* eslint-disable max-statements */
import { AIProviderStrategy, AIStreamCallbacks, CONTEXT_PROMPT, Message } from "./AIProviderStrategy"

export class OpenRouterStrategy implements AIProviderStrategy {
    private apiKey = "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"
    private baseUrl = "https://openrouter.ai/api/v1/chat/completions"

    init(): Promise<void> {
        return Promise.resolve()
    }

    async generateResponse(
        command: string,
        currentLayout: any,
        callbacks: AIStreamCallbacks,
        screenshotUrl: string | undefined,
        model = "gpt-4o-mini"
    ) {
        console.log(command, "command being sent to open router")
        try {
            callbacks.onStart?.()

            const messages: Message[] = [
                {
                    role: "system",
                    content: CONTEXT_PROMPT,
                },
                {
                    role: "user",
                    content: [
                        {
                            type: "text",
                            text: command,
                        },
                    ],
                },
            ]

            const response = await fetch(this.baseUrl, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${this.apiKey}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    model: model,
                    messages: messages,
                    temperature: 0.2,
                    stream: false,  // Keep this false for simple response
                }),
            })

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`)
            }

            const jsonResponse = await response.json()

            const content = jsonResponse.choices[0]?.message?.content

            if (content) {
                callbacks.onToken?.(content)
            }

            callbacks.onComplete?.()
        } catch (error) {
            callbacks.onError?.(error as Error)
        }
    }
}