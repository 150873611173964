import { Vector3 } from "three"
import { PartTypeAPI } from "../../../common/api/Types"
import { GenericPartState, PartIdType, TubeMarkerEnum } from "../../utils/Types"
import { PosAndRotType } from "../types"

export const sceneDefault: SceneType = {
    parts: {},
    partsIds: [],
    connections: [],
    unit: "in",
}

export type UnitType = "cm" | "in"

export type GroupType = {
    id: string,
    name: string,
    partIds: string[],
    color: string,
}

export type SceneType = {
    parts: Record<string, GenericPartState>,
    partsIds: PartIdType[],
    connections: PartConnectionType[],
    name?: string,
    groups?: GroupType[],
    unit?: UnitType,
}

export type PartConnectionType = {
    partA: {
        partId: string,
        markerName: string,
    },
    partB: {
        partId: string,
        markerName: string,
        slidePosition?: Vector3,
    },
}

export type ConnectionOfPart = {
    partMarkerName: string,
    destinationPartId: string,
    destinationMarkerName: string,
    slidePosition?: Vector3,
}

export type ConnectedPartType = { partId: string, markerName: string, connectionLength: number, }

export type OppositePartType = {
    oppositeMarkerName: string,
    oppositeConnectedPart: { partId: string, markerName: string, },
}

export type NewPartParamsType = {
    part: PartTypeAPI,
    posAndRot: PosAndRotType,
    userRotation?: number,
    placeholderId?: string,
    connectedPart?: ConnectedPartType,
    oppositePart?: OppositePartType,
    expandReduce?: {
        length: number,
        partId: string,
    },
    originMarkerName?: TubeMarkerEnum,
    duplicatedFrom?: string,
    bulkCreated?: boolean,
    markerOffset?: Vector3,
    length?: number,
    lengthNegativeSide?: number,
    initialMarkerName?: string | undefined,
    modifiedWidth?: number,
    modifiedWidthUnits?: string,
    modifiedHeight?: number,
    modifiedHeightUnits?: string,
    realWidth?: number,
    realHeight?: number,
}
